import React, { Component } from "react";
import PropTypes from "prop-types";

import PageBanner from "Components/Common/PageBanner/PageBanner";
import MarketPlaceLayout from "Components/MarketPlaceLayout/MarketPlaceLayout";
import AppConstants from "../../Constants/AppConstants";

class MarketPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      doUpdateVaultProductList,
      navigateToSignIn,
      navigateToRegister,
      doGetProductFromVault,
      doUpdateProductToVault,
      redirectFrom,
      openRegistration,
      openSignIn,
      switchBanking,
    } = this.props;
    console.log("render", redirectFrom);
    return (
      <div className="[ marketplace-container ]">
        <PageBanner
          handleSignIn={navigateToSignIn}
          handleRegister={navigateToRegister}
          switchBanking={switchBanking}
        />
        <main className="[ marketplace__body-container ] [ container-fluid ]">
          <MarketPlaceLayout
            openRegistration={openRegistration}
            openSignIn={openSignIn}
            redirectFrom={redirectFrom}
            doUpdateVaultProductList={doUpdateVaultProductList}
            doGetProductFromVault={doGetProductFromVault}
            doUpdateProductToVault={doUpdateProductToVault}
          />
        </main>
      </div>
    );
  }
}

MarketPlace.propTypes = {
  doUpdateVaultProductList: PropTypes.func,
  navigateToSignIn: PropTypes.func,
  navigateToRegister: PropTypes.func,
  doGetProductFromVault: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  redirectFrom: PropTypes.string,
};

MarketPlace.defaultProps = {
  navigateToSignIn: () => {},
  navigateToRegister: () => {},
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  doUpdateProductToVault: () => {},
  redirectFrom: AppConstants.BANKING_TYPE.PERSONAL,
};

export default MarketPlace;
