import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { format } from "date-fns";
import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../Constants/ValidationList";

class LoanAuto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      loanDetails: {},
      loanTerms: [],
      loanPurposes: [],
      loanRepayments: [],
      loanAmount: 0,
      vehiclePrice: 0,
      downPayment: 0,
      downPaymentPercent: 0,
      loanTerm: 12,
      loanRate: 0,
      monthlyPayment: 0,
      loanScheduleDate: format(new Date(), "yyyy-MM-dd"),
      paymentType: "monthly",
      paymentTypes: [],
      loanConfig: {
        loanAmount: "",
        loanTerm: "",
        loanRate: "",
      },
      priceError: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    getDataFromServer(AppConstants.APPLICATIONSTEP.LOAN_DETAILS, (response) => {
      this.setFormData(response);
    });
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { loanProducts, products } = this.props;

    if (loanProducts !== prevProps.loanProducts) {
      this.initialize();
    }
  }

  setFormData = () => {};

  handleChange = (prop) => (event) => {
    console.log("handleChange", prop);
    this.setState({ prop: event.target.value });
    this.doCalculation();
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { vehiclePrice } = this.state;

    let error = checkErrors(vehiclePrice, VALIDATIONS.LOANS.DETAIL.VEHICLE);
    if (error.length) {
      this.setState({ priceError: error });
    } else {
      handleContinue(this.doUpdateLoanForm());
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_DETAILS);
  };

  initialize() {
    console.log("initialize");
    const { loanProducts, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);

    if (!activeProduct) {
      return;
    }
    const productId = activeProduct.productId;
    let purposes = [];
    let paymentTypes = [];
    let loanConfig = {};

    if (loanProducts) {
      loanProducts.forEach((raw, idx) => {
        if (raw.id === productId) {
          purposes = raw.purpose;
          paymentTypes = raw.paymentTypes;
          loanConfig = {
            loanAmount: raw.loanAmount,
            loanTerm: raw.loanTerm,
            loanRate: raw.loanRate,
          };
          return;
        }
      });
    }

    let loanDetails = activeProduct.loanDetails
      ? activeProduct.loanDetails
      : {};

    this.setState(
      {
        loanPurposes: purposes,
        paymentTypes: paymentTypes,
        loanConfig: loanConfig,
        vehiclePrice: loanDetails.vehiclePrice
          ? loanDetails.vehiclePrice
          : this.state.vehiclePrice,
        loanAmount: loanDetails.loanAmount
          ? loanDetails.loanAmount
          : this.state.loanAmount,
        loanTerm: loanDetails.loanTerm
          ? loanDetails.loanTerm
          : this.state.loanTerm,
        loanRate: loanDetails.loanRate
          ? loanDetails.loanRate
          : this.state.loanRate,
        monthlyPayment: loanDetails.monthlyPayment
          ? loanDetails.monthlyPayment
          : this.state.monthlyPayment,
        loanScheduleDate: loanDetails.loanScheduleDate
          ? loanDetails.loanScheduleDate
          : this.state.loanScheduleDate,
        paymentType: loanDetails.paymentType
          ? loanDetails.paymentType
          : this.state.paymentType,
        downPayment: loanDetails.downPayment
          ? loanDetails.downPayment
          : this.state.downPayment,
        downPaymentPercent: loanDetails.downPaymentPercent
          ? loanDetails.downPaymentPercent
          : this.state.downPaymentPercent,
      },
      () => {
        this.doCalculation();
      }
    );
  }

  setVehiclePrice = (amount) => {
    console.log("setVehiclePrice", amount);
    const { products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    amount = parseFloat(amount).toFixed(2);
    this.setState({ vehiclePrice: amount }, function () {
      this.doCalculation();
    });
  };

  setDownPaymentValue = (amount) => {
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    const { loanAmount } = this.state;
    const downPaymentPercent = parseFloat((amount / loanAmount) * 100).toFixed(
      2
    );
    this.setState({ downPayment: amount, downPaymentPercent }, function () {
      this.doCalculation();
    });
  };

  setDownPaymentPercentValue = (amount) => {
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    const { loanAmount } = this.state;
    const downPayment = loanAmount * (amount / 100);
    this.setState({ downPaymentPercent: amount, downPayment }, function () {
      this.doCalculation();
    });
  };

  setLoanTermValue = (term) => {
    this.setState({ loanTerm: term }, function () {
      this.doCalculation();
    });
  };

  doCalculation() {
    const {
      vehiclePrice,
      loanTerm,
      loanConfig,
      loanScheduleDate,
      paymentType,
      downPayment,
    } = this.state;
    console.log("doCalculation", paymentType, loanConfig);
    const rates = loanConfig.loanRate;
    let loanRate = 0.0;

    const loanAmount = vehiclePrice - downPayment;
    console.log("loanAmount", loanAmount);

    rates.forEach((raw, idx) => {
      const term = parseInt(loanTerm);
      const minTerm = parseInt(raw.min);
      const maxTerm = parseInt(raw.max);
      if (term >= minTerm && term <= maxTerm) {
        loanRate = raw.rate;
      }
    });

    const newRate = loanRate / 100 / 12;
    const numberOfPayments = this.getNumberOfPayments(loanTerm, paymentType);
    console.log("newRate", newRate);
    console.log("numberOfPayments", numberOfPayments);
    let monthlyPayment =
      (newRate * loanAmount * Math.pow(1 + newRate, numberOfPayments)) /
      (Math.pow(1 + newRate, numberOfPayments) - 1);
    monthlyPayment = monthlyPayment.toFixed(2);
    monthlyPayment = parseFloat(monthlyPayment);
    this.setState({
      loanRate: loanRate,
      monthlyPayment,
      loanScheduleDate: loanScheduleDate,
      loanAmount,
    });
  }

  // NOT YET CORRECT
  getRate(annualRate, paymentType, loanTerm) {
    console.log(annualRate, paymentType, loanTerm);
    switch (paymentType) {
      case "acc_biweekly":
        return (
          (annualRate / 100 / 26) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "acc_weekly":
        return (
          (annualRate / 100 / 52) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "biweekly":
        return (
          (annualRate / 100 / 26) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "monthly":
        return (annualRate / 100) * (loanTerm / 12);
      case "quarterly":
        return annualRate / 100 / 4;
      case "semiannually":
        return annualRate / 100 / 2;
      case "semimonthly":
        return annualRate / 100 / 12;
      case "weekly":
        return (
          (annualRate / 100 / 52) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "annually":
        return (annualRate / 100) * (loanTerm / 12);
      default:
        return (annualRate / 100) * (loanTerm / 12);
    }
  }

  getNumberOfPayments(loanTerm, paymentType) {
    switch (paymentType) {
      case "acc_biweekly":
        return Math.floor(loanTerm * (26 / 12));
      case "acc_weekly":
        return Math.floor(loanTerm * (52 / 12));
      case "biweekly":
        return Math.floor(loanTerm * (26 / 12));
      case "monthly":
        return loanTerm;
      case "quarterly":
        return Math.floor(loanTerm * (4 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (4 / 12));
      case "semiannually":
        return Math.floor(loanTerm * (2 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (2 / 12));
      case "semimonthly":
        return Math.floor(loanTerm * (24 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (24 / 12));
      case "weekly":
        return Math.floor(loanTerm * (52 / 12));
      case "annually":
        return Math.floor(loanTerm * (1 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (1 / 12));
      default:
        return loanTerm;
    }
  }

  doUpdateLoanForm() {
    console.log("doUpdateLoanForm");
    const {
      loanAmount,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanScheduleDate,
      paymentType,
      downPayment,
      downPaymentPercent,
      vehiclePrice,
    } = this.state;

    return {
      loanAmount: parseFloat(loanAmount),
      loanTerm: parseInt(loanTerm),
      loanRate: parseFloat(loanRate),
      monthlyPayment: parseFloat(monthlyPayment),
      loanScheduleDate: loanScheduleDate,
      paymentType: paymentType,
      downPayment,
      downPaymentPercent,
      vehiclePrice: parseFloat(vehiclePrice),
    };
  }

  commaFormatted(amount) {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  render() {
    const {
      vehiclePrice,
      loanAmount,
      downPayment,
      downPaymentPercent,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanConfig,
      priceError,
    } = this.state;
    const { steps, activeStepID } = this.props;

    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.LOAN_DETAILS.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-lg-7 ]">
              <div className="[ form-subtitle ]">
                {STRINGS.LOAN_DETAILS.SUBTITLE}
              </div>
              <div className="[ details-section__item ] [ mb-3 ]">
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div className="[ form-label ]">
                        {STRINGS.LOAN_DETAILS.FORM.AUTO.LABEL_VALUE}
                      </div>
                      <NumberFormat
                        maxLength={AppConstants.LOANS.SALARY_LENGTH}
                        type="text"
                        allowLeadingZeros={false}
                        allowNegative={false}
                        thousandSeparator
                        prefix={AppConstants.LOANS.SALARY_PREFIX}
                        name="propertyValue"
                        className="[ form-control ]"
                        placeholder={
                          STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                        }
                        onChange={(e) => this.setVehiclePrice(e.target.value)}
                        value={vehiclePrice}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      priceError.length > 0 ? "[ error-msg ]" : "[ error-msg ]"
                    }
                  >
                    {priceError ? priceError[0] : ""}
                  </div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div className="[ form-label ]">
                        {STRINGS.LOAN_DETAILS.FORM.AUTO.LABEL_DOWN_PAYMENT}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ]">
                    <div className="[ col-sm-5 ]">
                      <div className="[ form-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM.AUTO
                            .LABEL_DOWN_PAYMENT_AMOUNT
                        }
                      </div>
                      <div className="[ input-group ]">
                        <div className="[ input-group-prepend ]">
                          <span className="[ input-group-text ]">$</span>
                        </div>
                        <NumberFormat
                          allowNegative={false}
                          maxLength={AppConstants.LOANS.SALARY_LENGTH}
                          type="text"
                          thousandSeparator
                          // prefix={AppConstants.LOANS.SALARY_PREFIX}
                          name="downPayment"
                          className="[ form-control ]"
                          placeholder={
                            STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                          }
                          onChange={(e) =>
                            this.setDownPaymentValue(e.target.value)
                          }
                          value={downPayment}
                        />
                        <div className="[ input-group-append ]">
                          <span className="[ input-group-text ]">.00</span>
                        </div>
                      </div>
                      <small className="[]">
                        {STRINGS.LOAN_DETAILS.FORM.DOWNPAYMENT_AMOUNT_HINT}
                      </small>
                    </div>
                    <div className="[ col-sm-2 ] [ align-items-center ] [ d-flex ] [ justify-content-center ]">
                      <div className="[ form-label ]">&nbsp;</div>
                      <span className="[ fas fa-exchange-alt ]"></span>
                    </div>
                    <div className="[ col-sm-5 ]">
                      <div className="[ form-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM.AUTO
                            .LABEL_DOWN_PAYMENT_PERCENTAGE
                        }
                      </div>
                      <div className="[ input-group ]">
                        <NumberFormat
                          allowNegative={false}
                          maxLength={AppConstants.LOANS.SALARY_LENGTH}
                          type="text"
                          thousandSeparator
                          // prefix={AppConstants.LOANS.SALARY_PREFIX}
                          name="downPaymentPercentage"
                          className="[ form-control ]"
                          placeholder="Enter a percentage"
                          onChange={(e) =>
                            this.setDownPaymentPercentValue(e.target.value)
                          }
                          value={downPaymentPercent}
                        />
                        <div className="[ input-group-append ]">
                          <span className="[ input-group-text ]">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="[ error-msg ]"></div>
                </div>
              </div>

              <div className="[ mb-5 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.AUTO.LABEL_ESTIMATED_LOAN}
                    </div>
                    <div className="[ row ]">
                      <div className="[ col-8 offset-2 col-lg-12 offset-lg-0 ]">
                        <NumberFormat
                          disabled={true}
                          maxLength={AppConstants.LOANS.SALARY_LENGTH}
                          type="text"
                          thousandSeparator
                          prefix={AppConstants.LOANS.SALARY_PREFIX}
                          name="loanAmount"
                          className="[ form-control ] [ rate ]"
                          placeholder={
                            STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                          }
                          value={loanAmount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.AUTO.LABEL_TERM}
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ row ]">
                      <div className="[ col-8 offset-2 col-lg-3 offset-lg-0 ] [ mb-4 ]">
                        <input
                          type="text"
                          inputMode="numeric"
                          value={
                            loanTerm + " " + STRINGS.LOAN_DETAILS.FORM.MONTHS
                          }
                          className="amount-inactive"
                          disabled
                        />
                      </div>

                      <div className="[ col-12 col-lg-9 order-lg-first ]">
                        <div className="[ row ]">
                          <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ] [ disabled ]">
                            {loanConfig.loanTerm.min}{" "}
                            {STRINGS.LOAN_DETAILS.FORM.MONTH}
                          </div>
                          <div className="[ col-6 ]">
                            <RangeSlider
                              disabled={false}
                              value={loanTerm}
                              min={loanConfig.loanTerm.min}
                              max={loanConfig.loanTerm.max}
                              onChange={(e) =>
                                this.setLoanTermValue(e.target.value)
                              }
                              tooltipLabel={(currentValue) => `${currentValue}`}
                              tooltipPlacement="top"
                              tooltip="off"
                              alwaysShowTooltip={true}
                              step={6}
                              className="[ w-100 ]"
                              readOnly
                            />
                          </div>
                          <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ] [ disabled ]">
                            {loanConfig.loanTerm.max}{" "}
                            {STRINGS.LOAN_DETAILS.FORM.MONTH}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="[ mb-5 ]">
                <div className="[ form-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.AUTO.LABEL_ESTIMATED_RATE}
                </div>
                <input
                  disabled={true}
                  value={parseFloat(loanRate).toFixed(2)}
                  type="text "
                  name="interestRate"
                  className="[ form-control ] [ rate ]"
                  placeholder=""
                />
              </div>
              <div className="[ mb-3 ] [ alert alert-info ]" role="alert">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <small className="[ footnotes form-text ]">
                      {STRINGS.LOAN_DETAILS.FORM.AUTO.NOTE1}
                    </small>
                  </div>
                  <div className="[ col-12 ]">
                    <small className="[ footnotes form-text ]">
                      {STRINGS.LOAN_DETAILS.FORM.AUTO.NOTE2}
                    </small>
                  </div>
                </div>
              </div>
              <hr className="[ mb-3 ] [ d-lg-none ]" />
            </div>

            <div className="[ col-lg-5 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-subtitle ]">
                  {STRINGS.LOAN_DETAILS.FORM.AUTO.TABLE_TITLE}
                </div>
                <div className="[ details-section ] [ summary-tab ]">
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                      }
                    </div>
                    <div className="[ details-section__item-description ]">
                      $ {this.commaFormatted(loanAmount)}
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                      }
                    </div>
                    <div className="[ details-section__item-description ]">
                      {loanTerm} {STRINGS.LOAN_DETAILS.FORM.MONTHS}
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                      }
                    </div>
                    <div className="[ details-section__item-description ]">
                      {parseFloat(loanRate).toFixed(2)}%
                    </div>
                  </div>
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT1}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ details-section ] [ summary-tab ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header ] [ mb-3 ]">
                  <div className="[ col-7 ]">
                    <h3 className="[ d-flex justify-content-start ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_MONTHLY_PAYMENT
                      }
                    </h3>
                  </div>
                  <div className="[ col-5 ]">
                    <h3 className="[ d-flex justify-content-end ]">
                      ${" "}
                      {this.commaFormatted(
                        parseFloat(monthlyPayment).toFixed(2)
                      )}
                    </h3>
                  </div>
                </div>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT2}
                      </small>
                    </div>
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT3}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

LoanAuto.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  loanProducts: PropTypes.oneOfType([PropTypes.array]),
  loanProductForm: PropTypes.oneOfType(PropTypes.object),
};

LoanAuto.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  loanProducts: [],
  loanProductForm: {},
};
export default LoanAuto;
