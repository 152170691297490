import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessUpload from "./BusinessUpload";
import {
  getDocumentList,
  addDocument,
  deleteDocument,
} from "./Actions/DocumentAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import withBusinessHOC from "../../Hoc/BusinessHOC";

class BusinessUploadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      documentList: [],
    };
  }

  componentDidMount() {
    const {
      getActiveProduct,
      products,
      doGetDocumentList,
      applicantData,
      actionBack,
      handleBack,
      continuehandler,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    const applicationId = activeProduct?.applicationId;
    const productId = activeProduct?.doximProductId;
    if (
      (activeProduct?.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
        activeProduct?.category === AppConstants.LOAN_CATEGORY.LOC) &&
      activeProduct?.loanDetails.loanAmount <= activeProduct?.cutoff
    ) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_UPLOAD);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_UPLOAD);
      }
    } else {
      this.setState({
        documentList: activeProduct?.documentList ?? [],
      });
    }
  }

  handleSkip = () => {
    const { continuehandler } = this.props;
    continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_UPLOAD);
  };

  deleteDocument = (docIndex) => {
    const { documentList } = this.state;
    const {
      getActiveProduct,
      products,
      doDeleteDocument,
      doUpdateProductToVault,
      history,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    const documentId = activeProduct.documentList[docIndex].documentId;
    const tempDocumentList = [...documentList];
    tempDocumentList.splice(docIndex, 1);

    this.setState({
      documentList: tempDocumentList,
    });

    doDeleteDocument(activeProduct.applicationId, documentId, (res) => {
      console.log(res);
      console.log(activeProduct?.documentList);
      activeProduct.documentList.splice(docIndex, 1);
      products[0] = activeProduct;
      console.log(this.state.documentList);
      doUpdateProductToVault(products, () => {
        console.log("activeProduct.documentList", activeProduct?.documentList);
        this.setState({
          documentList: activeProduct?.documentList ?? [],
        });
      });
    });
  };

  addDocumentData = (document) => {
    console.log("Add Document");
    const { documentList } = this.state;
    const {
      getActiveProduct,
      products,
      doAddDocument,
      doUpdateProductToVault,
      doGetProductFromVault,
    } = this.props;
    let activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    const applicationId = activeProduct.applicationId;
    const productId = activeProduct.doximProducts[0]?.id;
    console.log(document);
    const formData = new FormData();

    formData.append("System", "product");
    formData.append("ApplicationId", applicationId);
    formData.append("ProductId", productId);
    formData.append("ProductType", "Chequing");
    formData.append("DocumentSource", document.documentSource);

    doAddDocument(applicationId, productId, formData, (res) => {
      console.log("upload response", res);
      this.setState({
        documentList: [...documentList, document],
      });
      if (Array.isArray(activeProduct.documentList)) {
        activeProduct.documentList.push({
          documentId: res.data.docId,
          documentName: document.documentName,
        });
      } else {
        activeProduct.documentList = [
          {
            documentId: res.data.docId,
            documentName: document.documentName,
          },
        ];
      }
      products[0] = activeProduct;
      console.log(this.state.documentList);
      doUpdateProductToVault(products, (res) => {
        console.log(res);
      });
    });
  };

  handleChange = () => {};

  handleContinue = (data) => {
    const { continuehandler } = this.props;
    continuehandler(data, AppConstants.APPLICATIONSTEP.BUSINESS_UPLOAD);
  };

  render() {
    console.log("render BUSINESS UPLOAD");
    const { choice, documentList } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <BusinessUpload
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          documentList={documentList}
          addDocumentData={this.addDocumentData}
          deleteDocument={this.deleteDocument}
          handleSkip={this.handleSkip}
        />
      </>
    );
  }
}

BusinessUploadContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessUploadContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantData: state.ApplicationReducer.response,
  actionBack: state.ApplicationReducer.actionBack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetDocumentList: (applicationId, productId, callback) =>
      dispatch(getDocumentList(applicationId, productId, callback)),
    doAddDocument: (applicationId, productId, payload, callback) =>
      dispatch(addDocument(applicationId, productId, payload, callback)),
    doDeleteDocument: (applicationId, documentId, callback) =>
      dispatch(deleteDocument(applicationId, documentId, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const BusinessUploadWrapper = withBusinessHOC(BusinessUploadContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessUploadWrapper);
