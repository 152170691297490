import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

// Components
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import PopupModal from "../Common/PopupModal/PopupModal";

// Constants
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";

// Styles
import { QuestionCircle } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { findNaicsCode } from "../../Utils/CommonUtilities";

class BusinessOperations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelp: false,
      isBusinessLegallyFormed: "no",
      isBusinessPhysicalLocation: "no",
      isBusinessTransact: "no",
      businessEntity: "",
      businessCode: "",
      businessCodeSelection: "",
      businessSector: "",
      businessSubSector: "",
      businessGroup: "",
      businessIndustry: "",
      businessIndustryDescription: "",
      knowNaicsCode: false,
      countryIncorporation: "",
      countryPhysicalLocation: [
        // {
        //   country: "",
        // },
      ],
      countryTransaction: [
        // {
        //   country: "",
        // },
      ],

      errors: {
        businessSectorErrorList: [],
        businessSubSectorErrorList: [],
        businessGroupErrorList: [],
        businessIndustryErrorList: [],
        businessIndustryDescriptionErrorList: [],
        countryIncorporationErrorList: [],
        countryPhysicalLocationErrorList: [],
        countryTransactionErrorList: [],
        businessEntityErrorList: [],
        knowNaicsCodeErrorList: [],
        businessCodeErrorList: [],
      },
      formIsValid: true,

      countryOptions: [
        { code: "USA", description: "USA" },
        { code: "CANADA", description: "CANADA" },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_OPERATIONS,
      (response) => {}
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { applicantData } = this.props;

    if (applicantData && applicantData !== prevProps.applicantData) {
      this.setFormData(applicantData);
    }
  }

  setFormData = (data) => {
    const { activeProduct } = this.props;
    this.setState({
      isBusinessLegallyFormed:
        activeProduct?.countryIncorporation !== "" &&
        activeProduct?.countryIncorporation !== undefined
          ? "yes"
          : "no",
      isBusinessPhysicalLocation: activeProduct?.isBusinessPhysicalLocation
        ? activeProduct.isBusinessPhysicalLocation
        : "no",
      isBusinessTransact: activeProduct?.isBusinessTransact
        ? activeProduct.isBusinessTransact
        : "no",
      businessEntity: activeProduct?.businessEntity ?? "",
      businessCode: data.business?.naicsCode,
      businessCodeSelection: data.business?.naicsCode,
      businessSector: data.business?.naicsSector,
      businessSubSector: data.business?.naicsSubSector,
      businessGroup: data.business?.naicsIndustryGroups,
      businessIndustry: data.business?.naicsIndustries,
      businessIndustryDescription: data.business?.naicsDescription,
      countryIncorporation: activeProduct?.countryIncorporation ?? "",
      countryPhysicalLocation: activeProduct?.countryPhysicalLocation ?? [],
      countryTransaction: activeProduct?.countryTransaction ?? [],
      knowNaicsCode: !data.business?.naicsSector,
    });
  };

  handleContinue = () => {
    const { handleContinue, applicantData } = this.props;
    const {
      isBusinessLegallyFormed,
      isBusinessPhysicalLocation,
      isBusinessTransact,
      businessEntity,
      knowNaicsCode,
      businessCode,
      businessSector,
      businessSubSector,
      businessGroup,
      businessIndustry,
      businessIndustryDescription,
      businessCodeSelection,
      countryIncorporation,
      countryPhysicalLocation,
      countryTransaction,
    } = this.state;

    let valid = false;
    valid = this.verifyData();
    console.log("countryIncorporation", countryIncorporation);
    if (valid) {
      let applicantComments = applicantData.applicantComments;
      if (applicantComments.search("<p>Country of incorporation:.*</p>") > -1) {
        applicantComments = applicantComments
          .split(new RegExp("<p>Country of incorporation:.+?</p>"))
          .join(
            `<p>Country of incorporation: ${
              countryIncorporation === "" ? "Canada" : countryIncorporation
            }</p>`
          );
      } else {
        applicantComments += `<p>Country of incorporation: ${
          countryIncorporation === "" ? "Canada" : countryIncorporation
        }</p>`;
      }
      if (
        applicantComments.search(
          "<p>Physical location outside of Canada:.*</p>"
        ) > -1
      ) {
        applicantComments = applicantComments
          .split(new RegExp("<p>Physical location outside of Canada:.+?</p>"))
          .join(
            `<p>Physical location outside of Canada: ${
              countryPhysicalLocation.length === 0
                ? isBusinessPhysicalLocation
                : countryPhysicalLocation.map((x) => x.country)
            }</p>`
          );
      } else {
        applicantComments += `<p>Physical location outside of Canada: ${isBusinessPhysicalLocation}</p>`;
      }
      if (
        applicantComments.search(
          "<p>Business transaction outside of Canada:.*</p>"
        ) > -1
      ) {
        applicantComments = applicantComments
          .split(
            new RegExp("<p>Business transaction outside of Canada:.+?</p>")
          )
          .join(
            `<p>Business transaction outside of Canada: ${
              countryTransaction.length === 0
                ? "no"
                : countryTransaction.map((x) => x.country)
            }</p>`
          );
      } else {
        applicantComments += `<p>Business transaction outside of Canada: ${
          countryTransaction.length === 0
            ? "no"
            : countryTransaction.map((x) => x.country)
        }</p>`;
      }
      if (applicantComments.search("<p>Active or Passive entity:.*</p>") > -1) {
        applicantComments = applicantComments
          .split(new RegExp("<p>Active or Passive entity:.+?</p>"))
          .join(`<p>Active or Passive entity: ${businessEntity}</p>`);
      } else {
        applicantComments += `<p>Active or Passive entity: ${businessEntity}</p>`;
      }
      handleContinue({
        applicant: {
          contact: {},
          applicantComments,
          business: {
            naicsCode: knowNaicsCode ? businessCode : businessCodeSelection,
            naicsSector: knowNaicsCode ? "" : businessSector,
            naicsSubSector: knowNaicsCode ? "" : businessSubSector,
            naicsIndustryGroups: knowNaicsCode ? "" : businessGroup,
            naicsIndustries: knowNaicsCode ? "" : businessIndustry,
            naicsDescription: knowNaicsCode ? "" : businessIndustryDescription,
          },
        },
        vault: {
          isBusinessLegallyFormed,
          isBusinessPhysicalLocation,
          isBusinessTransact,
          businessEntity,
          countryIncorporation,
          countryPhysicalLocation,
          countryTransaction,
        },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_OPERATIONS);
  };

  handleChange = (event) => {
    const { finInfo } = this.props;
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    switch (eventName) {
      case "businessSector":
        this.setState({
          businessSubSector: null,
          businessGroup: null,
          businessIndustry: null,
          businessIndustryDescription: null,
          businessCodeSelection: "",
        });
        break;
      case "businessSubSector":
        this.setState({
          businessGroup: null,
          businessIndustry: null,
          businessIndustryDescription: null,
          businessCodeSelection: "",
        });
        break;
      case "businessGroup":
        this.setState({
          businessIndustry: null,
          businessIndustryDescription: null,
          businessCodeSelection: "",
        });
        break;
      case "businessIndustry":
        this.setState({
          businessIndustryDescription: null,
          businessCodeSelection: "",
        });
        break;
      case "businessIndustryDescription":
        this.setState({
          businessCodeSelection:
            finInfo.config.naics.find((x) => x["_Desc"] === eventValue)?._SIC ||
            "",
          businessCode:
            finInfo.config.naics.find((x) => x["_Desc"] === eventValue)?._SIC ||
            "",
        });
        break;
    }

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  handlePhysicalLocationChange = (raw, prop) => (event) => {
    console.log("handlePhysicalLocationChange", raw, prop);
    const { countryPhysicalLocation, errors } = this.state;
    const transact = raw;
    transact[prop] = event.currentTarget.value;
    this.setState({
      countryPhysicalLocation,
      errors: {
        ...errors,
        countryPhysicalLocationErrorList: "",
      },
    });
  };

  handleTransactChange = (raw, prop) => (event) => {
    console.log("handleTransactChange", raw, prop);
    const { countryTransaction, errors } = this.state;
    const transact = raw;
    transact[prop] = event.currentTarget.value;
    this.setState({
      countryTransaction,
      errors: {
        ...errors,
        countryTransactionErrorList: "",
      },
    });
  };

  handleBusinessFormedChoice = (value) => {
    this.setState({
      isBusinessLegallyFormed: value,
      countryIncorporation:
        value === "no" ? "" : this.state.countryIncorporation,
    });
  };
  handlePhysicalLocationChoice = (value) => {
    if (value === "no") {
      this.setState({
        isBusinessPhysicalLocation: value,
        countryPhysicalLocation: [],
      });
    } else {
      this.setState({
        isBusinessPhysicalLocation: value,
      });
    }
  };
  handleBusinessTransactChoice = (value) => {
    if (value === "no") {
      this.setState({
        isBusinessTransact: value,
        countryTransaction: [],
      });
    } else {
      this.setState({
        isBusinessTransact: value,
      });
    }
  };

  handleBusinessEntityChoice = (value) => {
    const { errors } = this.state;
    this.setState({
      businessEntity: value,
      errors: {
        ...errors,
        businessEntityErrorList: "",
      },
    });
  };

  handleNAICSChoice = (value) => {
    console.log("handleNAICSChoice", value);
    const { errors, knowNaicsCode } = this.state;
    if (knowNaicsCode !== value) {
      this.setState({
        knowNaicsCode: value,
        errors: {
          ...errors,
          knowNaicsCodeErrorList: "",
        },
      });
    }
  };

  /* Start Functions For Business Physical Location Country List*/

  handleAddPhysicalLocationCountry = (event) => {
    console.log("handleAddPhysicalLocationCountry");
    if (this.validatePhysicalLocationCountry()) {
      const country = {
        country: "",
      };
      const action = {
        type: "add",
        country: country,
      };
      this.updatePhysicalLocationCountryList(action);
    }
  };

  handleDeletePhysicalLocationCountry = (idx, transact) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updatePhysicalLocationCountryList(action);
  };

  validatePhysicalLocationCountry = () => {
    let valid = true;
    const {
      isBusinessPhysicalLocation,
      countryPhysicalLocation,
      errors,
    } = this.state;

    if (
      countryPhysicalLocation.length > 0 &&
      isBusinessPhysicalLocation === "yes"
    ) {
      let error = checkErrors(
        countryPhysicalLocation[countryPhysicalLocation.length - 1].country,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_PHYSICAL_LOCATION
      );

      if (error.length) {
        valid = false;
        this.setState({
          errors: {
            ...errors,
            countryPhysicalLocationErrorList: error,
          },
        });
      }
    }

    return valid;
  };

  updatePhysicalLocationCountryList = (action) => {
    const { countryPhysicalLocation, errors } = this.state;
    if (action.type === "add") {
      console.log(action);
      countryPhysicalLocation.push(action.country);
    } else if (action.type === "remove") {
      if (action.index === countryPhysicalLocation.length - 1) {
        this.setState({
          errors: {
            ...errors,
            countryPhysicalLocationErrorList: [],
          },
        });
      }
      if (action.index > -1) {
        console.log(action);
        countryPhysicalLocation.splice(action.index, 1);
      }
    }
    this.setState({ countryPhysicalLocation });
    if (countryPhysicalLocation.length === 0) {
      // const { handleChange } = this.props;
      // handleChange(false);
    }
  };
  /* END Functions For Business Physical Location Country list */

  /* Start Functions For Business Transaction Country List*/
  handleAddTransactCountry = (event) => {
    console.log("handleAddTransactCountry");
    if (this.validateTransactCountry()) {
      const transCountry = {
        country: "",
      };
      const action = {
        type: "add",
        transCountry: transCountry,
      };
      this.updateTransactionCountryList(action);
    }
  };

  handleDeleteTransactCountry = (idx, transact) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateTransactionCountryList(action);
  };

  validateTransactCountry = () => {
    let valid = true;
    const { countryTransaction, isBusinessTransact, errors } = this.state;

    if (countryTransaction.length > 0 && isBusinessTransact === "yes") {
      let error = checkErrors(
        countryTransaction[countryTransaction.length - 1].country,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_TRANSACT
      );

      if (error.length) {
        valid = false;
        this.setState({
          errors: {
            ...errors,
            countryTransactionErrorList: error,
          },
        });
      }
    }

    return valid;
  };

  updateTransactionCountryList = (action) => {
    const { countryTransaction, errors } = this.state;
    if (action.type === "add") {
      console.log(action);
      countryTransaction.push(action.transCountry);
    } else if (action.type === "remove") {
      if (action.index === countryTransaction.length - 1) {
        this.setState({
          errors: {
            ...errors,
            countryTransactionErrorList: [],
          },
        });
      }
      if (action.index > -1) {
        console.log(action);
        countryTransaction.splice(action.index, 1);
      }
    }
    this.setState({ countryTransaction });
    if (countryTransaction.length === 0) {
      // const { handleChange } = this.props;
      // handleChange(false);
    }
  };

  /* END Functions For Business Transaction */

  verifyData = () => {
    let formIsValid = true;
    const {
      businessCode,
      knowNaicsCode,
      businessEntity,
      businessSector,
      businessSubSector,
      businessGroup,
      businessIndustry,
      businessIndustryDescription,
      countryIncorporation,
      countryPhysicalLocation,
      countryTransaction,
      isBusinessLegallyFormed,
      isBusinessPhysicalLocation,
      isBusinessTransact,
      errors,
    } = this.state;
    const { finInfo } = this.props;

    const entityRes = checkErrors(
      businessEntity,
      VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_ENTITY
    );

    let codeRes = [];
    if (knowNaicsCode) {
      const naics = finInfo.config?.naics.find((x) => x._SIC === businessCode);
      if (!naics) {
        codeRes = ["Invalid NAICS code."];
      }
    }

    let sectorRes = [];
    let subSectorRes = [];
    let groupRes = [];
    let industryRes = [];
    let industryDescRes = [];

    if (!knowNaicsCode) {
      sectorRes = checkErrors(
        businessSector,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_SECTOR
      );
      subSectorRes = checkErrors(
        businessSubSector,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_SUB_SECTOR
      );
      groupRes = checkErrors(
        businessGroup,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_GROUP
      );
      industryRes = checkErrors(
        businessIndustry,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_INDUSTRY
      );
      industryDescRes = checkErrors(
        businessIndustryDescription,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_INDUSTRY_DESCRIPTION
      );
    }

    let countryIncorporationRes = [];
    let countryTransactionRes = [];
    let countryPhysicalLocationRes = [];

    if (isBusinessLegallyFormed === "yes")
      countryIncorporationRes = checkErrors(
        countryIncorporation,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_COUNTRY_INCORPORATION
      );

    if (countryTransaction.length > 0 && isBusinessTransact === "yes")
      countryTransactionRes = checkErrors(
        countryTransaction[countryTransaction.length - 1].country,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_TRANSACT
      );

    if (
      countryPhysicalLocation.length > 0 &&
      isBusinessPhysicalLocation === "yes"
    )
      countryPhysicalLocationRes = checkErrors(
        countryPhysicalLocation[countryPhysicalLocation.length - 1].country,
        VALIDATIONS.BUSINESS_OPERATIONS.BUSINESS_PHYSICAL_LOCATION
      );

    this.setState({
      errors: {
        ...errors,
        businessCodeErrorList: codeRes,
        businessSectorErrorList: sectorRes,
        businessSubSectorErrorList: subSectorRes,
        businessGroupErrorList: groupRes,
        businessIndustryErrorList: industryRes,
        businessIndustryDescriptionErrorList: industryDescRes,
        countryIncorporationErrorList: countryIncorporationRes,
        countryTransactionErrorList: countryTransactionRes,
        countryPhysicalLocationErrorList: countryPhysicalLocationRes,
        businessEntityErrorList: entityRes,
      },
    });

    if (
      codeRes.length > 0 ||
      sectorRes.length > 0 ||
      subSectorRes.length > 0 ||
      groupRes.length > 0 ||
      industryRes.length > 0 ||
      industryDescRes.length > 0 ||
      entityRes.length > 0 ||
      countryIncorporationRes.length > 0 ||
      countryTransactionRes.length > 0 ||
      countryPhysicalLocationRes.length > 0
    ) {
      formIsValid = false;
    }
    return formIsValid;
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const { steps, activeStepID, finInfo } = this.props;
    const {
      isBusinessLegallyFormed,
      countryIncorporation,

      isBusinessPhysicalLocation,
      countryPhysicalLocation,

      isBusinessTransact,
      countryTransaction,

      businessEntity,
      tradeName,
      businessSector,
      businessSubSector,
      businessGroup,
      businessIndustry,
      businessIndustryDescription,
      showHelp,
      helpText,
      errors,
      countryOptions,
      knowNaicsCode,
      businessCode,
      businessCodeSelection,
    } = this.state;

    const countryListComponent = countryOptions.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const countryIncorporationComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_INCORPORATION}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_INCORPORATION_LABEL}
            </div>
            <select
              value={countryIncorporation}
              name={"countryIncorporation"}
              className={
                errors?.countryIncorporationErrorList &&
                errors?.countryIncorporationErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handleChange}
              id={"countryIncorporation"}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {countryListComponent}
            </select>
            <div className="[ error-msg ]">
              {errors?.countryIncorporationErrorList[0]}
            </div>
          </div>
        </div>
      </div>
    );

    {
      /* Country Physical Location Component */
    }

    const countryPhysicalLocationListComponent = countryPhysicalLocation.map(
      (location, index) => (
        <>
          <div className="[ mb-3 mt-3 ]">
            <div className="[ divider ]" />
            <div className="[ info-title ]">
              {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY} {index + 1}
              <button
                type="button"
                className="[ fas fa-trash ]"
                onClick={() =>
                  this.handleDeletePhysicalLocationCountry(index, location)
                }
                aria-label="remove"
              >
                <span className="[ sr-only ]">
                  {STRINGS.BUSINESS_OPERATIONS.FORM.REMOVE_BTN}
                </span>
              </button>
            </div>

            <div className="[ form-label ]">
              {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_LABEL}
            </div>

            <select
              value={location?.country}
              name={"country"}
              className={
                errors?.countryPhysicalLocationErrorList &&
                errors?.countryPhysicalLocationErrorList.length > 0 &&
                countryPhysicalLocation.length === index + 1
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handlePhysicalLocationChange(location, "country")}
              id={"country"}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {countryListComponent}
            </select>

            <div className="[ error-msg ]">
              {countryPhysicalLocation.length === index + 1 &&
                errors?.countryPhysicalLocationErrorList[0]}
            </div>
          </div>
        </>
      )
    );

    const countryPhysicalLocationComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.PHYSICAL_LOCATION}
          </div>

          <div className="[ info-message  ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.PHYSICAL_LOCATION_LABEL}
          </div>

          {countryPhysicalLocationListComponent}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                  onClick={(event) =>
                    this.handleAddPhysicalLocationCountry(event)
                  }
                >
                  <span className="[ fas fa-plus ]" />
                  {STRINGS.BUSINESS_OPERATIONS.FORM.ADD_BTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const countryTransactListComponent = countryTransaction.map(
      (transaction, index) => (
        <>
          <div className="[ mb-3 mt-3 ]">
            <div className="[ divider ]" />
            <div className="[ info-title ]">
              {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY} {index + 1}
              <button
                type="button"
                className="[ fas fa-trash ]"
                onClick={() =>
                  this.handleDeleteTransactCountry(index, transaction)
                }
                aria-label="remove"
              >
                <span className="[ sr-only ]">
                  {STRINGS.INCOME.FORM.REMOVE_INCOME}
                </span>
              </button>
            </div>

            <div className="[ form-label ]">
              {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_LABEL}
            </div>

            <select
              value={transaction?.country}
              name={"country"}
              className={
                errors?.countryTransactionErrorList &&
                errors?.countryTransactionErrorList.length > 0 &&
                countryTransaction.length === index + 1
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.handleTransactChange(transaction, "country")}
              id={"country"}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {countryListComponent}
            </select>

            <div className="[ error-msg ]">
              {countryTransaction.length === index + 1 &&
                errors?.countryTransactionErrorList[0]}
            </div>
          </div>
        </>
      )
    );

    {
      /* Country Transact Component */
    }
    const countryTransactComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_TRANSACTIONS}
          </div>

          <div className="[ info-message  ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.COUNTRY_TRANSACTIONS_LABEL}
          </div>

          {countryTransactListComponent}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                  onClick={(event) => this.handleAddTransactCountry(event)}
                >
                  <span className="[ fas fa-plus ]" />
                  {STRINGS.BUSINESS_OPERATIONS.FORM.ADD_BTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    {
      /* NAICS CODE */
    }

    const selectionsComponent = (
      <>
        {/* Business Sector */}
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.BUSINESS_SECTOR_LABEL}
          </div>
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <select
                value={businessSector}
                name={"businessSector"}
                className={
                  errors?.businessSectorErrorList &&
                  errors?.businessSectorErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleChange}
                id={"businessSector"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {findNaicsCode(finInfo.config?.naics, "_Sector").map((data) => (
                  <option
                    className="[ dropdown__option ]"
                    value={data}
                    key={data}
                  >
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="[ error-msg ]">
            {errors?.businessSectorErrorList[0]}
          </div>
        </div>

        {/* Business Sub-Sector */}
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.BUSINESS_SUB_SECTOR_LABEL}
          </div>
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <select
                disabled={!businessSector}
                value={businessSubSector}
                name={"businessSubSector"}
                className={
                  errors?.businessSubSectorErrorList &&
                  errors?.businessSubSectorErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleChange}
                id={"businessSubSector"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {findNaicsCode(
                  finInfo.config?.naics,
                  "_SubSector",
                  "_Sector",
                  businessSector
                ).map((data) => (
                  <option
                    className="[ dropdown__option ]"
                    value={data}
                    key={data}
                  >
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="[ error-msg ]">
            {errors?.businessSubSectorErrorList[0]}
          </div>
        </div>

        {/* Business Group */}
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.BUSINESS_GROUP_LABEL}
          </div>
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <select
                disabled={!businessSubSector}
                value={businessGroup}
                name={"businessGroup"}
                className={
                  errors?.businessGroupErrorList &&
                  errors?.businessGroupErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleChange}
                id={"businessGroup"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {findNaicsCode(
                  finInfo.config?.naics,
                  "_IndustryGroup",
                  "_SubSector",
                  businessSubSector
                ).map((data) => (
                  <option
                    className="[ dropdown__option ]"
                    value={data}
                    key={data}
                  >
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="[ error-msg ]">
            {errors?.businessGroupErrorList[0]}
          </div>
        </div>

        {/* Business Industry */}
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.BUSINESS_INDUSTRY_LABEL}
          </div>
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <select
                disabled={!businessGroup}
                value={businessIndustry}
                name={"businessIndustry"}
                className={
                  errors?.businessIndustryErrorList &&
                  errors?.businessIndustryErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleChange}
                id={"businessIndustry"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {findNaicsCode(
                  finInfo.config?.naics,
                  "_Industries",
                  "_IndustryGroup",
                  businessGroup
                ).map((data) => (
                  <option
                    className="[ dropdown__option ]"
                    value={data}
                    key={data}
                  >
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="[ error-msg ]">
            {errors?.businessIndustryErrorList[0]}
          </div>
        </div>

        {/* Business Industry Description */}
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.BUSINESS_DESCRIBE_INDUSTRY_LABEL}
          </div>
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <select
                disabled={!businessIndustry}
                value={businessIndustryDescription}
                name={"businessIndustryDescription"}
                className={
                  errors?.businessIndustryDescriptionErrorList &&
                  errors?.businessIndustryDescriptionErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.handleChange}
                id={"businessIndustryDescription"}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {findNaicsCode(
                  finInfo.config?.naics,
                  "_Desc",
                  "_Industries",
                  businessIndustry
                ).map((data) => (
                  <option
                    className="[ dropdown__option ]"
                    value={data}
                    key={data}
                  >
                    {data}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="[ error-msg ]">
            {errors?.businessIndustryDescriptionErrorList[0]}
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE_FOUND_FORM_LABEL}
          </div>
          <input
            value={businessCodeSelection}
            name={"businessCodeSelection"}
            className={
              errors?.businessCodeSelectionErrorList &&
              errors?.businessCodeSelectionErrorList.length > 0
                ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                : "[ form-control ] [ select__dropdown ]"
            }
            maxLength={6}
            id={"businessCodeSelection"}
            disabled
          />
          <div>
            <small>
              {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE_FOUND_HINT_TEXT}
            </small>
          </div>
          <div className="[ error-msg ]">
            {errors?.businessCodeSelectionErrorList}
          </div>
        </div>
      </>
    );

    const actionComponent = (
      <div className="[ property-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_CONTACT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              {/* Business Formed */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {
                    STRINGS.BUSINESS_OPERATIONS.FORM
                      .WAS_BUSINESS_LEGALLY_FORMED_LABEL
                  }
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessLegallyFormed === "yes"
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isBusinessLegallyFormed"
                      onClick={(event) =>
                        this.handleBusinessFormedChoice("yes", event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessLegallyFormed === "no"
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isBusinessLegallyFormed"
                      onClick={(event) =>
                        this.handleBusinessFormedChoice("no", event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isBusinessLegallyFormed === "yes" &&
                countryIncorporationComponent}

              {/* Physical Location */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {
                    STRINGS.BUSINESS_OPERATIONS.FORM
                      .DOES_BUSINESS_PHYSICAL_LOCATION_LABEL
                  }
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessPhysicalLocation === "yes"
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isBusinessPhysicalLocation"
                      onClick={(event) =>
                        this.handlePhysicalLocationChoice("yes", event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessPhysicalLocation === "no"
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isBusinessPhysicalLocation"
                      onClick={(event) =>
                        this.handlePhysicalLocationChoice("no", event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isBusinessPhysicalLocation === "yes" &&
                countryPhysicalLocationComponent}

              {/* Business Formed */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {
                    STRINGS.BUSINESS_OPERATIONS.FORM
                      .DOES_BUSINESS_TRANSACT_LABEL
                  }
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessTransact === "yes"
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isBusinessTransact"
                      onClick={(event) =>
                        this.handleBusinessTransactChoice("yes", event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isBusinessTransact === "no"
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isBusinessTransact"
                      onClick={(event) =>
                        this.handleBusinessTransactChoice("no", event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isBusinessTransact === "yes" && countryTransactComponent}

              {/* Active or Passive */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_OPERATIONS.FORM.BUSINESS_ACTIVE_LABEL}
                  <QuestionCircle
                    className="question-icon"
                    size={20}
                    onClick={() =>
                      this.handleOpenHelp(
                        STRINGS.BUSINESS_OPERATIONS.HELP.ACTIVE_VS_PASSIVE
                      )
                    }
                  />
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        businessEntity === "active"
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="businessEntity"
                      onClick={(event) =>
                        this.handleBusinessEntityChoice("active", event)
                      }
                    >
                      {STRINGS.BUSINESS_OPERATIONS.FORM.ACTIVE_BTN}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        businessEntity === "passive"
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="businessEntity"
                      onClick={(event) =>
                        this.handleBusinessEntityChoice("passive", event)
                      }
                    >
                      {STRINGS.BUSINESS_OPERATIONS.FORM.PASSIVE_BTN}
                    </button>
                  </div>
                </div>

                <div className="[ error-msg ]">
                  {errors?.businessEntityErrorList[0]}
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE_LABEL}
                  <QuestionCircle
                    className="question-icon"
                    size={20}
                    onClick={() =>
                      this.handleOpenHelp(
                        STRINGS.BUSINESS_OPERATIONS.HELP.NAICS_CODE
                      )
                    }
                  />
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      name="knowNaicsCode"
                      className={
                        knowNaicsCode
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      onClick={(event) => this.handleNAICSChoice(true, event)}
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      name=""
                      className={
                        !knowNaicsCode
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      onClick={(event) => this.handleNAICSChoice(false, event)}
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {knowNaicsCode && (
                <div className="[ mb-3 ]">
                  <div className="[ drawer-content-container ] [ mb-3 ]">
                    <div className="[ info-title ]">
                      {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE}
                    </div>
                    <div className="[ mb-3 ]">
                      <div className="[ form-label ]">
                        {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE_FORM_LABEL}
                      </div>
                      <input
                        value={businessCode}
                        name={"businessCode"}
                        className={
                          errors?.businessCodeErrorList &&
                          errors?.businessCodeErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                            : "[ form-control ] [ select__dropdown ]"
                        }
                        onChange={this.handleChange}
                        maxLength={6}
                        id={"businessCode"}
                      />
                      <div>
                        <small>
                          {
                            STRINGS.BUSINESS_OPERATIONS.FORM
                              .NAICS_CODE_HINT_TEXT
                          }
                        </small>
                      </div>
                      <div className="[ error-msg ]">
                        {errors?.businessCodeErrorList}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!knowNaicsCode && (
                <div className="[ mb-3 ]">
                  <div className="[ drawer-content-container ] [ mb-3 ]">
                    <div className="[ info-title ]">
                      {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE}
                    </div>
                    <p className="[ info-message ]">
                      {STRINGS.BUSINESS_OPERATIONS.FORM.NAICS_CODE_DESCRIPTION}
                    </p>
                    <div className="[ mb-3 ]">{selectionsComponent}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

BusinessOperations.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessOperations.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessOperations;
