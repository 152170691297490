import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  addBundleResponse: [],
  deleteBundleResponse: [],
  success: "",
  error: "",
  isDebitCardEnabled: true,
  debitCardShown: false,
};

const DebitCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BUNDLE_PRODUCT_SUCCESS:
      return {
        ...state,
        success: true,
        error: "",
        enableDebitCard: true,
        addBundleData: action.response.data,
      };
    case actionTypes.ADD_BUNDLE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        addBundleResponse: [],
      };
    case actionTypes.DELETE_DEBIT_CARD_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        enableDebitCard: false,
        deleteBundleResponse: action.response.data,
      };
    case actionTypes.DELETE_DEBIT_CARD_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        deleteBundleResponse: [],
      };
    case actionTypes.STORE_DEBIT_CARD:
      return {
        ...state,
        isDebitCardEnabled: action.data.enableDebitCard,
        applicationId: action.data.applicationId,
      };
    case actionTypes.DEBIT_CARD_SHOWN:
      return {
        ...state,
        debitCardShown: action.data,
      };
    default:
      return state;
  }
};

export default DebitCardReducer;
