import { call, put, takeLatest } from "redux-saga/effects";
import GetApplicantType from "Communication/ApiClasses/GetApplicantType";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleGetApplicantType(action) {
  console.log("handleGetApplicantType");
  yield put(doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.GET_APPLICANT_TYPE));
  const getApplicantType = new GetApplicantType();
  try {
    const result = yield call(getApplicantType.getData);
    console.log(result);
    yield put({
      type: actionTypes.GET_APPLICANT_TYPE_SUCCESS,
      applicantTypes: result.data,
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPLICANT_TYPE_ERROR,
      error,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.GET_APPLICANT_TYPE)
    );
  }
}

// watcher
export default function* getApplicantTypeWatcherSaga() {
  yield takeLatest(actionTypes.GET_APPLICANT_TYPE, handleGetApplicantType);
}
