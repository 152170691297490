import * as actionTypes from "Redux/Actions/actionTypes";

export const updateProductInformation = (
  productData,
  applicationId,
  productType,
  doximProductId,
  callback = () => {}
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_INFORMATION,
    data: productData,
    applicationId,
    productType,
    doximProductId,
    callback,
  };
};

export const getProductInformation = (
  applicationId,
  productType,
  doximProductId,
  callback = () => {}
) => {
  return {
    type: actionTypes.GET_PRODUCT_INFORMATION,
    applicationId,
    productType,
    doximProductId,
    callback,
  };
};
