import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetPasswordCriteriaRequest from "Communication/ApiClasses/GetPasswordCriteriaRequest";
import SetPasswordRequest from "Communication/ApiClasses/SetPasswordRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetPasswordCriteria() {
  yield put(doLoadingStart(STRINGS.SETPASSWORD.API_KEYS.GET_PASSWORD));
  const getPasswordCriteriaAPI = new GetPasswordCriteriaRequest();
  try {
    const result = yield call(getPasswordCriteriaAPI.getData);
    yield put({
      type: actionTypes.GET_PASSWORD_CRITERIA_RESPONSE,
      success: result,
    });
  } catch (e) {
    yield put({
      type: actionTypes.GET_PASSWORD_CRITERIA_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.SETPASSWORD.API_KEYS.GET_PASSWORD));
  }
}

// watcher
export function* getPasswordCriteriaWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_PASSWORD_CRITERIA_REQUEST,
    handleGetPasswordCriteria
  );
}

// worker for update password
function* handleUpdatePassword(action) {
  yield put(doLoadingStart(STRINGS.SETPASSWORD.API_KEYS.SET_PASSWORD));
  const setPasswordRequestAPI = new SetPasswordRequest(action.data);
  try {
    const result = yield call(setPasswordRequestAPI.getData);
    yield put({
      type: actionTypes.UPDATE_PASSWORD_RESPONSE,
      success: result,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_PASSWORD_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.SETPASSWORD.API_KEYS.SET_PASSWORD));
  }
}

// watcher
export function* handleUpdatePasswordaWatcherSaga() {
  yield takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, handleUpdatePassword);
}
