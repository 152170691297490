import React, { Component } from "react";
import PropTypes from "prop-types";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";
import OverdraftProtection from "./OverdraftProtection";
import { connect } from "react-redux";

class OverdraftProtectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submitRequest = (body) => {
    const { continuehandler } = this.props;
    continuehandler(body, AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION);
  };

  render() {
    const {
      history,
      showVault,
      getDataFromServer,
      steps,
      activeStepID,
      handleBack,
      vaultProductList,
      getActiveProduct,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(vaultProductList);

    return (
      <OverdraftProtection
        showVault={showVault}
        history={history}
        submitRequest={this.submitRequest}
        getDataFromServer={getDataFromServer}
        steps={steps}
        activeStepID={activeStepID}
        handleBack={() =>
          handleBack(AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION)
        }
        activeProduct={activeProduct}
        finInfo={finInfo}
      />
    );
  }
}

OverdraftProtectionContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  showVault: PropTypes.bool,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
  vaultProductList: PropTypes.arrayOf(PropTypes.object).isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  finInfo: PropTypes.arrayOf(PropTypes.object),
};

OverdraftProtectionContainer.defaultProps = {
  history: {},
  showVault: false,
  steps: [],
  activeStepID: -1,
  finInfo: {},
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  finInfo: state.MarketplaceReducer.finInfo,
});

const OverdraftProtectionWrapper = withApplicantHOC(
  OverdraftProtectionContainer
);

export default connect(mapStateToProps)(OverdraftProtectionWrapper);
