import React from "react";
import PropTypes from "prop-types";
import Vault from "Components/Vault/Vault";
import InfoBanner from "Components/Common/InfoBanner/InfoBanner";
import NavigationBar from "Components/Common/NavigationBar/NavigationBar";
import ScreenHeader from "Components/Common/ScreenHeader/ScreenHeader";
import "./AuthBody.scss";
import FundingReviewWindow from "../FundingReviewWindow/FundingReviewWindow";

const AuthBody = (props) => {
  const {
    securityText,
    handleBack,
    actionComponent,
    infoComponent,
    renderInfoComponent,
    memberCreation,
    showFundAccount,
    fundMethod,
    fundAmount,
    productName,
    showNavigationBar,
    infoType,
    showProceedBtn,
    blockBack,
  } = props;
  let componentBody = renderInfoComponent ? (
    <>
      <div className="[ col-xl-6 col-lg-12 ] [ main-container__body__left-column ][ tri-column ]">
        {actionComponent}
      </div>
      <div className="[ col-xl-3 col-lg-12 ] [ main-container__body__middle-column ]">
        {infoComponent}
      </div>
    </>
  ) : (
    <>
      <div className="[ col-xl-6 col-lg-12 ] [ main-container__body__left-column ][ tri-column ]">
        {actionComponent}
      </div>
      <div className="[ col-xl-3 col-lg-12 ] [ main-container__body__middle-column ]" />
    </>
  );

  if (memberCreation) {
    componentBody = (
      <div className="[ col-xl-9 col-lg-12 ] [ main-container__body__left-column ] [ bi-column ]">
        {actionComponent}
      </div>
    );
  }
  return (
    <div className="[ content-wrapper ]">
      <InfoBanner infoType={infoType} infoText={securityText} />
      <NavigationBar
        handleBack={handleBack}
        show={showNavigationBar}
        blockBack={blockBack}
      />
      <ScreenHeader />
      <div className="[ container ] [ pb-3 ] [ main-container ]">
        <div className="[ row ] [ main-container__body ]">
          {componentBody}
          <div className="[ col-xl-3 col-lg-12 ] [ d-none d-xl-block ] [ main-container__body__right-column ]">
            {showFundAccount ? (
              <FundingReviewWindow
                fundMethod={fundMethod}
                fundAmount={fundAmount}
                productName={productName}
              />
            ) : (
              <Vault showProceedBtn={showProceedBtn} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AuthBody.propTypes = {
  securityText: PropTypes.string,
  handleBack: PropTypes.func,
  actionComponent: PropTypes.element.isRequired,
  infoComponent: PropTypes.element,
  renderInfoComponent: PropTypes.bool,
  memberCreation: PropTypes.bool,
  showFundAccount: PropTypes.bool,
  fundMethod: PropTypes.string,
  fundAmount: PropTypes.string,
  productName: PropTypes.string,
  showNavigationBar: PropTypes.bool,
  showProceedBtn: PropTypes.bool,
};
AuthBody.defaultProps = {
  handleBack: () => {},
  securityText: "",
  infoComponent: <div />,
  renderInfoComponent: true,
  memberCreation: false,
  showFundAccount: false,
  fundMethod: "-",
  fundAmount: "-",
  productName: "",
  showNavigationBar: true,
  showProceedBtn: false,
};
export default AuthBody;
