import * as actionTypes from "Redux/Actions/actionTypes";
import STRINGS from "Constants/Strings";

const initialState = {
  method: "",
  amount: "",
};
const FundReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUND_METHOD:
      return {
        ...state,
        method: action.data,
      };
    case actionTypes.FUND_AMOUNT:
      return {
        ...state,
        amount: action.data,
      };
    case actionTypes.EROR_FUND_PAYMENT_DETAILS:
      return {
        ...state,
        error: STRINGS.FUND_ACCOUNT.FIINFO_ERROR_MSG,
        success: "",
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default FundReducer;
