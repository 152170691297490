import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ROUTES from "Constants/Routes";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "../../Constants/AppConstants";

import withApplicantHOC from "../../Hoc/ApplicantHOC/ApplicantHOC";
import JointApplicationStatus from "./JointApplicationStatus";
import { HTTP_STATUS } from "../../Communication/Constants";
import { getProductFromVault } from "../MarketPlace/Action/MarketPlaceAction";
import {
  getApplicantType,
  getApplicationProperty,
  deleteApplicationSlot,
  addGlobalVault,
  getGlobalVault,
  setInSessionJointApplicant,
} from "./Actions/JointApplicantAction";
import { getCurrentStepIndexFromTemplateFlowByStep } from "../../Utils/CommonUtilities";

class JointApplicantStatusContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      errorCode: null,
      applicationId: "",
      appProperties: [],
      outOfSessionPending: false,
      inSessionPending: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      doGetApplicantType,
      doGetApplicationProperty,
      continuehandler,
      applicantTypes,
      products,
      doSetInSessionJointApplicant,
      getActiveProduct,
      inSessionJointApplicant,
    } = this.props;

    if (inSessionJointApplicant) {
      continuehandler(null, AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
    } else {
      if (!applicantTypes || applicantTypes.length === 0) {
        console.log("getting applicant types");
        doGetApplicantType();
      }
      const activeProduct = getActiveProduct(products);
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (res) => {
          this.setState({
            appProperties: res.slots,
          });
          if (res.slots.length === 1) {
            continuehandler(
              "",
              AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
            );
          } else {
            const pendingSlot = res.slots.find((slot) => !slot.appliedAt);
            if (pendingSlot) {
              if (pendingSlot.extra.inSession) {
                console.log("pendingSlot", pendingSlot);
                doSetInSessionJointApplicant(pendingSlot);
                this.setState({
                  inSessionPending: true,
                  showModal: true,
                  modalType: AppConstants.MODALTYPE.INFORMATION,
                  modal: {
                    title:
                      MODALMESSAGES.JOINT_APPLICANT_STATUS.PENDING_OS_TITLE,
                    description: MODALMESSAGES.JOINT_APPLICANT_STATUS.JOINT_START_IN_SESSION.replace(
                      "_EMAIL_",
                      pendingSlot.extra.email
                    ),
                  },
                });
              } else {
                this.setState({
                  outOfSessionPending: true,
                });
              }
            }
          }
        }
      );
    }
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  handleDeleteApplicant = (slotId, idx, mobileNo) => {
    const {
      doDeleteApplicationSlot,
      doGetApplicationProperty,
      doGetGlobalVault,
      products,
      getActiveProduct,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    if (slotId) {
      doDeleteApplicationSlot(
        {
          // eslint-disable-next-line object-shorthand
          applicationId: activeProduct.applicationId,
          // eslint-disable-next-line object-shorthand
          slotId: slotId,
        },
        (res) => {
          if (res && res.status === HTTP_STATUS.OK) {
            doGetApplicationProperty({
              applicationId: activeProduct.applicationId,
            });
            // this.updateApplicant({
            //   type: "remove",
            //   index: idx,
            // });
          }
        }
      );
    }
  };

  handleContinue = () => {
    const {
      continuehandler,
      products,
      getActiveProduct,
      doGetApplicationProperty,
      doSetInSessionJointApplicant,
      processTemplate,
    } = this.props;
    // init flags
    this.setState({
      inSessionPending: false,
      outOfSessionPending: false,
    });
    const activeProduct = getActiveProduct(products);
    doGetApplicationProperty(
      {
        applicationId: activeProduct.applicationId,
        delayTime: 0,
      },
      (res) => {
        if (res.inReview || res.uploadedAt) {
          const currentStepIndex = getCurrentStepIndexFromTemplateFlowByStep(
            processTemplate,
            AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
          );
          const nextStepIsSignature =
            processTemplate.flow[currentStepIndex + 1]?.componentName ===
            AppConstants.APPLICATIONSTEP.SIGNATURE;
          console.log("nextStepIsSignature", nextStepIsSignature);
          if (nextStepIsSignature) {
            this.handlePopupModalBtnClick();
          } else if (res.inReview) {
            // since application is ending here we need to show the in review message
            this.setState({
              showModal: true,
              modalType: AppConstants.MODALTYPE.SUCCESS,
              modal: {
                title: MODALMESSAGES.ON_REVIEW.TITLE,
                description: MODALMESSAGES.ON_REVIEW.MSG,
              },
            });
          } else {
            // application already uploaded
            this.setState({
              showModal: true,
              modalType: AppConstants.MODALTYPE.SUCCESS,
              modal: {
                title: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_TITLE,
                description: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_MSG,
              },
            });
          }
        } else {
          const pendingSlot = res.slots.find((slot) => !slot.appliedAt);
          if (pendingSlot) {
            if (res.slots[1].extra.inSession) {
              doSetInSessionJointApplicant(pendingSlot);
              this.setState({
                inSessionPending: true,
                showModal: true,
                modalType: AppConstants.MODALTYPE.INFORMATION,
                modal: {
                  title: MODALMESSAGES.JOINT_APPLICANT_STATUS.PENDING_OS_TITLE,
                  description: MODALMESSAGES.JOINT_APPLICANT_STATUS.JOINT_START_IN_SESSION.replace(
                    "_EMAIL_",
                    pendingSlot.extra.email
                  ),
                },
              });
            } else {
              this.setState({
                outOfSessionPending: true,
                showModal: true,
                modalType: AppConstants.MODALTYPE.INFORMATION,
                modal: {
                  title: MODALMESSAGES.JOINT_APPLICANT_STATUS.PENDING_OS_TITLE,
                  description:
                    MODALMESSAGES.JOINT_APPLICANT_STATUS.PENDING_OS_MESSAGE,
                },
              });
            }
          } else {
            doSetInSessionJointApplicant(null);
            continuehandler(
              {},
              AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
            );
          }
        }
      }
    );
  };

  toggleModal = () => {
    const { showModal, erroCode } = this.state;
    this.setState({
      showModal: !showModal,
    });
    if (erroCode) {
      const { history } = this.props;
      history.push(ROUTES.EXISTING_MEMBER);
    }
  };

  handlePopupModalBtnClick = () => {
    const { continuehandler, history } = this.props;
    const { inSessionPending, outOfSessionPending } = this.state;
    if (inSessionPending) {
      history.push(ROUTES.EXISTING_MEMBER);
    } else if (!outOfSessionPending) {
      continuehandler({}, AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS);
    }
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  updateApplicant(action) {
    const { appCnt, appProperties } = this.state;
    if (action.type === "add") {
      if (appProperties.length < appCnt) {
        appProperties.push(action.applicant);
      }
    } else if (action.type === "remove") {
      if (action.index > -1) {
        appProperties.splice(action.index, 1);
      }
    }
    console.log(appProperties);
    this.updateAppPropertiesState(appProperties);
  }

  verifyJointAppicant() {
    const { appProperties } = this.state;
    // eslint-disable-next-line no-unused-vars
    let statusCompleted = false;
    if (appProperties && appProperties.length > 0) {
      appProperties.forEach((raw) => {
        if (raw.inviteDetail) {
          statusCompleted = false;
        }
      });
    }

    // eslint-disable-next-line react/no-unused-state
    this.setState({ isJointAppCompleted: statusCompleted });
  }

  updateAppPropertiesState(appProps) {
    this.setState({ appProperties: appProps });
  }

  render() {
    const {
      modalType,
      modal,
      showModal,
      appProperties,
      inSessionPending,
      outOfSessionPending,
    } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      continuehandler,
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doDeleteApplicationSlot,
      doGetApplicantType,
      doAddGlobalVault,
      doGetGlobalVault,
      applicantTypes,
      history,
    } = this.props;

    return (
      <div>
        <JointApplicationStatus
          history={history}
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          continuehandler={continuehandler}
          handleBack={handleBack}
          getDataFromServer={getDataFromServer}
          steps={steps}
          activeStepID={activeStepID}
          applicantTypes={applicantTypes}
          doAddApplicationSlot={doAddApplicationSlot}
          doinviteApplicationSlot={doinviteApplicationSlot}
          doGetApplicantType={doGetApplicantType}
          doDeleteApplicationSlot={doDeleteApplicationSlot}
          doAddGlobalVault={doAddGlobalVault}
          doGetGlobalVault={doGetGlobalVault}
          appProperties={appProperties}
          handleDeleteApplicant={this.handleDeleteApplicant}
          handleContinue={this.handleContinue}
          inSessionPending={inSessionPending}
          outOfSessionPending={outOfSessionPending}
        />
      </div>
    );
  }
}

JointApplicantStatusContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddApplicationSlot: PropTypes.func.isRequired,
  doinviteApplicationSlot: PropTypes.func.isRequired,
  doDeleteApplicationSlot: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  appProperties: PropTypes.arrayOf(PropTypes.object),
  doAddGlobalVault: PropTypes.func.isRequired,
  doGetGlobalVault: PropTypes.func.isRequired,
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  getActiveProduct: PropTypes.func.isRequired,
  doSetInSessionJointApplicant: PropTypes.func.isRequired,
  inSessionJointApplicant: PropTypes.oneOfType([PropTypes.object]),
  processTemplate: PropTypes.oneOfType([PropTypes.object]),
};

JointApplicantStatusContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  appProperties: [],
  products: [],
  inSessionJointApplicant: null,
  processTemplate: {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doDeleteApplicationSlot: (request, callback) =>
      dispatch(deleteApplicationSlot(request, callback)),
    doAddGlobalVault: (callback) => dispatch(addGlobalVault(callback)),
    doGetGlobalVault: (request, callback) =>
      dispatch(getGlobalVault(request, callback)),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
  };
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantTypes: state.JoinApplicantReducer.applicantTypes,
  appProperties: state.JoinApplicantReducer.appProperties,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  processTemplate: state.ApplicationReducer.processTemplate,
});

const JointApplicantWrapper = withApplicantHOC(JointApplicantStatusContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JointApplicantWrapper);
