import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import { capitalize } from "../../../Utils/CommonUtilities";

const PageBanner = (props) => {
  const {
    handleSignIn,
    handleRegister,
    loggedInUser,
    userName,
    bankingType,
    switchBanking,
    finInfo,
  } = props;

  return (
    <>
      {!loggedInUser ? (
        // Begin Marketplace hero banner
        <>
          {finInfo && finInfo?.multipleBanking && (
            <section
              className={`[ fi-brand__container ] [ jumbotron-fluid ] [ ${bankingType}-banking ]`}
            >
              <div className="[ fi-brand__logo ]" />

              <div className="[ fi-brand__tagline-container ]">
                <h2 className="[ tagline__title-1 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE1.split(
                    "_BANKING_TYPE_"
                  ).join(bankingType)}
                </h2>
                <h3 className="[ tagline__title-2 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE2.split(
                    "_BANKING_TYPE_"
                  ).join(bankingType)}
                </h3>
                <p className="[ tagline__description-1 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE3.split(
                    "_BANKING_TYPE_"
                  ).join(bankingType)}
                </p>
                <p className="[ tagline__description-2 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE4}
                </p>
                {finInfo?.multipleBanking && (
                  <div className="[ row ] [  button-row ]">
                    <div className="[ col-12 ]">
                      <div className="[ mobile-signin__button ]">
                        <button
                          type="button"
                          className="[ btn submit-btn ]"
                          onClick={handleSignIn}
                        >
                          {AppConstants.MARKETPLACE.HEADER.SIGNIN_BTN.split(
                            "_BANKING_TYPE_"
                          ).join(bankingType)}
                        </button>
                      </div>
                      <div className="[ mobile-signin__button ]">
                        <button
                          type="button"
                          className="[ btn submit-btn ]"
                          onClick={handleRegister}
                        >
                          {AppConstants.MARKETPLACE.HEADER.REGISTER_BTN.split(
                            "_BANKING_TYPE_"
                          ).join(bankingType)}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="[ fi-brand__cta-container ]">
                <h3 className="[ cta__header ]">
                  {STRINGS.MARKETPLACE.HEADER.CARD.TITLE.split(
                    "_BANKING_TYPE_"
                  ).join(
                    bankingType === AppConstants.BANKING_TYPE.PERSONAL
                      ? AppConstants.BANKING_TYPE.BUSINESS
                      : AppConstants.BANKING_TYPE.PERSONAL
                  )}
                </h3>
                <p className="[ cta__body ] [ cta__body-1 ]">
                  {STRINGS.MARKETPLACE.HEADER.CARD.BODY.TITLE.split(
                    "_BANKING_TYPE_"
                  ).join(
                    bankingType === AppConstants.BANKING_TYPE.PERSONAL
                      ? AppConstants.BANKING_TYPE.BUSINESS
                      : AppConstants.BANKING_TYPE.PERSONAL
                  )}
                </p>

                <button
                  type="button"
                  className="[ cta__primary-action-call btn info-btn ]"
                  onClick={switchBanking}
                >
                  {STRINGS.MARKETPLACE.HEADER.CARD.BODY.SWITCH.split(
                    "_BANKING_TYPE_"
                  ).join(
                    bankingType === AppConstants.BANKING_TYPE.PERSONAL
                      ? AppConstants.BANKING_TYPE.BUSINESS
                      : AppConstants.BANKING_TYPE.PERSONAL
                  )}
                </button>
              </div>
            </section>
          )}
          {finInfo && !finInfo.multipleBanking && (
            <section className="[ fi-brand__container ] [ jumbotron-fluid ] [ personal-banking ]">
              <div className="[ fi-brand__logo ]" />

              <div className="[ fi-brand__tagline-container ]">
                <h2 className="[ tagline__title-1 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE1.split(
                    "_BANKING_TYPE_"
                  ).join(bankingType)}
                </h2>
                <h3 className="[ tagline__title-2 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE2.split(
                    "_BANKING_TYPE_"
                  ).join(bankingType)}
                </h3>
                <div className="[ row d-xl-none ]">
                  <div className="[ mobile-signin__button ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ]"
                      onClick={handleSignIn}
                    >
                      {AppConstants.MARKETPLACE.HEADER.SIGNINREGEBTN}
                    </button>
                  </div>
                </div>
                <p className="[ tagline__description-1 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE3.split(
                    "_BANKING_TYPE_"
                  ).join(bankingType)}
                </p>
                <p className="[ tagline__description-2 ]">
                  {AppConstants.MARKETPLACE.HEADER.TITLE4}
                </p>
              </div>

              <div className="[ fi-brand__cta-container ]">
                <h3 className="[ cta__header ]">
                  {STRINGS.MARKETPLACE.HEADER.CARD.TITLE_PERSONAL}
                </h3>
                <p className="[ cta__body ] [ cta__body-1 ]">
                  {STRINGS.MARKETPLACE.HEADER.CARD.BODY.TITLE_PERSONAL}
                </p>

                <button
                  type="button"
                  className="[ cta__primary-action-call btn submit-btn ]"
                  onClick={handleSignIn}
                >
                  {STRINGS.MARKETPLACE.HEADER.CARD.BODY.SIGNIN}
                </button>

                <p className="[ cta__body ] [ cta__body-2 ]">
                  {STRINGS.MARKETPLACE.HEADER.CARD.FOOTER.TITLE}
                  <span
                    className="[ cta__secondary-action-call ] [ link ]"
                    role="presentation"
                    onClick={handleRegister}
                  >
                    {STRINGS.MARKETPLACE.HEADER.CARD.FOOTER.REGISTER}
                  </span>
                </p>
              </div>
            </section>
          )}
        </>
      ) : (
        // Begin Logged-in hero banner
        <section className="[ fi-brand__container ] [ jumbotron-fluid ] [ personal-banking ]">
          <div className="[ fi-brand__logo ]" />

          <div className="[ fi-brand__tagline-container ]">
            {!userName || (userName && userName == "") ? (
              <h2 className="[ tagline__title-1 ]">
                {STRINGS.MARKETPLACE.PAGE_BANNER.DASHBOARD_WELCOME_TEXT_UNKNOWN}
              </h2>
            ) : (
              <h2 className="[ tagline__title-1 ]">
                {STRINGS.MARKETPLACE.PAGE_BANNER.DASHBOARD_WELCOME_TEXT_KNOWN}{" "}
                {userName},
              </h2>
            )}

            {!userName || (userName && userName == "") ? (
              <h3 className="[ tagline__title-2 ]">
                {AppConstants.DASHBOARD.HEADER.TITLE2_UNKNOWN.split(
                  "_BANKING_TYPE_"
                ).join(bankingType)}
              </h3>
            ) : (
              <h3 className="[ tagline__title-2 ]">
                {AppConstants.DASHBOARD.HEADER.TITLE2_KNOWN}
              </h3>
            )}
          </div>
        </section>
      )}
    </>
  );
};

PageBanner.propTypes = {
  handleSignIn: PropTypes.func,
  handleRegister: PropTypes.func,
  loggedInUser: PropTypes.bool,
  userName: PropTypes.string,
  bankingType: PropTypes.string,
};

PageBanner.defaultProps = {
  handleSignIn: () => {},
  handleRegister: () => {},
  loggedInUser: false,
  userName: "",
  bankingType: AppConstants.BANKING_TYPE.PERSONAL,
};

const mapStateToProps = (state) => ({
  bankingType: state.MarketplaceReducer.bankingType,
  finInfo: state.MarketplaceReducer.finInfo,
});

export default connect(mapStateToProps)(PageBanner);
