import BaseApiRequest from "./BaseApiRequest";
import { GET_PASSWORD_CRITERIA_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetPasswordCriteriaRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return GET_PASSWORD_CRITERIA_URL;
  };

  getBody = () => {
    return this.body;
  };
}
