import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import InstntDocumentVerify from "Communication/ApiClasses/InstntDocumentVerify";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleVerifyDoc(action) {
  yield put(
    doLoadingStart(
      STRINGS.ABOUTYOU.API_KEYS.INSTNT_DOC,
      "Not that we don't trust you, but we need about 10 seconds or so to verify your ID... Please Hold!"
    )
  );
  const verifyDoc = new InstntDocumentVerify(action.data);
  try {
    const result = yield call(verifyDoc.getData);
    // console.log("INSTNT_DOC_VERIFY",action.data);
    // yield put({
    //   type: actionTypes.SUBMIT_VERIFY,
    //   data: { instnttxnid: action.data.instnttxnid, docType: window.instnt.documentType },
    // });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.INSTNT_DOC_VERIFY_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.INSTNT_DOC));
  }
}

// watcher
function* instntDocWatcherSaga() {
  yield takeLatest(actionTypes.INSTNT_DOC_VERIFY, handleVerifyDoc);
}

export default instntDocWatcherSaga;
