import getErrorMsg from "Constants/ErrorMessages";

const requiredValidator = (value) => {
  return value !== null && value !== "" && typeof value !== "undefined";
};

const minLengthValidator = (value, minlength) => {
  return value.length >= minlength;
};

const maxLengthValidator = (value, maxlength) => {
  return value.length <= maxlength;
};

const amountValidator = (value) => {
  let result = false;
  if (value !== "" && typeof value !== "undefined") {
    if (value > 0) {
      result = true;
    }
  }
  return result;
};

const equalLengthValidator = (value, equallength) => {
  return value.length === equallength;
};

const patternValidator = (value, validator) => {
  if (typeof validator === "function") {
    return validator(value);
  }
  return validator.test(value);
};

const checkValidators = (value, err, errObj) => {
  switch (err) {
    case "required":
      return requiredValidator(value);
    case "minLength":
      return minLengthValidator(value, errObj);
    case "maxLength":
      return maxLengthValidator(value, errObj);
    case "equalLength":
      return equalLengthValidator(value, errObj);
    case "pattern":
      return patternValidator(value, errObj);
    case "amount":
      return amountValidator(value);
    default:
      return "";
  }
};

const checkErrors = (value, validators) => {
  const errors = Object.keys(validators);
  const errorsOccured = [];
  const { fieldName } = validators;
  const { fieldType } = validators;

  console.log(validators);
  errors.forEach((err) => {
    if (err !== "fieldName" && err !== "fieldType") {
      if (!checkValidators(value, err, validators[err])) {
        errorsOccured.push(
          getErrorMsg(err, validators[err], fieldName, fieldType)
        );
      }
    }
  });
  return errorsOccured;
};

export default checkErrors;
