import { call, put, takeLatest } from "redux-saga/effects";
import GetCountriesTaxList from "Communication/ApiClasses/GetCountriesTaxList";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(actionTypes.GET_COUNTRIES_TAX));
  const getCountriesTaxList = new GetCountriesTaxList();
  try {
    const result = yield call(getCountriesTaxList.getData);
    yield put({
      type: `${actionTypes.GET_COUNTRIES_TAX}_SUCCESS`,
      response: result.data,
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: `${actionTypes.GET_COUNTRIES_TAX}_ERROR`,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.GET_COUNTRIES_TAX));
  }
}
// watcher
export default function* getSaga() {
  yield takeLatest(actionTypes.GET_COUNTRIES_TAX, handleSaga);
}
