import { call, put, throttle, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddressCorrection from "Communication/ApiClasses/AddressCorrection";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleSaga(action) {
  //yield put(doLoadingStart(STRINGS.ABOUTYOU.API_KEYS.ADDRESS_CORRECTION));
  const addressCorrection = new AddressCorrection(action.data);
  try {
    const result = yield call(addressCorrection.getData);
    yield put({
      type: actionTypes.ADDRESS_CORRECTION_SUCCESS,
      response: result,
      formData: { ...action.requestData, ...action.additionalData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ADDRESS_CORRECTION_ERROR,
      error: e.message,
    });
  } finally {
    //yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.ADDRESS_CORRECTION));
  }
}

// watcher
function* sagaWatcher() {
  yield throttle(500, actionTypes.ADDRESS_CORRECTION, handleSaga);
}

export default sagaWatcher;
