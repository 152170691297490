import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import FundPaymentDetailsApiRequest from "Communication/ApiClasses/FundPaymentDetailsApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

//  worker
function* handleFundPaymentDetails(action) {
  yield put(doLoadingStart(STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PAYMENT_DETAILS));
  const fundPaymentDetailsApi = new FundPaymentDetailsApiRequest();
  try {
    const result = yield call(fundPaymentDetailsApi.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.EROR_FUND_PAYMENT_DETAILS,
      error: error.message,
    });
    yield put(
      doLoadingFinish(STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PAYMENT_DETAILS)
    );
  }
}
//  watcher
export default function* paysafeProcessWatcherSaga() {
  yield takeLatest(actionTypes.FUND_PAYMENT_DETAILS, handleFundPaymentDetails);
}
