import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";
import { HTTP_STATUS } from "Communication/Constants";
import {
  getProductFromVault,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  addBundleProduct,
  deleteDebitCard,
  storeDebitCard,
} from "Components/DebitCard/Actions/DebitCardAction";
import { setOnlineBanking } from "Components/OnlineBanking/Actions/OnlineBankingAction";
import {
  getApplicationList,
  toggleBackFlag,
} from "Redux/Actions/ApplicationAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import { getApplicationProperty } from "Components/JointApplicant/Actions/JointApplicantAction";
import getBundleProductRelation from "Redux/Actions/GetBundleProductRelationAction";
import DebitCard from "./DebitCard";
import STRINGS from "../../Constants/Strings";
import {
  getProductIdInActiveProduct,
  showDebitCard,
} from "../../Utils/LogicUtilities";
import { setInSessionJointApplicant } from "../JointApplicant/Actions/JointApplicantAction";

class DebitCardContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      enableDebitCard: false,
      rendered: false,
    };
  }

  componentDidMount() {
    const {
      products,
      doGetApplicationProperty,
      doximProducts,
      applicationAppliedList,
      isDebitCardEnabled,
      getActiveProduct,
      doStoreDebitCard,
      bundleProductRelation,
      actionBack,
      doGetBundleProductRelation,
      doGetApplicationList,
      applicantData,
      doUpdateProductToVault,
      applicationCompleteList,
      inSessionJointApplicant,
    } = this.props;
    console.log(
      "DebitCardContainer",
      isDebitCardEnabled,
      bundleProductRelation
    );
    const activeProduct = getActiveProduct(products);
    if (
      showDebitCard(
        activeProduct,
        applicationCompleteList,
        applicantData,
        inSessionJointApplicant
      )
    ) {
      if (inSessionJointApplicant) {
        // show debit card always if in session
        this.setState({
          enableDebitCard:
            inSessionJointApplicant.enableDebitCard === undefined
              ? true
              : inSessionJointApplicant.enableDebitCard,
        });
        this.checkBundleProducts();
      } else if (activeProduct.isJointProduct) {
        doGetApplicationList((res) => {
          res.data.forEach((item) => {
            const debitCardProduct = item.doximProducts?.find(
              (product) =>
                product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM &&
                product.ownerId === activeProduct.applicantId
            );
            if (
              debitCardProduct &&
              item.id !== activeProduct.jointExternalApplicationId
            ) {
              // skip debit if a different application is in app list
              doStoreDebitCard(true, activeProduct.jointExternalApplicationId);
              if (actionBack) {
                this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
              } else {
                this.doContinueHandle(
                  "",
                  AppConstants.APPLICATIONSTEP.DEBIT_CARD
                );
              }
            }
          });
          if (res.data.length > 1) {
            // debit is only shown for first application
            if (actionBack) {
              this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
            } else {
              this.doContinueHandle(
                "",
                AppConstants.APPLICATIONSTEP.DEBIT_CARD
              );
            }
            return;
          }
          this.checkBundleProducts();
          if (doximProducts && doximProducts.length === 0) {
            const reqData = {
              applicationId: activeProduct.applicationId,
            };
            doGetApplicationProperty(reqData);
          }
        });
      } else {
        doGetApplicationList((res) => {
          res.data.forEach((item) => {
            const debitCardProduct = item.doximProducts?.find(
              (product) =>
                product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
            );
            if (debitCardProduct && item.id !== activeProduct.applicationId) {
              // skip debit if a different application is in app list
              doStoreDebitCard(true, activeProduct.applicationId);
              if (actionBack) {
                this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
              } else {
                this.doContinueHandle(
                  "",
                  AppConstants.APPLICATIONSTEP.DEBIT_CARD
                );
              }
            }
          });
          if (res.data.length > 1) {
            // debit is only shown for first application
            if (actionBack) {
              this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
            } else {
              this.doContinueHandle(
                "",
                AppConstants.APPLICATIONSTEP.DEBIT_CARD
              );
            }
            return;
          }
          this.checkBundleProducts();
        });

        if (doximProducts && doximProducts.length === 0) {
          const reqData = {
            applicationId: activeProduct.applicationId,
          };
          doGetApplicationProperty(reqData);
        }
      }
    } else if (actionBack) {
      this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
    } else {
      this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
    }
  }

  checkBundleProducts = () => {
    const {
      products,
      isDebitCardEnabled,
      getActiveProduct,
      bundleProductRelation,
      actionBack,
      doGetBundleProductRelation,
      applicantData,
      doUpdateProductToVault,
    } = this.props;
    console.log(
      "DebitCardContainer",
      isDebitCardEnabled,
      bundleProductRelation
    );
    const activeProduct = getActiveProduct(products);
    if (activeProduct.bundleProducts === undefined) {
      doGetBundleProductRelation(
        {
          bundleProductId: getProductIdInActiveProduct(
            activeProduct,
            applicantData
          ),
          demandProductId: activeProduct.productId,
          applicationId: activeProduct.applicationId,
        },
        (getBundleProductRelationRes) => {
          if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
            console.log(
              "getBundleProductRelationRes",
              getBundleProductRelationRes
            );
            activeProduct.bundleProducts = getBundleProductRelationRes.data;
            const newProducts = products;
            newProducts[0] = activeProduct;
            doUpdateProductToVault(newProducts, () => {
              // TODO only check if type is DEBITCARD
              if (getBundleProductRelationRes.data.length <= 0) {
                if (actionBack) {
                  this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
                } else {
                  this.doContinueHandle(
                    "",
                    AppConstants.APPLICATIONSTEP.DEBIT_CARD
                  );
                }
              }
            });
          }
        }
      );
    } else if (activeProduct.bundleProducts.length <= 0) {
      if (actionBack) {
        this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      } else {
        this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      }
    }
  };

  componentDidUpdate() {
    const { rendered } = this.state;
    const { products, getActiveProduct, inSessionJointApplicant } = this.props;
    if (inSessionJointApplicant) {
      // show debit card always if in session
    } else {
      const debitCardAlreadyLoaded = sessionStorage.getItem(
        AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED
      );
      if (debitCardAlreadyLoaded) {
        if (rendered === false) {
          const activeProduct = getActiveProduct(products);
          if (activeProduct.debitProductId) {
            this.setState({
              enableDebitCard: true,
              rendered: true,
            });
          }
        }
      } else {
        this.setState({
          enableDebitCard: true,
        });
        console.log("setting debit card flag");
        sessionStorage.setItem(
          AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED,
          "true"
        );
      }
    }
  }

  doContinueHandle = (data, step) => {
    console.log("doContinueHandle", data, step);
    const { continuehandler, doToggleBackFlag } = this.props;
    doToggleBackFlag(false);
    continuehandler(data, step);
  };

  doHandleBack = (step) => {
    const { handleBack, doToggleBackFlag } = this.props;
    console.log("doHandleBack", step);
    doToggleBackFlag(true);
    handleBack(step);
  };

  handleChange = (choice) => {
    this.setState({ enableDebitCard: choice });
  };

  handleContinue = () => {
    const {
      products,
      bundleProductRelation,
      doAddBundleProduct,
      doDeleteDebitCard,
      doGetApplicationProperty,
      getActiveProduct,
      doSetOnlineBanking,
      doUpdateProductToVault,
      inSessionJointApplicant,
      doSetInSessionJointApplicant,
    } = this.props;
    const { enableDebitCard } = this.state;
    const activeProduct = getActiveProduct(products);
    const newProducts = products;
    activeProduct.enableDebitCard = enableDebitCard;

    if (inSessionJointApplicant) {
      inSessionJointApplicant.enableDebitCard = enableDebitCard;
      doSetInSessionJointApplicant(inSessionJointApplicant, () => {});
      this.doContinueHandle(null, AppConstants.APPLICATIONSTEP.DEBIT_CARD);
    } else {
      if (enableDebitCard === true) {
        if (activeProduct.debitProductId) {
          // debit already added
          this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
        } else {
          doAddBundleProduct(
            bundleProductRelation.applicationId,
            bundleProductRelation[0].bundleProduct.id,
            activeProduct.doximProductId,
            activeProduct.applicantId,
            (res2) => {
              if (res2 && res2.status === HTTP_STATUS.OK) {
                const debitProduct =
                  res2.data.doximProducts[
                    res2.data.doximProducts.findLastIndex(
                      (item) =>
                        item.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM &&
                        item.ownerId === activeProduct.applicantId
                    )
                  ];
                doSetOnlineBanking(
                  bundleProductRelation.applicationId,
                  debitProduct.id,
                  activeProduct.templateId
                );
                activeProduct.debitProductId = debitProduct.id;
                newProducts[0] = activeProduct;
                doUpdateProductToVault(newProducts, () => {});
                doGetApplicationProperty(
                  { applicationId: activeProduct.applicationId },
                  () => {
                    this.doContinueHandle(
                      "",
                      AppConstants.APPLICATIONSTEP.DEBIT_CARD
                    );
                  }
                );
              }
            }
          );
        }
      } else if (
        activeProduct.debitProductId === 0 ||
        !activeProduct.debitProductId
      ) {
        doSetOnlineBanking(activeProduct.applicationId, null, null);
        this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      } else {
        doDeleteDebitCard(
          bundleProductRelation.applicationId,
          activeProduct.debitProductId,
          (res) => {
            if (res && res.status === HTTP_STATUS.OK) {
              doSetOnlineBanking(activeProduct.applicationId, null, null);
              const { applicationId } = products[0];
              activeProduct.debitProductId = null;
              newProducts[0] = activeProduct;
              doUpdateProductToVault(newProducts, () => {});
              doGetApplicationProperty({ applicationId });
              this.doContinueHandle(
                "",
                AppConstants.APPLICATIONSTEP.DEBIT_CARD
              );
            }
          }
        );
      }
    }
  };

  addInSessionDebit = (activeProduct, inSessionJointApplicant) => {
    const {
      doAddBundleProduct,
      doSetInSessionJointApplicant,
      bundleProductRelation,
    } = this.props;

    doAddBundleProduct(
      bundleProductRelation.applicationId,
      bundleProductRelation[0].bundleProduct.id,
      activeProduct.doximProductId,
      inSessionJointApplicant.applicantId,
      (res2) => {
        if (res2 && res2.status === HTTP_STATUS.OK) {
          const debitProduct =
            res2.data.doximProducts[
              res2.data.doximProducts.findLastIndex(
                (item) => item.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
              )
            ];
          inSessionJointApplicant.debitProductId = debitProduct.id;
          doSetInSessionJointApplicant(inSessionJointApplicant, () => {});
          this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
        }
      }
    );
  };

  togglePopupModal = () => {
    this.setState({});
  };

  render() {
    const { enableDebitCard } = this.state;
    const { getDataFromServer, steps, activeStepID } = this.props;

    return (
      <>
        <DebitCard
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          isDebitCardEnabled={enableDebitCard}
          handleBack={this.doHandleBack}
          steps={steps}
          activeStepID={activeStepID}
        />
      </>
    );
  }
}

DebitCardContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddBundleProduct: PropTypes.func.isRequired,
  doDeleteDebitCard: PropTypes.func.isRequired,
  bundleProductRelation: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doximProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  applicationAppliedList: PropTypes.arrayOf(PropTypes.any).isRequired,
  isDebitCardEnabled: PropTypes.bool,
  getActiveProduct: PropTypes.func.isRequired,
  doSetOnlineBanking: PropTypes.func.isRequired,
  actionBack: PropTypes.bool,
  doToggleBackFlag: PropTypes.func.isRequired,
  doGetApplicationList: PropTypes.func.isRequired,
  inSessionJointApplicant: PropTypes.objectOf(PropTypes.any),
};

DebitCardContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  actionBack: false,
  isDebitCardEnabled: false,
  inSessionJointApplicant: null,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  bundleProductRelation: state.GetBundleProductRelationReducer.data,
  doximProducts: state.ApplicationReducer.doximProducts,
  applicationAppliedList: state.ApplicationReducer.applicationAppliedList,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
  actionBack: state.ApplicationReducer.actionBack,
  applicantData: state.ApplicationReducer.response,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doAddBundleProduct: (
      applicationId,
      bundleProductId,
      demandProductId,
      applicantId,
      callback
    ) =>
      dispatch(
        addBundleProduct(
          applicationId,
          bundleProductId,
          demandProductId,
          applicantId,
          callback
        )
      ),
    doDeleteDebitCard: (applicationId, debitProductId, callback) =>
      dispatch(deleteDebitCard(applicationId, debitProductId, callback)),
    doStoreDebitCard: (enableDebitCard, applicationId, callback) =>
      dispatch(storeDebitCard(enableDebitCard, applicationId, callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doSetOnlineBanking: (
      applicationId,
      debitCardProductId,
      templateId,
      callback
    ) =>
      dispatch(
        setOnlineBanking(
          applicationId,
          debitCardProductId,
          templateId,
          callback
        )
      ),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    doGetBundleProductRelation: (activeProduct, callback) =>
      dispatch(getBundleProductRelation(activeProduct, callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
  };
};

const DebitCardWrapper = withApplicantHOC(DebitCardContainer);

export default connect(mapStateToProps, mapDispatchToProps)(DebitCardWrapper);
