import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import IndicateWhoRequest from "Communication/ApiClasses/IndicateWhoRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleIndicateWho() {
  yield put(doLoadingStart(STRINGS.COMPLIANCE.API_KEYS.INDICATE_WHO_LIST));
  const indicateWhoApi = new IndicateWhoRequest();
  try {
    const result = yield call(indicateWhoApi.getData);
    yield put({
      type: actionTypes.ADD_INDICATE_WHO,
      indicateWhoList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.INDICATE_WHO_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.COMPLIANCE.API_KEYS.INDICATE_WHO_LIST));
  }
}

// watcher
export default function* indicateWhoWatcherSaga() {
  yield takeLatest(actionTypes.GET_INDICATE_WHO, handleIndicateWho);
}
