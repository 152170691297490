import * as actionTypes from "Redux/Actions/actionTypes";

const getIntendedUseList = () => {
  return {
    type: actionTypes.GET_INTENDED_USE,
  };
};

export const getApplicantBankingProductList = (
  applicantId,
  callback = () => {}
) => ({
  type: actionTypes.GET_APPLICANT_BANKING_PRODUCT_LIST,
  data: {
    applicantId,
  },
  callback,
});

export default getIntendedUseList;
