import * as actionTypes from "Redux/Actions/actionTypes";
import { UPLOAD_QUESTIONNAIRE } from "../../../Redux/Actions/actionTypes";

export const storeQuestionnaire = (questionnaire) => ({
  type: actionTypes.STORE_QUESTIONNAIRE,
  questionnaire,
});

export const storeQuestionnaireProduct = (product) => ({
  type: actionTypes.STORE_QUESTIONNAIRE_PRODUCT,
  product,
});

export const submitQuestionnaire = (request, callback = () => {}) => ({
  type: actionTypes.SUBMIT_QUESTIONNAIRE,
  data: request,
  callback,
});

export const uploadQuestionnaire = (request, callback = () => {}) => ({
  type: actionTypes.UPLOAD_QUESTIONNAIRE,
  data: request,
  callback,
});
