import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "Components/Common/PopupModal/PopupModal.scss";
import {
  TOKEN_KEY,
  HTTP_STATUS,
  SORT_ON_PRIORITY,
} from "Communication/Constants";
import qr from "query-string";

import FilterBar from "Components/Common/FilterBar/FilterBar";
import Vault from "Components/Vault/Vault";
import ProductCardContainer from "Components/Common/ProductCardContainer/ProductCardContainer";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { sortList } from "Utils/CommonUtilities";

import {
  storeFilterProduct,
  updateVaultProductList,
  updateProductToVault,
  getProductFromVault,
  getProductList,
  supportMessage,
} from "Components/MarketPlace/Action/MarketPlaceAction";

import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import { PRODUCT_IMAGE_URL } from "../../Communication/ApiUrls";
import { LENDING_PRODUCT } from "../../Communication/Constants";
import * as ROUTES from "Constants/Routes";
import { Modal } from "react-bootstrap";

class ApplyProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: {},
      searchInput: "",
      modalType: "",
      allCheck: true,
      isApplyProduct: false,
      appliedProducts: [],
      type: AppConstants.BANKING_TYPE.PERSONAL,
    };
  }

  componentDidMount() {
    const { doGetProductList } = this.props;

    const productParams = this.getProducts();
    const products = [];
    const processedProduct = [];
    const appliedProducts = [];
    doGetProductList((response) => {
      if (response && response.status === HTTP_STATUS.OK) {
        const productList = response.data;
        productList.forEach((rawProduct) => {
          if (rawProduct.products && rawProduct.products.length !== 0) {
            rawProduct.products.forEach((rawItem) => {
              products.push(rawItem);
              productParams.forEach((param) => {
                if (
                  (rawItem.show &&
                    param.toLowerCase() === rawItem.productId.toLowerCase()) ||
                  (Array.isArray(rawItem.productList) &&
                    rawItem.productId.includes(param))
                ) {
                  if (!processedProduct.includes(param.toLowerCase())) {
                    if (!appliedProducts.includes(rawItem.productName)) {
                      appliedProducts.push(rawItem.productName);
                    }
                    processedProduct.push(param.toLowerCase());
                    this.handleAddToVault({
                      ...rawItem,
                      bankingType: rawProduct.type,
                    });
                  }
                }
              });
            });
          }
        });
        this.setState({
          appliedProducts,
        });
        this.validateProduct(products, productParams);
      }
    });
  }

  getProducts() {
    const params = qr.parse(this.props.location.search);
    const products = params.products ? params.products.split(",") : [];
    const type = params.type;
    if (products && products.length !== 0) {
      this.setState({ isApplyProduct: true, type });
    } else {
      this.setState({ isApplyProduct: false, type });
    }
    return products;
  }

  setAllCheck = (allCheck) => {
    this.setState({ allCheck });
  };

  handleAddToVault = (cardItem) => {
    const {
      doUpdateVaultProductList,
      doUpdateProductToVault,
      vaultProductList,
      doGetProductFromVault,
    } = this.props;
    console.log("handleAddToVault", cardItem);
    const accessToken = sessionStorage.getItem(TOKEN_KEY);

    if (accessToken) {
      doGetProductFromVault((response) => {
        if (response.status === HTTP_STATUS.OK) {
          const vaultCards = this.isProductExists([...response.data], cardItem);
          doUpdateProductToVault(vaultCards, () => {});
        }
      });
    } else {
      const vaultCards = this.isProductExists([...vaultProductList], cardItem);
      doUpdateVaultProductList(vaultCards);
    }
  };

  isProductExists = (vaultCards, cardItem) => {
    const { selectCard, showModal } = this.state;
    const { loanProducts } = this.props;
    const selectedProduct = cardItem || selectCard;
    console.log("isProductExists", vaultCards, cardItem);
    const product = {
      ...selectedProduct,
      active: false,
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
      },
    };
    const rand = Math.floor(Math.random() * 1000);
    product.productIndex = rand;

    const valueIndex = vaultCards.findIndex(
      (elem) => elem.productId === selectedProduct.productId
    );

    if (product.type === LENDING_PRODUCT) {
      const loanProduct = loanProducts.find(
        (item) => product.productId === item.id
      );
      console.log("loanProduct", loanProduct);
      product.category = loanProduct.category;
      product.subCategory = loanProduct.subCategory;
      product.productType = loanProduct.productType;
      product.productCode = loanProduct.productCode;
      product.cutoff = loanProduct.cutoff;
      product.applicationType = loanProduct.applicationType;
    } else {
      product.category = "";
      product.subCategory = "";
    }
    if (valueIndex !== -1) {
      const modal = {};
      modal.title = STRINGS.MARKETPLACE.BODY.PRODUCT_ADDED;
      modal.description = STRINGS.MARKETPLACE.BODY.MULTIPLE_PRODUCT_ADD;

      this.setState({
        showModal: !showModal,
        modal,
        modalType: AppConstants.MODALTYPE.CUSTOM,
      });
    }
    vaultCards.push(product);
    let sortedVaultCards = sortList(vaultCards, SORT_ON_PRIORITY);

    //move in progress applications to first
    const cardNotStarted = sortedVaultCards.filter(
      (x) => x.applicationStep.index == null
    );
    const cardStarted = sortedVaultCards.filter(
      (x) => x.applicationStep.index != null
    );

    sortedVaultCards = [...cardStarted, ...cardNotStarted];

    return sortedVaultCards;
  };

  toggleModal = () => {
    const { showModal } = this.state;
    const modal = {};
    this.setState({ showModal: !showModal, modal });
  };

  validateProduct(products, productParams) {
    console.log("validateProduct", products, productParams);
    const { doSupportMessage, history } = this.props;
    const { type, appliedProducts } = this.state;

    sessionStorage.setItem(
      AppConstants.SESSION.APPLIED_PRODUCTS,
      appliedProducts
    );

    let result = false;
    productParams.forEach((raw) => {
      products.forEach((item) => {
        if (
          (item.show && item.productId.toLowerCase() === raw.toLowerCase()) ||
          (Array.isArray(item.productList) && item.productId.includes(raw))
        ) {
          result = true;
        }
      });
    });

    if (type === AppConstants.BANKING_TYPE.BUSINESS && result) {
      history.push({
        pathname: ROUTES.MARKETPLACE,
        state: { type },
      });
    } else if (result) {
      history.push(ROUTES.MARKETPLACE);
    } else {
      doSupportMessage(STRINGS.APPLY_PRODUCT.SUPPORT_MESSAGE, (res) => {});
      history.push({
        pathname: ROUTES.MARKETPLACE,
        search: "?productError=true",
        state: { productError: true },
      });
    }
  }

  render() {
    return <></>;
  }
}

ApplyProductContainer.propTypes = {
  doUpdateVaultProductList: PropTypes.func,
  doStoreFilterProduct: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doGetProductFromVault: PropTypes.func,
};

ApplyProductContainer.defaultProps = {
  doUpdateVaultProductList: () => {},
  doStoreFilterProduct: () => {},
  doUpdateProductToVault: () => {},
  doGetProductFromVault: () => {},
  categoryList: [],
  productList: [],
  vaultProductList: [],
  doSupportMessage: () => {},
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  productList: state.MarketplaceReducer.productList,
  categoryList: state.MarketplaceReducer.categoryList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreFilterProduct: storeFilterProduct,
      doUpdateVaultProductList: updateVaultProductList,
      doUpdateProductToVault: updateProductToVault,
      doGetProductFromVault: getProductFromVault,
      doGetProductList: getProductList,
      doSupportMessage: supportMessage,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyProductContainer);
