import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import RegistrationRequest from "Communication/ApiClasses/RegistrationRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleRegister(action) {
  yield put(doLoadingStart(STRINGS.REGISTRATION.API_KEYS.REGISTER));
  const userData = action.data;
  const registerApi = new RegistrationRequest(userData);
  try {
    const result = yield call(registerApi.getData);
    yield put({
      type: actionTypes.REGISTER_RESPONSE,
      success: result,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.REGISTER_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.REGISTRATION.API_KEYS.REGISTER));
  }
}

// watcher
export default function* registerWatcherSaga() {
  yield takeLatest(actionTypes.REGISTER_REQUEST, handleRegister);
}
