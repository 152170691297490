import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessEmployment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employmentStatus: "",
      position: "",
      employerName: "",
      errors: {
        employmentStatusErrorList: [],
        positionErrorList: [],
        employerNameErrorList: [],
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        employmentStatus: activeProduct?.employmentStatus ?? "",
        position: activeProduct?.position ?? "",
        employerName: activeProduct?.employerName ?? "",
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { employmentStatus, position, employerName } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({ employmentStatus, position, employerName });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT);
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    const { employmentStatus, position, employerName, errors } = this.state;

    const employmentStatusRes = checkErrors(
      employmentStatus,
      VALIDATIONS.BUSINESS_EMPLOYMENT.EMPLOYMENT_STATUS
    );
    const positionRes = checkErrors(
      position,
      VALIDATIONS.BUSINESS_EMPLOYMENT.POSITION
    );
    const employerNameRes = checkErrors(
      employerName,
      VALIDATIONS.BUSINESS_EMPLOYMENT.EMPLOYER_NAME
    );

    this.setState({
      errors: {
        ...errors,
        employmentStatusErrorList: employmentStatusRes,
        positionErrorList: positionRes,
        employerNameErrorList: employerNameRes,
      },
    });

    if (
      employmentStatusRes.length > 0 ||
      positionRes.length > 0 ||
      employerNameRes.length > 0
    ) {
      formIsValid = false;
    }

    return formIsValid;
  };

  render() {
    const { steps, activeStepID, finInfo } = this.props;
    const { employmentStatus, position, employerName, errors } = this.state;
    console.log(steps, activeStepID);

    const optionListComponent = finInfo?.config?.businessEmployment?.map(
      (data) => (
        <option
          className="[ dropdown__option ]"
          value={data.code}
          key={data.code}
        >
          {data.description}
        </option>
      )
    );

    const actionComponent = (
      <div className="[ property-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_ONLINE.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-sm-12 col-lg-8 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYMENT_STATUS_LABEL}
                </div>
                <select
                  name="employmentStatus"
                  className={
                    errors.employmentStatusErrorList &&
                    errors.employmentStatusErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                      : "[ form-control ] [ select__dropdown ]"
                  }
                  onChange={this.handleChange}
                  value={employmentStatus}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.DROPDOWN_INITIAL_TEXT}
                  </option>
                  {optionListComponent}
                </select>
                <div className="[ error-msg ]">
                  {errors?.employmentStatusErrorList[0]}
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_EMPLOYMENT.FORM.POSITION_LABEL}
                </div>

                <input
                  type="text"
                  name="position"
                  id="position"
                  className={
                    errors?.positionErrorList &&
                    errors?.positionErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={
                    STRINGS.BUSINESS_EMPLOYMENT.FORM.POSITION_PLACEHOLDER
                  }
                  value={position}
                  onChange={this.handleChange}
                />
                <div className="[ error-msg ]">
                  {errors?.positionErrorList[0]}
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYER_NAME_LABEL}
                </div>

                <input
                  type="text"
                  name="employerName"
                  id="employerName"
                  className={
                    errors?.employerNameErrorList &&
                    errors?.employerNameErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={
                    STRINGS.BUSINESS_EMPLOYMENT.FORM.EMPLOYER_NAME_PLACEHOLDER
                  }
                  value={employerName}
                  onChange={this.handleChange}
                />
                <div className="[ error-msg ]">
                  {errors?.employerNameErrorList[0]}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessEmployment.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessEmployment.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessEmployment;
