import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import { toggleEditFlag } from "Redux/Actions/ApplicationAction";

import BusinessAboutYou from "./BusinessAboutYou";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";

class BusinessAboutYouContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const { continuehandler, getActiveProduct, products } = this.props;
    const activeProduct = getActiveProduct(products);
    continuehandler(
      { ...activeProduct, ...data },
      AppConstants.APPLICATIONSTEP.BUSINESS_ABOUT_YOU
    );
  };

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      getActiveProduct,
      products,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessAboutYou
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          handleEditPage={this.handleEditPage}
          activeProduct={activeProduct}
        />
      </>
    );
  }
}

BusinessAboutYouContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doToggleEdit: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
};

BusinessAboutYouContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  products: [],
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessAboutYouContainer);

const BusinessAboutYouWrapper = withBusinessHOC(BusinessAboutYouContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessAboutYouWrapper);
