import { call, put, takeLatest } from "redux-saga/effects";
import GetBundleProductRelation from "Communication/ApiClasses/GetBundleProductRelation";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleGetBundleProductRelation(action) {
  yield put(
    doLoadingStart(STRINGS.DEBIT_CARD.API_KEYS.GET_BUNDLE_PRODUCT_RELATION)
  );
  const getBundleProductRelation = new GetBundleProductRelation(action.data);
  try {
    const result = yield call(getBundleProductRelation.getData);

    yield put({
      type: actionTypes.GET_BUNDLE_PRODUCT_RELATION_SUCCESS,
      response: { ...action.data, ...result.data },
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.GET_BUNDLE_PRODUCT_RELATION_ERROR,
      error,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.DEBIT_CARD.API_KEYS.GET_BUNDLE_PRODUCT_RELATION)
    );
  }
}

// watcher
export default function* getBundleProductRelationWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_BUNDLE_PRODUCT_RELATION,
    handleGetBundleProductRelation
  );
}
