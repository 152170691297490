import { call, put, takeLatest } from "redux-saga/effects";
import UpdateProductInformationRequest from "Communication/ApiClasses/UpdateProductInformationRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleUpdateProductInformation(action) {
  // Start the spinner
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.UPDATE_PRODUCT_INFORMATION));

  // Form API request
  const updateProductInfo = new UpdateProductInformationRequest(action.data, [
    action.applicationId,
    action.productType,
    action.doximProductId,
  ]);
  try {
    // Call the API
    const result = yield call(updateProductInfo.getData);
    // Give a call to the callback function
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_PRODUCT_INFORMATION_ERROR,
      error: e.message,
    });
  } finally {
    // Stop the spinner
    yield put(
      doLoadingFinish(STRINGS.COMMON.API_KEYS.UPDATE_PRODUCT_INFORMATION)
    );
  }
}

// watcher
export default function* handleUpdateProductInformationSaga() {
  yield takeLatest(
    actionTypes.UPDATE_PRODUCT_INFORMATION,
    handleUpdateProductInformation
  );
}
