export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const RESET = "RESET";
export const REGISTRATION = "REGISTRATION";
export const SIGNIN_RESPONSE = "SIGNIN_RESPONSE";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const REGISTER_RESPONSE = "REGISTER_RESPONSE";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const EXISTINGUSER_RESPONSE = "EXISTINGUSER_RESPONSE";
export const EXISTINGUSER_ERROR = "EXISTINGUSER_ERROR";
export const EXISTINGUSER_REQUEST = "EXISTINGUSER_REQUEST";
export const CREATE_INVERITE_REQUEST = "CREATE_INVERITE_REQUEST";
export const STORE_INVERITE_GUID = "STORE_INVERITE_GUID";
export const FETCH_INVERITE_REQUEST = "FETCH_INVERITE_REQUEST";
export const CREATE_INVERITE_ACCOUNT_LIST_REQUEST =
  "CREATE_INVERITE_ACCOUNT_LIST_REQUEST";
export const FETCH_INVERITE_ACCOUNT_LIST_REQUEST =
  "FETCH_INVERITE_ACCOUNT_LIST_REQUEST";

export const GET_PASSWORD_CRITERIA_REQUEST = "GET_PASSWORD_CRITERIA_REQUEST";
export const GET_PASSWORD_CRITERIA_RESPONSE = "GET_PASSWORD_CRITERIA_RESPONSE";
export const GET_PASSWORD_CRITERIA_ERROR = "GET_PASSWORD_CRITERIA_ERROR";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_RESPONSE = "UPDATE_PASSWORD_RESPONSE";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
export const VERIFICATIONCODE_RESPONSE = "VERIFICATIONCODE_RESPONSE";
export const VERIFICATIONCODE_ERROR = "VERIFICATIONCODE_ERROR";
export const VERIFICATIONCODE_REQUEST = "VERIFICATIONCODE_REQUEST";
export const ADD_USERNAME = "ADD_USERNAME";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_RESPONSE = "FORGOT_PASSWORD_RESPONSE";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_RESPONSE = "RECOVER_PASSWORD_RESPONSE";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";
export const LOADING_START = "LOADING_START";
export const LOADING_FINISH = "LOADING_FINISH";
export const ADD_COUNTRY_LIST = "ADD_COUNTRY_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const COUNTRY_ERROR = "COUNTRY_ERROR";
export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const ADD_STATUS_LIST = "ADD_STATUS_LIST";
export const STATUS_ERROR = "STATUS_ERROR";
export const GET_INCOME_SOURCES_LIST = "GET_INCOME_SOURCES_LIST";
export const ADD_INCOME_SOURCES_LIST = "ADD_INCOME_SOURCES_LIST";
export const INCOME_SOURCES_ERROR = "INCOME_SOURCES_ERROR";
export const EMPL0YMENT_AND_INCOME_RESPONSE = "EMPL0YMENT_AND_INCOME_RESPONSE";
export const EMPL0YMENT_AND_INCOME_ERROR = "EMPL0YMENT_AND_INCOME_ERROR";
export const EMPLOYMENT_AND_INCOME_REQUEST = "EMPLOYMENT_AND_INCOME_REQUEST";
export const ADD_EMPLOYMENT_AND_INCOME_FORM_DATA =
  "ADD_EMPLOYMENT_AND_INCOME_FORM_DATA";

export const CONSENT_REQUEST = "CONSENT_REQUEST";
export const CONSENT_RESPONSE = "CONSENT_RESPONSE";
export const CONSENT_ERROR = "CONSENT_ERROR";
export const TERMS_JSON_REQUEST = "TERMS_JSON_REQUEST";
export const TERMS_JSON_RESPONSE = "TERMS_JSON_RESPONSE";
export const TERMS_JSON_ERROR = "TERMS_JSON_ERROR";

export const GETTINGSTARTED_REQUEST = "GETTINGSTARTED_REQUEST";
export const GETTINGSTARTED_RESPONSE = "GETTINGSTARTED_RESPONSE";
export const GETTINGSTARTED_ERROR = "GETTINGSTARTED_ERROR";

export const GETTINGSTARTED_BRANCH_REQUEST = "GETTINGSTARTED_BRANCH_REQUEST";
export const GETTINGSTARTED_BRANCH_RESPONSE = "GETTINGSTARTED_BRANCH_RESPONSE";
export const GETTINGSTARTED_BRANCH_ERROR = "GETTINGSTARTED_BRANCH_ERROR";

export const GETTINGSTARTED_RESIDENT_REQUEST =
  "GETTINGSTARTED_RESIDENT_REQUEST";
export const GETTINGSTARTED_RESIDENT_RESPONSE =
  "GETTINGSTARTED_RESIDENT_RESPONSE";
export const GETTINGSTARTED_RESIDENT_ERROR = "GETTINGSTARTED_RESIDENT_ERROR";
export const GETTING_STARTED_FORM_DATA = "GETTING_STARTED_FORM_DATA";

export const ADD_ACCOUNT_PURPOSE = "ADD_ACCOUNT_PURPOSE";
export const GET_ACCOUNT_PURPOSE = "GET_ACCOUNT_PURPOSE";
export const ACCOUNT_PURPOSE_ERROR = "ACCOUNT_PURPOSE_ERROR";
export const ADD_INDICATE_WHO = "ADD_INDICATE_WHO";
export const GET_INDICATE_WHO = "GET_INDICATE_WHO";
export const INDICATE_WHO_ERROR = "INDICATE_WHO_ERROR";
export const VAULT_REQUEST = "VAULT_REQUEST";
export const VAULT_RESPONSE = "VAULT_RESPONSE";
export const VAULT_ERROR = "VAULT_ERROR";
export const COMPLIANCE_REQUEST = "COMPLIANCE_REQUEST";
export const COMPLIANCE_RESPONSE = "COMPLIANCE_RESPONSE";
export const COMPLIANCE_ERROR = "COMPLIANCE_ERROR";
export const ADD_COMPLIANCE_DATA = "ADD_COMPLIANCE_DATA";
export const GET_ID_TYPE = "GET_ID_TYPE";
export const GET_ID_TYPE_SUCCESS = "GET_ID_TYPE_SUCCESS";
export const GET_ID_TYPE_ERROR = "GET_ID_TYPE_ERROR";
export const INSTNT_DOC_VERIFY = "INSTNT_DOC_VERIFY";
export const INSTNT_DOC_VERIFY_SUCCESS = "INSTNT_DOC_VERIFY_SUCCESS";
export const INSTNT_DOC_VERIFY_ERROR = "INSTNT_DOC_VERIFY_ERROR";
export const SUBMIT_VERIFY = "SUBMIT_VERIFY";
export const SUBMIT_VERIFY_SUCCESS = "SUBMIT_VERIFY_SUCCESS";
export const SUBMIT_VERIFY_ERROR = "SUBMIT_VERIFY_ERROR";
export const CREATE_APPLICATION = "CREATE_APPLICATION";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_ERROR = "CREATE_APPLICATION_ERROR";
export const GET_PROVINCE_LIST = "GET_PROVINCE_LIST";
export const GET_PROVINCE_LIST_SUCCESS = "GET_PROVINCE_LIST_SUCCESS";
export const GET_PROVINCE_LIST_ERROR = "GET_PROVINCE_LIST_ERROR";
export const CONFIRM_APPLY_REQUEST = "CONFIRM_APPLY_REQUEST";
export const CONFIRM_APPLY_RESPONSE = "CONFIRM_APPLY_RESPONSE";
export const CONFIRM_APPLY_ERROR = "CONFIRM_APPLY_ERROR";
export const CONFIRM_RESULT_UPLOADED = "CONFIRM_RESULT_UPLOADED";
export const SAVE_CREATE_APP_STATUS = "SAVE_CREATE_APP_STATUS";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const RESET_STORE_REQUEST = "RESET_STORE_REQUEST";
export const RESET_STORE_RESPONSE = "RESET_STORE_RESPONSE";
export const RESET_STORE_ERROR = "RESET_STORE_ERROR";
export const UPDATE_APPLICANT_REQUEST = "UPDATE_APPLICANT_REQUEST";
export const UPDATE_APPLICANT_RESPONSE = "UPDATE_APPLICANT_RESPONSE";
export const UPDATE_APPLICANT_ERROR = "UPDATE_APPLICANT_ERROR";
export const GET_VAULT = "GET_VAULT";
export const GET_VAULT_SUCCESS = "GET_VAULT_SUCCESS";
export const GET_VAULT_ERROR = "GET_VAULT_ERROR";
export const GET_GETTING_STARTED_VAULT = "GET_GETTING_STARTED_VAULT";
export const GET_GETTING_STARTED_VAULT_ERROR =
  "GET_GETTING_STARTED_VAULT_ERROR";
export const GET_APPLICANT = "GET_APPLICANT";
export const GET_APPLICANT_SUCCESS = "GET_APPLICANT_SUCCESS";
export const GET_APPLICANT_ERROR = "GET_APPLICANT_ERROR";
export const STORE_APPLICANT_DETAILS = "STORE_APPLICANT_DETAILS";

export const FUND_METHOD = "FUND_METHOD";
export const FUND_AMOUNT = "FUND_AMOUNT";
export const FUND_PROCESS_RESPONSE = "FUND_PROCESS_RESPONSE";
export const FUND_PROCESS_ERROR = "FUND_PROCESS_ERROR";
export const PROCESS_REQUEST = "PROCESS_REQUEST";
export const FUND_PAYMENT_DETAILS = "FUND_PAYMENT_DETAILS";
export const ADD_FUND_PAYMENT_DETAILS = "ADD_FUND_PAYMENT_DETAILS";
export const EROR_FUND_PAYMENT_DETAILS = "EROR_FUND_PAYMENT_DETAILS";
export const STORE_FUND_PRODUCT = "STORE_FUND_PRODUCT";

// FOR CENTERLIZED CALL
export const STORE_APPLICATION_STEPS = "STORE_APPLICATION_STEPS";
export const STORE_APPLICATION_STEPS_REQUEST =
  "STORE_APPLICATION_STEPS_REQUEST";
export const APPLICATION_STEPS_ERROR = "APPLICATION_STEPS_ERROR";
export const APPLICATION_DELETE = "APPLICATION_DELETE";
export const APPLICATION_LIST = "APPLICATION_LIST";
export const STORE_APPLICATION_LIST = "STORE_APPLICATION_LIST";

export const CALL_GETTING_STARTED_API = "CALL_GETTING_STARTED_API";
export const STORE_GETTING_STARTED = "STORE_GETTING_STARTED";
export const GETTING_STARTED_ERROR = "GETTING_STARTED_ERROR";

export const CALL_APPLICANT_API = "CALL_APPLICANT_API";
export const CALL_COMPLETE_APPLICANT_API = "CALL_COMPLETE_APPLICANT_API";
export const STORE_APPLICANT_RESPONSE = "STORE_APPLICANT_RESPONSE";
export const STORE_PERSONAL_DATA = "STORE_PERSONAL_DATA";
export const APPLICATION_ERROR = "APPLICATION_ERROR";

export const TOGGLE_EDIT_FLAG = "TOGGLE_EDIT_FLAG";
export const TOGGLE_EDIT_CONFIRM_FLAG = "TOGGLE_EDIT_CONFIRM_FLAG";
export const DISABLE_BACK = "DISABLE_BACK";

// to add application current progress
export const STORE_APPLICATION_PROGRESS_REQUEST =
  "STORE_APPLICATION_PROGRESS_REQUEST";

// To get applicant list
export const GET_APPLICANT_LIST = "GET_APPLICANT_LIST";
export const GET_APPLICANT_LIST_SUCCESS = "GET_APPLICANT_LIST_SUCCESS";
export const GET_APPLICANT_LIST_ERROR = "GET_APPLICANT_LIST_ERROR";
// to check terms and condition accepted or not
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";

// Marketplace
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const STORE_PRODUCT_LIST = "STORE_PRODUCT_LIST";
export const GET_FI_INFO = "GET_FI_INFO";
export const STORE_FI_INFO = "STORE_FI_INFO";
export const UPDATE_VAULT_PRODUCT_LIST = "UPDATE_VAULT_PRODUCT_LIST";
export const UPDATE_PRODUCT_TO_VAULT = "UPDATE_PRODUCT_TO_VAULT";
export const GET_PRODUCT_FROM_VAULT = "GET_PRODUCT_FROM_VAULT";
export const FILTER_PRODUCT = "FILTER_PRODUCT";
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB";
export const GET_PRODUCT_TEMPLATE = "GET_PRODUCT_TEMPLATE";
export const STORE_GET_PRODUCT_TEMPLATE = "STORE_GET_PRODUCT_TEMPLATE";
export const TOGGLE_SCAN_FLAG = "TOGGLE_SCAN_FLAG";
export const UPDATE_PRODUCT_TEMPLATE = "UPDATE_PRODUCT_TEMPLATE";
export const STORE_PRODUCT_TEMPLATE = "STORE_PRODUCT_TEMPLATE";
export const FILTER_BANKING_TYPE = "FILTER_BANKING_TYPE";

// Account Information
export const UPDATE_PRODUCT_INFORMATION = "UPDATE_PRODUCT_INFORMATION";
export const UPDATE_PRODUCT_INFORMATION_ERROR =
  "UPDATE_PRODUCT_INFORMATION_ERROR";
export const GET_INTENDED_USE = "GET_INTENDED_USE";
export const INTENDED_USE_ERROR = "INTENDED_USE_ERROR";
export const ADD_INTENDED_USE = "ADD_INTENDED_USE";
export const GET_PRODUCT_INFORMATION = "GET_PRODUCT_INFORMATION";
export const GET_PRODUCT_INFORMATION_ERROR = "GET_PRODUCT_INFORMATION_ERROR";

// Beneficiary
export const GET_BENEFICIARY_TYPE = "GET_BENEFICIARY_TYPE";
export const ADD_BENEFICIARY_TYPE_LIST = "ADD_BENEFICIARY_TYPE_LIST";
export const GET_BENEFICIARY_TYPE_ERROR = "GET_BENEFICIARY_TYPE_ERROR";
export const GET_BENEFICIARY_RELATION = "GET_BENEFICIARY_RELATION";
export const ADD_BENEFICIARY_RELATION_LIST = "ADD_BENEFICIARY_RELATION_LIST";
export const GET_BENEFICIARY_RELATION_ERROR = "GET_BENEFICIARY_RELATION_ERROR";
export const ADD_BENEFICIARIES = "ADD_BENEFICIARIES";
export const ADD_BENEFICIARIES_SUCCESS = "ADD_BENEFICIARIES_SUCCESS";
export const ADD_BENEFICIARIES_ERROR = "ADD_BENEFICIARIES_ERROR";
export const GET_BENEFICIARIES = "GET_BENEFICIARIES";
export const GET_BENEFICIARIES_ERROR = "GET_BENEFICIARIES_ERROR";
export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const EDIT_BENEFICIARIES = "EDIT_BENEFICIARIES";
export const EDIT_BENEFICIARIES_SUCCESS = "EDIT_BENEFICIARIES_SUCCESS";
export const EDIT_BENEFICIARIES_ERROR = "EDIT_BENEFICIARIES_ERROR";

// SpousalInfo
export const SET_SPOUSAL_INFO = "SET_SPOUSAL_INFO";
export const GET_SPOUSAL_INFORMATION = "GET_SPOUSAL_INFORMATION";
export const GET_SPOUSAL_INFORMATION_ERROR = "GET_SPOUSAL_INFORMATION_ERROR";
export const ADD_SET_SPOUSAL_INFO = "ADD_SET_SPOUSAL_INFO";
export const SET_SPOUSAL_INFO_ERROR = "SET_SPOUSAL_INFO_ERROR";

// Joint Application
export const ADD_APPLICATION_SLOT = "ADD_APPLICATION_SLOT";
export const ADD_JOINT_APPLICANTS = "ADD_JOINT_APPLICANTS";
export const ADD_JOINT_APPLICANTS_SUCCESS = "ADD_JOINT_APPLICANTS_SUCCESS";
export const ADD_JOINT_APPLICANTS_ERROR = "ADD_JOINT_APPLICANTS_ERROR";
export const ADD_APPLICATION_SLOT_SUCCESS = "ADD_APPLICATION_SLOT_SUCCESS";
export const ADD_APPLICATION_SLOT_ERROR = "ADD_APPLICATION_SLOT_ERROR";
export const INVITE_APPLICATION_SLOT = "INVITE_APPLICATION_SLOT";
export const INVITE_APPLICATION_SLOT_SUCCESS =
  "INVITE_APPLICATION_SLOT_SUCCESS";
export const INVITE_APPLICATION_SLOT_ERROR = "INVITE_APPLICATION_SLOT_ERROR";
export const GET_APPLICATION_PROPERTY = "GET_APPLICATION_PROPERTY";
export const GET_APPLICATION_PROPERTY_SUCCESS =
  "GET_APPLICATION_PROPERTY_SUCCESS";
export const GET_APPLICATION_PROPERTY_ERROR = "GET_APPLICATION_PROPERTY_ERROR";
export const DELETE_APPLICATION_SLOT = "DELETE_APPLICATION_SLOT";
export const DELETE_APPLICATION_SLOT_SUCCESS =
  "DELETE_APPLICATION_SLOT_SUCCESS";
export const DELETE_APPLICATION_SLOT_ERROR = "DELETE_APPLICATION_SLOT_ERROR";

export const GET_APPLICANT_TYPE = "GET_APPLICANT_TYPE";
export const GET_APPLICANT_TYPE_SUCCESS = "GET_APPLICANT_TYPE_SUCCESS";
export const GET_APPLICANT_TYPE_ERROR = "GET_APPLICANT_TYPE_ERROR";

export const GET_BUNDLE_PRODUCT_RELATION = "GET_BUNDLE_PRODUCT_RELATION";
export const GET_BUNDLE_PRODUCT_RELATION_SUCCESS =
  "GET_BUNDLE_PRODUCT_RELATION_SUCCESS";
export const GET_BUNDLE_PRODUCT_RELATION_ERROR =
  "GET_BUNDLE_PRODUCT_RELATION_ERROR";

export const ADD_BUNDLE_PRODUCT = "ADD_BUNDLE_PRODUCT";
export const ADD_BUNDLE_PRODUCT_SUCCESS = "ADD_BUNDLE_PRODUCT_SUCCESS";
export const ADD_BUNDLE_PRODUCT_ERROR = "ADD_BUNDLE_PRODUCT_SUCCESS";

export const DELETE_DEBIT_CARD = "DELETE_DEBIT_CARD";
export const DELETE_DEBIT_CARD_SUCCESS = "DELETE_DEBIT_CARD_SUCCESS";
export const DELETE_DEBIT_CARD_ERROR = "DELETE_DEBIT_CARD_ERROR";
export const DEBIT_CARD_SHOWN = "DEBIT_CARD_SHOWN";
export const STORE_DEBIT_CARD = "STORE_DEBIT_CARD";

export const ADD_GLOBAL_VAULT = "ADD_GLOBAL_VAULT";
export const ADD_GLOBAL_VAULT_SUCCESS = "ADD_GLOBAL_VAULT_SUCCESS";
export const ADD_GLOBAL_VAULT_ERROR = "ADD_GLOBAL_VAULT_ERROR";

export const GET_APPLICANT_VAULT = "GET_APPLICANT_VAULT";
export const GET_APPLICANT_VAULT_SUCCESS = "GET_APPLICANT_VAULT_SUCCESS";
export const GET_APPLICANT_VAULT_ERROR = "GET_APPLICANT_VAULT_ERROR";
export const GET_GLOBAL_VAULT = "GET_GLOBAL_VAULT";
export const GET_GLOBAL_VAULT_SUCCESS = "GET_GLOBAL_VAULT_SUCCESS";
export const GET_GLOBAL_VAULT_ERROR = "GET_GLOBAL_VAULT_ERROR";

export const APPLICANT_CREATE_REQUEST = "APPLICANT_CREATE_REQUEST";
export const APPLICANT_CREATE_REQUEST_SUCCESS =
  "APPLICANT_CREATE_REQUEST_SUCCESS";
export const APPLICANT_CREATE_REQUEST_ERROR = "APPLICANT_CREATE_REQUEST_ERROR";

export const APPLICANT_CREATE_SELF = "APPLICANT_CREATE_SELF";
export const APPLICANT_CREATE_SELF_SUCCESS = "APPLICANT_CREATE_SELF_SUCCESS";
export const APPLICANT_CREATE_SELF_ERROR = "APPLICANT_CREATE_SELF_ERROR";

export const OTP_VERIFY_INVITE = "OTP_VERIFY_INVITE";
export const OTP_VERIFY_INVITE_SUCCESS = "OTP_VERIFY_INVITE_SUCCESS";
export const OTP_VERIFY_INVITE_ERROR = "OTP_VERIFY_INVITE_ERROR";

// multi product skipping steps
export const SET_NOT_FIRST_FLAG = "SET_NOT_FIRST_FLAG";
export const STORE_COMPLETED_PRODUCT_IN_A_SESSION =
  "STORE_COMPLETED_PRODUCT_IN_A_SESSION";

export const ENABLE_ONLINE_BANKING = "ENABLE_ONLINE_BANKING";
export const UPLOAD_ONLINE_BANKING = "UPLOAD_ONLINE_BANKING";
export const ENABLE_ONLINE_BANKING_SUCCESS = "ENABLE_ONLINE_BANKING_SUCCESS";
export const ENABLE_ONLINE_BANKING_ERROR = "ENABLE_ONLINE_BANKING_ERROR";
export const SET_ONLINE_BANKING = "SET_ONLINE_BANKING";

export const FILL_APPLICATION_SLOT = "FILL_APPLICATION_SLOT";
export const FILL_APPLICATION_SLOT_SUCCESS = "FILL_APPLICATION_SLOT_SUCCESS";
export const FILL_APPLICATION_SLOT_ERROR = "FILL_APPLICATION_SLOT_ERROR";

export const ESIGN_CREATE = "ESIGN_CREATE";
export const ESIGN_CREATE_SUCCESS = "ESIGN_CREATE_SUCCESS";
export const ESIGN_CREATE_ERROR = "ESIGN_CREATE_ERROR";

export const ESIGN_SEND = "ESIGN_SEND";
export const ESIGN_SEND_SUCCESS = "ESIGN_SEND_SUCCESS";
export const ESIGN_SEND_ERROR = "ESIGN_SEND_ERROR";

export const ESIGN_GET = "ESIGN_GET";
export const ESIGN_GET_SUCCESS = "ESIGN_GET_SUCCESS";
export const ESIGN_GET_ERROR = "ESIGN_GET_ERROR";

export const SET_IN_SESSION_JOINT_APPLICANT = "SET_IN_SESSION_JOINT_APPLICANT";
export const SET_IN_SESSION_JOINT_VAULT = "SET_IN_SESSION_JOINT_VAULT";
export const SET_E_SIGNATURE_ENABLED = "SET_E_SIGNATURE_ENABLED";

export const APPLICANT_CREATE_CONFIRM = "APPLICANT_CREATE_CONFIRM";
export const APPLICANT_CREATE_CONFIRM_SUCCESS =
  "APPLICANT_CREATE_CONFIRM_SUCCESS";
export const APPLICANT_CREATE_CONFIRM_ERROR = "APPLICANT_CREATE_CONFIRM_ERROR";

export const TOGGLE_BACK_FLAG = "TOGGLE_BACK_FLAG";

export const GET_LOAN_PRODUCT = "GET_LOAN_PRODUCT";
export const GET_LOAN_PRODUCT_SUCCESS = "GET_LOAN_PRODUCT_SUCCESS";
export const GET_LOAN_PRODUCT_ERROR = "GET_LOAN_PRODUCT_ERROR";

export const LOAN_ADD_ASSET = "LOAN_ADD_ASSET";
export const LOAN_ADD_ASSET_SUCCESS = "LOAN_ADD_ASSET_SUCCESS";
export const LOAN_ADD_ASSET_ERROR = "LOAN_ADD_ASSET_ERROR";

export const LOAN_UPDATE_ASSET = "LOAN_UPDATE_ASSET";
export const LOAN_UPDATE_ASSET_SUCCESS = "LOAN_UPDATE_ASSET_SUCCESS";
export const LOAN_UPDATE_ASSET_ERROR = "LOAN_UPDATE_ASSET_ERROR";

export const LOAN_GET_ASSET = "LOAN_GET_ASSET";
export const LOAN_GET_ASSET_SUCCESS = "LOAN_GET_ASSET_SUCCESS";
export const LOAN_GET_ASSET_ERROR = "LOAN_GET_ASSET_ERROR";

export const LOAN_ADD_INCOME = "LOAN_ADD_INCOME";
export const LOAN_ADD_INCOME_SUCCESS = "LOAN_ADD_INCOME_SUCCESS";
export const LOAN_ADD_INCOME_ERROR = "LOAN_ADD_INCOME_ERROR";

export const LOAN_UPDATE_INCOME = "LOAN_UPDATE_INCOME";
export const LOAN_UPDATE_INCOME_SUCCESS = "LOAN_UPDATE_INCOME_SUCCESS";
export const LOAN_UPDATE_INCOME_ERROR = "LOAN_UPDATE_INCOME_ERROR";

export const LOAN_GET_INCOME = "LOAN_GET_INCOME";
export const LOAN_GET_INCOME_SUCCESS = "LOAN_GET_INCOME_SUCCESS";
export const LOAN_GET_INCOME_ERROR = "LOAN_GET_INCOME_ERROR";

export const LOAN_ADD_EXPENSE = "LOAN_ADD_EXPENSE";
export const LOAN_ADD_EXPENSE_SUCCESS = "LOAN_ADD_EXPENSE_SUCCESS";
export const LOAN_ADD_EXPENSE_ERROR = "LOAN_ADD_EXPENSE_ERROR";

export const LOAN_UPDATE_EXPENSE = "LOAN_UPDATE_EXPENSE";
export const LOAN_UPDATE_EXPENSE_SUCCESS = "LOAN_UPDATE_EXPENSE_SUCCESS";
export const LOAN_UPDATE_EXPENSE_ERROR = "LOAN_UPDATE_EXPENSE_ERROR";

export const LOAN_GET_EXPENSE = "LOAN_GET_EXPENSE";
export const LOAN_GET_EXPENSE_SUCCESS = "LOAN_GET_EXPENSE_SUCCESS";
export const LOAN_GET_EXPENSE_ERROR = "LOAN_GET_EXPENSE_ERROR";

export const LOAN_ADD_LIABILITIES = "LOAN_ADD_LIABILITIES";
export const LOAN_ADD_LIABILITIES_SUCCESS = "LOAN_ADD_LIABILITIES_SUCCESS";
export const LOAN_ADD_LIABILITIES_ERROR = "LOAN_ADD_LIABILITIES_ERROR";

export const LOAN_UPDATE_LIABILITIES = "LOAN_UPDATE_LIABILITIES";
export const LOAN_UPDATE_LIABILITIES_SUCCESS =
  "LOAN_UPDATE_LIABILITIES_SUCCESS";
export const LOAN_UPDATE_LIABILITIES_ERROR = "LOAN_UPDATE_LIABILITIES_ERROR";

export const LOAN_GET_LIABILITIES = "LOAN_GET_LIABILITIES";
export const LOAN_GET_LIABILITIES_SUCCESS = "LOAN_GET_LIABILITIES_SUCCESS";
export const LOAN_GET_LIABILITIES_ERROR = "LOAN_GET_LIABILITIES_ERROR";

export const LOAN_ADD_VEHICLE = "LOAN_ADD_VEHICLE";
export const LOAN_ADD_VEHICLE_SUCCESS = "LOAN_ADD_VEHICLE_SUCCESS";
export const LOAN_ADD_VEHICLE_ERROR = "LOAN_ADD_VEHICLE_ERROR";

export const LOAN_UPDATE_VEHICLE = "LOAN_UPDATE_VEHICLE";
export const LOAN_UPDATE_VEHICLE_SUCCESS = "LOAN_UPDATE_VEHICLE_SUCCESS";
export const LOAN_UPDATE_VEHICLE_ERROR = "LOAN_UPDATE_VEHICLE_ERROR";

export const LOAN_GET_VEHICLE = "LOAN_GET_VEHICLE";
export const LOAN_GET_VEHICLE_SUCCESS = "LOAN_GET_LIABILITIES_SUCCESS";
export const LOAN_GET_VEHICLE_ERROR = "LOAN_GET_VEHICLE_ERROR";

export const LOAN_ADD_REAL_ESTATE = "LOAN_ADD_REAL_ESTATE";
export const LOAN_ADD_REAL_ESTATE_SUCCESS = "LOAN_ADD_REAL_ESTATE_SUCCESS";
export const LOAN_ADD_REAL_ESTATE_ERROR = "LOAN_ADD_REAL_ESTATE_ERROR";

export const LOAN_UPDATE_REAL_ESTATE = "LOAN_UPDATE_REAL_ESTATE";
export const LOAN_UPDATE_REAL_ESTATE_SUCCESS =
  "LOAN_UPDATE_REAL_ESTATE_SUCCESS";
export const LOAN_UPDATE_REAL_ESTATE_ERROR = "LOAN_UPDATE_REAL_ESTATE_ERROR";

export const LOAN_GET_REAL_ESTATE = "LOAN_GET_REAL_ESTATE";
export const LOAN_GET_REAL_ESTATE_SUCCESS = "LOAN_GET_REAL_ESTATE_SUCCESS";
export const LOAN_GET_REAL_ESTATE_ERROR = "LOAN_GET_REAL_ESTATE_ERROR";

export const LOAN_UPDATE_SECURITY = "LOAN_UPDATE_SECURITY";
export const LOAN_UPDATE_SECURITY_SUCCESS = "LOAN_UPDATE_SECURITY_SUCCESS";
export const LOAN_UPDATE_SECURITY_ERROR = "LOAN_UPDATE_SECURITY_ERROR";

export const LOAN_UPDATE_LENDING_PRODUCT = "LOAN_UPDATE_LENDING_PRODUCT";
export const LOAN_UPDATE_LENDING_PRODUCT_SUCCESS =
  "LOAN_UPDATE_LENDING_PRODUCT_SUCCESS";
export const LOAN_UPDATE_LENDING_PRODUCT_ERROR =
  "LOAN_UPDATE_LENDING_PRODUCT_ERROR";

export const LOAN_GET_LOAN_SECURITY = "LOAN_GET_LOAN_SECURITY";
export const LOAN_GET_LOAN_SECURITY_SUCCESS = "LOAN_GET_LOAN_SECURITY_SUCCESS";
export const LOAN_GET_LOAN_SECURITY_ERROR = "LOAN_GET_LOAN_SECURITY_ERROR";

export const LOAN_DELETE_FINANCIAL = "LOAN_DELETE_FINANCIAL";
export const LOAN_DELETE_FINANCIAL_SUCCESS = "LOAN_DELETE_FINANCIAL_SUCCESS";
export const LOAN_DELETE_FINANCIAL_ERROR = "LOAN_DELETE_FINANCIAL_ERROR";

export const LOAN_DELETE_LOAN_SECURITY = "LOAN_DELETE_LOAN_SECURITY";
export const LOAN_DELETE_LOAN_SECURITY_SUCCESS =
  "LOAN_DELETE_LOAN_SECURITY_SUCCESS";
export const LOAN_DELETE_LOAN_SECURITY_ERROR =
  "LOAN_DELETE_LOAN_SECURITY_ERROR";

export const SET_JOINT_APPLICANT_OTP = "SET_JOINT_APPLICANT_OTP";
export const UPDATE_LOAN_PRODUCT_FORM = "UPDATE_LOAN_PRODUCT_FORM";
export const GET_FINANCIALANALYSYS_LIST = "GET_FINANCIALANALYSYS_LIST";
export const GET_FINANCIALANALYSYS_LIST_SUCCESS =
  "GET_FINANCIALANALYSYS_LIST_SUCCESS";
export const GET_FINANCIALANALYSYS_LIST_ERROR =
  "GET_FINANCIALANALYSYS_LIST_ERROR";

export const GET_FINANCIALANALYSYS_FINANCIAL_LIST =
  "GET_FINANCIALANALYSYS_FINANCIAL_LIST";
export const GET_FINANCIALANALYSYS_FINANCIAL_LIST_SUCCESS =
  "GET_FINANCIALANALYSYS_FINANCIAL_LIST_SUCCESS";
export const GET_FINANCIALANALYSYS_FINANCIAL_LIST_ERROR =
  "GET_FINANCIALANALYSYS_FINANCIAL_LIST_ERROR";

export const GET_LENDING_PRODUCT = "GET_LENDING_PRODUCT";
export const GET_LENDING_PRODUCT_SUCCESS = "GET_LENDING_PRODUCT_SUCCESS";
export const GET_LENDING_PRODUCT_ERROR = "GET_LENDING_PRODUCT_ERROR";

export const UPDATE_LENDING_PRODUCT = "UPDATE_LENDING_PRODUCT";
export const UPDATE_LENDING_PRODUCT_SUCCESS = "UPDATE_LENDING_PRODUCT_SUCCESS";
export const UPDATE_LENDING_PRODUCT_ERROR = "UPDATE_LENDING_PRODUCT_ERROR";

export const GET_DOCUMENT_LIST = "GET_DOCUMENT_LIST";
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS";
export const GET_DOCUMENT_LIST_ERROR = "GET_DOCUMENT_LIST_ERROR";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";

export const GET_APPLICANT_BANKING_PRODUCT_LIST =
  "GET_APPLICANT_BANKING_PRODUCT_LIST";
export const GET_APPLICANT_BANKING_PRODUCT_LIST_SUCCESS =
  "GET_APPLICANT_BANKING_PRODUCT_LIST_SUCCESS";
export const GET_APPLICANT_BANKING_PRODUCT_LIST_ERROR =
  "GET_APPLICANT_BANKING_PRODUCT_LIST_ERROR";

export const ADDRESS_CORRECTION = "ADDRESS_CORRECTION";
export const ADDRESS_CORRECTION_SUCCESS = "ADDRESS_CORRECTION_SUCCESS";
export const ADDRESS_CORRECTION_ERROR = "ADDRESS_CORRECTION_ERROR";

export const CREATE_ADDRESS_KEY = "CREATE_ADDRESS_KEY";
export const CREATE_ADDRESS_KEY_SUCCESS = "CREATE_ADDRESS_KEY_SUCCESS";
export const CREATE_ADDRESS_KEY_ERROR = "CREATE_ADDRESS_KEY_ERROR";

export const SUPPORT_MESSAGE = "SUPPORT_MESSAGE";
export const SUPPORT_MESSAGE_SUCCESS = "SUPPORT_MESSAGE_SUCCESS";
export const SUPPORT_MESSAGE_ERROR = "SUPPORT_MESSAGE_ERROR";

export const GET_OCCUPATION_CODES = "GET_OCCUPATION_CODES";
export const GET_REASON_CODES = "GET_REASON_CODES";
export const GET_EMPLOYER_POSITION_CODES = "GET_EMPLOYER_POSITION_CODES";

export const ADD_APPLICANT = "ADD_APPLICANT";
export const FIND_APPLICANT = "FIND_APPLICANT";

export const GET_TERM_PRODUCT = "GET_TERM_PRODUCT";
export const UPDATE_TERM_PRODUCT = "UPDATE_TERM_PRODUCT";

export const GET_APPLICATION = "GET_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";

export const GET_CONTRACT = "GET_CONTRACT";
export const UPDATE_CONTRACT = "UPDATE_CONTRACT";

export const ESIGN_CHECK = "ESIGN_CHECK";
export const GET_CENTRAL = "GET_CENTRAL";

export const GET_COUNTRIES_TAX = "GET_COUNTRIES_TAX";

export const GET_DEBIT_CARD_NUMBER = "GET_DEBIT_CARD_NUMBER";

export const ESC_SEARCH = "ESC_SEARCH";
export const ESC_REQUEST = "ESC_REQUEST";
export const ESC_PROFILE = "ESC_PROFILE";

export const STORE_QUESTIONNAIRE = "STORE_QUESTIONNAIRE";
export const STORE_QUESTIONNAIRE_PRODUCT = "STORE_QUESTIONNAIRE_PRODUCT";

export const SUBMIT_QUESTIONNAIRE = "SUBMIT_QUESTIONNAIRE";
export const UPLOAD_QUESTIONNAIRE = "UPLOAD_QUESTIONNAIRE";

export const GET_PROSPECT = "GET_PROSPECT";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const KYC_INVITE = "KYC_INVITE";

export const GET_OWNER_APPLICANT = "GET_OWNER_APPLICANT";
export const GET_VAULT_MEMBER = "GET_VAULT_MEMBER";
export const UPDATE_VAULT_MEMBER = "UPDATE_VAULT_MEMBER";

export const UPDATE_PERSONAL = "UPDATE_PERSONAL";
export const STORE_MEMBERSHIPS_DATA = "STORE_MEMBERSHIPS_DATA";
