import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

// Components
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

// Constants
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";

// Styles
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class BusinessMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foundMemberList: [],
      additionalMemberList: [
        {
          firstName: "",
          lastName: "",
          email: "",
          mobileNo: "",
          owner: false,
          officeDirector: false,
          authorizedSignatory: false,
          error: [],
        },
      ],
      isFoundMember: true,
      isAdditionalSignatories: "no",

      errors: {
        firstNameErrorList: [],
        lastNameErrorList: [],
        emailErrorList: [],
        mobileNoErrorList: [],
        memberTypeErrorList: [],
      },
      formIsValid: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    console.log("active product", activeProduct);
    if (prevProps !== this.props) {
      const isAdditionalSignatories =
        activeProduct?.isAdditionalSignatories !== "" &&
        activeProduct?.isAdditionalSignatories !== undefined
          ? activeProduct?.isAdditionalSignatories
          : "no";
      const isFoundMember = activeProduct?.parties?.length > 0 ? true : false;
      let foundMemberList = activeProduct?.foundMemberList;
      if (!foundMemberList) {
        foundMemberList = isFoundMember
          ? activeProduct?.parties?.map((member) => {
              return {
                ...member,
                owner: member?.designation?.toLowerCase().includes("owner"),
                officeDirector: member?.designation
                  ?.toLowerCase()
                  .includes("director"),
                authorizedSignatory: member?.designation
                  ?.toLowerCase()
                  .includes("signatory"),
              };
            })
          : [];
      }
      const additionalMemberList = activeProduct?.additionalMemberList ?? [];
      this.setState({
        isFoundMember,
        foundMemberList,
        additionalMemberList,
        isAdditionalSignatories,
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const {
      foundMemberList,
      additionalMemberList,
      isAdditionalSignatories,
    } = this.state;
    const allMemberList = [...foundMemberList, ...additionalMemberList];
    let valid = this.verifyData();
    if (valid) {
      handleContinue({
        foundMemberList,
        additionalMemberList,
        allMemberList,
        isAdditionalSignatories,
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_MEMBER);
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  handleMemberChange = (raw, prop) => (event) => {
    console.log("handleMemberChange", raw, prop, event.currentTarget.value);
    const { additionalMemberList, errors, foundMemberList } = this.state;
    const item = raw;
    const errorObject = `${prop}${STRINGS.COMMON.ERROR_LIST}`;
    if (event.currentTarget.type === "checkbox") {
      item[prop] = !item[prop];
    } else item[prop] = event.currentTarget.value;

    this.setState({
      foundMemberList,
      additionalMemberList,
      errors: {
        ...errors,
        [errorObject]: "",
      },
    });
  };
  handleTaxOthersChange = (raw, prop) => (event) => {
    console.log("handleTaxOthersChange", raw, prop);
    const { businessFileTaxesOthersList, errors } = this.state;
    const tax = raw;
    tax[prop] = event.currentTarget.value;
    this.setState({
      businessFileTaxesOthersList,
      errors: {
        ...errors,
        businessFileTaxesOthersCountryErrorList: "",
        businessFileTaxesOthersTinErrorList: "",
      },
    });
  };

  handleChoice = (name, value) => {
    console.log("handleChoice", name, value);
    const { additionalMemberList } = this.state;
    if (
      value === "yes" &&
      (!additionalMemberList || additionalMemberList.length === 0)
    ) {
      this.handleAddAdditionalMember();
    }
    if (value === "no") {
      this.setState({ additionalMemberList: [] });
    }
    this.setState({
      [name]: value,
    });
  };

  handleAddAdditionalMember = (event) => {
    console.log("handleAddAdditionalMember");
    if (this.verifyData()) {
      const data = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNo: "",
      };
      const action = {
        type: "add",
        data: data,
      };
      this.updateAdditionalMemberList(action);
    }
  };

  handleDeleteAdditionalMember = (idx) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateAdditionalMemberList(action);
  };

  updateAdditionalMemberList = (action) => {
    const { additionalMemberList, errors } = this.state;
    if (action.type === "add") {
      additionalMemberList.push(action.data);
    } else if (action.type === "remove") {
      if (action.index === additionalMemberList.length - 1) {
        this.setState({
          errors: {
            ...errors,
            firstNameErrorList: [],
            lastNameErrorList: [],
            emailErrorList: [],
            mobileNoErrorList: [],
            memberTypeErrorList: [],
          },
        });
      }
      if (action.index > -1) {
        additionalMemberList.splice(action.index, 1);
      }
    }
    this.setState({ additionalMemberList });
    if (additionalMemberList.length === 0) {
      this.handleChoice("isAdditionalSignatories", "no");
    }
  };

  verifyData = () => {
    let valid = true;
    const {
      foundMemberList,
      additionalMemberList,
      isAdditionalSignatories,
      errors,
    } = this.state;

    foundMemberList.forEach((raw, idx) => {
      foundMemberList[idx].errorEmail = checkErrors(
        foundMemberList[idx].email,
        VALIDATIONS.BUSINESS_MEMBER.EMAIL
      );
      foundMemberList[idx].errorMobileNo = checkErrors(
        foundMemberList[idx].mobileNo,
        VALIDATIONS.BUSINESS_MEMBER.MOBILE_NO
      );
    });
    foundMemberList.forEach((data, idx) => {
      if (
        foundMemberList[idx].errorEmail?.length > 0 ||
        foundMemberList[idx].errorMobileNo?.length > 0
      ) {
        valid = false;
      }
    });

    additionalMemberList.forEach((raw, idx) => {
      additionalMemberList[idx].errorFirstName = checkErrors(
        additionalMemberList[idx].firstName,
        VALIDATIONS.BUSINESS_MEMBER.FIRSTNAME
      );
      additionalMemberList[idx].errorLastName = checkErrors(
        additionalMemberList[idx].lastName,
        VALIDATIONS.BUSINESS_MEMBER.LASTNAME
      );
      additionalMemberList[idx].errorEmail = checkErrors(
        additionalMemberList[idx].firstName,
        VALIDATIONS.BUSINESS_MEMBER.FIRSTNAME
      );
      additionalMemberList[idx].errorEmail = checkErrors(
        additionalMemberList[idx].email,
        VALIDATIONS.BUSINESS_MEMBER.EMAIL
      );
      additionalMemberList[idx].errorMobileNo = checkErrors(
        additionalMemberList[idx].mobileNo,
        VALIDATIONS.BUSINESS_MEMBER.MOBILE_NO
      );
      if (
        !additionalMemberList[idx].owner &&
        !additionalMemberList[idx].officeDirector &&
        !additionalMemberList[idx].authorizedSignatory
      ) {
        additionalMemberList[idx].errorType = ["Please select member type"];
      } else {
        additionalMemberList[idx].errorType = [];
      }
    });
    additionalMemberList.forEach((data, idx) => {
      if (
        additionalMemberList[idx].errorFirstName?.length > 0 ||
        additionalMemberList[idx].errorLastName?.length > 0 ||
        additionalMemberList[idx].errorEmail?.length > 0 ||
        additionalMemberList[idx].errorMobileNo?.length > 0
      ) {
        valid = false;
      }
    });

    this.setState({
      foundMemberList,
      additionalMemberList,
    });

    return valid;
  };

  render() {
    const { steps, activeStepID } = this.props;
    const {
      additionalMemberList,
      foundMemberList,
      isFoundMember,
      isAdditionalSignatories,

      errors,
    } = this.state;

    const signatoryDetailComponent = (member, index, type) => (
      <>
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {`#${index + 1}`} {STRINGS.BUSINESS_MEMBER.FORM.GROUP_LABEL}
            {type === "additional" && (
              <button
                type="button"
                className="[ fas fa-trash ]"
                onClick={() => this.handleDeleteAdditionalMember(index)}
                aria-label="remove"
              >
                <span className="[ sr-only ]">
                  {STRINGS.INCOME.FORM.REMOVE_INCOME}
                </span>
              </button>
            )}
          </div>

          <div className="[ row ]">
            <div className="[ col-sm-6 col-lg-6 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.FIRSTNAME_LABEL}
              </div>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className={
                  member.error
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ] "
                }
                disabled={type === "found"}
                placeholder={STRINGS.BUSINESS_MEMBER.FORM.FIRSTNAME_LABEL}
                value={member?.firstName}
                onChange={this.handleMemberChange(
                  member,
                  "firstName",
                  index,
                  type
                )}
              />
              <div className="[ error-msg ]">
                {member.errorFirstName ? member.errorFirstName[0] : ""}
              </div>
            </div>

            <div className="[ col-sm-6 col-lg-6 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.LASTNAME_LABEL}
              </div>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className={
                  errors?.lastNameErrorList &&
                  errors?.lastNameErrorList.length > 0 &&
                  type === "additional" &&
                  additionalMemberList.length === index + 1
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ] "
                }
                disabled={type === "found"}
                placeholder={STRINGS.BUSINESS_MEMBER.FORM.LASTNAME_LABEL}
                value={member?.lastName}
                onChange={this.handleMemberChange(
                  member,
                  "lastName",
                  index,
                  type
                )}
              />
              <div className="[ error-msg ]">
                {member.errorLastName ? member.errorLastName[0] : ""}
              </div>
            </div>
          </div>

          <div className="[ row ]">
            <div className="[ col-sm-6 col-lg-6 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.EMAIL_LABEL}
              </div>
              <input
                type="text"
                id="email"
                name="email"
                className={
                  member.errorEmail?.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ] "
                }
                // disabled={type === "found"}
                placeholder={STRINGS.BUSINESS_MEMBER.FORM.EMAIL_LABEL}
                value={member?.email}
                onChange={this.handleMemberChange(member, "email", index, type)}
              />
              <div className="[ error-msg ]">
                {member.errorEmail ? member.errorEmail[0] : ""}
              </div>
            </div>

            <div className="[ col-sm-6 col-lg-6 ]">
              <div className="[ form-label ]">
                {STRINGS.BUSINESS_MEMBER.FORM.MOBILE_NO_LABEL}
              </div>

              <NumberFormat
                format={AppConstants.REGISTRATION.NUMBERFORMAT}
                className={
                  member.errorMobileNo?.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ] "
                }
                // disabled={type === "found"}
                type="text"
                name="mobileNo"
                value={member?.mobileNo}
                placeholder={STRINGS.BUSINESS_MEMBER.FORM.MOBILE_NO_LABEL}
                onChange={this.handleMemberChange(
                  member,
                  "mobileNo",
                  index,
                  type
                )}
              />
              <div className="[ error-msg ]">
                {member.errorMobileNo ? member.errorMobileNo[0] : ""}
              </div>
            </div>
          </div>
          <hr />
          <div className="[ row ]">
            <div className="[ col-sm-4 col-lg-4 ]">
              <div className="[ checkbox custom-control custom-checkbox ]">
                <input
                  type="checkbox"
                  name={"owner"}
                  value={member?.owner}
                  checked={member?.owner}
                  id={`${type}owner${index + 1}`}
                  onChange={this.handleMemberChange(member, "owner")}
                  className="[ custom-control-input ]"
                  // disabled={type === "found"}
                />
                <label
                  htmlFor={`${type}owner${index + 1}`}
                  className="[ checkbox-text custom-control-label ]"
                >
                  {STRINGS.BUSINESS_MEMBER.OWNER}
                </label>
              </div>
            </div>

            <div className="[ col-sm-4 col-lg-4 ]">
              <div className="[ checkbox custom-control custom-checkbox ]">
                <input
                  type="checkbox"
                  name={"officeDirector"}
                  value={member?.officeDirector}
                  checked={member?.officeDirector}
                  id={`${type}officeDirector${index + 1}`}
                  onChange={this.handleMemberChange(member, "officeDirector")}
                  className="[ custom-control-input ]"
                  // disabled={type === "found"}
                />
                <label
                  htmlFor={`${type}officeDirector${index + 1}`}
                  className="[ checkbox-text custom-control-label ]"
                >
                  {STRINGS.BUSINESS_MEMBER.OFFICE_DIRECTOR}
                </label>
              </div>
            </div>

            <div className="[ col-sm-4 col-lg-4 ]">
              <div className="[ checkbox custom-control custom-checkbox ]">
                <input
                  type="checkbox"
                  name={"authorizedSignatory"}
                  value={member?.authorizedSignatory}
                  checked={member?.authorizedSignatory}
                  id={`${type}authorizedSignatory${index + 1}`}
                  onChange={this.handleMemberChange(
                    member,
                    "authorizedSignatory"
                  )}
                  className="[ custom-control-input ]"
                  // disabled={type === "found"}
                />
                <label
                  htmlFor={`${type}authorizedSignatory${index + 1}`}
                  className="[ checkbox-text custom-control-label ]"
                >
                  {STRINGS.BUSINESS_MEMBER.AUTHORIZED_SIGNATORY}
                </label>
              </div>
            </div>
            <div className="[ col-sm-4 col-lg-4 ]">
              <div className="[ error-msg ]">
                {member.errorType ? member.errorType[0] : ""}
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const additionalMemberListComponent = additionalMemberList.map(
      (member, index) => (
        <>{signatoryDetailComponent(member, index, "additional")}</>
      )
    );

    const additionalMemberComponent = (
      <div className="[ mb-3 ]">
        <div className="[ drawer-content-container ] [ mb-3 ]">
          <div className="[ info-title ]">
            {STRINGS.BUSINESS_MEMBER.FORM.ADDITIONAL_AUTHORIZED_SIGNATORIES}
          </div>

          {additionalMemberListComponent}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                  onClick={(event) => this.handleAddAdditionalMember(event)}
                >
                  <span className="[ fas fa-plus ]" />
                  {STRINGS.BUSINESS_MEMBER.FORM.ADD_BTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const foundMemberListComponent = foundMemberList?.map((member, index) => (
      <>{signatoryDetailComponent(member, index, "found")}</>
    ));

    const isFoundComponent = (
      <>
        {isFoundMember ? (
          <>
            <div className="[ mb-3 ] [ alert alert-info ]">
              <div className="[ info-title ]">
                {`${STRINGS.BUSINESS_MEMBER.FOUND_MSG1} ${foundMemberList.length} ${STRINGS.BUSINESS_MEMBER.FOUND_MSG2}`}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="[ mb-3 ] [ alert alert-warning ]">
              <div className="[ info-title ]">
                {STRINGS.BUSINESS_MEMBER.WARNING_MSG}
              </div>
            </div>
          </>
        )}
      </>
    );
    const foundMemberComponent = (
      <>
        {isFoundComponent}
        {isFoundMember && (
          <>
            <div className="[ mb-3 ]">{foundMemberListComponent}</div>
          </>
        )}
      </>
    );

    const actionComponent = (
      <div className="[ business-members-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_MEMBER.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_MEMBER.SUB_HEADER}
          </div>

          <div className="[ row ]">
            <div className="[ col-12 ]">
              {foundMemberComponent}

              {/* Additional Members */}
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_MEMBER.FORM.ADDITIONAL_AUTHORIZED_QUESTION}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isAdditionalSignatories === "yes"
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="isAdditionalSignatories"
                      onClick={(event) =>
                        this.handleChoice(
                          "isAdditionalSignatories",
                          "yes",
                          event
                        )
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        isAdditionalSignatories === "no"
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="isAdditionalSignatories"
                      onClick={(event) =>
                        this.handleChoice(
                          "isAdditionalSignatories",
                          "no",
                          event
                        )
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
              </div>

              {isAdditionalSignatories === "yes" && additionalMemberComponent}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
                disabled={
                  !(
                    foundMemberList?.length > 0 ||
                    additionalMemberList?.length > 0
                  )
                }
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
        blockBack={true}
      />
    );
  }
}

BusinessMember.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessMember.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessMember;
