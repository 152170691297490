import BaseApiRequest from "./BaseApiRequest";
import { UPDATE_PERSONAL_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class UpdatePersonalRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = UPDATE_PERSONAL_URL;

  constructor(request) {
    super();

    this.body = request;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
