import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import CreateAddressInstanceKey from "Communication/ApiClasses/CreateAddressInstanceKey";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(STRINGS.ABOUTYOU.API_KEYS.CREATE_INSTANCE_KEY));
  const createAddressInstanceKey = new CreateAddressInstanceKey();
  try {
    const result = yield call(createAddressInstanceKey.getData);
    yield put({
      type: actionTypes.CREATE_ADDRESS_KEY_SUCCESS,
      response: result,
      formData: { ...action.requestData, ...action.additionalData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_ADDRESS_KEY_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.CREATE_INSTANCE_KEY));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.CREATE_ADDRESS_KEY, handleSaga);
}

export default sagaWatcher;
