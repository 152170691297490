import BaseApiRequest from "./BaseApiRequest";
import { METHOD } from "../Constants";
import { GENERATE_ACCESS_TOKEN } from "../ApiUrls";

export default class RefreshTokenRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    token: "",
  };

  constructor(token) {
    super();
    this.body.token = token;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return GENERATE_ACCESS_TOKEN;
  };

  getBody = () => {
    return this.body;
  };
}
