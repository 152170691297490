import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessOnline from "./BusinessOnline";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";

class BusinessOnlineContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const { continuehandler } = this.props;
    continuehandler(data, AppConstants.APPLICATIONSTEP.BUSINESS_ONLINE);
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessOnline
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
        />
      </>
    );
  }
}

BusinessOnlineContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessOnlineContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessOnlineContainer);

const BusinessOnlineWrapper = withBusinessHOC(BusinessOnlineContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessOnlineWrapper);
