import InstntApiRequest from "./InstntApiRequest";
import { INSTNT_URLS, DOCUMENT_VERIFY, LENDING_PRODUCT_URL } from "../ApiUrls";
import { METHOD } from "../Constants";
import { formURL } from "../../Utils/CommonUtilities";

export default class InstntDocumentVerify extends InstntApiRequest {
  method = METHOD.HTTP.POST;

  url = DOCUMENT_VERIFY;

  body = {
    formKey: INSTNT_URLS.INSTNT_ID,
    documentType: "",
    instnttxnid: "",
  };

  constructor(documentData) {
    super();
    this.body.documentType = documentData.docType;
    this.body.instnttxnid = documentData.instnttxnid;
    const paramList = [documentData.instnttxnid];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
