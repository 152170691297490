import React, { Component } from "react";
import PropTypes from "prop-types";

import STRINGS from "Constants/Strings";

import { PRODUCT_IMAGE_URL, COMMON_BUCKET_URL } from "Communication/ApiUrls";

import withVaultHOC from "Hoc/VaultHOC/VaultHOC";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import ConfirmPopupModal from "Components/Common/ConfirmPopupModal/ConfirmPopupModal";

import AppConstants from "Constants/AppConstants";

import FIInformation from "Constants/FIInformation";

class ProductCardWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        showModal: false,
        header: "",
        title: "",
        subtitle: "",
        description: "",
        productAddtoVault: true,
        tag: false,
      },
      confirmShowModal: false,
      confirmModal: {},
      selectCard: {},
    };
  }

  detailsClick = (card, slots) => {
    const { addToVault, isShoppingCartList, isCardAddedToVault } = this.props;

    const modal = {};
    modal.modalType = AppConstants.MODALTYPE.CUSTOM;
    modal.showModal = true;
    modal.header = card.productName;
    modal.title = card.productName;
    modal.subtitle = card.productCategory;
    modal.productDescription = card.productDescription;
    modal.productAddtoVault =
      card.addToVault && (addToVault || isShoppingCartList);
    modal.description = (
      <div dangerouslySetInnerHTML={{ __html: card.productDetails }} />
    );
    modal.btnText = !addToVault
      ? STRINGS.MARKETPLACE.BODY.REMOVE_FROM_VAULT
      : STRINGS.MARKETPLACE.BODY.ADD_TO_VAULT;
    modal.tag = isCardAddedToVault(card);
    modal.slots = slots;
    this.setState({ modal, selectCard: card });
  };

  handleProductDeleteClick = (card) => {
    const { confirmShowModal } = this.state;
    const prepareModal = {};
    prepareModal.title = STRINGS.VAULT_DASHBOARD.DELETE_TITLE;
    prepareModal.description = STRINGS.VAULT_DASHBOARD.DELETE_DESCRIPTION;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: prepareModal,
      selectCard: card,
    });
  };

  toggleModal = () => {
    this.setState({ modal: { showModal: false } });
  };

  /**
   * Handled Yes clicked event of delete product popup
   */
  handleYesClick = () => {
    const { deleteApplication } = this.props;
    const { selectCard } = this.state;
    console.log("edwin his here", selectCard);
    deleteApplication(selectCard);
  };

  /**
   * Handled No clicked event of delete product popup
   */
  handleNoClick = () => {};

  confirmToggleModal = () => {
    const { confirmShowModal } = this.state;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: {},
    });
  };

  handleAddToVault = (product) => {
    const { handleAddToVault } = this.props;
    const { selectCard } = this.state;
    handleAddToVault(product || selectCard);
  };

  render() {
    const {
      product,
      addToVault,
      isCardAddedToVault,
      isShoppingCartList,
      handleAddToVault,
      active,
      slots,
      applicantTypes,
    } = this.props;

    const { modal, confirmShowModal, confirmModal, selectCard } = this.state;
    const {
      modalType,
      showModal,
      title,
      description,
      btnText,
      subtitle,
      productAddtoVault,
      tag,
    } = modal;

    let productStatusContainerClass = "";
    let productStatusTitleClass = "";
    let productStatusText = "";
    if (product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_REVIEW) {
      productStatusContainerClass = "[ review-container ]";
      productStatusTitleClass = "[ review-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.IN_REVIEW;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_COMPLETED
    ) {
      productStatusContainerClass = "[ completed-container ]";
      productStatusTitleClass = "[ completed-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.COMPLETED;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_REJECTED
    ) {
      productStatusContainerClass = "[ rejected-container ]";
      productStatusTitleClass = "[ rejected-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.REJECTED;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_PROGRESS
    ) {
      productStatusContainerClass = "[ progress-container ]";
      productStatusTitleClass = "[ progress-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.IN_PROGRESS;
    }

    return (
      <>
        {confirmShowModal && (
          <ConfirmPopupModal
            title={confirmModal.title}
            description={confirmModal.description}
            toggleModal={this.confirmToggleModal}
            showModal={confirmShowModal}
            noBtnClick={this.handleNoClick}
            yesBtnClick={this.handleYesClick}
            closeBtnClick={this.handleNoClick}
          />
        )}
        {showModal && (
          <PopupModal
            modalType={modalType}
            title={title}
            subtitle={subtitle}
            description={description}
            popupBtnClick={
              addToVault
                ? this.handleAddToVault
                : () => {
                    this.handleProductDeleteClick(selectCard);
                  }
            }
            addToVault={productAddtoVault}
            showModal={showModal}
            btnText={btnText}
            showPopupModalButtons
            toggleModal={this.toggleModal}
            closeBtnClick={this.toggleModal}
            shortDescription={modal.productDescription}
            tag={tag}
            seeDetailsClick
            product={product}
            slots={modal.slots}
            applicantTypes={applicantTypes}
          />
        )}

        <div key={product.productId} className="[ product ] [ card ]">
          <div className="[ wrapper ]">
            <img
              src={`${PRODUCT_IMAGE_URL}${product.productLogo}`}
              className="[ product-image ]"
              alt="Image associated with product"
            />

            {isCardAddedToVault(product) && !isShoppingCartList && (
              <div className="[ added-container ]">
                <span className="[ added-title ]">
                  {STRINGS.MARKETPLACE.BODY.ADDED}
                </span>
              </div>
            )}

            {isShoppingCartList && (
              <span
                role="presentation"
                className="[ fas fa-trash ]"
                onClick={() => {
                  const modal = {};
                  modal.title = STRINGS.VAULT.DELETE_TITLE;
                  modal.description = STRINGS.VAULT.DELETE_DESCRIPTION;
                  this.setState({
                    confirmShowModal: !confirmShowModal,
                    confirmModal: modal,
                    selectCard: product,
                  });
                }}
              />
            )}

            <div className={`${productStatusContainerClass}`}>
              <span className={`${productStatusTitleClass}`}>
                {productStatusText}
              </span>
            </div>

            <div className="[ data ]">
              <div
                className={`[ content ]${
                  active && isShoppingCartList
                    ? " [ card-container-active ]"
                    : " [ content_color ]"
                }`}
              >
                {/* Begin Product Title */}
                <div className="[ row ] [ pb-2 ]">
                  <div className="[  col-12 ] [ text-left ]">
                    <span
                      className={`[ card-title ]${
                        active && isShoppingCartList
                          ? " [ card-container-active-text ]"
                          : ""
                      }`}
                    >
                      {product.productName}
                    </span>
                  </div>
                </div>
                {/* END: // Product Title */}

                {/* Begin Product Description */}
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ module overflow ]">
                      <p
                        className={`[ text ]${
                          active && isShoppingCartList
                            ? " [ card-container-active-text ]"
                            : ""
                        }`}
                      >
                        {product.productDescription}
                      </p>
                    </div>
                  </div>
                </div>
                {/* END: // Product Description */}

                {/* Begin Product CTA */}
                <div className="[ row ] [ cta-container ]">
                  <div className="[ col-12 ]">
                    <div>
                      {product.addToVault &&
                      !isShoppingCartList &&
                      addToVault ? (
                        <button
                          type="button"
                          className="[ btn ] [ submit-btn ]"
                          onClick={() => handleAddToVault(product)}
                        >
                          {STRINGS.MARKETPLACE.BODY.ADD_TO_VAULT}
                        </button>
                      ) : (
                        !isShoppingCartList &&
                        addToVault && (
                          <div className="[ card-link see-details-link short-description-link ]">
                            {STRINGS.MARKETPLACE.BODY.SEE_BRANCH_DETAILS}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {/* END // Product CTA */}
              </div>
              {/* Begin Product footer */}
              <div className=" [ card-footer ] ">
                <a
                  href="#"
                  className={`[ btn info-btn btn-sm ]${
                    active && isShoppingCartList
                      ? " [ card-container-active-text ]"
                      : " [ see-details-link_color ]"
                  }`}
                  onClick={() => this.detailsClick(product, slots)}
                  role="presentation"
                  title={STRINGS.MARKETPLACE.BODY.SEE_DETAILS}
                >
                  <i className="[ fas fa-info-circle ]"></i>
                  <span className="sr-only">
                    {STRINGS.MARKETPLACE.BODY.SEE_DETAILS}
                  </span>
                </a>
                <a
                  href={FIInformation.FI_BOOK_APPOINTMENT}
                  className="[ btn info-btn btn-sm ] [ book-appointment ]"
                  title={STRINGS.MARKETPLACE.BODY.BOOK_APPOINTMENT}
                  target="_blank"
                >
                  <i className="[ fas fa-calendar-day ]"></i>
                  <span className="sr-only">
                    {STRINGS.MARKETPLACE.BODY.BOOK_APPOINTMENT}
                  </span>
                </a>
              </div>
              {/* END: // Product footer */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProductCardWrapper.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]),
  active: PropTypes.bool,
  handleAddToVault: PropTypes.func,
  addToVault: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  isCardAddedToVault: PropTypes.func,
  isShoppingCartList: PropTypes.bool,
  deleteApplication: PropTypes.func.isRequired,
};

ProductCardWrapper.defaultProps = {
  active: false,
  product: {},
  handleAddToVault: () => {},
  isCardAddedToVault: () => {},
  isShoppingCartList: false,
  deleteApplication: () => {},
};

const ProductCard = withVaultHOC(ProductCardWrapper);

export default ProductCard;
