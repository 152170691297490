import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import ProductCard from "Components/Common/ProductCardContainer/ProductCard";
import * as ROUTES from "Constants/Routes";
import withVaultHOC from "../../Hoc/VaultHOC/VaultHOC";
import RangeSlider from "react-bootstrap-range-slider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { add, format, getMonth, getYear, parse, sub } from "date-fns";
import { VAULT_DASHBOARD } from "../../Constants/Routes";
import {
  commaFormatted,
  commaFormattedNoDecimal,
} from "../../Utils/CommonUtilities";
import { bindActionCreators } from "redux";
import { submitQuestionnaire } from "./Actions/QuestionnaireAction";
import PopupModal from "../Common/PopupModal/PopupModal";
import AppConstants from "../../Constants/AppConstants";
import { updateVaultProductList } from "../MarketPlace/Action/MarketPlaceAction";
import { S_EMAIL, S_MSISDN } from "../../Communication/Constants";
import ReCAPTCHA from "react-google-recaptcha";

const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => end - i);
};

class QuestionFormContainer extends Component {
  constructor(props) {
    super(props);
    // inputfield error object name  should be inputobject with suffix 'Error'
    //  For example input object: username, input error object: usernameError
    const minDate = sub(new Date(), { years: 100 });
    const maxDate = sub(new Date(), { years: 0 });
    const years = range(getYear(minDate), getYear(maxDate));
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      selectValue: "",
      errors: {},
      sliderValue: 0,
      customDate: null,
      years,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      recaptcha: null,
      errorRecaptcha: "",
    };
  }

  componentDidMount() {
    this.getCurrentQuestion();
  }

  componentDidUpdate(prevProps) {}

  verifyData = () => {
    const { currentQuestion, currentAnswer } = this.props;
    const { errors } = this.state;
    let errorField = [];
    switch (currentQuestion?.type) {
      case "select":
      case "radio":
      case "button":
        errorField = checkErrors(
          currentAnswer[currentQuestion?.id]?.id,
          VALIDATIONS.QUESTIONNAIRE.SELECT_FIELD
        );
        break;
      case "checkbox":
        errorField = checkErrors(
          currentAnswer[currentQuestion?.id]?.length > 0
            ? currentAnswer[currentQuestion?.id]?.filter(
                (ans) => ans.checked === true
              )?.length > 0
              ? 1
              : ""
            : "",
          VALIDATIONS.QUESTIONNAIRE.SELECT_FIELD
        );
        break;
      default:
        errorField = checkErrors(
          currentAnswer[currentQuestion?.id]?.value,
          VALIDATIONS.QUESTIONNAIRE.TEXT_FIELD
        );
        break;
    }

    this.setState({
      errors: {
        ...errors,
        [currentQuestion.id]: errorField,
      },
    });
    if (errorField.length > 0) return false;

    return true;
  };

  isCardAddedToVault = (card) => {
    const { vaultProductList } = this.props;
    if (vaultProductList && vaultProductList.length !== 0) {
      const index = vaultProductList.findIndex(
        (elem) => elem.productId === card.productId
      );
      if (index !== -1) return true;
    }
    return false;
  };

  getCurrentQuestion = () => {
    const {
      questionnaire,
      currentQuestion,
      onCurrentQuestionChange,
    } = this.props;
    const questionList = questionnaire?.questionList;

    let question = questionList?.find((q) => q.id === questionList[0].id);
    if (currentQuestion !== undefined && currentQuestion !== null) {
      question = questionList.find((q) => q.id === currentQuestion.id);
    }

    onCurrentQuestionChange(question);
    return question;
  };

  getQuestion = (questionId) => {
    const { questionnaire } = this.props;
    const questionList = questionnaire?.questionList;
    return questionList.find((q) => q.id === questionId);
  };

  goToNextQuestion = (next) => {
    const {
      questionnaire,
      onPrevQuestionChange,
      currentQuestion,
      onCurrentQuestionChange,
      onCurrentAnswerChange,
      currentAnswer,
      onUpdateNextQuestionAnswers,
      isEdit,
      applicantData,
      toggleEdit,
      hideCaptcha,
    } = this.props;
    console.log("goToNextQuestion", next);

    const questionList = questionnaire?.questionList;

    const nextQuestion = questionList.find((q) => q.id === next);

    if (this.verifyData()) {
      if (currentQuestion?.id === 1) {
        hideCaptcha();
      }
      onUpdateNextQuestionAnswers(currentQuestion, nextQuestion);
      onPrevQuestionChange(currentQuestion);
      onCurrentQuestionChange(nextQuestion);
      if (!isEdit && nextQuestion !== null && nextQuestion !== undefined) {
        // Set default value for next question if slider type
        if (nextQuestion.type === "slider") {
          const answerList = questionnaire?.answerList;
          const sliderValue = nextQuestion.default;
          const answer = answerList
            ?.filter((ans) => ans.questionId === nextQuestion.id)
            ?.find(
              (item) => sliderValue >= item.min && sliderValue <= item.max
            );

          answer["value"] = sliderValue;

          onCurrentAnswerChange({
            ...currentAnswer,
            [nextQuestion.id]: answer,
          });
        } else {
          console.log("nextQuestion", nextQuestion);
          if (nextQuestion.field === "fullName") {
            onCurrentAnswerChange({
              ...currentAnswer,
              [nextQuestion.id]: {
                questionId: nextQuestion.id,
                value: applicantData?.member
                  ? applicantData.member.name +
                    " " +
                    applicantData.member.surname
                  : "",
              },
            });
          } else if (nextQuestion.field === "phoneNumber") {
            const msisdn = sessionStorage.getItem(S_MSISDN);
            onCurrentAnswerChange({
              ...currentAnswer,
              [nextQuestion.id]: {
                questionId: nextQuestion.id,
                value: msisdn ? msisdn : "",
              },
            });
          } else if (nextQuestion.field === "email") {
            onCurrentAnswerChange({
              ...currentAnswer,
              [nextQuestion.id]: {
                questionId: nextQuestion.id,
                value: sessionStorage.getItem(S_EMAIL),
              },
            });
            // } else if (nextQuestion.field === "dob") {
            //   onCurrentAnswerChange({
            //     ...currentAnswer,
            //     [nextQuestion.id]: {
            //       questionId: nextQuestion.id,
            //       selected: applicantData?.member
            //         ? parse(applicantData.member.dob, "yyyy-MM-dd", new Date())
            //         : null,
            //       value: applicantData?.member ? applicantData.member.dob : "",
            //     },
            //   });
          } else {
            onCurrentAnswerChange({
              ...currentAnswer,
              [nextQuestion.id]: {
                questionId: nextQuestion.id,
                value: nextQuestion.default,
              },
            });
          }
        }
      }
    }
    if (isEdit) {
      toggleEdit();
    }
  };

  goToPrevQuestion = (prev) => {
    const { prevQuestion } = this.props;
  };

  handleChange = (event) => {
    const {
      currentQuestion,
      currentAnswer,
      onCurrentAnswerChange,
    } = this.props;
    const { errors } = this.state;
    let eventValue = event?.target.value;
    onCurrentAnswerChange({
      ...currentAnswer,
      [currentQuestion.id]: {
        value: eventValue,
      },
    });
    this.setState({
      errors: {
        ...errors,
        [currentQuestion.id]: [],
      },
    });
  };

  handleRadioChange = (answer) => {
    const {
      currentQuestion,
      currentAnswer,
      onCurrentAnswerChange,
    } = this.props;
    const { errors } = this.state;

    onCurrentAnswerChange({ ...currentAnswer, [currentQuestion.id]: answer });
    this.setState({
      errors: {
        ...errors,
        [currentQuestion.id]: [],
      },
    });
  };

  handleSelectChange = (event) => {
    const {
      questionnaire,
      currentQuestion,
      currentAnswer,
      onCurrentAnswerChange,
    } = this.props;
    const answerList = questionnaire?.answerList;
    const { errors } = this.state;
    const answer = answerList.find(
      (ans) => ans.id === parseInt(event.target.value)
    );
    onCurrentAnswerChange({ ...currentAnswer, [currentQuestion.id]: answer });
    this.setState({
      errors: {
        ...errors,
        [currentQuestion.id]: [],
      },
    });
  };

  handleSliderChange = (event) => {
    const sliderValue = event.target.value;
    const {
      questionnaire,
      currentQuestion,
      currentAnswer,
      onCurrentAnswerChange,
    } = this.props;
    const answerList = questionnaire?.answerList;
    const answer = answerList
      ?.filter((ans) => ans.questionId === currentQuestion.id)
      ?.find((item) => sliderValue >= item.min && sliderValue <= item.max);

    answer.value = sliderValue;

    onCurrentAnswerChange({ ...currentAnswer, [currentQuestion.id]: answer });
    this.setState({
      sliderValue: sliderValue,
    });
  };

  handleDateChange = (selectedData, answerId) => {
    const { errors } = this.state;
    const {
      questionnaire,
      currentQuestion,
      currentAnswer,
      onCurrentAnswerChange,
    } = this.props;
    const answerList = questionnaire?.answerList;
    const answer = answerList.find((ans) => ans.id === parseInt(answerId));
    answer["selected"] = selectedData;
    answer["value"] = format(selectedData, "yyyy-MM-dd");

    onCurrentAnswerChange({ ...currentAnswer, [currentQuestion.id]: answer });

    this.setState({
      errors: {
        ...errors,
        [currentQuestion.id]: [],
      },
      customDate: selectedData,
    });
  };

  onChangeCheckBoxHandler = (code, object, checked) => {
    console.log("onChangeCheckBoxHandler", code, object, checked);
    const {
      questionnaire,
      currentQuestion,
      currentAnswer,
      onCurrentAnswerChange,
    } = this.props;
    const answerList = questionnaire?.answerList;
    const { errors } = this.state;
    let tempAnswerList = [...answerList];
    tempAnswerList = tempAnswerList.filter(
      (ans) => ans.questionId === currentQuestion.id
    );
    const index = tempAnswerList.map((o) => o.id).indexOf(code);

    const answer = answerList.find((ans) => ans.id === parseInt(code));

    let isChecked = false;

    if (checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    let checkboxAnswerList = [];
    const currentQuestionAnswer = currentAnswer[currentQuestion.id];

    answer.checked = isChecked;
    if (currentQuestionAnswer?.length > 0) {
      checkboxAnswerList = [...currentAnswer[currentQuestion.id]];

      const currAnsIndex = currentQuestionAnswer?.findIndex(
        (ans) => ans.id === parseInt(answer.id)
      );

      if (currAnsIndex >= 0) {
        currentQuestionAnswer[currAnsIndex].checked = isChecked;

        checkboxAnswerList = currentQuestionAnswer;
      } else {
        checkboxAnswerList.push(answer);
      }
    } else {
      checkboxAnswerList.push(answer);
    }

    if (isChecked) {
      if (answer.exclusive) {
        // if selecting exclusive checkbox (e.g. non of the above)
        checkboxAnswerList.forEach((item, idx) => {
          if (item.id !== answer.id) {
            checkboxAnswerList[idx].checked = false;
          }
        });
      } else {
        // check if there is exclusive selection
        checkboxAnswerList.forEach((item, idx) => {
          if (item.checked && item.exclusive) {
            checkboxAnswerList[idx].checked = false;
          }
        });
      }
    }

    onCurrentAnswerChange({
      ...currentAnswer,
      [currentQuestion.id]: checkboxAnswerList,
    });
    this.setState({
      errors: {
        ...errors,
        [currentQuestion.id]: [],
      },
    });
  };

  goToEdit = (question) => {
    const {
      onCurrentQuestionChange,
      toggleEdit,
      toggleRecommendation,
    } = this.props;
    onCurrentQuestionChange(question);

    toggleRecommendation(false);
    toggleEdit();
  };

  showAnswer = (currentQuestion, currentAnswer) => {
    let answer = "";
    switch (currentQuestion?.type) {
      case "slider":
        if (currentQuestion.useDescriptionAsValue) {
          answer = currentAnswer.description;
        } else {
          answer = `${currentQuestion.prefix || ""}${commaFormatted(
            currentAnswer?.value
          )}`;
        }
        break;
      case "select":
      case "radio":
      case "button":
        answer = currentAnswer?.description;
        break;
      case "checkbox":
        answer =
          currentAnswer?.length > 0
            ? currentAnswer
                ?.filter((item) => item?.checked === true)
                .map((item) => item?.description)
                .join(",")
            : "";
        break;

      default:
        answer = currentAnswer?.value;
        break;
    }
    return answer;
  };

  actionButtonText = () => {
    const { applicantData, questionnaire, showRecommendation } = this.props;
    console.log("actionButtonText", showRecommendation, questionnaire?.result);
    if (questionnaire?.result?.length > 0) {
      if (showRecommendation) {
        if (applicantData) {
          return "Go To Dashboard";
        }
        return "Go To Marketplace";
      }
      return STRINGS.COMMON.CONTINUEBTN;
    } else {
      if (showRecommendation) {
        return "Submit product request";
      }
      return STRINGS.COMMON.CONTINUEBTN;
    }
  };

  processActionButton = () => {
    const {
      applicantData,
      currentQuestion,
      currentAnswer,
      history,
      questionnaire,
      doSubmitQuestionnaire,
      showRecommendation,
      showCaptcha,
    } = this.props;
    if (!applicantData && showCaptcha) {
      if (!this.state.recaptcha) {
        this.setState({ errorRecaptcha: "Please click on checkbox." });
        return;
      } else {
        this.setState({ errorRecaptcha: "" });
      }
    } else {
      this.setState({ errorRecaptcha: "" });
    }
    console.log("processActionButton");
    if (questionnaire?.result?.length > 0) {
      if (showRecommendation) {
        if (applicantData) {
          history.push(ROUTES.VAULT_DASHBOARD);
        } else {
          history.push(ROUTES.MARKETPLACE);
        }
      } else {
        if (Array.isArray(currentAnswer[currentQuestion?.id])) {
          this.goToNextQuestion(
            currentAnswer[currentQuestion?.id][0]?.next ?? currentQuestion?.next
          );
        } else {
          this.goToNextQuestion(
            currentAnswer[currentQuestion?.id]?.next ?? currentQuestion?.next
          );
        }
      }
    } else if (showRecommendation) {
      const questionList = questionnaire?.questionList;
      const result = [];
      for (const key in currentAnswer) {
        if (currentAnswer.hasOwnProperty(key)) {
          const answerData = currentAnswer[key];
          const question = questionList.find((q) => q.id === parseInt(key));
          result.push({
            ...question,
            answer: answerData,
          });
        }
      }
      doSubmitQuestionnaire({ answers: result }, () => {
        this.setState({
          showModal: true,
          modalType: AppConstants.MODALTYPE.SUCCESS,
          modal: {
            title: "Product request accepted",
            description: "The bank will be contacting you.",
          },
        });
      });
    } else {
      if (Array.isArray(currentAnswer[currentQuestion?.id])) {
        this.goToNextQuestion(
          currentAnswer[currentQuestion?.id][0]?.next ?? currentQuestion?.next
        );
      } else {
        this.goToNextQuestion(
          currentAnswer[currentQuestion?.id]?.next ?? currentQuestion?.next
        );
      }
    }
  };

  doAddToVault = (e) => {
    const {
      handleAddToVault,
      questionnaire,
      currentAnswer,
      recommendedProducts,
    } = this.props;
    const questionList = questionnaire?.questionList;
    const result = [];
    for (const key in currentAnswer) {
      if (currentAnswer.hasOwnProperty(key)) {
        const answerData = currentAnswer[key];
        const question = questionList.find((q) => q.id === parseInt(key));
        result.push({
          ...question,
          answer: answerData,
        });
      }
    }
    handleAddToVault({
      ...e,
      questionnaire: {
        sendAnswers: questionnaire.sendAnswers,
        answers: result,
        recommendedProducts: recommendedProducts?.map((x) => {
          return x.productId;
        }),
      },
    });
  };

  handleCaptcha = (e) => {
    console.log("handleCaptcha", e);
    this.setState({ recaptcha: e, errorRecaptcha: "" });
  };
  render() {
    const {
      errors,
      years,
      months,
      showModal,
      modalType,
      modal,
      errorRecaptcha,
    } = this.state;
    const {
      showVaultClicked,
      finInfo,
      productList,
      handleAddToVault,
      handleSeeDetailsClick,
      questionnaire,
      questionnaireProduct,
      currentQuestion,
      currentAnswer,
      history,
      showRecommendation,
      recommendedProducts,
      recommendedRelatedProducts,
      applicantData,
      onPrintPdf,
      showCaptcha,
    } = this.props;
    const answerList = questionnaire?.answerList;
    const questionList = questionnaire?.questionList;
    let min = 0;
    let max = 100;
    let step = 1;

    if (currentQuestion?.type === "slider") {
      const minRangeValues = answerList
        ?.filter((ans) => ans.questionId === currentQuestion?.id)
        .map((item) => item?.min);
      const maxRangeValues = answerList
        ?.filter((ans) => ans.questionId === currentQuestion?.id)
        .map((item) => item?.max);
      min = Math.min(minRangeValues);
      max = Math.max(maxRangeValues);
      step = 1;
    }

    console.log(
      "Current Answer",
      currentAnswer,
      currentQuestion,
      showRecommendation,
      questionnaire
    );
    const renderCard = (cardArray) => {
      return (
        <div className="[ product-cards-wrapper ]">
          {cardArray.map((product) => {
            return (
              product.show && (
                <ProductCard
                  key={product.productId}
                  product={product}
                  handleAddToVault={this.doAddToVault}
                  addToVault
                  isCardAddedToVault={this.isCardAddedToVault}
                  handleSeeDetailsClick={handleSeeDetailsClick}
                />
              )
            );
          })}
        </div>
      );
    };

    const recommendationComponent = (
      <>
        <div className="[ recommendation-wrapper ] [ row ] [ mb-3 ]">
          {questionnaire?.result?.length > 0 && (
            <>
              {recommendedProducts?.length > 0 ? (
                <>
                  <div className="[ mb-3 ] [ col-12 ]">
                    <div className="[ form-subtitle ]">
                      {STRINGS.QUESTIONNAIRE.THANKYOU_TEXT1}
                    </div>
                    <div className="[ form-description ]">
                      {STRINGS.QUESTIONNAIRE.THANKYOU_TEXT2}
                    </div>
                  </div>

                  <div className="[ mb-3 ] [ col-12 ] [ drawer-content-container ]">
                    <div className="[ info-title ]">
                      {STRINGS.QUESTIONNAIRE.RECOMMENDATION_TITLE}
                    </div>
                    {Object.keys(questionnaireProduct)?.length > 0
                      ? renderCard(recommendedProducts)
                      : ""}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="[ col-12 ] [ alert alert-warning ]"
                    role="alert"
                  >
                    <p>
                      <span>
                        <strong>{STRINGS.QUESTIONNAIRE.SORRY_TEXT1}</strong>
                      </span>
                    </p>
                    <p>
                      <span>
                        <strong>{STRINGS.QUESTIONNAIRE.SORRY_TEXT2}</strong>
                      </span>
                    </p>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: STRINGS.QUESTIONNAIRE.SORRY_HINT_TEXT1,
                        }}
                      />
                    </p>
                  </div>
                </>
              )}
            </>
          )}

          <div className="[ col-12 ] [ confirm-account-container ]">
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ details-section__header">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.QUESTIONNAIRE.RECOMMENDATION_SUMMARY_TITLE}{" "}
                  {questionnaireProduct?.title}
                </h3>
              </div>

              {Object.keys(currentAnswer)?.length > 0
                ? Object.keys(currentAnswer)?.map((itemQuestionId) => {
                    const question = questionList?.find(
                      (q) => q.id === parseInt(itemQuestionId)
                    );

                    return (
                      <>
                        <div className="[ row ] [ details-section__item ] [ mb-3 ]">
                          <div className="[ col-12 ]">
                            <div className="[ details-section__item-label ]">
                              {question?.question}
                            </div>
                            <button
                              type="button"
                              className="[ btn edit-btn ]"
                              onClick={() => {
                                this.goToEdit(question);
                              }}
                              aria-label="edit"
                            >
                              <span className="[ edit-icon ]" />
                              <span className="[ edit-btn__label ]">
                                {STRINGS.QUESTIONNAIRE.EDIT_BTN_TEXT}
                              </span>
                            </button>
                          </div>
                          <div className="[ col-12 ]">
                            <div
                              className="[ details-section__item-description ]"
                              style={{ textTransform: "none" }}
                            >
                              {this.showAnswer(
                                question,
                                currentAnswer[itemQuestionId]
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </>
    );

    const questionComponent = (
      <>
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">{currentQuestion?.question} </div>

          {currentQuestion?.type === "radio" ||
          currentQuestion?.type === "button" ? (
            <div className="[ row ] [ custom-radios ]">
              {answerList
                ?.filter((ans) => ans.questionId === currentQuestion?.id)
                .map((answer) => (
                  <div className="[ col-6 ]" key={answer.id}>
                    <button
                      type="button"
                      className={
                        answer.id === currentAnswer[currentQuestion?.id]?.id
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      // disabled={answer.id === currentAnswer[currentQuestion?.id]?.id}
                      name={answer.description}
                      value={answer.id}
                      onClick={(event) => this.handleRadioChange(answer)}
                    >
                      {answer.description}
                    </button>
                  </div>
                ))}
            </div>
          ) : (
            ""
          )}
          {currentQuestion?.type === "checkbox" ? (
            <div className="[ row ]">
              {answerList
                .filter((ans) => ans.questionId === currentQuestion?.id)
                .map((answer) => {
                  const currentQuestionAnswer =
                    currentAnswer[currentQuestion.id];
                  let qAns = answer;
                  if (currentQuestionAnswer?.length > 0) {
                    const currAns = currentAnswer[currentQuestion?.id]?.find(
                      (a) => a.id === parseInt(answer.id)
                    );
                    if (currAns !== undefined) {
                      qAns = currAns;
                    }
                  }
                  return (
                    <div className="[ col-12 ] [ mb-3 ]" key={qAns.id}>
                      <div className="[ checkbox custom-control custom-checkbox ]">
                        <input
                          type="checkbox"
                          value={qAns.description}
                          onChange={(e) =>
                            this.onChangeCheckBoxHandler(
                              qAns.id,
                              qAns,
                              e.target.checked
                            )
                          }
                          checked={
                            currentQuestionAnswer?.length > 0
                              ? qAns.checked
                              : false
                          }
                          id={qAns.description}
                          className={"[ custom-control-input ]"}
                        />
                        <label
                          className="[ custom-control-label ]"
                          htmlFor={qAns.description}
                        >
                          <span className="[ form-checkbox__text ]">
                            {qAns.description}
                          </span>
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {currentQuestion?.type === "select" ? (
            <select
              value={currentAnswer[currentQuestion?.id]?.id}
              className="[ form-control ] [ select__dropdown ]"
              onChange={this.handleSelectChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.QUESTIONNAIRE.FORM.SELECT_PLACEHOLDER}
              </option>
              {answerList
                .filter((ans) => ans.questionId === currentQuestion?.id)
                .map((answer) => (
                  <option
                    value={answer.id}
                    key={answer.id}
                    className="[ dropdown__option ]"
                  >
                    {answer.description}
                  </option>
                ))}
            </select>
          ) : (
            ""
          )}
          {currentQuestion?.type === "slider" ? (
            <div className="[ questionnaire-range-widget ]">
              <div className="[ row ] [ range-slider__labels-row ]">
                <div className="[ col-3 ] [ text-left ] [ form-label ]">
                  {currentQuestion.label?.min
                    ? currentQuestion.label?.min
                    : `${currentQuestion.prefix || ""}${commaFormatted(
                        currentQuestion.min
                      )}`}
                </div>
                <div className="[ col-6 ] [ form-label ]">
                  <input
                    type="text"
                    className="[ range-reader ]"
                    value={`${commaFormattedNoDecimal(
                      currentAnswer[currentQuestion?.id]?.value
                    )}`}
                    disabled
                  />
                </div>
                <div className="[ col-3 ] [ text-right ] [ form-label ]">
                  {currentQuestion.label?.max
                    ? currentQuestion.label?.max
                    : `${commaFormatted(currentQuestion.max)}`}
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-12 ]">
                  <RangeSlider
                    value={
                      currentAnswer[currentQuestion?.id]?.value ||
                      currentQuestion.default
                    }
                    min={currentQuestion.min}
                    max={currentQuestion.max}
                    onChange={(e) => this.handleSliderChange(e)}
                    tooltipLabel={(currentValue) => `${currentValue}`}
                    tooltipPlacement="top"
                    step={currentQuestion.step}
                    className="[ w-100 ]"
                  />
                </div>
              </div>
              <div className="[ row ]">
                <div className="[ col-6 ] [ text-left ] [ form-label ] [ slider-label ]"></div>
                <div className="[ col-6 ] [ text-right ] [ form-label ] [ slider-label ]"></div>
              </div>
            </div>
          ) : (
            ""
          )}
          {currentQuestion?.type === "date" ? (
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <DatePicker
                  className="[ datepicker ]"
                  dateFormat="yyyy-MM-dd"
                  placeholderText={`${STRINGS.DOBPLACEHOLDER}`}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      // escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  selected={currentAnswer[currentQuestion?.id]?.selected}
                  onChange={(date) =>
                    this.handleDateChange(
                      date,
                      answerList.filter(
                        (ans) => ans.questionId === currentQuestion?.id
                      )[0]?.id ?? null
                    )
                  }
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div>
                      <button
                        type="button"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {currentQuestion?.type === "text" ? (
            <input
              type="text"
              name={currentQuestion?.id}
              className={
                errors?.firstNameErrorList?.length > 0
                  ? "[ form-control form-control-sm ] [ invalid-input ]"
                  : "[ form-control form-control-sm ]"
              }
              style={{ textTransform: "none" }}
              placeholder={currentQuestion?.question}
              value={currentAnswer[currentQuestion?.id]?.value ?? ""}
              onChange={(event) => this.handleChange(event)}
            />
          ) : (
            ""
          )}

          <div className="[ error-msg ]">
            {errors[currentQuestion?.id] ? errors[currentQuestion?.id][0] : ""}
          </div>
        </div>
      </>
    );

    const actionComponent = (
      <>
        <div className="[ form-container ] [ questionnaire ]">
          {showRecommendation ? recommendationComponent : questionComponent}
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                {!applicantData && showCaptcha && (
                  <div id={"recaptcha"} style={{ textAlign: "center" }}>
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={this.handleCaptcha}
                      theme={"light"}
                    />
                    <div
                      style={{ display: "inline-block" }}
                      className="[ error-msg ]"
                    >
                      {errorRecaptcha ? errorRecaptcha : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                <button
                  type="button"
                  className="[ submit-btn btn ] [ w-100 ]"
                  onClick={this.processActionButton}
                >
                  {this.actionButtonText()}
                </button>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={() => history.push(ROUTES.MARKETPLACE)}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
    return actionComponent;
  }
}

const mapStateToProps = (state) => ({
  signinState: state.SigninReducer,
  applicantData: state.ApplicationReducer.response,
  productList: state.MarketplaceReducer.productList,
});
QuestionFormContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  recommendedProducts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  recommendedRelatedProducts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  isEdit: PropTypes.bool,
  showRecommendation: PropTypes.bool,
  handleSeeDetailsClick: PropTypes.func,
  handleAddToVault: PropTypes.func,
  questionnaire: PropTypes.oneOfType([PropTypes.object]),
  prevQuestion: PropTypes.oneOfType([PropTypes.object]),
  currentQuestion: PropTypes.oneOfType([PropTypes.object]),
  currentAnswer: PropTypes.oneOfType([PropTypes.object]),
  onPrevQuestionChange: PropTypes.func,
  onCurrentQuestionChange: PropTypes.func,
  onCurrentAnswerChange: PropTypes.func,
  onUpdateNextQuestionAnswers: PropTypes.func,
  toggleEdit: PropTypes.func,
  toggleRecommendation: PropTypes.func,
  computeRecommendationScores: PropTypes.func.isRequired,
  onPrintPdf: PropTypes.func,
  applicantData: PropTypes.oneOfType([PropTypes.object]),
  doSubmitQuestionnaire: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doSubmitQuestionnaire: submitQuestionnaire,
      doUpdateVaultProductList: updateVaultProductList,
    },
    dispatch
  );
};
QuestionFormContainer.defaultProps = {
  history: {},
  finInfo: {},
  isEdit: false,
  showRecommendation: false,
  productList: [],
  recommendedProducts: [],
  recommendedRelatedProducts: [],
  vaultProductList: [],
  handleAddToVault: () => {},
  handleSeeDetailsClick: () => {},
  questionnaire: {},
  prevQuestion: {},
  currentQuestion: {},
  currentAnswer: {},
  onPrevQuestionChange: () => {},
  onCurrentQuestionChange: () => {},
  onCurrentAnswerChange: () => {},
  onUpdateNextQuestionAnswers: () => {},
  toggleEdit: () => {},
  toggleRecommendation: () => {},
  computeRecommendationScores: () => {},
  onPrintPdf: () => {},
  applicantData: null,
};

const QuestionFormContainerWrapper = withVaultHOC(QuestionFormContainer);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionFormContainerWrapper);
