import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import { UPLOAD_ONLINE_BANKING } from "../../../Redux/Actions/actionTypes";
import UploadOnlineBankingRequest from "../../../Communication/ApiClasses/UploadOnlineBankingRequest";

// worker
function* handleUploadOnlineBanking(action) {
  yield put(doLoadingStart(`${UPLOAD_ONLINE_BANKING}_SAGA`));
  const uploadOnlineBankingRequest = new UploadOnlineBankingRequest(
    action.data.applicantId,
    action.data.applicationId,
    action.data.onlineBankingProductId,
    action.data.password
  );
  try {
    const result = yield call(uploadOnlineBankingRequest.getData);
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${UPLOAD_ONLINE_BANKING}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${UPLOAD_ONLINE_BANKING}_SAGA`));
  }
}

// watcher
function* uploadOnlineBankingSaga() {
  yield takeLatest(UPLOAD_ONLINE_BANKING, handleUploadOnlineBanking);
}

export default uploadOnlineBankingSaga;
