import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessType from "./BusinessType";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { HTTP_STATUS } from "../../Communication/Constants";

class BusinessTypeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const { continuehandler, doUpdateApplicant, applicantData } = this.props;
    if (applicantData?.id) {
      doUpdateApplicant(data.applicant, applicantData.id, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          continuehandler(
            data.vault,
            AppConstants.APPLICATIONSTEP.BUSINESS_TYPE
          );
        }
      });
    } else {
      continuehandler(data.vault, AppConstants.APPLICATIONSTEP.BUSINESS_TYPE);
    }
  };

  removeApplication = () => {
    const { removeCurrentActiveApplication } = this.props;
    removeCurrentActiveApplication();
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      applicantData,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessType
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          removeApplication={this.removeApplication}
          finInfo={finInfo}
          activeProduct={activeProduct}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessTypeContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessTypeContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  finInfo: state.MarketplaceReducer.finInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
  };
};

const BusinessTypeWrapper = withBusinessHOC(BusinessTypeContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessTypeWrapper);
