import BaseApiRequest from "./BaseApiRequest";
import { APPLICANT_CREATE_CONFIRM } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class ConfirmApplicantCreateRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = APPLICANT_CREATE_CONFIRM;

  constructor(reqData) {
    super();
    this.body = reqData;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
