import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { HTTP_STATUS } from "Communication/Constants";
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";
import {
  doForgotPassword,
  addUsername,
} from "Components/Signin/Actions/SigninAction";

import ForgotPassword from "./ForgotPassword";

class ForgotPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successResponse: false,
      showModal: false,
      title: "",
      modalMessage: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { forgotPasswordState } = this.props;
    if (prevProps.forgotPasswordState !== forgotPasswordState) {
      if (
        forgotPasswordState.success &&
        forgotPasswordState.success.status === HTTP_STATUS.OK
      ) {
        if (
          forgotPasswordState.success.data.otpTo ===
          STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL
        ) {
          this.setModalState({
            showModal: true,
            title: MODALMESSAGES.FORGOT_PASSWORD.SUCCESS_MAIL,
            modalMessage: STRINGS.FORGOTPASSWORD.MODAL.SUCCESS_MAIL,
            error: false,
          });
        } else {
          this.setModalState({
            showModal: true,
            title: MODALMESSAGES.FORGOT_PASSWORD.SUCCESSMSG,
            modalMessage: STRINGS.FORGOTPASSWORD.MODAL.SUCCESS_MESSAGE,
            error: false,
          });
        }
      }
    }
  }

  setModalState = (modal) => {
    this.setState(modal);
  };

  textMessageClick = (mobileNo) => {
    const { doAddUserName, doForgotPswd, bankingType } = this.props;

    const validMobileNo =
      AppConstants.REGISTRATION.NUMBERPREFIX + mobileNo.replace(/[- )(]/g, "");
    doAddUserName(validMobileNo);
    const userDetails = {
      credential: validMobileNo,
      otpTo: STRINGS.FORGOTPASSWORD.OTP_TO.MSISDN,
      bankingType,
    };
    doForgotPswd(userDetails);
  };

  backClick = () => {
    const { history } = this.props;
    const { successResponse } = this.state;
    if (!successResponse) {
      history.push(ROUTES.SIGN_IN);
    } else {
      this.setState({ successResponse: false });
    }
  };

  toggleModal = () => {
    const { forgotPasswordState, history } = this.props;
    const { showModal } = this.state;
    if (
      forgotPasswordState.success &&
      forgotPasswordState.success.status === HTTP_STATUS.OK
    ) {
      if (
        forgotPasswordState.success.data.otpTo ===
        STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL
      ) {
        this.setState({ showModal: !showModal });
        history.push(ROUTES.SIGN_IN);
      } else {
        this.setState({ showModal: !showModal });
        history.push(ROUTES.SIGNIN_FORGOT_VERIFICATION);
      }
    }
  };

  emailClick = (email) => {
    const { doAddUserName, doForgotPswd, bankingType } = this.props;
    doAddUserName(email);
    const userDetails = {
      credential: email,
      otpTo: STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL,
      bankingType,
    };
    doForgotPswd(userDetails);
  };

  render() {
    const { showModal, error, modalMessage, showVault, title } = this.state;
    return (
      <ForgotPassword
        showModal={showModal}
        error={error}
        description={modalMessage}
        showVault={showVault}
        emailClick={this.emailClick}
        textMessageClick={this.textMessageClick}
        backClick={this.backClick}
        toggleModal={this.toggleModal}
        title={title}
      />
    );
  }
}

ForgotPasswordContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  forgotPasswordState: PropTypes.objectOf(PropTypes.any).isRequired,
  doAddUserName: PropTypes.func.isRequired,
  doForgotPswd: PropTypes.func.isRequired,
};

ForgotPasswordContainer.defaultProps = {
  history: {},
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doForgotPswd: doForgotPassword,
      doAddUserName: addUsername,
    },
    dispatch
  );
};
const mapStateToProps = (state) => ({
  signinState: state.SigninReducer,
  forgotPasswordState: state.ForgotPasswordReducer,
  bankingType: state.MarketplaceReducer.bankingType,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
