import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";
import FetchInveriteAccountListRequest from "Communication/ApiClasses/FetchInveriteAccountListRequest";

//  worker
function* handleFetchInverite(action) {
  yield put(
    doLoadingStart(STRINGS.INVERITE_IFRAME_MODAL.API_KEYS.FETCH_ACCOUNT_LIST)
  );
  const fetchApi = new FetchInveriteAccountListRequest(action.data.requestGuid);
  try {
    const result = yield call(fetchApi.getData);
    yield call(action.callback, result);
  } finally {
    yield put(
      doLoadingFinish(STRINGS.INVERITE_IFRAME_MODAL.API_KEYS.FETCH_ACCOUNT_LIST)
    );
  }
}

//  watcher
export default function* fetchApiWatcherSaga() {
  yield takeLatest(
    actionTypes.FETCH_INVERITE_ACCOUNT_LIST_REQUEST,
    handleFetchInverite
  );
}
