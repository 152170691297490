import * as actionTypes from "Redux/Actions/actionTypes";

export const getApplicant = (payLoad, callback = () => {}) => ({
  type: actionTypes.CALL_APPLICANT_API,
  data: payLoad,
  callback,
});

export const getOwnerApplicant = (id, callback = () => {}) => ({
  type: actionTypes.GET_OWNER_APPLICANT,
  id,
  callback,
});

export const getCompleteApplicant = (id, callback = () => {}) => ({
  type: actionTypes.CALL_COMPLETE_APPLICANT_API,
  id,
  callback,
});

export const getApplication = (applicationId, callback = () => {}) => ({
  type: actionTypes.GET_APPLICATION,
  applicationId,
  callback,
});

export const updateApplication = (
  applicationId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.UPDATE_APPLICATION,
  applicationId,
  payLoad,
  callback,
});

export const getContract = (
  applicationId,
  applicationType,
  productId,
  waitMessage,
  callback = () => {}
) => ({
  type: actionTypes.GET_CONTRACT,
  applicationId,
  applicationType,
  productId,
  waitMessage,
  callback,
});

export const updateContract = (
  applicationId,
  applicationType,
  productId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.UPDATE_CONTRACT,
  applicationId,
  applicationType,
  productId,
  payLoad,
  callback,
});

export const getGettingStartedData = (callback = () => {}) => ({
  type: actionTypes.CALL_GETTING_STARTED_API,
  callback,
});

export const toggleEditFlag = (flag) => ({
  type: actionTypes.TOGGLE_EDIT_FLAG,
  flag,
});

export const toggleEditConfirmFlag = (flag) => ({
  type: actionTypes.TOGGLE_EDIT_CONFIRM_FLAG,
  flag,
});
export const disableBack = (flag) => ({
  type: actionTypes.DISABLE_BACK,
  flag,
});

export const deleteApplication = (applicationId, callback = () => {}) => ({
  type: actionTypes.APPLICATION_DELETE,
  data: applicationId,
  callback,
});

export const getApplicationList = (callback = () => {}) => ({
  type: actionTypes.APPLICATION_LIST,
  callback,
});

export const toggleScanFlag = (flag) => {
  return {
    type: actionTypes.TOGGLE_SCAN_FLAG,
    flag,
  };
};

export const updateProductTemplate = (activeProduct) => ({
  type: actionTypes.UPDATE_PRODUCT_TEMPLATE,
  activeProduct,
});

export const setNotFirstFlag = (flag) => ({
  type: actionTypes.SET_NOT_FIRST_FLAG,
  flag,
});

export const storeCompletedProductInASession = (product) => ({
  type: actionTypes.STORE_COMPLETED_PRODUCT_IN_A_SESSION,
  product,
});

export const setESignatureEnabled = (flag) => ({
  type: actionTypes.SET_E_SIGNATURE_ENABLED,
  flag,
});

export const toggleBackFlag = (flag) => ({
  type: actionTypes.TOGGLE_BACK_FLAG,
  flag,
});

export const getOccupationCodes = (callback = () => {}) => ({
  type: actionTypes.GET_OCCUPATION_CODES,
  callback,
});

export const getReasonCodes = (callback = () => {}) => ({
  type: actionTypes.GET_REASON_CODES,
  callback,
});

export const getCountriesTax = (callback = () => {}) => ({
  type: actionTypes.GET_COUNTRIES_TAX,
  callback,
});

export const getProspect = (request, callback = () => {}) => ({
  type: actionTypes.GET_PROSPECT,
  request,
  callback,
});
