import BucketApiRequest from "./BucketApiRequest";
import { GET_PRODUCT_TEMPLATE } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetProducttemplate extends BucketApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return GET_PRODUCT_TEMPLATE;
  };

  getBody = () => {
    return this.body;
  };
}
