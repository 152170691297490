import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import * as ROUTES from "Constants/Routes";
import PopupModal from "../Common/PopupModal/PopupModal";

class BusinessIndividual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedBusinessType: "",

      isCannabisOrMoneyIndustry: "",
      isFinancialInstitution: "",

      reasonOpenAccount: "",
      relationshipBusiness: "",
      accountStatementType: "",
      businessBranch: "",

      legalBusinessName: "",
      tradeName: "",
      businessNumber: "",
      hasCertifcateOfIncorporation: "",
      hasBusinessOperate: "",
      hasCertificateOfIncorporation: "",

      businessStreetName: "",
      businessSuiteNo: "",
      businessStreetNumber: "",
      businessCity: "",
      businessProvince: "",
      businessPostalCode: "",

      businessMailingStreetName: "",
      businessMailingSuiteNo: "",
      businessMailingStreetNumber: "",
      businessMailingCity: "",
      businessMailingProvince: "",
      businessMailingPostalCode: "",

      businessEmail: "",
      businessPhoneNumber: "",
      businessPhoneExtension: "",

      isBusinessLegagllyFormed: "no",
      isBusinessPhysicalLocation: "no",
      isBusinessTransact: "no",
      businessEntity: "",
      businessSector: "",
      businessSubSector: "",
      businessGroup: "",
      businessIndustry: "",
      businessIndustryDescription: "",
      countryIncorporation: "",
      countryPhysicalLocation: [],
      countryTransaction: [],

      isBusinessNotForProfit: false,
      isBusinessFileTaxesUSA: false,
      isBusinessFileTaxesOthers: false,
      isBusinessSolicitDonations: false,

      businessNotForProfit: "",
      businessTinUSA: "",
      businessFileTaxesOthersList: [],

      show: [],
      processFlow: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct, processTemplate } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        // Business Type
        selectedBusinessType: activeProduct?.selectedBusinessType ?? "",

        // Business Eligibility
        isCannabisOrMoneyIndustry:
          activeProduct?.isCannabisOrMoneyIndustry ?? "",
        isFinancialInstitution: activeProduct?.isFinancialInstitution ?? "",

        reasonOpenAccount: activeProduct?.reasonOpenAccount ?? "",
        relationshipBusiness: activeProduct?.relationshipBusiness ?? "",
        accountStatementType: activeProduct?.accountStatementType ?? "",
        businessBranch: activeProduct?.businessBranch ?? "",

        // Details
        tradeName: activeProduct?.tradeName ?? "",
        hasCertifcateOfIncorporation:
          activeProduct?.hasCertifcateOfIncorporation,
        legalBusinessName: activeProduct?.legalBusinessName ?? "",
        hasBusinessOperate:
          activeProduct?.tradeName !== "" &&
          activeProduct?.tradeName !== undefined
            ? "yes"
            : "no",
        hasCertificateOfIncorporation: activeProduct.hasCertificateOfIncorporation
          ? "yes"
          : "no",
        // Address
        businessStreetName: activeProduct?.businessStreetName ?? "",
        businessSuiteNo: activeProduct?.businessSuiteNo ?? "",
        businessStreetNumber: activeProduct?.businessStreetNumber ?? "",
        businessCity: activeProduct?.businessCity ?? "",
        businessProvince: activeProduct?.businessProvince ?? "",
        businessPostalCode: activeProduct?.businessPostalCode ?? "",

        // Mailing address
        businessMailingStreetName:
          activeProduct?.businessMailingStreetName ?? "",
        businessMailingSuiteNo: activeProduct?.businessMailingSuiteNo ?? "",
        businessMailingStreetNumber:
          activeProduct?.businessMailingStreetNumber ?? "",
        businessMailingCity: activeProduct?.businessMailingCity ?? "",
        businessMailingProvince: activeProduct?.businessMailingProvince ?? "",
        businessMailingPostalCode:
          activeProduct?.businessMailingPostalCode ?? "",

        // Contact
        businessEmail: activeProduct?.businessEmail,
        businessPhoneNumber: activeProduct?.businessPhoneNumber,
        businessPhoneExtension: activeProduct?.businessPhoneExtension,

        // Operations
        isBusinessLegagllyFormed:
          activeProduct?.countryIncorporation !== "" &&
          activeProduct?.countryIncorporation !== undefined
            ? "yes"
            : "no",
        isBusinessPhysicalLocation:
          activeProduct?.countryPhysicalLocation &&
          activeProduct?.countryPhysicalLocation.length > 0
            ? "yes"
            : "no", //activeProduct?.isBusinessPhysicalLocation,
        isBusinessTransact:
          activeProduct?.countryTransaction &&
          activeProduct?.countryTransaction.length > 0
            ? "yes"
            : "no", //activeProduct?.isBusinessTransact,
        businessEntity: activeProduct?.businessEntity ?? "",
        businessSector: activeProduct?.businessSector ?? "",
        businessSubSector: activeProduct?.businessSubSector ?? "",
        businessGroup: activeProduct?.businessGroup ?? "",
        businessIndustry: activeProduct?.businessIndustry ?? "",
        businessIndustryDescription:
          activeProduct?.businessIndustryDescription ?? "",
        countryIncorporation: activeProduct?.countryIncorporation ?? "",
        countryPhysicalLocation: activeProduct?.countryPhysicalLocation ?? [],
        countryTransaction: activeProduct?.countryTransaction ?? [],

        // Taxation
        isBusinessNotForProfit: activeProduct?.isBusinessNotForProfit,
        isBusinessFileTaxesUSA: activeProduct?.isBusinessFileTaxesUSA,
        isBusinessFileTaxesOthers: activeProduct?.isBusinessFileTaxesOthers,
        isBusinessSolicitDonations:
          activeProduct?.isBusinessSolicitDonations ?? "",
        businessNotForProfit: activeProduct?.businessNotForProfit ?? "",
        businessTinUSA: activeProduct?.businessTinUSA ?? "",
        businessFileTaxesOthersList:
          activeProduct?.businessFileTaxesOthersList ?? [],
      });
      const processFlow = processTemplate.flow;
      const show = processFlow.map((item) => item.componentName);
      this.setState({
        processFlow,
        show,
      });
    }
  }

  setFormData = () => {};

  toggleModal = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    if (valid) {
      handleContinue(property);
    }
  };
  handleContinue = () => {
    const { property } = this.state;
    let valid = true;

    if (valid) {
      this.setState({ showModal: true });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL);
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    return formIsValid;
  };

  render() {
    const {
      steps,
      activeStepID,
      handleEditPage,
      finInfo,
      applicantData,
      activeProduct,
    } = this.props;
    const { showModal } = this.state;

    const {
      selectedBusinessType,

      isCannabisOrMoneyIndustry,
      isFinancialInstitution,

      reasonOpenAccount,
      relationshipBusiness,
      accountStatementType,
      businessBranch,

      legalBusinessName,
      tradeName,
      businessNumber,
      hasCertifcateOfIncorporation,
      hasBusinessOperate,
      hasCertificateOfIncorporation,

      businessStreetName,
      businessSuiteNo,
      businessStreetNumber,
      businessCity,
      businessProvince,
      businessPostalCode,

      businessMailingStreetName,
      businessMailingSuiteNo,
      businessMailingStreetNumber,
      businessMailingCity,
      businessMailingProvince,
      businessMailingPostalCode,

      businessEmail,
      businessPhoneNumber,
      businessPhoneExtension,

      isBusinessLegagllyFormed,
      isBusinessPhysicalLocation,
      isBusinessTransact,
      businessEntity,
      businessSector,
      businessSubSector,
      businessGroup,
      businessIndustry,
      businessIndustryDescription,
      countryIncorporation,
      countryPhysicalLocation,
      countryTransaction,

      isBusinessNotForProfit,
      isBusinessFileTaxesUSA,
      isBusinessFileTaxesOthers,
      isBusinessSolicitDonations,
      businessNotForProfit,
      businessTinUSA,
      businessFileTaxesOthersList,
      show,
    } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.BUSINESS_INDIVIDUAL.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_INDIVIDUAL.REVIEW_DETAILS_MSG1}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_INDIVIDUAL.REVIEW_DETAILS_MSG2}
          </div>

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_KYC) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.BUSINESS_ABOUT_YOU}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.BUSINESS_KYC)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_INDIVIDUAL.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.TYPE_OF_BUSINESS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.business?.businessType}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.ADDRESS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressLine1}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.ADDRESS2}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressLine2}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.CITY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressLine3}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.PROVINCE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressProvince}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.POSTAL_CODE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressPostalCode}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.ADDRESS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingAddressLine1}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.ADDRESS2}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingAddressLine2}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.CITY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingCity}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.PROVINCE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingProvince}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.POSTAL_CODE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingPostalCode}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_EMPLOYMENT) && (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.BUSINESS_EMPLOYMENT}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_EMPLOYMENT)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_INDIVIDUAL.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.IS_CANNABIS_OR_MONEY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      No
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_INDIVIDUAL.IS_FINANCIAL_INSTITUTION}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      No
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title={STRINGS.BUSINESS_INDIVIDUAL.SUCCESS_POP_UP_TITLE}
            description={STRINGS.BUSINESS_INDIVIDUAL.SUCCESS_POP_UP_DESCRIPTION}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessIndividual.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  handleSample: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessIndividual.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  handleSample: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessIndividual;
