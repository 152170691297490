import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import { Typeahead } from "react-bootstrap-typeahead";

class SecurityProperty extends React.Component {
  constructor(props) {
    super(props);
    this.searchField = React.createRef();
    this.state = {
      property: {
        streetNumber: "",
        street: "",
        city: "",
        province: "",
        postalCode: "",
        tenure: "",
      },
      streetNoError: [],
      streetNameError: [],
      cityError: [],
      provinceError: [],
      postalError: [],
      tenureError: [],
      showSearchResult: false,
      addressSearchResult: [],
      executeAdressSearch: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { property } = this.props;
    if (prevProps.property !== property) {
      this.setState({
        property,
      });
    }
  }

  setFormData = () => {};

  handleAddPropertyBtn = (choice) => {
    this.setState({ isAddProperty: choice });
  };

  updateProperty = (prop, value) => {
    let { property } = this.state;
    property[prop] = value;
    this.setState({
      property,
    });
    if (prop === "streetNumber") {
      this.setState({ streetNoError: [] });
    }
    if (prop === "street") {
      this.setState({ streetNameError: [] });
    }
    if (prop === "city") {
      this.setState({ cityError: [] });
    }
    if (prop === "province") {
      this.setState({ provinceError: [] });
    }
    if (prop === "postalCode") {
      this.setState({ postalError: [] });
    }
    if (prop === "tenure") {
      this.setState({ tenureError: [] });
    }
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    this.setState({
      streetNoError: [],
      streetNameError: [],
      cityError: [],
      provinceError: [],
      postalError: [],
      tenureError: [],
    });

    let error = checkErrors(
      property.streetNumber ? property.streetNumber : "",
      VALIDATIONS.LOANS.ASSET_PROPERTY.STREET_NO
    );
    if (error.length) {
      valid = false;
      this.setState({ streetNoError: error });
    }

    error = checkErrors(
      property.street ? property.street : "",
      VALIDATIONS.LOANS.ASSET_PROPERTY.STREET_NAME
    );
    if (error.length) {
      valid = false;
      this.setState({ streetNameError: error });
    }

    error = checkErrors(
      property.city ? property.city : "",
      VALIDATIONS.LOANS.ASSET_PROPERTY.CITY
    );
    if (error.length) {
      valid = false;
      this.setState({ cityError: error });
    }

    error = checkErrors(
      property.province ? property.province : "",
      VALIDATIONS.LOANS.ASSET_PROPERTY.PROVINCE
    );
    if (error.length) {
      valid = false;
      this.setState({ provinceError: error });
    }

    error = checkErrors(
      property.postalCode ? property.postalCode : "",
      VALIDATIONS.LOANS.ASSET_PROPERTY.POSTAL
    );
    if (error.length) {
      valid = false;
      this.setState({ postalError: error });
    }
    error = checkErrors(
      property.tenure ? property.tenure : "",
      VALIDATIONS.LOANS.ASSET_PROPERTY.TENURE
    );
    if (error.length) {
      valid = false;
      this.setState({ tenureError: error });
    }

    if (valid) {
      handleContinue(property);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
  };

  handleChangeAddress = (value) => {
    console.log("handleChangeAddress", value);
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].label,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          showSearchResult: false,
          property: {
            unitNumber: value[0].Unit,
            streetNumber: value[0].StreetNumber,
            street: value[0].StreetName,
            city: value[0].City,
            province: value[0].StateProvince,
            postalCode: value[0].PostalCode,
          },
        });
      } else {
        this.setState({
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  doFindAddress = (value, event) => {
    const {
      executeAdressSearch,
      addressInstanceKey,
      province,
      postalCode,
      city,
      country,
    } = this.state;
    const { doGetAddress, finInfo } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddressOvation", value, eventValue);
    this.setState({ selectedAddress: [] }, () => {
      if (value && value.length > 4) {
        const params = {
          InstanceKey: addressInstanceKey,
          AddressLine1: value,
          Country: "Canada",
        };
        const addressSearchResult = [];
        doGetAddress(params, (response) => {
          console.log("doGetAddress", response);
          const addressRes = response.data.Output;
          console.log("doGetAddress", addressRes);
          if (addressRes && addressRes.length > 0 && executeAdressSearch) {
            addressRes.forEach((raw, idx) => {
              if (raw.Status !== "F") {
                if (raw.Ranges && raw.Ranges.length > 0) {
                  raw.Ranges.forEach((range, idx) => {
                    const streetNo =
                      range.Range?.indexOf("-") > -1
                        ? range.Range.split("-")[1]
                        : range.Range;
                    const address = {
                      label: range.FormattedRangeAddress,
                      AddressLine1: raw.AddressLine1,
                      StreetNumber: streetNo,
                      StreetName: streetNo
                        ? raw.AddressLine1.substring(streetNo.length)?.trim()
                        : raw.AddressLine1,
                      StateProvince: raw.StateProvince,
                      PostalCode: raw.PostalCode,
                      City: raw.City,
                      ProcessedBy: raw.ProcessedBy,
                      Country:
                        raw.Country === "CAN" ? "Canada" : "United States",
                      Unit:
                        range.Range?.indexOf("-") > -1
                          ? range.Range.split("-")[0]
                          : "",
                    };
                    if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                      range.UnitsInfo.forEach((unit) => {
                        addressSearchResult.push({
                          ...address,
                          label: unit.FormattedUnitAddress,
                          Unit: unit.UnitInfo,
                        });
                      });
                    } else {
                      addressSearchResult.push(address);
                    }
                  });
                } else {
                  const address = {
                    label: raw.FormattedAddress,
                    AddressLine1: raw.AddressLine1,
                    StreetNumber: raw.HouseNumber,
                    StreetName:
                      raw.AddressLine1.indexOf(`${raw.HouseNumber} `) === -1
                        ? raw.AddressLine1
                        : raw.AddressLine1.substring(
                            raw.HouseNumber.length
                          )?.trim(),
                    StateProvince: raw.StateProvince,
                    PostalCode: raw.PostalCode,
                    City: raw.City,
                    ProcessedBy: raw.ProcessedBy,
                    Country: raw.Country === "CAN" ? "Canada" : "United States",
                    Unit: "",
                  };
                  addressSearchResult.push(address);
                }
              }
            });
          }
          const address = {
            label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
            AddressLine1: eventValue,
            StateProvince: province,
            PostalCode: postalCode,
            City: city,
            Country: country,
          };
          addressSearchResult.push(address);
          console.log("addressSearchResult", addressSearchResult);
          this.setState({ addressSearchResult, showSearchResult: true });
        });
      }
    });
  };

  render() {
    const {
      steps,
      activeStepID,
      provinceList,
      streetTypes,
      finInfo,
    } = this.props;
    const {
      property,
      streetNoError,
      streetNameError,
      cityError,
      provinceError,
      postalError,
      tenureError,
      showSearchResult,
      addressSearchResult,
    } = this.state;
    console.log(steps, activeStepID);

    const selectProvinceList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const streetList = streetTypes?.map((streetType, index) => (
      <option value={streetType.code} className="[ dropdown__option ]">
        {streetType.description}
      </option>
    ));

    const tenureList = finInfo?.config?.tenureList?.map((tenure, index) => (
      <option value={tenure.code} className="[ dropdown__option ]">
        {tenure.description}
      </option>
    ));

    const actionComponent = (
      <div className="[ property-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.PROPERTY_DETAILS.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.PROPERTY_DETAILS.FORM.ADDRESS_SUBTITLE}
          </div>
          <div className="[ form-description ]">
            {STRINGS.PROPERTY_DETAILS.FORM.ADDRESS_DESCRIPTION1}
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="col-md-12">
                <div className="[ form-label ]">
                  {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                </div>
                <>
                  <Typeahead
                    ref={this.searchField}
                    id="basic-typeahead-single"
                    className="[ address-suggestions ]"
                    clearButton={false}
                    onChange={this.handleChangeAddress}
                    onInputChange={this.doFindAddress}
                    options={addressSearchResult}
                    labelKey="label"
                    emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                    open={showSearchResult}
                    filterBy={() => true}
                    onBlur={() =>
                      this.setState({
                        showSearchResult: false,
                      })
                    }
                  />
                  <div>
                    <small>
                      {STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}{" "}
                      {STRINGS.ADDRESS_SEARCH.RESIDENTIAL_ADDRESS_REQ}
                    </small>
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-12 col-lg-3 ]">
                {/* UNIT */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.UNIT_LABEL}
                </div>
                <input
                  type="text "
                  id="unitNumber"
                  className="[ form-control ]"
                  placeholder={STRINGS.PROPERTY_DETAILS.FORM.UNIT_LABEL}
                  value={property.unitNumber}
                  onChange={(e) =>
                    this.updateProperty("unitNumber", e.target.value)
                  }
                  maxLength={8}
                />
                <div className="[ error-msg ] "></div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* St. No. */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.STREETNUMBER_LABEL}
                </div>
                <input
                  type="text "
                  id="streetNumber"
                  className="[ form-control ]"
                  placeholder={STRINGS.PROPERTY_DETAILS.FORM.STREETNUMBER_LABEL}
                  value={property.streetNumber}
                  onChange={(e) =>
                    this.updateProperty("streetNumber", e.target.value)
                  }
                />
                <div className="[ error-msg ] ">
                  {streetNoError ? streetNoError[0] : ""}
                </div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* St. NAME */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.STREETNAME_LABEL}
                </div>
                <input
                  type="text "
                  id="street"
                  className="[ form-control ]"
                  placeholder={STRINGS.PROPERTY_DETAILS.FORM.STREETNAME_LABEL}
                  value={property.street}
                  onChange={(e) =>
                    this.updateProperty("street", e.target.value)
                  }
                />
                <div className="[ error-msg ] ">
                  {streetNameError ? streetNameError[0] : ""}
                </div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* STREET TYPE */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.STREETTYPE_LABEL}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id="streetType"
                  name="streetType"
                  value={property.streetType}
                  onChange={(e) =>
                    this.updateProperty("streetType", e.target.value)
                  }
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.PROPERTY_DETAILS.FORM.STREETTYPE_LABEL}
                  </option>
                  {streetList}
                </select>
                <div className="[ error-msg ] "></div>
              </div>
            </div>

            <div className="[ row ]">
              <div className="[ col-12 col-md-6 ]">
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                </div>
                <>
                  <input
                    type="text"
                    id="city"
                    className="[ form-control ]"
                    placeholder={STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                    value={property.city}
                    onChange={(e) =>
                      this.updateProperty("city", e.target.value)
                    }
                  />
                  <div className="[ error-msg ]">
                    {cityError ? cityError[0] : ""}
                  </div>
                </>
              </div>

              <div className="[ col-12 col-md-6 ]">
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.PROVINCE_LABEL}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id="province"
                  value={property.province}
                  onChange={(e) =>
                    this.updateProperty("province", e.target.value)
                  }
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.PROPERTY_DETAILS.FORM.PROVINCE_LABEL}
                  </option>
                  {selectProvinceList}
                </select>
                <div className="[ error-msg ]">
                  {provinceError ? provinceError[0] : ""}
                </div>
              </div>

              <div className="[ col-12 col-md-6 ]">
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.POST_CODE_LABEL}
                </div>
                <>
                  <MaskedInput
                    mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                    className="[ form-control ] [ postalCode ]"
                    placeholder={STRINGS.PROPERTY_DETAILS.FORM.POST_CODE_LABEL}
                    guide={false}
                    id="postalCode"
                    value={property.postalCode}
                    onChange={(e) =>
                      this.updateProperty("postalCode", e.target.value)
                    }
                  />
                </>
                <div className="[ error-msg ]">
                  {postalError ? postalError[0] : ""}
                </div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* STREET TYPE */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.OWNERSHIP}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id="tenure"
                  name="tenure"
                  value={property.tenure}
                  onChange={(e) =>
                    this.updateProperty("tenure", e.target.value)
                  }
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.PROPERTY_DETAILS.FORM.OWNERSHIP}
                  </option>
                  {tenureList}
                </select>
                <div className="[ error-msg ] ">
                  {tenureError ? tenureError[0] : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

SecurityProperty.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.oneOfType(PropTypes.object),
};

SecurityProperty.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  finInfo: {},
};

export default SecurityProperty;
