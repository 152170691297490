import * as actionTypes from "Redux/Actions/actionTypes";

export const getBeneficiaryTypeList = () => {
  return {
    type: actionTypes.GET_BENEFICIARY_TYPE,
  };
};

export const getBeneficiaryRelationList = () => {
  return {
    type: actionTypes.GET_BENEFICIARY_RELATION,
  };
};

export const addBeneficiaries = (
  requestBody,
  applicationId,
  contractProductId,
  callback = () => {}
) => {
  return {
    type: actionTypes.ADD_BENEFICIARIES,
    data: requestBody,
    applicationId,
    contractProductId,
    callback,
  };
};

export const deleteBeneficiary = (
  applicationId,
  contractProductId,
  beneficiaryId,
  callback = () => {}
) => {
  return {
    type: actionTypes.DELETE_BENEFICIARY,
    applicationId,
    contractProductId,
    beneficiaryId,
    callback,
  };
};

export const getBeneficiaries = (
  applicationId,
  contractProductId,
  callback = () => {}
) => {
  return {
    type: actionTypes.GET_BENEFICIARIES,
    applicationId,
    contractProductId,
    callback,
  };
};

export const editBeneficiary = (
  requestBody,
  applicationId,
  contractProductId,
  beneficiaryId,
  callback = () => {}
) => {
  return {
    type: actionTypes.EDIT_BENEFICIARIES,
    data: requestBody,
    applicationId,
    contractProductId,
    beneficiaryId,
    callback,
  };
};
