import * as actionTypes from "Redux/Actions/actionTypes";
import STRINGS from "Constants/Strings";
import { GET_APPLICANT_BANKING_PRODUCT_LIST_URL } from "../../../Communication/ApiUrls";

const initialState = {
  success: "",
  error: "",
  intendedUseList: [],
  bankingProductList: [],
};

const AccountInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_INTENDED_USE:
      return {
        ...state,
        intendedUseList: action.intendedUseList,
      };

    case actionTypes.INTENDED_USE_ERROR:
      return {
        ...state,
        error: STRINGS.ACCOUNT_INFORMATION.ERROR.INTENDED_USE_ERROR,
      };
    case actionTypes.UPDATE_PRODUCT_INFORMATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_PRODUCT_INFORMATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_APPLICANT_BANKING_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        bankingProductList: action.data,
      };
    default:
      return state;
  }
};

export default AccountInformationReducer;
