import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import options, { setOptions } from "Utils/EmploymentAndIncomeUtilities";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";
import { LENDING_PRODUCT } from "../../Communication/Constants";
import formatStringByPattern from "format-string-by-pattern";
import { isNewMember, stepAlreadyProcessed } from "../../Utils/LogicUtilities";

class TaxReporting extends React.Component {
  fieldAreYouTaxResidentOfCanada = "areYouTaxResidentOfCanada";
  fieldAreYouTaxResidentOfUsa = "areYouTaxResidentOfUsa";
  fieldAreYouTaxResidentOfOtherCountry = "areYouTaxResidentOfOtherCountry";

  fieldCountryName = "countryName";

  constructor(props) {
    super(props);
    this.state = {
      areYouTaxResidentOfCanada: true,
      areYouTaxResidentOfUsa: false,
      areYouTaxResidentOfUsaProspect: false,
      areYouTaxResidentOfOtherCountry: false,
      areYouTaxResidentOfOtherCountryProspect: false,
      errors: {
        areYouTaxResidentOfCanadaErrorList: "",
        areYouTaxResidentOfUsaErrorList: "",
        areYouTaxResidentOfOtherCountryErrorList: "",
        countryNameErrorList: "",
        countryListErrorList: "",
        sinSsnNoneErrorList: "",
        sinErrorList: "",
        ssnUsaErrorList: "",
        ssnErrorList: "",
      },
      sin: "",
      ssn: "",
      ssnUsa: "",
      countryName: "",
      countryList: "",
      formIsValid: true,
      countryOption: [],
      showOtherTaxResidencyField: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    window.scrollTo(0, 0);
    getDataFromServer(AppConstants.APPLICATIONSTEP.TAX_INFO, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  setFormData = (response) => {
    const {
      finInfo,
      isEdit,
      actionBack,
      activeProduct,
      processFlow,
      vaultMember,
      inSessionJointApplicant,
    } = this.props;
    let { regulatory, member } = response.data;
    if (!inSessionJointApplicant && !activeProduct.prospect) {
      regulatory = { ...regulatory, ...vaultMember };
      member = { ...member, ...vaultMember };
    } else if (inSessionJointApplicant) {
      regulatory = { ...regulatory, ...inSessionJointApplicant.vault };
      member = { ...member, ...inSessionJointApplicant.vault };
    }
    if (
      activeProduct?.prospect?.id &&
      !stepAlreadyProcessed(
        AppConstants.APPLICATIONSTEP.TAX_INFO,
        activeProduct.applicationStep.index,
        processFlow
      )
    ) {
      regulatory.ssn = activeProduct.prospect.regulatory?.ssn;
      member.sin = activeProduct.prospect.member?.sin;
    }

    const resOptions = setOptions(
      regulatory.taxResidency
        ? regulatory.taxResidency
        : finInfo.defaultTaxResidency,
      finInfo
    );

    const resOptionsProspect = setOptions(
      activeProduct.prospect?.regulatory.taxResidency
        ? activeProduct.prospect?.regulatory.taxResidency
        : finInfo.defaultTaxResidency,
      finInfo
    );

    console.log("resOptions", resOptions, isEdit);
    console.log("checking", regulatory.fatcaStatus, finInfo.fatca);
    if (finInfo.useRegulatoryFieldsModel) {
      this.setState({
        sin: member.sin,
        ssnUsa: regulatory.ssn,
        areYouTaxResidentOfUsa: regulatory.ssn
          ? true
          : regulatory.fatcaStatus?.toLowerCase() ===
            finInfo.fatca?.toLowerCase(),
        areYouTaxResidentOfUsaProspect: activeProduct.prospect?.regulatory?.ssn
          ? true
          : activeProduct.prospect?.regulatory?.fatcaStatus?.toLowerCase() ===
            finInfo.fatca?.toLowerCase(),
      });

      const oecdTaxResidency = regulatory.regulatoryFields.find(
        (reg) => reg.Code === 3
      );
      console.log("oecdTaxResidency", oecdTaxResidency);
      const oecdTaxId = regulatory.regulatoryFields.find(
        (reg) => reg.Code === 2
      );
      const oecdTaxStatus = regulatory.regulatoryFields.find(
        (reg) => reg.Code === 1
      );

      let areYouTaxResidentOfOtherCountryProspect = false;
      if (activeProduct.prospect?.id) {
        const taxResidency = activeProduct.prospect?.regulatory?.regulatoryFields.find(
          (reg) => reg.Code === 3
        );
        console.log("oecdTaxResidency", oecdTaxResidency);
        const taxId = activeProduct.prospect?.regulatory?.regulatoryFields.find(
          (reg) => reg.Code === 2
        );
        const taxStatus = activeProduct.prospect?.regulatory?.regulatoryFields.find(
          (reg) => reg.Code === 1
        );
        if (
          (taxResidency && taxId?.Value) ||
          taxStatus?.Value?.toLowerCase() === finInfo.fatca?.toLowerCase()
        ) {
          areYouTaxResidentOfOtherCountryProspect = true;
        }
      }

      console.log("oecdTaxId", oecdTaxId);
      if (
        (oecdTaxResidency && oecdTaxResidency?.Value) ||
        oecdTaxStatus?.Value?.toLowerCase() === finInfo.fatca?.toLowerCase()
      ) {
        this.setState({
          areYouTaxResidentOfCanada: true,
          areYouTaxResidentOfOtherCountry: true,
          areYouTaxResidentOfOtherCountryProspect,
          ssn: oecdTaxId?.Value,
          countryName: oecdTaxResidency?.Value,
        });
      }

      if (resOptions) {
        const selectedOption = resOptions.options.find(
          (obj) =>
            obj.taxResidencyResult.taxResidency ===
            (regulatory.taxResidency
              ? regulatory.taxResidency
              : finInfo.defaultTaxResidency)
        );

        this.handleChoice(this.fieldAreYouTaxResidentOfCanada, true);
      }
    } else {
      this.setState({
        sin: member.sin,
        ssn: regulatory.ssn,
      });

      if (resOptions) {
        this.setState({
          areYouTaxResidentOfCanada: resOptions.taxResidentHome,
          areYouTaxResidentOfOtherCountry: resOptions.taxResidentOther,
          areYouTaxResidentOfOtherCountryProspect:
            resOptionsProspect?.taxResidentOther,
          countryOption: resOptions.options,
        });

        const selectedOption = resOptions.options.find(
          (obj) =>
            obj.taxResidencyResult.taxResidency ===
            (regulatory.taxResidency
              ? regulatory.taxResidency
              : finInfo.defaultTaxResidency)
        );

        const {
          showOtherTaxResidencyField,
        } = selectedOption.taxResidencyResult;
        this.setState({
          showOtherTaxResidencyField,
          countryList: regulatory.taxResidencyOther,
          countryName: selectedOption.taxResidentOtherName,
        });
      }
    }

    if (isEdit || actionBack) {
      console.log("i'm here", member);
      this.handleChoice(this.fieldAreYouTaxResidentOfCanada, !!member.sin);
    } else {
      this.handleChoice(this.fieldAreYouTaxResidentOfCanada, true);
    }
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    if (valid) {
      handleContinue(property);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.TAX_INFO);
  };

  handleCorrespondenceBtn = (flag) => {
    this.setState({
      isPaperCorrespondence: flag,
    });
  };

  handleOverdraftBtn = (flag) => {
    this.setState({
      isOverdraftProtected: flag,
    });
  };

  getOptions = (taxResidentHome, taxResidentOther) => {
    console.log("getOptions", taxResidentHome, taxResidentOther);
    const { finInfo } = this.props;
    const option =
      options(taxResidentHome || false, taxResidentOther || false, finInfo) ??
      [];
    this.setState({ countryOption: option });
  };

  showCountryListField = (countryName) => {
    const { countryOption } = this.state;
    const selectedOption = countryOption.find(
      (obj) => obj.taxResidentOtherName === countryName
    );

    const { showOtherTaxResidencyField } = selectedOption.taxResidencyResult;
    this.setState({ showOtherTaxResidencyField });
  };

  handleChoice = (name, choice, event) => {
    console.log("handleChoise", name, choice, event);
    this.setState({ [name]: choice });

    const {
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfOtherCountry,
      errors,
    } = this.state;
    this.getOptions(
      name === this.fieldAreYouTaxResidentOfCanada
        ? choice
        : areYouTaxResidentOfCanada,
      name === this.fieldAreYouTaxResidentOfOtherCountry
        ? choice
        : areYouTaxResidentOfOtherCountry
    );
    if (name === this.fieldAreYouTaxResidentOfCanada) {
      if (choice) {
        this.setState(
          {
            errors: {
              ...errors,
              areYouTaxResidentOfCanadaErrorList: "",
              areYouTaxResidentOfUsaErrorList: "",
              areYouTaxResidentOfOtherCountryErrorList: "",
              sinSsnNoneErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      } else {
        this.setState(
          {
            sin: "",
            errors: {
              ...errors,
              sinErrorList: "",
              areYouTaxResidentOfCanadaErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      }
    } else if (name === this.fieldAreYouTaxResidentOfUsa) {
      if (choice) {
        this.setState(
          {
            errors: {
              ...errors,
              areYouTaxResidentOfCanadaErrorList: "",
              areYouTaxResidentOfUsaErrorList: "",
              areYouTaxResidentOfOtherCountryErrorList: "",
              sinSsnNoneErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      } else {
        this.setState(
          {
            ssnUsa: "",
            errors: {
              ...errors,
              sinErrorList: "",
              areYouTaxResidentOfUsaErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      }
    } else if (choice) {
      this.setState(
        {
          errors: {
            ...errors,
            areYouTaxResidentOfOtherCountryErrorList: "",
            sinSsnNoneErrorList: "",
          },
        },
        () => {
          const { formIsValid } = this.state;
          if (!formIsValid) this.verifyData();
        }
      );
    } else {
      this.setState(
        {
          countryName: "",
          ssn: "",
          errors: {
            ...errors,
            countryNameErrorList: "",
            ssnErrorList: "",
            areYouTaxResidentOfOtherCountryErrorList: "",
          },
        },
        () => {
          const { formIsValid } = this.state;
          if (!formIsValid) this.verifyData();
        }
      );
    }
  };

  showCountryListField = (countryName) => {
    const { countryOption } = this.state;
    console.log("showCountryListField", countryName, countryOption);
    const selectedOption = countryOption.find(
      (obj) => obj.taxResidentOtherName === countryName
    );
    console.log("selectedOption", selectedOption);
    const { showOtherTaxResidencyField } = selectedOption.taxResidencyResult;
    this.setState({ showOtherTaxResidencyField });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target.name, event?.target.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    const eventName = event?.target.name;
    const eventValue = event?.target.value;
    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
    if (event.target.name === STRINGS.TAX_INFO.FORM.COUNTRYFIELDNAME) {
      this.showCountryListField(event.target.value);
    }
  };

  verifyData = (isContinueClicked = false) => {
    console.log("verifyData", this.state);
    const {
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfUsa,
      areYouTaxResidentOfOtherCountry,
      ssn,
      sin,
      ssnUsa,
      countryName,
      countryList,
      errors,
      showOtherTaxResidencyField,
    } = this.state;
    const { activeProduct, finInfo } = this.props;
    let errorLength = 0;
    let formIsValid = true;

    const areYouTaxResidentOfCanadaError = checkErrors(
      areYouTaxResidentOfCanada,
      VALIDATIONS.TAX_INFO.taxResidentConfirmation
    );
    errorLength += areYouTaxResidentOfCanadaError.length;

    const areYouTaxResidentOfOtherCountryError = checkErrors(
      areYouTaxResidentOfOtherCountry,
      VALIDATIONS.TAX_INFO.taxResidentOfOtherCountryConfirmation
    );
    errorLength += areYouTaxResidentOfOtherCountryError.length;

    let sinError = "";
    if (areYouTaxResidentOfCanada && isContinueClicked) {
      sinError = checkErrors(sin, VALIDATIONS.TAX_INFO.sin);
      errorLength += sinError.length;
    }

    let ssnUsaError = "";
    if (areYouTaxResidentOfUsa && isContinueClicked) {
      ssnUsaError = checkErrors(ssnUsa, VALIDATIONS.TAX_INFO.ssn);
      errorLength += ssnUsaError.length;

      ssnUsaError = checkErrors(ssnUsa, VALIDATIONS.TAX_INFO.ssn_usa);
      errorLength += ssnUsaError.length;
    }

    let countryNameError = "";
    let ssnError = "";
    let countryListError = "";
    if (areYouTaxResidentOfOtherCountry) {
      if (!isContinueClicked) {
        if (countryName) countryNameError = "";
        if (ssn) {
          ssnError = "";
        } else if (showOtherTaxResidencyField) {
          countryListError = "";
        }
      } else {
        countryNameError = checkErrors(
          countryName,
          VALIDATIONS.TAX_INFO.country
        );
        errorLength += countryNameError.length;
        if (finInfo.useRegulatoryFieldsModel) {
          ssnError = checkErrors(ssn, VALIDATIONS.TAX_INFO.ssn_required);
          errorLength += ssnError.length;
        } else {
          ssnError = checkErrors(ssn, VALIDATIONS.TAX_INFO.ssn);
          errorLength += ssnError.length;
        }
        if (showOtherTaxResidencyField) {
          countryListError = checkErrors(
            countryList,
            VALIDATIONS.TAX_INFO.listOfCountries
          );
          errorLength += countryListError.length;
        }
      }
    }
    let sinSsnNoneError = "";
    if (
      !areYouTaxResidentOfCanada &&
      !areYouTaxResidentOfUsa &&
      !areYouTaxResidentOfOtherCountry
    ) {
      sinSsnNoneError = STRINGS.TAX_INFO.FORM.ERROR.BOTHSELECTEDNONEERROR;
      errorLength += 1;
    }

    if (errorLength > 0) {
      formIsValid = false;
    }
    this.setState({
      formIsValid,
      errors: {
        ...errors,
        areYouTaxResidentOfCanadaErrorList: areYouTaxResidentOfCanadaError,
        areYouTaxResidentOfOtherCountryErrorList: areYouTaxResidentOfOtherCountryError,
        countryNameErrorList: countryNameError,
        countryListErrorList: countryListError,
        sinErrorList: sinError,
        ssnErrorList: ssnError,
        sinSsnNoneErrorList: sinSsnNoneError,
        ssnUsaErrorList: ssnUsaError,
      },
    });
    return formIsValid;
  };

  changeSin = (event) => {
    const { errors } = this.state;
    this.setState({
      sin: event.value,
      errors: {
        ...errors,
        sinErrorList: "",
      },
    });
  };

  changeSsnUsa = (event) => {
    const { errors } = this.state;
    this.setState({
      ssnUsa: event.value,
      errors: {
        ...errors,
        ssnUsaErrorList: "",
      },
    });
  };

  changeSsn = (event) => {
    const { errors } = this.state;
    const { finInfo } = this.props;
    if (finInfo.useRegulatoryFieldsModel) {
      const value = event.target.value
        ? event.target.value.replace(/[^0-9a-zA-Z]+/gi, "")
        : "";
      if (event.target.value === value) {
        this.setState({
          ssn: event.target.value,
          errors: {
            ...errors,
            ssnErrorList: "",
          },
        });
      }
    } else {
      this.setState({
        ssn: event.value,
        errors: {
          ...errors,
          ssnErrorList: "",
        },
      });
    }
  };

  countryUsaTax = () => {
    const { ssnUsa, errors, areYouTaxResidentOfUsaProspect } = this.state;

    return (
      <div className="[ drawer-content-container ]">
        <div className="[ info-title ]">
          {STRINGS.TAX_INFO.FORM.USA_TAX_RESIDENCY}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ form-label ]">{STRINGS.TAX_INFO.FORM.SSN}</div>
          <NumberFormat
            format={AppConstants.TAX_INFO.SSN_USA_FORMAT}
            className={
              errors?.ssnUsaErrorList.length > 0
                ? "[ form-control ] [ invalid-input ]"
                : "[ form-control ]"
            }
            disabled={areYouTaxResidentOfUsaProspect}
            type="text"
            name="ssnUsa"
            value={ssnUsa}
            placeholder={`${STRINGS.TAX_INFO.FORM.PLACEHOLDER.SSN}`}
            onValueChange={(value) => {
              this.changeSsnUsa(value);
            }}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;
              return formattedValue?.length < 21;
            }}
          />
          <div className="[ error-msg ]">
            {errors?.ssnUsaErrorList && <>{errors?.ssnUsaErrorList[0]}</>}
          </div>
        </div>
      </div>
    );
  };

  countryTax = () => {
    const {
      countryName,
      countryList,
      ssn,
      errors,
      countryOption,
      showOtherTaxResidencyField,
      areYouTaxResidentOfOtherCountryProspect,
    } = this.state;
    const { finInfo } = this.props;

    return (
      <div className="[ drawer-content-container ]">
        <div className="[ info-title ]">
          {STRINGS.TAX_INFO.FORM.FOREIGN_TAX_RESIDENCY}
        </div>
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.TAX_INFO.FORM.SELECT_COUNTRY}
          </div>

          <select
            className={
              errors?.countryNameErrorList.length > 0
                ? "[ form-control ] [ select__dropdown ] [ invalid-input ]"
                : "[ form-control ] [ select__dropdown ]"
            }
            disabled={areYouTaxResidentOfOtherCountryProspect}
            id={STRINGS.TAX_INFO.FORM.COUNTRY}
            name="countryName"
            value={countryName}
            onChange={(event) => this.handleChange(event)}
          >
            <option value="" className="[ default ] [ dropdown__option ]">
              {STRINGS.TAX_INFO.FORM.PLACEHOLDER.COUNTRY}
            </option>
            {countryOption
              ? countryOption.map((country) => (
                  <option
                    key={country?.taxResidencyResult?.taxResidency}
                    value={country?.taxResidentOtherName}
                    className="[ dropdown__option ]"
                  >
                    {country.taxResidentOtherName}
                  </option>
                ))
              : ""}
          </select>

          <div className="[ error-msg ] ">
            {errors?.countryNameErrorList && (
              <>{errors?.countryNameErrorList}</>
            )}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.TAX_INFO.FORM.TAX_NUMBER}
          </div>
          {finInfo.useRegulatoryFieldsModel && (
            <input
              maxLength={24}
              type="text"
              name="ssn"
              className={
                errors?.ssnErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              disabled={areYouTaxResidentOfOtherCountryProspect}
              placeholder={`${STRINGS.TAX_INFO.FORM.PLACEHOLDER.SSN}`}
              value={ssn}
              onChange={this.changeSsn}
            />
          )}
          {!finInfo.useRegulatoryFieldsModel && (
            <NumberFormat
              format={AppConstants.TAX_INFO.SSN_FORMAT}
              className={
                errors?.ssnErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              disabled={areYouTaxResidentOfOtherCountryProspect}
              type="text"
              name="ssn"
              value={ssn}
              placeholder={`${STRINGS.TAX_INFO.FORM.PLACEHOLDER.SSN}`}
              onValueChange={(value) => {
                this.changeSsn(value);
              }}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue?.length < 21;
              }}
            />
          )}
          <div className="[ error-msg ]">
            {errors?.ssnErrorList && <>{errors?.ssnErrorList[0]}</>}
          </div>
        </div>

        {showOtherTaxResidencyField ? (
          <>
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.TAX_INFO.FORM.LIST_THE_COUNTRIES}
              </div>

              <input
                type="text"
                className={
                  errors?.countryListErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                disabled={areYouTaxResidentOfOtherCountryProspect}
                name="countryList"
                value={countryList}
                placeholder={
                  STRINGS.TAX_INFO.FORM.PLACEHOLDER.LIST_THE_COUNTRIES
                }
                onChange={this.handleChange}
                maxLength={30}
              />
              <div className="[ error-msg ]">
                {errors?.countryListErrorList}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  sinRender = (editMode) => {
    const { sin, errors } = this.state;
    const { newMember, activeProduct } = this.props;
    return (
      <div className="[ drawer-content-container ]">
        <div className="[ info-title ]">
          {STRINGS.TAX_INFO.FORM.TAX_RESIDENCY}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ form-label ]">{STRINGS.TAX_INFO.FORM.SIN}</div>
          {sin && !newMember && (
            <input
              typeof="text"
              readOnly
              className={
                errors?.sinErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              name="sin"
              value={`**** ${sin.substr(sin.length - 3)}`}
              disabled={!(editMode && !activeProduct?.prospect?.member?.sin)}
              placeholder={`${STRINGS.TAX_INFO.FORM.PLACEHOLDER.SIN}`}
              onValueChange={(value) => {
                this.changeSin(value);
              }}
            />
          )}
          {(!sin || newMember) && (
            <NumberFormat
              disabled={!(editMode && !activeProduct?.prospect?.member?.sin)}
              format={AppConstants.TAX_INFO.SIN_FORMAT}
              className={
                errors?.sinErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              type="text"
              name="sin"
              value={sin}
              placeholder={`${STRINGS.TAX_INFO.FORM.PLACEHOLDER.SIN}`}
              onValueChange={(value) => {
                this.changeSin(value);
              }}
            />
          )}
          <div className="[ error-msg ]">
            {errors?.sinErrorList && <>{errors?.sinErrorList[0]}</>}
          </div>
        </div>
      </div>
    );
  };

  handleSubmit = () => {
    const { handleContinue } = this.props;
    if (this.verifyData(true)) handleContinue(this.state);
  };

  render() {
    const {
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfUsa,
      areYouTaxResidentOfUsaProspect,
      areYouTaxResidentOfOtherCountry,
      areYouTaxResidentOfOtherCountryProspect,
      errors,
    } = this.state;

    const {
      steps,
      activeStepID,
      editMode,
      countriesTax,
      finInfo,
      activeProduct,
    } = this.props;

    const actionComponent = (
      <div className="[ tax-reporting ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.TAX_INFO.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            {editMode && (
              <div className="[ form-label ]">
                {STRINGS.TAX_INFO.FORM.TAX_RESIDENT_CONFIRMATION}
              </div>
            )}
            {editMode && (
              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    disabled={
                      !(editMode && !activeProduct?.prospect?.member?.sin)
                    }
                    type="button"
                    className={
                      areYouTaxResidentOfCanada !== "" &&
                      areYouTaxResidentOfCanada
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    onClick={(event) =>
                      this.handleChoice(
                        this.fieldAreYouTaxResidentOfCanada,
                        true,
                        event
                      )
                    }
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>

                <div className="[ col-6 ]">
                  <button
                    disabled={
                      !(editMode && !activeProduct?.prospect?.member?.sin)
                    }
                    type="button"
                    className={
                      areYouTaxResidentOfCanada !== "" &&
                      !areYouTaxResidentOfCanada
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    onClick={(event) =>
                      this.handleChoice(
                        this.fieldAreYouTaxResidentOfCanada,
                        false,
                        event
                      )
                    }
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
              </div>
            )}

            {editMode && (
              <div className="[ error-msg ]">
                {errors?.areYouTaxResidentOfCanadaErrorList && (
                  <>{errors?.areYouTaxResidentOfCanadaErrorList}</>
                )}
              </div>
            )}

            {areYouTaxResidentOfCanada ? this.sinRender(editMode) : null}
          </div>

          {finInfo.useRegulatoryFieldsModel && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.TAX_INFO.FORM.RESIDENT_OF_USA}
              </div>
              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      areYouTaxResidentOfUsa
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    disabled={areYouTaxResidentOfUsaProspect}
                    name="areYouTaxResidentOfUsa"
                    onClick={(event) =>
                      this.handleChoice("areYouTaxResidentOfUsa", true, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      areYouTaxResidentOfUsa
                        ? "[ btn custom-radio-unselected ]"
                        : "[ btn custom-radio-selected ]"
                    }
                    disabled={areYouTaxResidentOfUsaProspect}
                    name="areYouTaxResidentOfUsa"
                    onClick={(event) =>
                      this.handleChoice("areYouTaxResidentOfUsa", false, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
              </div>

              <div className="[ error-msg ]">
                {errors?.areYouTaxResidentOfUsaErrorList}
                {areYouTaxResidentOfOtherCountry === false &&
                areYouTaxResidentOfUsa === false &&
                areYouTaxResidentOfCanada === false
                  ? errors?.sinSsnNoneErrorList
                  : ""}
              </div>
              {areYouTaxResidentOfUsa ? this.countryUsaTax() : ""}
            </div>
          )}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.TAX_INFO.FORM.RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION}
            </div>
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    areYouTaxResidentOfOtherCountry !== "" &&
                    areYouTaxResidentOfOtherCountry
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  disabled={areYouTaxResidentOfOtherCountryProspect}
                  name="areYouTaxResidentOfOtherCountry"
                  onClick={(event) =>
                    this.handleChoice(
                      this.fieldAreYouTaxResidentOfOtherCountry,
                      true,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    areYouTaxResidentOfOtherCountry !== "" &&
                    !areYouTaxResidentOfOtherCountry
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  disabled={areYouTaxResidentOfOtherCountryProspect}
                  name="areYouTaxResidentOfOtherCountry"
                  onClick={(event) =>
                    this.handleChoice(
                      this.fieldAreYouTaxResidentOfOtherCountry,
                      false,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>

            <div className="[ error-msg ]">
              {errors?.areYouTaxResidentOfOtherCountryErrorList}
              {areYouTaxResidentOfOtherCountry === false &&
              areYouTaxResidentOfUsa === false &&
              areYouTaxResidentOfCanada === false
                ? errors?.sinSsnNoneErrorList
                : ""}
            </div>
            {areYouTaxResidentOfOtherCountry ? this.countryTax() : ""}
          </div>

          <div className="[ error-msg ] [ row ]">
            <div className="[ col-12 ]" />
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

TaxReporting.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  editMode: PropTypes.bool,
  finInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  applicantData: PropTypes.objectOf(PropTypes.any),
  countriesTax: PropTypes.arrayOf(PropTypes.object),
  isEdit: PropTypes.bool,
  actionBack: PropTypes.bool,
};

TaxReporting.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  editMode: true,
  applicantData: {},
  countriesTax: [],
};

export default TaxReporting;
