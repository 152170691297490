import { call, put, takeLatest } from "redux-saga/effects";
import GetDocumentList from "Communication/ApiClasses/GetDocumentList";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetDocumentListSaga(action) {
  yield put(doLoadingStart(STRINGS.DOCUMENT_UPLOAD.API_KEYS.GET_DOCUMENT_LIST));

  const getDocumentList = new GetDocumentList(action.data.applicationId);
  try {
    const result = yield call(getDocumentList.getData);
    yield put({
      type: actionTypes.GET_DOCUMENT_LIST_SUCCESS,
      response: result.data,
      formData: { ...action.requestData },
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_DOCUMENT_LIST_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.DOCUMENT_UPLOAD.API_KEYS.GET_DOCUMENT_LIST)
    );
  }
}
// watcher
function* getGetDocumentListWatcherSaga() {
  yield takeLatest(actionTypes.GET_DOCUMENT_LIST, handleGetDocumentListSaga);
}

export default getGetDocumentListWatcherSaga;
