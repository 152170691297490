import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "Constants/ValidationList";
import AppConstants from "Constants/AppConstants";

import { COMMON_BUCKET_URL } from "Communication/ApiUrls";

import checkErrors from "Utils/InputValidator";

// CHRIS NOTE: Stepper not needed on this page
// import Stepper from "Components/Common/Stepper/Stepper";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { QuestionCircle } from "react-bootstrap-icons";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import CardComponent from "Components/Common/CardComponent/CardComponent";

import LinkTermComponent from "./LinkTermComponent";
import CheckBoxComponent from "./CheckBoxComponent";

import "./TermsAndConditions.scss";
import MODALMESSAGES from "../../Constants/Messages";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      showHelp: false,
      termsAccepted: "",
      termsErrorText: "",
      checkboxArray: [],
      linkTermsArray: [],
      modal: {
        title: null,
        description: MODALMESSAGES.TERMS_AND_CONDITIONS.INFO1.replace(
          AppConstants.TEMPLATE.PRODUCT,
          props.activeProduct.productName
        ),
        description2: MODALMESSAGES.TERMS_AND_CONDITIONS.INFO2,
      },
      modalType: AppConstants.MODALTYPE.INFORMATION,
      evaluate: false,
      rendered: false, // TODO :Fix in better way
    };
  }

  componentDidMount() {
    // written here as its prepares the stepper as well.
    const { getDataFromServer } = this.props;
    getDataFromServer(AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS);
  }

  componentDidUpdate() {
    const { jsonState } = this.props;
    const data = jsonState.jsonData;
    const { rendered } = this.state;
    // Getting data from reducer
    if (data.length > 0 && !rendered) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { jsonState, bankingType } = this.props;
    const { jsonData } = jsonState;

    // initialize variables
    let documentArray = [];
    const checkboxArray = [];
    const tempArray = [];

    // Iterating over jsondata component to get required data
    if (jsonData) {
      jsonData.map((data) => {
        // Check if object has any documents
        if (data.document) {
          documentArray = data.document;
          documentArray.map((document) => {
            // push the elements to temperory array
            console.log("document", document);
            if (data?.bankingType === bankingType) {
              tempArray.push({
                description: document.description,
                filename: document.filename,
              });
            }
            return null;
          });
        }

        if (data?.bankingType === bankingType) {
          // Push the elements to checkbox data array
          checkboxArray.push({
            mandatory: data.mandatory,
            description: data.description,
            code: data.code,
            consent: data.consent,
            checked: false,
          });
        }
        return null;
      });

      // set the state variables
      this.setState({
        linkTermsArray: tempArray,
        checkboxArray,
        rendered: true,
      });
    }
  };

  // handle submit request
  handleSubmit = (consentArray) => {
    const { submitConsent } = this.props;
    submitConsent(consentArray);
  };

  // Verify all mandatory data filled
  verifyData = () => {
    const { checkboxArray, termsAccepted } = this.state;
    this.setState({ evaluate: true });
    const error = checkErrors(
      termsAccepted,
      VALIDATIONS.TERMS_AND_CONDITIONS.tearmAndCondition
    );

    this.setState({ termsErrorText: error });
    // Array for consent
    const tempArray = [];
    let valid = true;
    // Get all mandatory checkboxes
    const checkboxes = checkboxArray;
    checkboxes.map((data) => {
      if (data.mandatory && !data.checked) {
        valid = false;
      }
      return null;
    });

    // If all mandatory checkboxes checked
    if (valid) {
      checkboxes.map((data) => {
        if (data.checked) {
          tempArray.push(data.consent);
        }
        return null;
      });

      // Request to consent API
      this.handleSubmit(tempArray);
    } else {
      this.setState({ termsAccepted: "" });
    }
  };

  onChangeCheckBoxHandler = (code, object, checked) => {
    const { checkboxArray } = this.state;
    const index = checkboxArray.map((o) => o.code).indexOf(code);
    let termsAccepted;
    if (checked) {
      checkboxArray[index].checked = true;
      termsAccepted = true;
    } else {
      checkboxArray[index].checked = false;
      termsAccepted = false;
    }
    if (checkboxArray[index].mandatory === true && termsAccepted) {
      this.setState({ checkboxArray, termsAccepted });
    } else {
      this.setState({ termsErrorText: "", termsAccepted: "" });
    }
  };

  linkClickHandler = (fileName, name) => {
    // if filename is valid
    if (fileName) {
      // First reinitialize modal state
      this.setState({ showModal: false }, () => {
        // Initialize variables
        const modal = {};

        // Set data into modal variable
        modal.title = "";
        modal.description = (
          <object
            type="text/html"
            aria-label="Default"
            data={`${COMMON_BUCKET_URL}${fileName}`}
          />
        );

        // set values to state variables
        this.setState({
          showModal: true,
          modal,
          modalType: name,
        });
      });
    }
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const {
      linkTermsArray,
      checkboxArray,
      evaluate,
      showModal,
      showHelp,
      helpText,
      modalType,
      modal,
      termsErrorText,
    } = this.state;

    const cardComponentFirst = (
      <div className="[ info-tile__header]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-birthday-cake ]" />
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="age">
            {STRINGS.MEMBERCREATION.AGE_TEXT}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.MEMBERCREATION.FIRST_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    const cardComponentTwo = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="fab fa-canadian-maple-leaf" />
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="sin">
            {STRINGS.MEMBERCREATION.SIN_TEXT}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.MEMBERCREATION.SECOND_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    const cardComponentThree = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-address-card ]" />
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="govtID">
            {STRINGS.MEMBERCREATION.GOVT_ID}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.MEMBERCREATION.THIRD_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    const cardComponentFour = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-chart-pie ]" />
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="membershare">
            {STRINGS.MEMBERCREATION.MEMBERSHARE_TEXT}
            <QuestionCircle
              className="question-icon"
              size={20}
              onClick={() =>
                this.handleOpenHelp(STRINGS.MEMBERCREATION.FOURTH_CARD_MOREINFO)
              }
            />
          </div>
        </div>
      </div>
    );
    // CHRIS NOTE: Stepper not needed on this page
    // const { steps, activeStepID } = this.props;
    const memberCreationComponent = (
      <div className="[ terms-and-conditions-container ]">
        <div className="[ form-title-terms ]">
          {STRINGS.MEMBERCREATION.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ row info-items]">
            <CardComponent
              firstComponent={cardComponentFirst}
              secondComponent={STRINGS.MEMBERCREATION.FIRST_CARD_TEXT}
            />
            <CardComponent
              firstComponent={cardComponentTwo}
              secondComponent={STRINGS.MEMBERCREATION.SECOND_CARD_TEXT}
            />
            <CardComponent
              firstComponent={cardComponentThree}
              secondComponent={STRINGS.MEMBERCREATION.THIRD_CARD_TEXT}
            />
            <CardComponent
              firstComponent={cardComponentFour}
              secondComponent={STRINGS.MEMBERCREATION.FOURTH_CARD_TEXT}
            />
          </div>

          <div className="[ divider ]" />
          <div className="[ terms-and-conditions__links-container ]">
            <div className="[ alert alert-info ]" role="alert">
              <h4 className="[ alert-heading ]">
                {STRINGS.MEMBERCREATION.AGREE_TERMS}
              </h4>
              <hr />
              {linkTermsArray.length > 0 && (
                <LinkTermComponent
                  linkTermsArray={linkTermsArray}
                  linkClickHandler={this.linkClickHandler}
                />
              )}
            </div>
          </div>
          <div className="[ divider ]" />
          <div>
            {checkboxArray.map((data) => (
              <CheckBoxComponent
                key={data.code}
                data={data}
                onChangeCheckBoxHandler={this.onChangeCheckBoxHandler}
                evaluate={evaluate}
                error={termsErrorText}
              />
            ))}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            description2={modal.description2}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={memberCreationComponent}
          memberCreation
          handleBack={() => {}}
        />
        {showHelp && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}
TermsAndConditions.propTypes = {
  submitConsent: PropTypes.func,
  getDataFromServer: PropTypes.func.isRequired,
  jsonState: PropTypes.oneOfType([PropTypes.object]),
  activeProduct: PropTypes.oneOfType([PropTypes.object]),
};
TermsAndConditions.defaultProps = {
  submitConsent: () => {},
  jsonState: {},
  activeProduct: {},
};

const mapStateToProps = (state) => ({
  termsState: state.TermsReducer,
  jsonState: state.TermsJsonReducer,
});

const wrapper = connect(mapStateToProps)(TermsAndConditions);

export default withApplicantHOC(wrapper);
