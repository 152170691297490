import * as actionTypes from "Redux/Actions/actionTypes";

const doVerificationCodeAction = (userdata) => {
  return {
    type: actionTypes.VERIFICATIONCODE_REQUEST,
    data: userdata,
  };
};

export default doVerificationCodeAction;
