import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  accessToken: "",
  refreshToken: "",
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.types) {
    case actionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.token,
      };
    case actionTypes.LOGOUT:
      return {
        ...initialState,
      };

    case actionTypes.REGISTRATION:
      return {
        ...state,
        userdata: action.userdata,
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};
export default LoginReducer;
