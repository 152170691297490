import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import base64url from "base64url";
import * as ROUTES from "Constants/Routes";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import { HTTP_STATUS } from "Communication/Constants";

import checkErrors from "Utils/InputValidator";

import PopupModal from "Components/Common/PopupModal/PopupModal";

import doRegistration from "./Actions/RegisterAction";
import doCheckExistingUser from "./Actions/ExistingUserActions";
import Register from "./Register";
import {
  otpVerifyInvite,
  setOutSessionOtp,
} from "../JointApplicant/Actions/JointApplicantAction";
import doLogout from "../../Redux/Actions/LogoutAction";
import { storeFilterBankingType } from "../MarketPlace/Action/MarketPlaceAction";

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    // inputfield error object name  should be inputobject with suffix 'Error'
    //  For example input object: username, input error object: usernameError
    this.state = {
      email: "",
      emailConfirm: "",
      mobileNo: "",
      errors: {
        emailErrorList: [],
        emailConfirmErrorList: [],
        mobileNoErrorList: [],
        termsConditionErrorList: [],
      },
      isChecked: false,
      optTo: AppConstants.REGISTRATION.OtpTo,
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      formSubmit: false,
    };
    this.userNameInput = React.createRef();
  }

  componentDidMount() {
    this.userNameInput.current.focus();
    this.executeOtpVerify();
  }

  componentDidUpdate(prevProps) {
    const {
      existingUserState,
      registerState,
      history,
      match,
      otpVerify,
    } = this.props;
    const { formSubmit } = this.state;

    if (existingUserState !== prevProps.existingUserState) {
      const existingUserFlag = existingUserState.success?.data?.existing;
      const autoCreatedUserFlag = existingUserState.success?.data?.autoCreated;
      if (!match.path.startsWith(ROUTES.INVITE_APPLY)) {
        if (existingUserFlag) {
          this.showExistingUserModal();
        } else if (autoCreatedUserFlag) {
          this.showExistingUserModal();
        } else if (!existingUserFlag && formSubmit) {
          this.registrationHandler(existingUserFlag);
        }
      }
    }
    if (registerState !== prevProps.registerState) {
      if (registerState.success.status === HTTP_STATUS.OK) {
        history.push(ROUTES.VERIFICATION_CODE);
      }
    }

    if (otpVerify !== prevProps.otpVerify) {
      if (otpVerify && otpVerify.success) {
        const type = otpVerify.data.type ? otpVerify.data.type : "";
        const username = otpVerify.data.data.email
          ? otpVerify.data.data.email
          : "";
        if (type && type === "register") {
          history.push(ROUTES.VERIFICATION_CODE);
        } else {
          if (username && username !== "") {
            localStorage.setItem("checkbox", true);
            localStorage.setItem("username", username);
          }
          const { slotId, applicationId } = otpVerify?.data?.data;

          if (slotId && slotId !== "") {
            sessionStorage.setItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID,
              `${slotId}-${type}`
            );
            sessionStorage.setItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID,
              applicationId
            );
          }
          history.push(ROUTES.SIGN_IN);
        }
      }
    }
  }

  submitData = (event) => {
    const { doCheckExistingUsers, bankingType } = this.props;
    const { email } = this.state;
    event.preventDefault();
    this.setState({ formSubmit: true });
    const formIsValid = this.verifyData();
    if (formIsValid) {
      const existingUser = {
        credential: email,
        bankingType,
      };

      doCheckExistingUsers(existingUser);
    }
  };

  changeHandler = (event) => {
    const { errors } = this.state;
    const errorObject = `${event.target?.name}${STRINGS.COMMON.ERROR_LIST}`;
    this.setState({
      [event?.target?.name]: event?.target?.value?.toLowerCase(),
      errors: {
        ...errors,
        [errorObject]: [],
      },
    });
  };

  verifyData = () => {
    let formIsValid = true;
    const { email, emailConfirm, mobileNo, isChecked, errors } = this.state;
    const emailRes = checkErrors(email, VALIDATIONS.REGISTRATION.email);

    let emailConfirmRes = [];
    if (emailRes.length === 0) {
      emailConfirmRes = checkErrors(
        emailConfirm,
        VALIDATIONS.REGISTRATION.email
      );
      if (email !== emailConfirm || emailConfirmRes.length > 0) {
        emailConfirmRes.push(STRINGS.REGISTRATION.ERROR.EMAIL_DO_NOT_MATCH);
      }
    }

    const mobileRes = checkErrors(mobileNo, VALIDATIONS.REGISTRATION.mobileno);

    const termscondition = checkErrors(
      isChecked || "",
      VALIDATIONS.REGISTRATION.termscondition
    );
    console.log(emailRes);
    this.setState({
      errors: {
        ...errors,
        emailErrorList: emailRes,
        emailConfirmErrorList: emailConfirmRes,
        mobileNoErrorList: mobileRes,
        termsConditionErrorList: termscondition,
      },
    });

    if (
      emailRes.length > 0 ||
      emailConfirmRes.length > 0 ||
      mobileRes.length > 0 ||
      termscondition.length > 0
    ) {
      formIsValid = false;
    }
    return formIsValid;
  };

  handleChecked = () => {
    const { isChecked, errors } = this.state;
    this.setState({
      isChecked: !isChecked,
      errors:
        isChecked === false
          ? {
              ...errors,
              termsConditionErrorList: [],
            }
          : errors,
    });
  };

  showExistingUserModal = () => {
    this.setState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.FAILURE,
      modal: {
        title: MODALMESSAGES.REGISTRATION.USEREXIST,
        description: "",
      },
    });
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handleSignIn = () => {
    const { history } = this.props;
    history.push(ROUTES.SIGN_IN);
  };

  registrationHandler(existingUserFlag) {
    const { doRegistrations, bankingType } = this.props;
    const { email, mobileNo, optTo, emailConfirm } = this.state;
    const formIsValid = this.verifyData();

    this.setState({ formSubmit: false });

    if (formIsValid) {
      const mobileState = mobileNo;
      const validMobileNo =
        AppConstants.REGISTRATION.NUMBERPREFIX +
        mobileState.replace(AppConstants.REGISTRATION.CONTACT_NO_PATTERN, ""); // For Removing special characters

      const userDetail = {
        email,
        msisdn: validMobileNo,
        otpTo: optTo,
        confirm: emailConfirm,
        bankingType,
      };

      if (!existingUserFlag) {
        doRegistrations(userDetail);
      }
    }
  }

  executeOtpVerify() {
    const {
      doOtpVerifyInvite,
      location,
      match,
      history,
      doSetOutSessionOtp,
      doLogOutAction,
      bankingType,
      doStoreFilterBankingType,
    } = this.props;

    try {
      if (match.path.startsWith(ROUTES.INVITE_APPLY)) {
        doLogOutAction(() => {
          doStoreFilterBankingType(AppConstants.BANKING_TYPE.PERSONAL);
          const inviteId = location.pathname.substring(
            location.pathname.lastIndexOf("/") + 1
          );
          const cread = base64url.decode(inviteId);
          const credentials = cread.split(":");

          doSetOutSessionOtp({
            otpId: credentials[0],
            otp: credentials[1],
          });

          doOtpVerifyInvite(
            {
              purpose: "register",
              otpId: credentials[0],
              otp: credentials[1],
              bankingType,
            },
            (otpVerifyRes) => {
              if (otpVerifyRes.status === HTTP_STATUS.OK) {
                const otpVerifyEmail = otpVerifyRes.data.data.email;
                const otpVerifyMobileNo = otpVerifyRes.data.data.msisdn;
                this.setState({
                  isJointApplicant: true,
                  email: otpVerifyEmail,
                  mobileNo: otpVerifyMobileNo,
                });
              }
            }
          );
        });
      }
    } catch (err) {
      history.push(ROUTES.SIGN_IN);
    }
  }

  render() {
    const {
      email,
      emailConfirm,
      mobileNo,
      errors,
      isChecked,
      showModal,
      modalType,
      modal,
    } = this.state;
    const { bankingType } = this.props;
    return (
      <>
        <Register
          email={email}
          emailConfirm={emailConfirm}
          inputFocus={this.userNameInput}
          mobileNo={mobileNo}
          emailError={errors?.emailErrorList}
          emailConfirmError={errors?.emailConfirmErrorList}
          mobileNoError={errors?.mobileNoErrorList}
          termsConditionError={errors?.termsConditionErrorList}
          onChangeHandler={this.changeHandler}
          isChecked={isChecked}
          showModal={showModal}
          modalType={modalType}
          modal={modal}
          toggleModal={this.toggleModal}
          signinHandler={this.handleSignIn}
          submitData={this.submitData}
          handleChecked={this.handleChecked}
          bankingType={bankingType}
        />
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.handleSignIn}
          />
        )}
      </>
    );
  }
}

RegisterContainer.propTypes = {
  existingUserState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  registerState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  history: PropTypes.oneOfType([PropTypes.object]),
  doCheckExistingUsers: PropTypes.func,
  doRegistrations: PropTypes.func,
  doOtpVerifyInvite: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]),
  doSetOutSessionOtp: PropTypes.func.isRequired,
  doLogOutAction: PropTypes.func.isRequired,
  otpVerify: PropTypes.oneOfType([PropTypes.object]),
};

RegisterContainer.defaultProps = {
  existingUserState: "",
  registerState: "",
  history: {},
  doCheckExistingUsers: () => {},
  doRegistrations: () => {},
  location: {},
  match: {},
  otpVerify: {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    doRegistrations: (userDetails) => dispatch(doRegistration(userDetails)),
    doCheckExistingUsers: (userDetails) =>
      dispatch(doCheckExistingUser(userDetails)),
    doOtpVerifyInvite: (request, callback) =>
      dispatch(otpVerifyInvite(request, callback)),
    doSetOutSessionOtp: (request, callback) =>
      dispatch(setOutSessionOtp(request, callback)),
    doLogOutAction: (callback) => dispatch(doLogout(callback)),
    doStoreFilterBankingType: (bankingType) =>
      dispatch(storeFilterBankingType(bankingType)),
  };
};
const mapStateToProps = (state) => ({
  registerState: state.RegisterReducer,
  existingUserState: state.ExistingUserReducer,
  otpVerify: state.JoinApplicantReducer.otpVerify,
  bankingType: state.MarketplaceReducer.bankingType,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
