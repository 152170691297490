import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { getFutureDate } from "../../Utils/LosUtilities";

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      loanDetails: {},
      loanTerms: [],
      loanPurposes: [],
      loanRepayments: [],
      isOtherPerson: "",
      loanAmount: 0,
      downPayment: 0,
      downPaymentPercent: 0,
      estimatedMortgage: 0,
      amortization: 25,
      insuranceAmount: 0,
      loanTerm: 0,
      loanRate: 0.0,
      monthlyPayment: 0,
      loanScheduleDate: format(new Date(), "yyyy-MM-dd"),
      paymentType: "monthly",
      paymentTypes: [],
      loanConfig: {
        loanAmount: "",
        loanTerm: "",
        loanRate: "",
      },
      amortizationList: [10, 15, 20, 25, 30],
    };
  }

  componentDidMount() {
    const {
      getDataFromServer,
      products,
      getActiveProduct,
      loanProducts,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    const currentProduct = loanProducts.find(
      (product) => product.id === activeProduct.productId
    );
    let loanAmount = 0;
    let loanTerm = 0;
    let loanRate = 0.0;
    if (currentProduct) {
      if (currentProduct.loanAmount?.min) {
        loanAmount = currentProduct.loanAmount.min;
      }
      if (currentProduct.loanTerm?.min) {
        loanTerm = currentProduct.loanTerm.min;
      }
      if (currentProduct.loanRate?.length > 0) {
        loanRate = currentProduct?.loanRate[0].min;
      }
    }
    this.setState({
      loanAmount,
      loanTerm,
      loanRate,
    });
    getDataFromServer(AppConstants.APPLICATIONSTEP.LOAN_DETAILS, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    const { loanProducts, products } = this.props;

    if (loanProducts !== prevProps.loanProducts) {
      this.initialize();
    }
  }

  setFormData = () => {};

  handleChange = (prop) => (event) => {
    console.log("handleChange", prop);
    this.setState({ prop: event.target.value });
    this.doCalculation();
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue(this.doUpdateLoanForm());
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_DETAILS);
  };

  handleOtherPerson = (choice) => {
    this.setState({ isOtherPerson: choice });
  };

  initialize() {
    console.log("initialize");
    const { loanProducts, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);

    if (!activeProduct) {
      return;
    }
    const productId = activeProduct.productId;
    let purposes = [];
    let paymentTypes = [];
    let loanConfig = {};

    if (loanProducts) {
      loanProducts.forEach((raw, idx) => {
        if (raw.id === productId) {
          purposes = raw.purpose;
          paymentTypes = raw.paymentTypes;
          loanConfig = {
            loanAmount: raw.loanAmount,
            loanTerm: raw.loanTerm,
            loanRate: raw.loanRate,
          };
        }
      });
    }

    let loanDetails = activeProduct.loanDetails
      ? activeProduct.loanDetails
      : {};

    this.setState(
      {
        loanPurposes: purposes,
        paymentTypes: paymentTypes,
        loanConfig: loanConfig,
        loanAmount: loanDetails.loanAmount
          ? loanDetails.loanAmount
          : this.state.loanAmount,
        loanTerm: loanDetails.loanTerm
          ? loanDetails.loanTerm
          : this.state.loanTerm,
        loanRate: loanDetails.loanRate
          ? loanDetails.loanRate
          : this.state.loanRate,
        monthlyPayment: loanDetails.monthlyPayment
          ? loanDetails.monthlyPayment
          : this.state.monthlyPayment,
        loanScheduleDate: loanDetails.loanScheduleDate
          ? loanDetails.loanScheduleDate
          : this.state.loanScheduleDate,
        paymentType: loanDetails.paymentType
          ? loanDetails.paymentType
          : this.state.paymentType,
        downPayment: loanDetails.downPayment
          ? loanDetails.downPayment
          : this.state.downPayment,
        downPaymentPercent: loanDetails.downPaymentPercent
          ? loanDetails.downPaymentPercent
          : this.state.downPaymentPercent,
        amortization: loanDetails.amortization
          ? loanDetails.amortization
          : this.state.amortization,
      },
      () => {
        this.doCalculation();
      }
    );
  }

  setPaymentTypeValue = (value) => {
    console.log("setPaymentTypeValue", value);
    this.setState({ paymentType: value }, function () {
      this.doCalculation();
    });
  };

  setLoanAmountValue = (amount) => {
    console.log("setLoanAmountValue", amount);
    const { products, getActiveProduct } = this.props;
    const { loanConfig } = this.state;
    const activeProduct = getActiveProduct(products);
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    amount = parseFloat(amount).toFixed(2);
    if (activeProduct.productType === "Loan") {
      if (amount > loanConfig.loanAmount.max) {
        amount = loanConfig.loanAmount.max;
      }
    }
    this.setState({ loanAmount: amount }, function () {
      this.doCalculation();
    });
  };

  setDownPaymentValue = (amount) => {
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    const { loanAmount } = this.state;
    const downPaymentPercent = parseFloat((amount / loanAmount) * 100).toFixed(
      2
    );
    this.setState({ downPayment: amount, downPaymentPercent }, function () {
      this.doCalculation();
    });
  };

  setDownPaymentPercentValue = (amount) => {
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    const { loanAmount } = this.state;
    const downPayment = loanAmount * (amount / 100);
    this.setState({ downPaymentPercent: amount, downPayment }, function () {
      this.doCalculation();
    });
  };

  setInsuranceValue = (amount) => {
    amount = amount.split("$").join("");
    amount = amount.split(",").join("");
    this.setState({ insuranceAmount: amount }, function () {
      this.doCalculation();
    });
  };

  setEstimatedMortgageValue = (amount) => {
    this.setState({ estimatedMortgage: amount }, function () {
      this.doCalculation();
    });
  };

  setLoanTermValue = (term) => {
    this.setState({ loanTerm: term }, function () {
      this.doCalculation();
    });
  };

  setAmortizationValue = (value) => {
    this.setState({ amortization: value }, function () {
      this.doCalculation();
    });
  };

  doCalculationPersonal() {
    const {
      loanAmount,
      loanTerm,
      loanRate,
      loanConfig,
      loanScheduleDate,
      paymentType,
    } = this.state;
    console.log("doCalculation", loanAmount, paymentType);
    const rates = loanConfig.loanRate;
    let newLoanRate = loanRate;
    rates.forEach((raw, idx) => {
      const term = parseInt(loanTerm);
      const minTerm = parseInt(raw.min);
      const maxTerm = parseInt(raw.max);
      if (term >= minTerm && term <= maxTerm) {
        newLoanRate = raw.rate;
      }
    });

    const newRate = newLoanRate / 100 / 12;
    const numberOfPayments = this.getNumberOfPayments(loanTerm, paymentType);
    console.log("newRate", newRate);
    console.log("numberOfPayments", numberOfPayments);
    let monthlyPayment =
      (newRate * loanAmount * Math.pow(1 + newRate, numberOfPayments)) /
      (Math.pow(1 + newRate, numberOfPayments) - 1);
    monthlyPayment = monthlyPayment.toFixed(2);
    monthlyPayment = parseFloat(monthlyPayment);
    this.setState({
      loanRate: newLoanRate,
      monthlyPayment,
      loanScheduleDate: loanScheduleDate,
    });
  }

  doCalculationMortgage() {
    const {
      loanAmount,
      loanTerm,
      loanConfig,
      loanScheduleDate,
      paymentType,
      downPayment,
      downPaymentPercent,
      amortization,
      loanRate,
    } = this.state;
    console.log("doCalculationMortgage", loanAmount, downPayment);
    const currLoanAmount = loanAmount !== "" ? parseFloat(loanAmount) : 0;
    const currDownPayment = downPayment !== "" ? parseFloat(downPayment) : 0;

    const mortgageAmount = currLoanAmount - currDownPayment;
    let lv = mortgageAmount / currLoanAmount;
    console.log("LV", lv);
    let insuranceAmount = 0;

    const currDownPaymentPercent = downPaymentPercent / 100;
    console.log("currDownPaymentPercent", currDownPaymentPercent);
    if (currDownPaymentPercent < 0.2) {
      if (lv <= 0.65) {
        insuranceAmount = mortgageAmount * 0.017;
      } else if (lv <= 0.75) {
        insuranceAmount = mortgageAmount * 0.017;
      } else if (lv <= 0.8) {
        insuranceAmount = mortgageAmount * 0.024;
      } else if (lv <= 0.85) {
        insuranceAmount = mortgageAmount * 0.028;
      } else if (lv <= 0.9) {
        insuranceAmount = mortgageAmount * 0.031;
      } else if (lv <= 0.95) {
        insuranceAmount = mortgageAmount * 0.04;
      }
    }

    if (currDownPayment === 0) {
      insuranceAmount = 0;
    }

    console.log(currLoanAmount, currDownPayment, insuranceAmount);
    const estimatedMortgage = parseFloat(
      currLoanAmount - currDownPayment + insuranceAmount
    );
    console.log("estimatedMortgage", estimatedMortgage);
    const monthlyRate = parseFloat(loanRate) / 12 / 100;
    console.log("monthlyRate", monthlyRate);
    const numberMonths = amortization * 12;
    console.log("numberMonths", numberMonths);
    const numerator =
      estimatedMortgage * monthlyRate * Math.pow(1 + monthlyRate, numberMonths);
    console.log("numerator", numerator);
    const denominator = Math.pow(1 + monthlyRate, numberMonths) - 1;
    console.log("denominator", denominator);
    let monthlyPayment = numerator / denominator;
    monthlyPayment = monthlyPayment.toFixed(2);
    monthlyPayment = parseFloat(monthlyPayment);
    insuranceAmount = insuranceAmount.toFixed(2);
    this.setState({
      loanRate,
      monthlyPayment,
      estimatedMortgage,
      insuranceAmount,
    });
  }

  doCalculation() {
    const { activeProduct } = this.props;
    if (activeProduct.productType === "Mortgage") {
      this.doCalculationMortgage();
    } else {
      this.doCalculationPersonal();
    }
  }

  // NOT YET CORRECT
  getRate(annualRate, paymentType, loanTerm) {
    console.log(annualRate, paymentType, loanTerm);
    switch (paymentType) {
      case "acc_biweekly":
        return (
          (annualRate / 100 / 26) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "acc_weekly":
        return (
          (annualRate / 100 / 52) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "biweekly":
        return (
          (annualRate / 100 / 26) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "monthly":
        return (annualRate / 100) * (loanTerm / 12);
      case "quarterly":
        return annualRate / 100 / 4;
      case "semiannually":
        return annualRate / 100 / 2;
      case "semimonthly":
        return annualRate / 100 / 12;
      case "weekly":
        return (
          (annualRate / 100 / 52) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "annually":
        return (annualRate / 100) * (loanTerm / 12);
      default:
        return (annualRate / 100) * (loanTerm / 12);
    }
  }

  getNumberOfPayments(loanTerm, paymentType) {
    switch (paymentType) {
      case "acc_biweekly":
        return Math.floor(loanTerm * (26 / 12));
      case "acc_weekly":
        return Math.floor(loanTerm * (52 / 12));
      case "biweekly":
        return Math.floor(loanTerm * (26 / 12));
      case "monthly":
        return loanTerm;
      case "quarterly":
        return Math.floor(loanTerm * (4 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (4 / 12));
      case "semiannually":
        return Math.floor(loanTerm * (2 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (2 / 12));
      case "semimonthly":
        return Math.floor(loanTerm * (24 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (24 / 12));
      case "weekly":
        return Math.floor(loanTerm * (52 / 12));
      case "annually":
        return Math.floor(loanTerm * (1 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (1 / 12));
      default:
        return loanTerm;
    }
  }

  doUpdateLoanForm() {
    console.log("doUpdateLoanForm");
    const {
      loanAmount,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanScheduleDate,
      paymentType,
      downPayment,
      downPaymentPercent,
      amortization,
      estimatedMortgage,
    } = this.state;

    return {
      loanAmount: parseFloat(loanAmount),
      loanTerm: parseInt(loanTerm),
      loanRate: parseFloat(loanRate),
      monthlyPayment: parseFloat(monthlyPayment),
      loanScheduleDate: loanScheduleDate,
      paymentType: paymentType,
      downPayment,
      downPaymentPercent,
      amortization,
      estimatedMortgage,
    };
  }

  commaFormatted(amount) {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  render() {
    const {
      choice,
      loanDetails,
      isOtherPerson,
      loanAmount,
      downPayment,
      downPaymentPercent,
      insuranceAmount,
      estimatedMortgage,
      amortization,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanScheduleDate,
      paymentType,
      paymentTypes,
      loanConfig,
      amortizationList,
    } = this.state;
    const {
      steps,
      activeStepID,
      loanProducts,
      products,
      getActiveProduct,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    /*console.log(
      steps,
      activeStepID,
      loanProducts,
      loanDetails,
      isOtherPerson,
      paymentTypes,
      loanConfig
    );*/

    const amortizationComponent = amortizationList.map((value, index) => (
      <option value={value} className="[ dropdown__option ]">
        {value} years
      </option>
    ));

    const loanProductOptions =
      loanProducts &&
      loanProducts.length !== 0 &&
      loanProducts.map((loan) => (
        <option value={loan.name} className="[ dropdown__option ]">
          {loan.description}
        </option>
      ));

    const loanPaymentTypeOptions =
      paymentTypes &&
      paymentTypes.length !== 0 &&
      paymentTypes.map((pType) => (
        <option value={pType.paymentTypeCodes} className="[ dropdown__option ]">
          {pType.description}
        </option>
      ));

    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.LOAN_DETAILS.TITLE}</div>

        <div className="[ form-container ]">
          {/* BEGIN MORTGAGE */}
          {activeProduct.productType === "Mortgage" && (
            <>
              <div className="[ row ]">
                <div className="[ col-lg-7 ]">
                  <div className="[ form-subtitle ]">
                    {STRINGS.LOAN_DETAILS.SUBTITLE}
                  </div>
                  <div className="[ details-section__item ] [ mb-3 ]">
                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <div className="[ form-label ]">
                            {STRINGS.LOAN_DETAILS.FORM.PROPERTY_VALUE_LABEL}
                          </div>
                          <NumberFormat
                            maxLength={
                              AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                            }
                            type="text"
                            allowLeadingZeros={false}
                            allowNegative={false}
                            thousandSeparator
                            prefix={
                              AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX
                            }
                            name="propertyValue"
                            className="[ form-control ]"
                            placeholder={
                              STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                            }
                            onChange={(e) =>
                              this.setLoanAmountValue(e.target.value)
                            }
                            value={loanAmount}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-sm-5 ]">
                          <div className="[ form-label ]">
                            {STRINGS.LOAN_DETAILS.FORM.DOWNPAYMENT_AMOUNT_LABEL}
                          </div>
                          <div className="[ input-group ]">
                            <div className="[ input-group-prepend ]">
                              <span className="[ input-group-text ]">$</span>
                            </div>
                            <NumberFormat
                              maxLength={
                                AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                              }
                              type="text"
                              thousandSeparator
                              allowNegative={false}
                              // prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              name="downPayment"
                              className="[ form-control ]"
                              placeholder={
                                STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                              }
                              onChange={(e) =>
                                this.setDownPaymentValue(e.target.value)
                              }
                              value={downPayment}
                            />
                            <div className="[ input-group-append ]">
                              <span className="[ input-group-text ]">.00</span>
                            </div>
                          </div>
                          <small className="[]">
                            {STRINGS.LOAN_DETAILS.FORM.DOWNPAYMENT_AMOUNT_HINT}
                          </small>
                        </div>
                        <div className="[ col-sm-2 ] [ align-items-center ] [ d-flex ] [ justify-content-center ]">
                          <div className="[ form-label ]">&nbsp;</div>
                          <span className="[ fas fa-exchange-alt ]"></span>
                        </div>
                        <div className="[ col-sm-5 ]">
                          <div className="[ form-label ]">&nbsp;</div>
                          <div className="[ input-group ]">
                            <NumberFormat
                              maxLength={
                                AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                              }
                              type="text"
                              thousandSeparator
                              allowNegative={false}
                              // prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              name="downPaymentPercentage"
                              className="[ form-control ]"
                              placeholder="Enter a percentage"
                              onChange={(e) =>
                                this.setDownPaymentPercentValue(e.target.value)
                              }
                              value={downPaymentPercent}
                            />
                            <div className="[ input-group-append ]">
                              <span className="[ input-group-text ]">%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="[ error-msg ]"></div>
                      {downPayment > 0 && (
                        <div className="[ drawer-content-container ]">
                          <div className="[ form-label ]">
                            {STRINGS.LOAN_DETAILS.FORM.INSURANCE_LABEL}
                          </div>
                          <NumberFormat
                            maxLength={
                              AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                            }
                            type="text"
                            thousandSeparator
                            allowNegative={false}
                            prefix={
                              AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX
                            }
                            name="insuranceAmount"
                            className="[ form-control ]"
                            placeholder={
                              STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                            }
                            onChange={(e) =>
                              this.setInsuranceValue(e.target.value)
                            }
                            value={insuranceAmount}
                            readOnly
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="[ mb-5 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ form-label ]">
                          {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_ESTIMATE_LABEL}
                        </div>
                        <div className="[ row ]">
                          <div className="[ col-8 offset-2 col-lg-12 offset-lg-0 ]">
                            <NumberFormat
                              disabled={true}
                              maxLength={
                                AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                              }
                              type="text"
                              thousandSeparator
                              allowNegative={false}
                              prefix={
                                AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX
                              }
                              name="estimatedMortgage"
                              className="[ form-control ] [ rate ]"
                              placeholder={
                                STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                              }
                              onChange={(e) =>
                                this.setEstimatedMortgageValue(e.target.value)
                              }
                              value={estimatedMortgage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.PRODUCT_LABEL_LOAN_TERM_YEARS}
                    </div>
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ row ]">
                          <div className="[ col-8 offset-2 col-lg-3 offset-lg-0 ] [ mb-4 ]">
                            <input
                              type="text"
                              inputMode="numeric"
                              value={loanTerm}
                              className="amount-inactive"
                              disabled
                            />
                          </div>

                          <div className="[ col-12 col-lg-9 order-lg-last ]">
                            <div className="[ row ]">
                              <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ] [ disabled ]">
                                {loanConfig.loanTerm.min}{" "}
                                {STRINGS.LOAN_DETAILS.FORM.YEAR}
                              </div>
                              <div className="[ col-6 ]">
                                <RangeSlider
                                  disabled={true}
                                  value={loanTerm}
                                  min={1}
                                  max={15}
                                  onChange={(e) =>
                                    this.setLoanTermValue(e.target.value)
                                  }
                                  tooltipLabel={(currentValue) =>
                                    `${currentValue}`
                                  }
                                  tooltipPlacement="top"
                                  tooltip="off"
                                  alwaysShowTooltip={true}
                                  step={1}
                                  className="[ w-100 ]"
                                  readOnly
                                />
                              </div>
                              <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ] [ disabled ]">
                                {loanConfig.loanTerm.max}{" "}
                                {STRINGS.LOAN_DETAILS.FORM.YEAR}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="[ mb-5 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ form-label ]">
                          {STRINGS.LOAN_DETAILS.FORM.AMORTIZATION}
                        </div>
                        <select
                          value={amortization}
                          className="[ form-control ] [ select__dropdown ]"
                          onChange={(e) =>
                            this.setAmortizationValue(e.target.value)
                          }
                        >
                          {amortizationComponent}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="[ mb-5 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.PRODUCT_LABEL_ESTIMATED_RATE}
                    </div>
                    <input
                      disabled={true}
                      value={loanRate.toFixed(2) + " %"}
                      type="text "
                      name="interestRate"
                      className="[ form-control ] [ rate ]"
                      placeholder=""
                    />
                  </div>

                  <hr className="[ mb-3 ] [ d-lg-none ]" />
                </div>

                <div className="[ col-lg-5 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-subtitle ]">
                      {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_TABLE_TITLE}
                    </div>
                    <div className="[ details-section ] [ summary-tab ]">
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                          }
                        </div>
                        <div className="[ details-section__item-description ]">
                          $ {this.commaFormatted(estimatedMortgage)}
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                          }
                        </div>
                        <div className="[ details-section__item-description ]">
                          {loanTerm} {STRINGS.LOAN_DETAILS.FORM.YEAR}
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          Amortization
                        </div>
                        <div className="[ details-section__item-description ]">
                          {amortization} {STRINGS.LOAN_DETAILS.FORM.YEARS}
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                          }
                        </div>
                        <div className="[ details-section__item-description ]">
                          {parseFloat(loanRate).toFixed(2)}%
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.LOAN_DETAILS.FORM.MONTHLY_PAYMENT}
                        </div>
                        <div className="[ details-section__item-description ]">
                          ${" "}
                          {this.commaFormatted(
                            parseFloat(monthlyPayment).toFixed(2)
                          )}
                        </div>
                      </div>
                      <div className="[ mb-3 ]">
                        <div className="[ row ]">
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT1}
                            </small>
                          </div>
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT2}
                            </small>
                          </div>
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT3}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* END MORTGAGE */}

          {/* BEGIN PERSONAL LOAN */}
          {activeProduct.productType === "Loan" && (
            <>
              <div className="[ row ]">
                <div className="[ col-lg-7 ]">
                  <div className="[ form-subtitle ]">
                    {STRINGS.LOAN_DETAILS.SUBTITLE}
                  </div>

                  <div className="[ mb-4 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.PRODUCT_LABEL_LOAN_AMOUNT}
                    </div>
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ row ]">
                          <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ] [ pl-0 ]">
                            <div className="[ input-group ] [ loan-amount ]">
                              <div className="[ input-group-prepend ]">
                                <span className="[ input-group-text ]">$</span>
                              </div>
                              <NumberFormat
                                maxLength={
                                  AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                                }
                                type="text"
                                allowLeadingZeros={false}
                                allowNegative={false}
                                thousandSeparator
                                // prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                                className="[ form-control ] [ amount-active ]"
                                // placeholder={
                                //   STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                                // }
                                onChange={(e) =>
                                  this.setLoanAmountValue(e.target.value)
                                }
                                value={loanAmount}
                              />
                            </div>
                          </div>

                          <div className="[ col-12 col-lg-8 order-lg-first ]">
                            <div className="[ row ]">
                              <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ]">
                                ${" "}
                                {this.commaFormatted(loanConfig.loanAmount.min)}
                              </div>
                              <div className="[ col-6 ]">
                                <RangeSlider
                                  value={loanAmount}
                                  min={loanConfig.loanAmount.min}
                                  max={loanConfig.loanAmount.max}
                                  onChange={(e) =>
                                    this.setLoanAmountValue(e.target.value)
                                  }
                                  tooltipLabel={(currentValue) =>
                                    `$ ${this.commaFormatted(currentValue)}`
                                  }
                                  tooltipPlacement="top"
                                  step={loanConfig.loanAmount.steps}
                                  className="[ w-100 ]"
                                />
                              </div>
                              <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ]">
                                ${" "}
                                {this.commaFormatted(loanConfig.loanAmount.max)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.PRODUCT_LABEL_LOAN_TERM}
                    </div>
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ row ]">
                          <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ]">
                            <input
                              type="text"
                              inputMode="numeric"
                              value={
                                loanTerm +
                                " " +
                                STRINGS.LOAN_DETAILS.FORM.MONTHS
                              }
                              className="[ amount-inactive ]"
                              disabled
                            />
                          </div>

                          <div className="[ col-12 col-lg-8 order-lg-first ]">
                            <div className="[ row ]">
                              <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ]">
                                {loanConfig.loanTerm.min}
                              </div>
                              <div className="[ col-6 ]">
                                <RangeSlider
                                  value={loanTerm}
                                  min={loanConfig.loanTerm.min}
                                  max={loanConfig.loanTerm.max}
                                  onChange={(e) =>
                                    this.setLoanTermValue(e.target.value)
                                  }
                                  tooltipLabel={(currentValue) =>
                                    `${currentValue}`
                                  }
                                  tooltipPlacement="top"
                                  step={loanConfig.loanTerm.steps}
                                  className="[ w-100 ]"
                                />
                              </div>
                              <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ]">
                                {loanConfig.loanTerm.max}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="[ mb-5 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.PRODUCT_LABEL_ESTIMATED_RATE}
                    </div>
                    <input
                      disabled={true}
                      value={parseFloat(loanRate).toFixed(2) + " %"}
                      type="text "
                      name="interestRate"
                      className="[ form-control ] [ rate ]"
                      placeholder=""
                    />
                  </div>
                  <hr className="[ mb-3 ] [ d-lg-none ]" />
                </div>
                <div className="[ col-lg-5 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-subtitle ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_BREAKDOWN
                      }
                    </div>
                    <div className="[ details-section ] [ summary-tab ]">
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                          }
                        </div>
                        <div className="[ details-section__item-description ]">
                          $ {this.commaFormatted(loanAmount)}
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                          }
                        </div>
                        <div className="[ details-section__item-description ]">
                          {loanTerm}{" "}
                          {activeProduct.productType === "Loan"
                            ? "months"
                            : "years"}
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                          }
                        </div>
                        <div className="[ details-section__item-description ]">
                          {parseFloat(loanRate).toFixed(2)}%
                        </div>
                      </div>
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <small className="[ footnotes form-text text-muted ]">
                            {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT1}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="[ details-section ] [ summary-tab ] [ mb-3 ]">
                    <div className="[ row ] [ details-section__header ] [ mb-3 ]">
                      <div className="[ col-7 ]">
                        <h3 className="[ d-flex justify-content-start ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM
                              .PRODUCT_LABEL_LOAN_DETAILS_MONTHLY_PAYMENT
                          }
                        </h3>
                      </div>
                      <div className="[ col-5 ]">
                        <h3 className="[ d-flex justify-content-end ]">
                          ${" "}
                          {this.commaFormatted(
                            parseFloat(monthlyPayment).toFixed(2)
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <small className="[ footnotes form-text text-muted ]">
                            {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT2}
                          </small>
                        </div>
                        <div className="[ col-12 ]">
                          <small className="[ footnotes form-text text-muted ]">
                            {STRINGS.LOAN_DETAILS.FORM.MORTGAGE_FINE_PRINT3}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* REPAYMENT SCHEDULE  & DATE - THIS IS HIDDEN*/}
              {!true && (
                <div className="[ mb-3 ]">
                  <hr className="[ mb-5 ]" />
                  <div className="[ row ]">
                    {/* REPAYMENT SCHEDULE*/}
                    <div className="[ col-sm-6 ] [ mb-3 ]">
                      <div className="[ form-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM
                            .PRODUCT_LABEL_REPAYMENT_SCHEDULE
                        }
                      </div>
                      <select
                        name="paymentType"
                        value={paymentType}
                        onChange={(e) =>
                          this.setPaymentTypeValue(e.target.value)
                        }
                        className="[ form-control ] [ select__dropdown ]"
                      >
                        {loanPaymentTypeOptions}
                      </select>
                    </div>
                    {/* REPAYMENT DATE*/}
                    <div className="[ col-sm-6 ] [ mb-3 ]">
                      <div className="[ form-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM
                            .PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE
                        }
                      </div>
                      <DatePicker
                        selected={loanScheduleDate}
                        placeholderText={`${STRINGS.DOBPLACEHOLDER}`}
                        onChange={this.handleChange("loanScheduleDate")}
                        // startDate={startDate}
                        // endDate={endDate}
                        selectsRange
                        inline
                      />

                      <small className="form-text text-muted">
                        {
                          STRINGS.LOAN_DETAILS.FORM
                            .PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE_HINT
                        }
                      </small>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {/* END PERSONAL LOAN */}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

LoanDetails.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  loanProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.array]),
  loanProductForm: PropTypes.oneOfType(PropTypes.object),
};

LoanDetails.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  loanProducts: [],
  loanProductForm: {},
};
export default LoanDetails;
