import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";
import AppConstants from "Constants/AppConstants";
import {
  getProductFromVault,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import Income from "./Income";
import {
  addLoanIncome,
  getLoanIncome,
  updateLoanIncome,
} from "./Actions/IncomeActions";
import {
  deleteLoanFinancial,
  getFinancialAnalysisFinancialList,
  getFinancialList,
  getLoanProduct,
} from "../LoanDetails/Actions/LoanDetailsAction";
import { showIncomePage } from "../../Utils/LogicUtilities";

class IncomeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: false,
      financialType: "Income",
      oldIncomeList: null,
      incomeList: [],
      success: true,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      actionBack,
      handleBack,
      config,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);

    if (showIncomePage(activeProduct, config)) {
      this.initializeLoanDetail();
    } else {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.INCOME);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.INCOME);
      }
    }
  }

  handleChange = (choice) => {
    console.log(choice);
    const { incomeList } = this.state;
    this.setState({ choice: choice });
    if (choice) {
      incomeList.push({
        incomeType: "",
        description: "",
        monthlyAmount: "",
        adjustment: "",
      });
      this.setState({
        incomeList,
      });
    } else {
      this.setState({
        incomeList: [],
      });
    }
  };

  handleContinue = (incomeList) => {
    console.log("handleContinue", incomeList);
    const { financialType } = this.state;
    const {
      continuehandler,
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
    } = this.props;
    const activeProduct = getActiveProduct(products);

    doGetFinancialAnalysisFinancialList(
      {
        applicantId: activeProduct.applicantId,
        finAnalysisId: activeProduct.finAnalysisId,
      },
      (res) => {
        const currentIncomeList = [];
        res.forEach((financial, idx) => {
          if (financial.type === financialType && idx !== 0) {
            currentIncomeList.push({
              incomeType: financial.subType,
              description: financial.description,
              monthlyAmount: financial.monthly,
              financialId: financial.financialId,
            });
          }
        });
        this.processIncome(currentIncomeList, incomeList, activeProduct);
      }
    );
  };

  processIncome(currentIncomeList, newIncomeList, activeProduct) {
    console.log("processIncome", currentIncomeList, newIncomeList);
    const { continuehandler, doGetApplicationProperty } = this.props;
    let updateCtr = 0;

    newIncomeList.forEach((income, idx) => {
      if (income.financialId) {
        const currentIncome = currentIncomeList.find(
          (item) => item.financialId === income.financialId
        );
        this.updateIncome(activeProduct, currentIncome, income);
        updateCtr += 1;
      } else {
        this.addIncome(activeProduct, income);
        updateCtr += 1;
      }
    });

    currentIncomeList.forEach((income, idx) => {
      const curr = newIncomeList.find(
        (item) => item.financialId === income.financialId
      );
      if (!curr) {
        this.deleteIncome(activeProduct, income);
        updateCtr += 1;
      }
    });

    console.log("waiting before continuing", updateCtr);
    doGetApplicationProperty(
      {
        applicationId: activeProduct.applicationId,
        delayTime: updateCtr * 1000,
      },
      () => {
        continuehandler("", AppConstants.APPLICATIONSTEP.INCOME);
      }
    );
  }

  removeInvalidChars(value) {
    if (value && typeof value !== "number") {
      return value.split("$").join("").split(",").join("");
    }
    return value;
  }

  addIncome(activeProduct, income) {
    console.log("addIncome", income);
    const { doAddLoanIncome } = this.props;
    doAddLoanIncome(
      activeProduct.applicantId,
      activeProduct.finAnalysisId,
      {
        SubType: income.incomeType,
        Description: income.description,
        MonthlyAmount: this.removeInvalidChars(income.monthlyAmount),
      },
      (res) => {
        console.log(res);
      }
    );
  }

  updateIncome(activeProduct, oldIncome, newIncome) {
    console.log("updateIncome", oldIncome, newIncome);
    const { doUpdateLoanIncome } = this.props;
    if (
      oldIncome.incomeType !== newIncome.incomeType ||
      oldIncome.description !== newIncome.description ||
      oldIncome.monthlyAmount !== newIncome.monthlyAmount
    ) {
      doUpdateLoanIncome(
        activeProduct.applicantId,
        activeProduct.finAnalysisId,
        newIncome.financialId,
        {
          SubType: newIncome.incomeType,
          Description: newIncome.description,
          MonthlyAmount: this.removeInvalidChars(newIncome.monthlyAmount),
        },
        (res) => {
          console.log(res);
        }
      );
    }
  }

  parseAmount(str) {
    if (isNaN(str)) {
      const matches = str.match(/(\d+)/);
      if (matches) {
        return matches[0];
      } else {
        return 0;
      }
    } else {
      return str;
    }
  }

  deleteIncome = (activeProduct, income) => {
    console.log("deleteIncome", income);
    const { doDeleteLoanFinancial } = this.props;

    if (income) {
      doDeleteLoanFinancial(
        activeProduct.applicantId,
        activeProduct.finAnalysisId,
        income.financialId,
        (res) => {
          console.log(res);
        }
      );
    }
  };

  initializeLoanDetail() {
    const {
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
    } = this.props;
    const { financialType } = this.state;

    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    doGetFinancialAnalysisFinancialList(
      {
        applicantId: activeProduct.applicantId,
        finAnalysisId: activeProduct.finAnalysisId,
      },
      (res) => {
        const incomeList = [];
        res.forEach((financial, idx) => {
          if (financial.type === financialType && idx !== 0) {
            incomeList.push({
              incomeType: financial.subType,
              description: financial.description,
              monthlyAmount: financial.monthly,
              adjustment: financial.adjustment,
              financialId: financial.financialId,
              applicantId: activeProduct.applicantId,
              finAnalysisId: activeProduct.finAnalysisId,
            });
          }
        });
        console.log(incomeList);
        this.setState({
          incomeList: incomeList,
          choice: incomeList && incomeList.length > 0,
        });
      }
    );
  }

  render() {
    const { choice, incomeList } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      incomeTypes,
    } = this.props;

    return (
      <>
        <Income
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          deleteIncome={this.deleteIncome}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          incomeList={incomeList}
          incomeTypes={incomeTypes}
        />
      </>
    );
  }
}

IncomeContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  actionBack: PropTypes.bool,
};

IncomeContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  loanProductForm: state.LoanDetailsReducer.loanProductForm,
  finAnalysisId: state.LoanDetailsReducer.finAnalysisId,
  financialAnalysisFinancialList:
    state.LoanDetailsReducer.financialAnalysisFinancialList.data,
  incomeTypes: state.LoanDetailsReducer.incomeTypes,
  actionBack: state.ApplicationReducer.actionBack,
  config: state.LoanDetailsReducer.config,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doAddLoanIncome: (applicantId, finAnalysisId, payLoad, callback) =>
      dispatch(addLoanIncome(applicantId, finAnalysisId, payLoad, callback)),
    doUpdateLoanIncome: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLoanIncome(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
    doGetLoanProduct: (callback) => dispatch(getLoanProduct(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetLoanIncome: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        getLoanIncome(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    doGetFinancialList: (reqData, callback) =>
      dispatch(getFinancialList(reqData, callback)),
    doDeleteLoanFinancial: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        deleteLoanFinancial(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
  };
};

const IncomeWrapper = withApplicantHOC(IncomeContainer);

export default connect(mapStateToProps, mapDispatchToProps)(IncomeWrapper);
