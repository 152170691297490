import * as actionTypes from "Redux/Actions/actionTypes";

export const addLoanIncome = (
  applicantId,
  finAnalysisId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_ADD_INCOME,
  data: {
    applicantId,
    finAnalysisId,
    payLoad,
  },
  callback,
});

export const updateLoanIncome = (
  applicantId,
  finAnalysisId,
  financialId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_INCOME,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
    payLoad,
  },
  callback,
});

export const getLoanIncome = (
  applicantId,
  finAnalysisId,
  financialId,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_GET_INCOME,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
  },
  callback,
});
