/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
class BaseApi {
  callApi(method, url, requestBody, accessToken) {
    throw new Error("callApi: method not implemented");
  }
}
export default BaseApi;

let api = null;

export function getApiImplementation() {
  return api;
}

export function setApiImplementation(apiObj) {
  if (!(apiObj instanceof BaseApi)) {
    throw new Error("setApi: invalid BaseApi instance");
  }
  api = apiObj;
}
