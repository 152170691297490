export const getLoanRate = (lendingProduct) => {
  const loanRate =
    lendingProduct.lpTermsDetails.baseRate +
    lendingProduct.lpTermsDetails.incrementRate +
    lendingProduct.lpTermsDetails.discretionRate;
  return loanRate;
};

export const getFutureDate = (numberOfDays) => {
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + numberOfDays);
  return endDate;
};
