import * as actionTypes from "Redux/Actions/actionTypes";

export const doConsent = (userdata, callback = () => {}) => ({
  type: actionTypes.CONSENT_REQUEST,
  data: userdata,
  callback,
});

export const fetchJsonData = (callback = () => {}) => ({
  type: actionTypes.TERMS_JSON_REQUEST,
  callback,
});
