import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import STRINGS from "Constants/Strings";
import * as ROUTES from "Constants/Routes";
import { HTTP_STATUS, S_APPLICANT_ID } from "Communication/Constants";

import { doGetApplicantList } from "Redux/Actions/GetApplicantListAction";
import getAccount from "Redux/Actions/GetAccountAction";

import { callFetchInverite } from "Components/Inverite/Actions/InveriteAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { doConsent } from "../TermsAndConditions/Actions/TermsActions";
import { addApplicant, findApplicant } from "./Actions/InveriteAction";
import { setInSessionJointApplicant } from "../JointApplicant/Actions/JointApplicantAction";

class Inverite extends Component {
  componentDidMount() {
    const {
      history,
      inSessionJointApplicant,
      doSetInSessionJointApplicant,
    } = this.props;
    if (inSessionJointApplicant && process.env.REACT_APP_TEST_IN_SESSION) {
      const inSessionData = {
        ...inSessionJointApplicant,
        applicantId: process.env.REACT_APP_TEST_IN_SESSION,
      };
      doSetInSessionJointApplicant(inSessionData);
      history.push(ROUTES.ABOUT_YOU);
    } else {
      if (window.addEventListener) {
        window.addEventListener("message", this.listener, false);
      } else {
        window.attachEvent("onmessage", this.listener);
      }
    }
  }

  listener = (event) => {
    const {
      doGetApplicantListAction,
      history,
      doConsents,
      inSessionJointApplicant,
      doAddApplicant,
      requestGuid,
      doSetInSessionJointApplicant,
      doFindApplicant,
      processInverite,
    } = this.props;
    if (
      event.origin === "https://sandbox.inverite.com" ||
      event.origin === "https://live.inverite.com" ||
      event.origin === "https://i1.inverite.com" ||
      event.origin === "https://www.inverite.com"
    ) {
      console.log(event);
      if (event.data === "success") {
        // perform success operations here
        const { doFetchInverite } = this.props;

        if (inSessionJointApplicant) {
          // we dont have to search for the joint applicant, just add it
          // doFindApplicant({ inveriteRequestGuid: requestGuid }, (res) => {
          //   console.log(res);
          //   if (res.data.applicantId) {
          //     const inSessionData = {
          //       ...inSessionJointApplicant,
          //       applicantId: res.data.applicantId,
          //     };
          //     doSetInSessionJointApplicant(inSessionData);
          //     history.push(ROUTES.ABOUT_YOU);
          //   } else {
          doAddApplicant(
            {
              inveriteRequestGuid: requestGuid,
              // relationship: "joint",
            },
            (response) => {
              console.log(response);
              const inSessionData = {
                ...inSessionJointApplicant,
                applicantId: response.data.applicantId,
              };
              doSetInSessionJointApplicant(inSessionData);
              history.push(ROUTES.ABOUT_YOU);
            }
          );
          // }
          // });
        } else {
          doFetchInverite((response) => {
            if (response.status === HTTP_STATUS.OK) {
              // call applicant list api
              if (processInverite) {
                processInverite(response);
              } else {
                doGetApplicantListAction((res) => {
                  if (res.status === HTTP_STATUS.OK) {
                    if (res.data.length === 0) {
                      history.push(ROUTES.EXISTING_MEMBER);
                    } else {
                      // get first applicantID from the list
                      const getFirstApplicantID = res.data[0].id;
                      sessionStorage.setItem(
                        S_APPLICANT_ID,
                        getFirstApplicantID
                      );
                      doConsents({ creditCheck: "yes" }, (consentRes) => {
                        this.getAccountDetails();
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    }
  };

  getAccountDetails = () => {
    // call getaccount api to check terms and condition accepted or not
    const { doGetAccountAction, history } = this.props;
    doGetAccountAction((response) => {
      history.push(ROUTES.ABOUT_YOU);
    });
  };

  render() {
    const {
      iframeUrl,
      iframeModal,
      showModal,
      popupBtnClick,
      toggleModal,
    } = this.props;

    return (
      <PopupModal
        title={STRINGS.INVERITE_IFRAME_MODAL.TITLE}
        footer={STRINGS.INVERITE_IFRAME_MODAL.FOOTER}
        iframeUrl={iframeUrl}
        iframeModal={iframeModal}
        toggleModal={toggleModal}
        showModal={showModal}
        popupBtnClick={popupBtnClick}
      />
    );
  }
}

Inverite.propTypes = {
  iframeUrl: PropTypes.string,
  iframeModal: PropTypes.bool,
  showModal: PropTypes.bool,
  popupBtnClick: PropTypes.func,
  doFetchInverite: PropTypes.func.isRequired,
  doGetAccountAction: PropTypes.func,
  doGetApplicantListAction: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  toggleModal: PropTypes.func,
};
Inverite.defaultProps = {
  showModal: false,
  iframeUrl: PropTypes.string,
  iframeModal: false,
  popupBtnClick: () => {},
  doGetAccountAction: () => {},
  doGetApplicantListAction: () => {},
  history: {},
  toggleModal: () => {},
};

const mapStateToProps = (state) => ({
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  requestGuid: state.ExistingUserReducer.requestGuid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchInverite: (callback) => dispatch(callFetchInverite(callback)),
    doGetApplicantListAction: (callback) =>
      dispatch(doGetApplicantList(callback)),
    doGetAccountAction: (callback) => dispatch(getAccount(callback)),
    doConsents: (consentDetails, callback) =>
      dispatch(doConsent(consentDetails, callback)),
    doAddApplicant: (payLoad, callback) =>
      dispatch(addApplicant(payLoad, callback)),
    doFindApplicant: (payLoad, callback) =>
      dispatch(findApplicant(payLoad, callback)),
    doSetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Inverite)
);
