import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import RequestEscRequest from "../../Communication/ApiClasses/RequestEscRequest";

// worker
function* handleAction(action) {
  yield put(doLoadingStart(actionTypes.ESC_REQUEST));

  const requestEscRequest = new RequestEscRequest(action.data);
  try {
    const result = yield call(requestEscRequest.getData);
    yield put({
      type: `${actionTypes.ESC_REQUEST}_SUCCESS`,
      response: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.ESC_REQUEST}_ERROR`,
      error,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.ESC_REQUEST));
  }
}

// watcher
export default function* handleSaga() {
  yield takeLatest(actionTypes.ESC_REQUEST, handleAction);
}
