import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import IncomeSourcesRequest from "Communication/ApiClasses/IncomeSourcesRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleIncomeSource() {
  yield put(
    doLoadingStart(STRINGS.EMPLOYMENT_AND_INCOME.API_KEYS.INCOME_SOURCE_LIST)
  );
  const incomeSourceApi = new IncomeSourcesRequest();
  try {
    const result = yield call(incomeSourceApi.getData);
    yield put({
      type: actionTypes.ADD_INCOME_SOURCES_LIST,
      incomeSourcesList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.INCOME_SOURCES_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.EMPLOYMENT_AND_INCOME.API_KEYS.INCOME_SOURCE_LIST)
    );
  }
}

// watcher
export default function* incomeSourceWatcherSaga() {
  yield takeLatest(actionTypes.GET_INCOME_SOURCES_LIST, handleIncomeSource);
}
