import React, { Component } from "react";
// import bootstrap components
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
// import constants
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import * as ROUTES from "Constants/Routes";
// import common components
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { PasswordInput } from "Components/Common/PasswordInput/PasswordInput";
import AuthBody from "Components/Common/AuthBody/AuthBody";

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "", // first user entered password
      confirmPasswordValue: "", // confirm password user entered value
      passwordChecksResult: {}, // object for storing run-time validations checks
      confirmPasswordError: false, // error message if passwords does not match
      isFormValid: false,
      passwordCriteriaMatch: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   *
   * @param {object} event represents
   * browser event on password text input.
   * validateInput checks for the current password with
   * regex rules fetched from server
   */

  validateInput = (event) => {
    const password = event.target.value;
    const { confirmPasswordValue } = this.state;
    if (password !== confirmPasswordValue) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordValue,
        isFormValid: false,
      });
    } else {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordValue,
        isFormValid: true,
      });
    }
    const { value } = event.target;
    const { passwdCriteria } = this.props;
    const data = value;
    this.setState({
      password: data,
    });

    /**
     * passwordValidationRegex represents
     * object with regex and code values
     */

    const passwordValidationRegex = passwdCriteria.map(({ regex, code }) => ({
      regex,
      code,
    }));
    passwordValidationRegex.map((rx) => {
      const isValid = new RegExp(rx.regex).test(data);
      const { passwordChecksResult } = this.state;
      passwordChecksResult[rx.code] = isValid;
      return this.setState(
        {
          passwordChecksResult,
        },
        () => {
          const allTrue = Object.keys(passwordChecksResult).every((k) => {
            return passwordChecksResult[k];
          });
          if (allTrue) {
            this.setState({ passwordCriteriaMatch: true });
          } else {
            this.setState({ passwordCriteriaMatch: false });
          }
        }
      );
    });
  };
  /**
   *
   * @param {object} event represents
   * browser event on confirm password
   * text input
   */

  handleConfirmPassword = (event) => {
    const confirmPasswordValue = event.target.value;
    const { password } = this.state;
    if (password !== confirmPasswordValue) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordValue,
        isFormValid: false,
      });
    } else {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordValue,
        isFormValid: true,
      });
    }
  };
  /**
   * handlePopupModalBtnClick redirects user
   * to the defined route on PopupModal
   * button click
   */

  handlePopupModalBtnClick = () => {
    const { prepareVaultProductList, nextComponent, doLogout } = this.props;
    if (nextComponent === ROUTES.SIGN_IN) {
      doLogout();
    } else {
      prepareVaultProductList(false);
    }
  };

  render() {
    const {
      showModal,
      type,
      modal,
      toggleModal,
      passwdCriteria,
      showVaultClicked,
      showVault,
      handleSetPasswordRequest,
      handleBack,
    } = this.props;
    const {
      password,
      confirmPasswordError,
      passwordChecksResult,
      confirmPasswordValue,
      isFormValid,
      passwordCriteriaMatch,
    } = this.state;
    const actionComponent = (
      // set password block start

      <Form
        onSubmit={(event) => {
          event.preventDefault();
          handleSetPasswordRequest(password);
        }}
        className="[ set-password-container ]"
      >
        <div className="[ form-title-password ]">
          {STRINGS.SETPASSWORD.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.SETPASSWORD.FORM.NEWPASSWORDLABEL}
            </div>

            <PasswordInput
              id="Password-1"
              changeHandler={this.validateInput}
              password={password}
            />

            <div
              className={`drawer-content-container collapsing-container ${
                passwordCriteriaMatch ? "collapsed" : ""
              }`}
            >
              <div className="[ drawer-content-container__title ]">
                {STRINGS.SETPASSWORD.FORM.PASSWORDCRITERIA}
              </div>

              <div className="[ validations-rules-wrapper ]">
                {passwdCriteria &&
                  typeof passwdCriteria !== "undefined" &&
                  passwdCriteria.map((record) => {
                    return (
                      <div
                        key={record.code}
                        className={
                          record.code &&
                          passwordChecksResult[record.code] &&
                          passwordChecksResult[record.code] === true
                            ? "[ icon check-icon ] [ set-password-validations-item ]"
                            : "[ icon invalid-icon ] [ set-password-validations-item ]"
                        }
                      >
                        {record.message}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.SETPASSWORD.FORM.RETYPEPASSWORDLABEL}
            </div>

            <PasswordInput
              id="Password-2"
              changeHandler={this.handleConfirmPassword}
              password={confirmPasswordValue}
            />
            <div className="[ error-msg ]">
              {confirmPasswordError
                ? STRINGS.SETPASSWORD.ERROR.CONFIRM_PASSWORD_ERROR
                : null}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                className="[ btn  submit-btn ] [ w-100 ]"
                type="submit"
                disabled={!(isFormValid && passwordCriteriaMatch)}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </Form>
    );
    const infoComponent = (
      <div className="[ set-password-secondary-container ]">
        <div className="[ info-title ]">
          {STRINGS.SETPASSWORD.INFOBLOCK.TITLE}
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.SETPASSWORD.INFOBLOCK.DESCRIPTION}
        </div>
      </div>
    );
    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? this.handlePopupModalBtnClick
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? this.handlePopupModalBtnClick
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionComponent}
          infoComponent={infoComponent}
          showVault={showVault}
          showVaultClicked={() => showVaultClicked}
          handleBack={handleBack}
        />
      </>
    );
  }
}

SetPassword.propTypes = {
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  nextComponent: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  passwdCriteria: PropTypes.arrayOf(PropTypes.any),
  handleSetPasswordRequest: PropTypes.func.isRequired,
  showVaultClicked: PropTypes.func,
  showVault: PropTypes.bool,
  prepareVaultProductList: PropTypes.func,
  doLogout: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};
SetPassword.defaultProps = {
  passwdCriteria: [],
  showVaultClicked: undefined,
  showVault: undefined,
  prepareVaultProductList: () => {},
};

export default SetPassword;
