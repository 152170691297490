import * as actionTypes from "Redux/Actions/actionTypes";

export const addBundleProduct = (
  applicationId,
  bundleProductId,
  demandProductId,
  applicantId,
  callback = () => {}
) => ({
  type: actionTypes.ADD_BUNDLE_PRODUCT,
  data: {
    applicationId,
    bundleProductId,
    demandProductId,
    applicantId,
  },
  callback,
});

export const deleteDebitCard = (
  applicationId,
  debitProductId,
  callback = () => {}
) => ({
  type: actionTypes.DELETE_DEBIT_CARD,
  data: {
    applicationId,
    debitProductId,
  },
  callback,
});

export const storeDebitCard = (enableDebitCard, applicationId) => ({
  type: actionTypes.STORE_DEBIT_CARD,
  data: {
    enableDebitCard,
    applicationId,
  },
});

export const debitCardShown = (flagDebitCardShown) => ({
  type: actionTypes.DEBIT_CARD_SHOWN,
  data: flagDebitCardShown,
});
