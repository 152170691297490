import { call, put, takeLatest } from "redux-saga/effects";
import RefreshTokenRequest from "Communication/ApiClasses/RefreshTokenRequest";
import {
  REFRESH_TOKEN_TIME,
  TOKEN_KEY,
  REFRESH_TIMER_ID,
} from "Communication/Constants";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleRefreshToken(action) {
  try {
    const strTimestamp = `${action.timeout.toString()}000`;
    const timestampNow = Number(strTimestamp);
    const RefreshTokenApi = new RefreshTokenRequest(
      sessionStorage.getItem(TOKEN_KEY)
    );
    console.log(
      "refresh",
      timestampNow - new Date().getTime() - REFRESH_TOKEN_TIME
    );
    const timerID = yield call(
      setInterval,
      () => {
        RefreshTokenApi.getData().then((res) => {
          sessionStorage.setItem(TOKEN_KEY, res.data.token);
        });
      },
      timestampNow - new Date().getTime() - REFRESH_TOKEN_TIME
    );
    sessionStorage.setItem(REFRESH_TIMER_ID, timerID);
  } catch (error) {
    yield put({
      type: actionTypes.SIGNIN_ERROR,
      error,
    });
  }
}

// watcher
export default function* refreshTokenSaga() {
  yield takeLatest(actionTypes.GET_ACCESS_TOKEN, handleRefreshToken);
}
