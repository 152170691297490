import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "../../Constants/ValidationList";
import getErrorMsg from "../../Constants/ErrorMessages";

class SecurityVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yearList: [],
      vehicle: { init: true, year: "", make: "", model: "", serial: "" },
      yearError: [],
      makeError: [],
      modelError: [],
      vinError: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE,
      (response) => {
        this.setFormData(response);
      }
    );

    const currentYear = new Date().getFullYear();
    let yearList = [];
    let i = 0;
    while (i < 7) {
      yearList.push(currentYear - i);
      i++;
    }
    this.setState({
      yearList,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { vehicle } = this.props;
    if (prevProps.vehicle !== vehicle) {
      this.setState({
        vehicle,
      });
    }
  }

  setFormData = () => {};

  updateVehicle = (prop, value) => {
    let { vehicle } = this.state;

    let cleanValue = value;
    if (prop === "serial") {
      cleanValue = value ? value.replace(/[^0-9a-zA-Z]+/gi, "") : "";
    }

    vehicle[prop] = cleanValue;
    this.setState({
      vehicle,
    });
    if (prop === "year") {
      this.setState({ yearError: [] });
    }
    if (prop === "make") {
      this.setState({ makeError: [] });
    }
    if (prop === "model") {
      this.setState({ modelError: [] });
    }
    if (prop === "serial") {
      this.setState({ vinError: [] });
    }
  };

  validate = () => {
    const { vehicle } = this.state;
    let valid = true;

    this.setState({
      yearError: [],
      makeError: [],
      modelError: [],
      vinError: [],
    });

    let error = checkErrors(vehicle.year, VALIDATIONS.LOANS.VEHICLE.YEAR);
    if (error.length) {
      valid = false;
      this.setState({ yearError: error });
    }

    error = checkErrors(vehicle.make, VALIDATIONS.LOANS.VEHICLE.MAKE);
    console.log(error);
    if (error.length) {
      valid = false;
      this.setState({ makeError: error });
    }

    error = checkErrors(vehicle.model, VALIDATIONS.LOANS.VEHICLE.MODEL);
    if (error.length) {
      valid = false;
      this.setState({ modelError: error });
    }

    error = checkErrors(vehicle.serial, VALIDATIONS.LOANS.VEHICLE.VIN);
    if (error.length) {
      valid = false;
      this.setState({ vinError: error });
    }

    return valid;
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { vehicle } = this.state;
    console.log("handleContinue", vehicle);
    let valid = this.validate();

    if (valid) {
      handleContinue(vehicle);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
  };

  render() {
    const {
      yearList,
      vehicle,
      yearError,
      makeError,
      modelError,
      vinError,
    } = this.state;
    const { steps, activeStepID } = this.props;
    const yearComponent = yearList.map((value) => (
      <option value={value} className="[ dropdown__option ]">
        {value}
      </option>
    ));
    const actionComponent = (
      <div className="[ vehicle-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.SECURITY_VEHICLE.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-3 ]">
                {/* YEAR */}
                <div className="[ form-label ]">
                  {STRINGS.SECURITY_VEHICLE.FORM.YEAR_LABEL}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id=""
                  name=""
                  value={vehicle.year}
                  onChange={(e) => this.updateVehicle("year", e.target.value)}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.YEAR_LABEL}
                  </option>
                  {yearComponent}
                </select>
                <div
                  className={
                    yearError.length > 0 ? "[ error-msg ]" : "[ error-msg ]"
                  }
                >
                  {yearError ? yearError[0] : ""}
                </div>
              </div>

              <div className="[ col-lg-3 ]">
                {/* MAKE */}
                <div className="[ form-label ]">
                  {STRINGS.SECURITY_VEHICLE.FORM.MAKE_LABEL}
                </div>
                <input
                  type="text "
                  name="make"
                  value={vehicle.make}
                  className="[ form-control ]"
                  placeholder={STRINGS.SECURITY_VEHICLE.FORM.MAKE_DESCRIPTION}
                  onChange={(e) => this.updateVehicle("make", e.target.value)}
                />
                <div
                  className={
                    makeError.length > 0 ? "[ error-msg ]" : "[ error-msg ]"
                  }
                >
                  {makeError ? makeError[0] : ""}
                </div>
              </div>

              <div className="[ col-lg-3 ]">
                {/* MODEL */}
                <div className="[ form-label ]">
                  {STRINGS.SECURITY_VEHICLE.FORM.MODEL_LABEL}
                </div>
                <input
                  type="text "
                  name="make"
                  value={vehicle.model}
                  className="[ form-control ]"
                  placeholder={STRINGS.SECURITY_VEHICLE.FORM.MODEL_DESCRIPTION}
                  onChange={(e) => this.updateVehicle("model", e.target.value)}
                />
                <div
                  className={
                    modelError.length > 0 ? "[ error-msg ]" : "[ error-msg ]"
                  }
                >
                  {modelError ? modelError[0] : ""}
                </div>
              </div>
              <div className="[ col-lg-3 ]">
                {/* CHRIS NOTE: TODO - add a 'more info' button that opens a pop-up to tell user to look at the inside of their Driver side door for VIN label */}
                <div className="[ form-label ]">
                  {STRINGS.SECURITY_VEHICLE.FORM.VIN_LABEL}
                </div>
                <input
                  type="text "
                  name="serial"
                  value={vehicle.serial}
                  maxLength={17}
                  className="[ form-control ]"
                  placeholder={STRINGS.SECURITY_VEHICLE.FORM.VIN_LABEL}
                  onChange={(e) => this.updateVehicle("serial", e.target.value)}
                />
                <div className="[ error-msg ] ">
                  {vinError ? vinError[0] : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

SecurityVehicle.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SecurityVehicle.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default SecurityVehicle;
