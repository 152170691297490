import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import { PRODUCT_IMAGE_URL, COMMON_BUCKET_URL } from "Communication/ApiUrls";
import { LENDING_PRODUCT } from "../../Communication/Constants";

class GettingStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      loanDetails: {},
      loanTerms: [],
      loanPurposes: [],
      loanRepayments: [],
      isOtherPerson: "",
      requirePurpose: false,
      selectedPurpose: null,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.LOAN_PURPOSE, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    const { loanProducts, products } = this.props;

    if (loanProducts !== prevProps.loanProducts) {
      let productId =
        products && products.length !== 0 ? products[0].productId : "";
      this.renderSelections(productId);
    }
  }

  setFormData = () => {
    const { getDataFromServer, loanPurposeCode } = this.props;
    if (loanPurposeCode) {
      this.setState({
        selectedPurpose: loanPurposeCode,
      });
    }
  };

  handleChange = (prop) => (event) => {
    console.log("handleChange", prop, event);
    const { loanDetails } = this.state;
    loanDetails[prop] = event.currentTarget.value;
    if (prop === "isOtherPerson") {
      this.setState({ isOtherPerson: event.currentTarget.value });
    } else {
      this.setState({ loanDetails: loanDetails });
    }

    if (prop === LENDING_PRODUCT) {
      this.renderSelections(event.currentTarget.value);
    }
  };

  handleSelectPurpose = (event) => {
    console.log(event.currentTarget.textContent);
    const { handleSelectPurpose } = this.props;
    const purposeId = event.currentTarget.value;
    if (purposeId) {
      this.setState({
        selectedPurpose: purposeId,
        requirePurpose: false,
      });
      handleSelectPurpose(purposeId, event.currentTarget.textContent);
    }
  };

  handleContinue = () => {
    const { selectedPurpose } = this.state;
    if (selectedPurpose) {
      const { handleContinue } = this.props;
      handleContinue();
    } else {
      this.setState({
        requirePurpose: true,
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_PURPOSE);
  };

  handleOtherPerson = (choice) => {
    this.setState({ isOtherPerson: choice });
  };

  renderSelections(productId) {
    const { loanProducts, getActiveProduct } = this.props;
    if (Array.isArray(loanProducts)) {
      loanProducts.forEach((raw, idx) => {
        if (raw.id === productId) {
          console.log(raw);
          this.setState({
            loanPurposes: raw.purpose,
          });
        }
      });
    }
  }

  render() {
    console.log("rendering");
    const { choice, loanDetails, isOtherPerson, requirePurpose } = this.state;
    const {
      steps,
      activeStepID,
      loanProducts,
      products,
      loanProductForm,
      loanPurposes,
      loanPurposeCode,
    } = this.props;
    /*console.log(
      steps,
      activeStepID,
      loanProducts,
      loanDetails,
      loanPurposes,
      isOtherPerson,
      products,
      loanProductForm
    );*/
    console.log(loanPurposes, loanPurposeCode);
    const loanProductOptions =
      loanProducts &&
      loanProducts.length !== 0 &&
      Array.isArray(loanProducts) &&
      loanProducts.map((loan) => (
        <option value={loan.name} className="[ dropdown__option ]">
          {loan.description}
        </option>
      ));

    const loanPurposesOptions =
      loanPurposes &&
      loanPurposes.length !== 0 &&
      loanPurposes.map((purpose) => (
        <option value={purpose.purposeId} className="[ dropdown__option ]">
          {purpose.description}
        </option>
      ));

    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">Getting Started</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-subtitle ]">
              {STRINGS.LOAN_PURPOSE.FORM.PRODUCT_LABEL_PURPOSE}
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-12 ]">
                <div className="[ product-list ] ">
                  {loanPurposes &&
                    loanPurposes.length !== 0 &&
                    loanPurposes.map((purpose, idx) => (
                      <>
                        <button
                          type="button"
                          disabled={
                            purpose.purposeId === loanPurposeCode ? true : false
                          }
                          className="[ btn custom-radio-unselected ]"
                          value={purpose.purposeId}
                          onClick={(event) => this.handleSelectPurpose(event)}
                        >
                          {purpose.description}
                        </button>
                      </>
                    ))}
                </div>
                {requirePurpose && (
                  <div className="[ error-msg ]">
                    Please select purpose of loan.
                  </div>
                )}
              </div>
            </div>
            {loanPurposeCode === "90" && (
              <div className="[ drawer-content-container ]">
                <div className="[ form-label ]">
                  {STRINGS.LOAN_PURPOSE.FORM.PURPOSE_LABEL_INPUT_OPTION}
                </div>
                <input
                  type="text"
                  id=""
                  name=""
                  className="form-control"
                  value=""
                />
              </div>
            )}
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

GettingStarted.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  loanProducts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loanProductForm: PropTypes.oneOfType(PropTypes.object),
  loanPurposeCode: PropTypes.oneOfType(PropTypes.object),
  loanPurposes: PropTypes.arrayOf(PropTypes.object),
};

GettingStarted.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  loanProducts: {},
  loanProductForm: {},
  loanPurposeCode: "",
};
export default GettingStarted;
