import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";
import AppConstants from "Constants/AppConstants";
import {
  getProductFromVault,
  updateVaultProductList,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import LoanDetails from "./LoanDetails";
import {
  getFinancialList,
  getLoanProduct,
  updateLendingProduct,
  updateLoanProductForm,
} from "./Actions/LoanDetailsAction";
import LoanAuto from "./LoanAuto";
import LoanHeloc from "./LoanHeloc";
import LoanLoc from "./LoanLoc";
import LoanMortgage from "./LoanMortgage";
import * as ROUTES from "../../Constants/Routes";
import { add, format } from "date-fns";
import STRINGS from "../../Constants/Strings";

class LoanDetailsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
    };
  }

  componentDidMount() {
    const { doGetLoanProduct } = this.props;
    doGetLoanProduct();
    this.initializeLoanDetail();
  }

  componentDidUpdate(prevProps) {}

  handleChange = () => {};

  handleContinue = (loanProductForm) => {
    console.log("handleContinue", loanProductForm);
    const { continuehandler } = this.props;
    this.updateFormToProductVault(loanProductForm);
  };

  updateLoanForm = (loanProductForm) => {
    console.log(loanProductForm);
    const { updateLoanProductForm } = this.props;
    updateLoanProductForm(loanProductForm);
    console.log(this.props.loanProductForm);
  };

  updateJointInfo(activeProduct) {
    console.log("updateJointInfo", activeProduct);
    const { doGetGlobalVault, doUpdateGlobalVault, slots } = this.props;
    if (slots.length > 1) {
      for (const slot of slots) {
        if (slot.extra) {
          const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${slot.extra.msisdn}`;
          doGetGlobalVault({ vaultKey: vKey }, (res) => {
            doUpdateGlobalVault(
              {
                vaultKey: vKey,
                body: {
                  ...res,
                  vaultProducts: [
                    {
                      ...res.vaultProducts[0],
                      loanDetails: activeProduct.loanDetails,
                    },
                  ],
                },
              },
              (gResponse) => {
                console.log(gResponse);
              }
            );
          });
        }
      }
    }
  }

  updateFormToProductVault(loanProductForm) {
    console.log("updateFormToProductVault", loanProductForm);
    const {
      products,
      getActiveProduct,
      doUpdateProductToVault,
      doUpdateGlobalVault,
      slots,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    let loanDetails = activeProduct.loanDetails;
    console.log(loanDetails);
    if (loanDetails) {
      loanDetails["estimatedMortgage"] = loanProductForm.estimatedMortgage;
      loanDetails["loanAmount"] = loanProductForm.loanAmount;
      loanDetails["vehiclePrice"] = loanProductForm.vehiclePrice;
      loanDetails["loanRate"] = loanProductForm.loanRate;
      loanDetails["loanTerm"] = loanProductForm.loanTerm;
      loanDetails["monthlyPayment"] = loanProductForm.monthlyPayment;
      loanDetails["loanScheduleDate"] = loanProductForm.loanScheduleDate;
      loanDetails["paymentType"] = loanProductForm.paymentType;
      loanDetails["downPayment"] = loanProductForm.downPayment;
      loanDetails["downPaymentPercent"] = loanProductForm.downPaymentPercent;
      loanDetails["amortization"] = loanProductForm.amortization;
      loanDetails["assessedValue"] = loanProductForm.assessedValue;
      loanDetails["mortgageBalance"] = loanProductForm.mortgageBalance;
      this.updateDoxim(activeProduct, loanDetails);
      products[0]["loanDetails"] = loanDetails;
      this.updateJointInfo(products[0]);
      doUpdateProductToVault(products, () => {});
    } else {
      products[0]["loanDetails"] = loanProductForm;
      this.updateDoxim(activeProduct, products[0]["loanDetails"]);
      this.updateJointInfo(products[0]);
      doUpdateProductToVault(products, () => {});
    }
  }

  handleCancelApplication = () => {
    const { history, products, doUpdateProductToVault } = this.props;
    products.splice(0, 1);
    doUpdateProductToVault(products, () => {
      history.push(ROUTES.VAULT_DASHBOARD);
    });
  };

  getTermYears(loanTerm) {
    return Math.floor(loanTerm / 12);
  }

  getTermMonths(loanTerm) {
    const years = Math.floor(loanTerm / 12) * 12;
    return loanTerm - years;
  }

  getFirstPaymentDate(startDate, paymentType) {
    const endDate = new Date(startDate);
    /* first payment date default is current date
    if (paymentType === "weekly") {
      endDate.setDate(endDate.getDate() + 7);
    } else if (paymentType === "biweekly") {
      endDate.setDate(endDate.getDate() + 14);
    } else {
      endDate.setDate(endDate.getDate() + 30);
    }
     */
    console.log("endDate", endDate);
    return endDate;
  }

  updateDoxim(activeProduct, loanDetails) {
    const { doUpdateLendingProduct, continuehandler } = this.props;
    console.log("updateDoxim", loanDetails);
    let payLoad = {};
    const currentDate = new Date();
    const futureDate = add(currentDate, { days: 30 });
    if (activeProduct.productType === "Loan") {
      if (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL) {
        payLoad = {
          LPBasicDetails: {
            NewMoney: loanDetails.loanAmount,
            PurposeCode: activeProduct.loanPurposeCode,
            PurposeOfCredit: activeProduct.loanPurposeDesc,
          },
          LPTermsDetails: {
            TermYears: this.getTermYears(loanDetails.loanTerm),
            TermMonths: this.getTermMonths(loanDetails.loanTerm),
            MonthlyPayment: loanDetails.monthlyPayment,
            AmortYears: this.getTermYears(loanDetails.loanTerm),
            AmortMonths: this.getTermMonths(loanDetails.loanTerm),
            RateCommittedDate: format(currentDate, "yyyy-MM-dd"),
            BaseRate: loanDetails.loanRate,
            PaymentFrequency: loanDetails.paymentType,
            FirstPaymentDate: format(
              this.getFirstPaymentDate(currentDate, loanDetails.paymentType),
              "yyyy-MM-dd"
            ),
          },
        };
      } else if (activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) {
        payLoad = {
          LPBasicDetails: {
            NewMoney: loanDetails.loanAmount,
            PurposeCode: activeProduct.loanPurposeCode,
            PurposeOfCredit: activeProduct.loanPurposeDesc,
          },
          LPUDFDetails: {
            UDF1: "",
            UDF2: "",
            UDF3: "",
            UDF4: "",
            UDF5: "",
            UDF6: "",
            UDF7: "",
            UDF8: "",
            UDF9: activeProduct.applicationType,
            UDF10: "",
          },
        };
      } else if (activeProduct.category === AppConstants.LOAN_CATEGORY.AUTO) {
        payLoad = {
          LPBasicDetails: {
            NewMoney: loanDetails.loanAmount,
            PurposeCode: activeProduct.loanPurposeCode,
            PurposeOfCredit: activeProduct.loanPurposeDesc,
          },
          LPTermsDetails: {
            MonthlyPayment: loanDetails.monthlyPayment,
            PaymentFrequency: loanDetails.paymentType,
            FirstPaymentDate: format(
              this.getFirstPaymentDate(currentDate, loanDetails.paymentType),
              "yyyy-MM-dd"
            ),
            RateCommittedDate: format(currentDate, "yyyy-MM-dd"),
            TermYears: this.getTermYears(loanDetails.loanTerm),
            TermMonths: this.getTermMonths(loanDetails.loanTerm),
            AmortYears: this.getTermYears(loanDetails.loanTerm),
            AmortMonths: this.getTermMonths(loanDetails.loanTerm),
            BaseRate: loanDetails.loanRate,
          },
        };
      }

      doUpdateLendingProduct(
        activeProduct.applicationId,
        activeProduct.doximProductId,
        payLoad,
        (res) => {
          console.log(res);
          continuehandler(null, AppConstants.APPLICATIONSTEP.LOAN_DETAILS);
        }
      );
    } else {
      if (activeProduct.category === AppConstants.LOAN_CATEGORY.MORTGAGE) {
        payLoad = {
          LPBasicDetails: {
            PurposeCode: activeProduct.loanPurposeCode,
            PurposeOfCredit: activeProduct.loanPurposeDesc,
            NewMoney: loanDetails.estimatedMortgage,
            StartDate: format(currentDate, "yyyy-MM-dd"),
          },
          LPTermsDetails: {
            TermYears: loanDetails.loanTerm,
            AmortYears: loanDetails.amortization,
            BaseRate: loanDetails.loanRate,
            FirstPaymentDate: format(futureDate, "yyyy-MM-dd"),
          },
          LPFeesDetails: {
            AppraisalInspectionFeesFinance: true,
            AppraisalInspectionFeesMandatory: true,
          },
        };
      } else if (activeProduct.category === AppConstants.LOAN_CATEGORY.HELOC) {
        payLoad = {
          LPBasicDetails: {
            NewMoney: loanDetails.loanAmount,
            PurposeCode: activeProduct.loanPurposeCode,
            PurposeOfCredit: activeProduct.loanPurposeDesc,
          },
        };
      }
      doUpdateLendingProduct(
        activeProduct.applicationId,
        activeProduct.doximProductId,
        payLoad,
        (res) => {
          console.log(res);
          continuehandler(null, AppConstants.APPLICATIONSTEP.LOAN_DETAILS);
        }
      );
    }
  }

  initializeLoanDetail() {
    const {
      loanProducts,
      products,
      getActiveProduct,
      doGetFinancialList,
      doUpdateProductToVault,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (!activeProduct) {
      return;
    }
    const productId = activeProduct.productId;
    let purposes = [];
    let paymentTypes = [];
    let loanConfig = {};
    console.log(loanProducts);
    if (loanProducts) {
      loanProducts.forEach((raw, idx) => {
        if (raw.id === productId) {
          purposes = raw.purpose;
          paymentTypes = raw.paymentTypes;
          loanConfig = {
            loanAmount: raw.loanAmount,
            loanTerm: raw.loanTerm,
            loanRate: raw.loanRate,
          };
        }
      });
    }

    let loanDetails = activeProduct.loanDetails;

    if (loanDetails) {
      updateLoanProductForm(loanDetails);
    }
    if (!activeProduct.finAnalysisId) {
      doGetFinancialList({ applicantId: activeProduct.applicantId }, (res) => {
        if (res) {
          res.forEach((raw, idx) => {
            if (raw.applicationId == activeProduct.applicationId) {
              products[0]["finAnalysisId"] = raw.finAnalysisId;
              doUpdateProductToVault(products, () => {});
            }
          });
        }
      });
    }
  }

  render() {
    const { choice, loanPurposes, paymentTypes, loanConfig } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      loanProducts,
      loanProductForm,
      getActiveProduct,
      products,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(loanProducts);
    let loanProduct = {};
    try {
      loanProduct = loanProducts
        ? loanProducts.find((loan) => loan.id === activeProduct.productId)
        : {};
    } catch (e) {}
    return (
      <>
        {loanProduct?.category === AppConstants.LOAN_CATEGORY.AUTO && (
          <LoanAuto
            handleContinue={this.handleContinue}
            handleChange={this.handleChange}
            updateLoanForm={this.updateLoanForm}
            getDataFromServer={getDataFromServer}
            choice={choice}
            handleBack={handleBack}
            steps={steps}
            activeStepID={activeStepID}
            products={products}
            loanProducts={loanProducts}
            loanPurposes={loanPurposes}
            paymentTypes={paymentTypes}
            loanConfig={loanConfig}
            loanProductForm={loanProductForm}
            activeProduct={activeProduct}
            getActiveProduct={getActiveProduct}
          />
        )}
        {loanProduct?.category === AppConstants.LOAN_CATEGORY.HELOC && (
          <LoanHeloc
            handleContinue={this.handleContinue}
            handleChange={this.handleChange}
            updateLoanForm={this.updateLoanForm}
            getDataFromServer={getDataFromServer}
            choice={choice}
            handleBack={handleBack}
            steps={steps}
            activeStepID={activeStepID}
            products={products}
            loanProducts={loanProducts}
            loanPurposes={loanPurposes}
            paymentTypes={paymentTypes}
            loanConfig={loanConfig}
            loanProductForm={loanProductForm}
            activeProduct={activeProduct}
            getActiveProduct={getActiveProduct}
            handleCancelApplication={this.handleCancelApplication}
          />
        )}
        {loanProduct?.category === AppConstants.LOAN_CATEGORY.LOC && (
          <LoanLoc
            handleContinue={this.handleContinue}
            handleChange={this.handleChange}
            updateLoanForm={this.updateLoanForm}
            getDataFromServer={getDataFromServer}
            choice={choice}
            handleBack={handleBack}
            steps={steps}
            activeStepID={activeStepID}
            products={products}
            loanProducts={loanProducts}
            loanPurposes={loanPurposes}
            paymentTypes={paymentTypes}
            loanConfig={loanConfig}
            loanProductForm={loanProductForm}
            activeProduct={activeProduct}
            getActiveProduct={getActiveProduct}
          />
        )}
        {loanProduct?.category === AppConstants.LOAN_CATEGORY.MORTGAGE && (
          <LoanMortgage
            handleContinue={this.handleContinue}
            handleChange={this.handleChange}
            updateLoanForm={this.updateLoanForm}
            getDataFromServer={getDataFromServer}
            choice={choice}
            handleBack={handleBack}
            steps={steps}
            activeStepID={activeStepID}
            products={products}
            loanProducts={loanProducts}
            loanPurposes={loanPurposes}
            paymentTypes={paymentTypes}
            loanConfig={loanConfig}
            loanProductForm={loanProductForm}
            activeProduct={activeProduct}
            getActiveProduct={getActiveProduct}
          />
        )}
        {loanProduct?.category === AppConstants.LOAN_CATEGORY.PERSONAL && (
          <LoanDetails
            handleContinue={this.handleContinue}
            handleChange={this.handleChange}
            updateLoanForm={this.updateLoanForm}
            getDataFromServer={getDataFromServer}
            choice={choice}
            handleBack={handleBack}
            steps={steps}
            activeStepID={activeStepID}
            products={products}
            loanProducts={loanProducts}
            loanPurposes={loanPurposes}
            paymentTypes={paymentTypes}
            loanConfig={loanConfig}
            loanProductForm={loanProductForm}
            activeProduct={activeProduct}
            getActiveProduct={getActiveProduct}
          />
        )}
      </>
    );
  }
}

LoanDetailsContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  getActiveProduct: PropTypes.func,
};

LoanDetailsContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  getActiveProduct: () => {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  loanProductForm: state.LoanDetailsReducer.loanProductForm,
  slots: state.ApplicationReducer.slots,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLoanProduct: (callback) => dispatch(getLoanProduct(callback)),
    updateLoanProductForm: (loanProductForm, callback) =>
      dispatch(updateLoanProductForm(loanProductForm, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doUpdateLendingProduct: (
      applicationId,
      doximProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLendingProduct(applicationId, doximProductId, payLoad, callback)
      ),
    doGetFinancialList: (reqData, callback) =>
      dispatch(getFinancialList(reqData, callback)),
  };
};

const LoanDetailsWrapper = withApplicantHOC(LoanDetailsContainer);
export default connect(mapStateToProps, mapDispatchToProps)(LoanDetailsWrapper);
