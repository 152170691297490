import React from "react";
import "./PasswordInput.scss";
import PropTypes from "prop-types";

export class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.inputFocus = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props;
    if (id === "Password-1") this.inputFocus.current.focus();
  }

  togglePasswordHandler = () => {
    this.setState((prevState) => {
      return { showPassword: !prevState.showPassword };
    });
  };

  render() {
    const { showPassword } = this.state;
    const {
      name,
      passwordError,
      placeholder,
      password,
      changeHandler,
      onKeyDownHandler,
      id,
    } = this.props;

    return (
      <div className="[ w-100 ] [ text-right ] [ password-input ]">
        <input
          type={showPassword ? "text" : "password"}
          name={name || "password"}
          id={id || "password"}
          className={
            passwordError && passwordError.length > 0
              ? "[ form-control ] [ invalid-input ]"
              : "[ form-control ]"
          }
          placeholder={placeholder}
          value={password}
          onChange={changeHandler}
          onKeyDown={onKeyDownHandler}
          data-toggle="password"
          ref={this.inputFocus}
        />

        <span
          role="presentation"
          className={
            !showPassword
              ? "[ password-input_eye-icon ]"
              : "[ password-input_eye-icon-close ]"
          }
          onClick={this.togglePasswordHandler}
        />
      </div>
    );
  }
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  password: PropTypes.string,
  changeHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  passwordError: PropTypes.arrayOf(PropTypes.array),
  id: PropTypes.string,
};
PasswordInput.defaultProps = {
  changeHandler: () => {},
  onKeyDownHandler: () => {},
  name: "",
  placeholder: "",
  password: "",
  passwordError: [],
  id: "",
};

export default PasswordInput;
