import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  questionnaire: {},
  product: {},
};

const QuestionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: action.questionnaire,
      };
    case actionTypes.STORE_QUESTIONNAIRE_PRODUCT:
      return {
        ...state,
        product: action.product,
      };

    default:
      return state;
  }
};
export default QuestionnaireReducer;
