import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  toggleBackFlag,
  getApplication,
  getContract,
  updateApplication,
  updateContract,
} from "Redux/Actions/ApplicationAction";
import STRINGS from "../../Constants/Strings";
import { HTTP_STATUS, S_SSO } from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import withApplicantHOC from "../../Hoc/ApplicantHOC/ApplicantHOC";
import JointApplicant from "./Membership";
import getAccount from "../../Redux/Actions/GetAccountAction";
import { getProductFromVault } from "../MarketPlace/Action/MarketPlaceAction";
import { isNewMember, showJoint } from "../../Utils/LogicUtilities";
import {
  getApplicantType,
  isScanningAllowed,
} from "../../Utils/CommonUtilities";
import Membership from "./Membership";
import {
  addGlobalVault,
  addJointApplicants,
  deleteApplicationSlot,
  getApplicationProperty,
  setInSessionJointApplicant,
} from "../JointApplicant/Actions/JointApplicantAction";

class MembershipContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { actionBack } = this.props;
    console.log("MembershipContainer mount", actionBack);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { finInfo } = this.props;
    if (prevProps.finInfo !== finInfo) {
    }
  }

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      continuehandler,
      doGetProductFromVault,
      products,
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicantType,
      applicantTypes,
      doAddGlobalVault,
      doGetApplicationProperty,
      getActiveProduct,
      account,
      finInfo,
    } = this.props;
    const {} = this.state;
    return (
      <div>
        <Membership
          finInfo={finInfo}
          continuehandler={continuehandler}
          handleBack={this.handleBack}
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          steps={steps}
          activeStepID={activeStepID}
          doGetProductFromVault={doGetProductFromVault}
          doAddApplicationSlot={doAddApplicationSlot}
          doinviteApplicationSlot={doinviteApplicationSlot}
          doGetApplicantType={doGetApplicantType}
          doGetApplicationProperty={doGetApplicationProperty}
          doAddGlobalVault={doAddGlobalVault}
          products={products}
          applicantTypes={applicantTypes}
          getActiveProduct={getActiveProduct}
          handleChange={this.handleChange}
          account={account}
        />
      </div>
    );
  }
}

MembershipContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddApplicationSlot: PropTypes.func.isRequired,
  doinviteApplicationSlot: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  doGetProductFromVault: PropTypes.func.isRequired,
  doAddGlobalVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doDeleteApplicationSlot: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  globalVault: PropTypes.object,
  doToggleBackFlag: PropTypes.func.isRequired,
  finInfo: PropTypes.object,
  doUpdateContract: PropTypes.func.isRequired,
  applicationCompleteList: PropTypes.arrayOf(PropTypes.any),
};
MembershipContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  globalVault: {},
  finInfo: {},
  applicationCompleteList: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAccountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doAddGlobalVault: (callback) => dispatch(addGlobalVault(callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doDeleteApplicationSlot: (request, callback) =>
      dispatch(deleteApplicationSlot(request, callback)),
    dosetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    doAddJointApplicants: (request, callback) =>
      dispatch(addJointApplicants(request, callback)),
    doGetApplication: (applicationId, callback) =>
      dispatch(getApplication(applicationId, callback)),
    doGetContract: (
      applicationId,
      applicationType,
      productId,
      waitMessage,
      callback
    ) =>
      dispatch(
        getContract(
          applicationId,
          applicationType,
          productId,
          waitMessage,
          callback
        )
      ),
    doUpdateContract: (
      applicationId,
      applicationType,
      productId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateContract(
          applicationId,
          applicationType,
          productId,
          payLoad,
          callback
        )
      ),
  };
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantTypes: state.JoinApplicantReducer.applicantTypes,
  globalVault: state.JoinApplicantReducer.globalVault,
  applicantData: state.ApplicationReducer.response,
  finInfo: state.MarketplaceReducer.finInfo,
  account: state.AboutYouReducer.account,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  actionBack: state.ApplicationReducer.actionBack,
});

const MembershipContainerWrapper = withApplicantHOC(MembershipContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipContainerWrapper);
