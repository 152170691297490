import { call, put, takeLatest } from "redux-saga/effects";
import GetApplicationListRequest from "Communication/ApiClasses/GetApplicationListRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleGetApplicationList(action) {
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.GET_APPLICATION_LIST));
  const getApplicationListApi = new GetApplicationListRequest();
  try {
    const result = yield call(getApplicationListApi.getData);
    yield put({
      type: actionTypes.STORE_APPLICATION_LIST,
      data: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.GET_APPLICATION_LIST));
  }
}

// watcher
export default function* getAccountWatcherSaga() {
  yield takeLatest(actionTypes.APPLICATION_LIST, handleGetApplicationList);
}
