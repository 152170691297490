import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import "./Income.scss";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";

class Income extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      incomeTypeError: [],
      descriptionError: [],
      monthlyAmountError: [],
      valueError: [],
      incomeList: [
        {
          incomeType: "",
          description: "",
          monthlyAmount: "",
          adjustment: "",
        },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.INCOME, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    const { products, getActiveProduct, incomeList } = this.props;
    if (incomeList !== prevProps.incomeList) {
      console.log(incomeList);
      this.setState({ incomeList: incomeList });
    }
  }

  setFormData = () => {};

  validateForm = () => {
    let valid = true;
    const { incomeList } = this.state;
    const { choice } = this.props;
    this.setState({
      incomeTypeError: [],
      descriptionError: [],
      monthlyAmountError: [],
      formIsValid: true,
    });
    console.log("income length", incomeList.length, choice);
    if (incomeList.length > 0 && choice) {
      // Check error for asset Type
      let error = checkErrors(
        incomeList[incomeList.length - 1].incomeType,
        VALIDATIONS.LOANS.INCOME.INCOME_TYPE
      );
      if (error.length) {
        valid = false;
        this.setState({ incomeTypeError: error });
      }

      error = checkErrors(
        incomeList[incomeList.length - 1].description,
        VALIDATIONS.LOANS.INCOME.DESCRIPTION
      );
      if (error.length) {
        valid = false;
        this.setState({ descriptionError: error });
      }

      error = checkErrors(
        incomeList[incomeList.length - 1].monthlyAmount,
        VALIDATIONS.LOANS.INCOME.MONTHLY_AMOUNT
      );
      if (error.length) {
        valid = false;
        this.setState({ monthlyAmountError: error });
      }

      this.setState({
        formIsValid: valid,
      });
    }

    return valid;
  };

  handleIncomeChange = (raw, prop) => (event) => {
    console.log("handleIncomeChange", raw, prop, `${prop}Error`);
    const { incomeList } = this.state;
    const income = raw;
    income[prop] = event.currentTarget.value;
    this.setState({ incomeList, [`${prop}Error`]: [] });
  };

  handleChange = (raw, event) => {
    const { handleChange } = this.props;
    handleChange(raw);
  };

  handleAddIncome = () => {
    console.log("handleAddIncome");
    if (this.validateForm()) {
      const income = {
        incomeType: "",
        description: "",
        monthlyAmount: "",
        adjustment: "",
      };
      const action = {
        type: "add",
        income: income,
      };
      this.updateIncomeList(action);
    }
  };

  handleDeleteIncome = (idx, income) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateIncomeList(action);
  };

  updateIncomeList(action) {
    const { incomeList } = this.state;
    if (action.type === "add") {
      console.log(action);
      incomeList.push(action.income);
    } else if (action.type === "remove") {
      if (action.index === incomeList.length - 1) {
        this.setState({
          incomeTypeError: [],
          descriptionError: [],
          monthlyAmountError: [],
          valueError: [],
        });
      }
      if (action.index > -1) {
        console.log(action);
        incomeList.splice(action.index, 1);
      }
    }
    this.setState({ incomeList });
    if (incomeList.length === 0) {
      const { handleChange } = this.props;
      handleChange(false);
    }
  }

  handleContinue = () => {
    const { handleContinue } = this.props;
    if (this.validateForm()) {
      handleContinue(this.state.incomeList);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.INCOME);
  };

  render() {
    const {
      incomeList,
      incomeTypeError,
      descriptionError,
      monthlyAmountError,
    } = this.state;
    const { steps, activeStepID, choice, incomeTypes } = this.props;
    const adjustmentTypeList = ["Con", "Exc", "New"];
    console.log(incomeTypes);
    const incomeTypeComponent = incomeTypes.map((incomeType, index) => (
      <option value={incomeType.code} className="[ dropdown__option ]">
        {incomeType.description}
      </option>
    ));
    const adjustmentTypeComponent = adjustmentTypeList.map(
      (adjustmentType, index) => (
        <option value={adjustmentType} className="[ dropdown__option ]">
          {adjustmentType}
        </option>
      )
    );

    const addIncomeListComponent = incomeList.map((income, index) => (
      <div className="[ drawer-content-item ]">
        <div className="[ info-title ]">
          {/* CHRIS NOTE: For some reason, index + 1 doesn't want to work here. {index + 1} Please include */}
          {STRINGS.INCOME.FORM.INCOME_TYPE_NO} {index + 1}
          <button
            type="button"
            className="[ fas fa-trash ]"
            onClick={() => this.handleDeleteIncome(index, income)}
            aria-label="remove"
          >
            <span className="[ sr-only ]">
              {STRINGS.INCOME.FORM.REMOVE_INCOME}
            </span>
          </button>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            {/* INCOME TYPE */}
            <div className="[ col-12 col-lg-3 ]">
              <div className="[ form-label ]">
                {STRINGS.INCOME.FORM.INCOME_TYPE}
              </div>
              <select
                value={income.incomeType ? income.incomeType : ""}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.handleIncomeChange(income, "incomeType")}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.INCOME.FORM.INCOME_TYPE_DEFAULT_SELECT}
                </option>
                {incomeTypeComponent}
              </select>
              <div className="[ error-msg ]">
                {incomeTypeError
                  ? index + 1 === incomeList.length
                    ? incomeTypeError[0]
                    : ""
                  : ""}
              </div>
            </div>

            {/* INCOME DESCRIPTION */}
            <div className="[ col-12 col-lg-6 ]">
              <div className="[ form-label ] ">
                {STRINGS.INCOME.FORM.DESCRIPTION}
              </div>
              <input
                type="text"
                name="description"
                className="[ form-control ]"
                placeholder={STRINGS.INCOME.FORM.DESCRIPTION}
                onChange={this.handleIncomeChange(income, "description")}
                value={income.description ? income.description : ""}
              />
              <div className="[ error-msg ]">
                {descriptionError
                  ? index + 1 === incomeList.length
                    ? descriptionError[0]
                    : ""
                  : ""}
              </div>
            </div>

            {/* MONTHLY AMOUNT */}
            <div className="[ col-12 col-lg-3 ]">
              <div className="[ form-label ] ">
                {STRINGS.INCOME.FORM.MONTHLY_AMOUNT}
              </div>
              <NumberFormat
                maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                type="text"
                thousandSeparator
                allowNegative={false}
                prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                name="monthlyAmount"
                className="[ form-control ]"
                placeholder={STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER}
                onChange={this.handleIncomeChange(income, "monthlyAmount")}
                value={income.monthlyAmount ? income.monthlyAmount : ""}
              />
              <div className="[ error-msg ]">
                {monthlyAmountError
                  ? index + 1 === incomeList.length
                    ? monthlyAmountError[0]
                    : ""
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="[ divider ] [ mb-3 ]" />
      </div>
    ));
    const actionComponent = (
      <div className="[ income-details-container ]">
        <Stepper
          steps={steps}
          active={activeStepID}
          title={STRINGS.INCOME.TITLE}
        />

        <div className="[ form-title ]">{STRINGS.INCOME.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.INCOME.PAGE_DESCRIPTION}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.INCOME.FORM.INCOME_RADIO_LABEL}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && !choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(false, event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]"></div>
            </div>
          </div>

          {choice && (
            <div className="[ drawer-content-container mb-3 ]">
              {choice && addIncomeListComponent}
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                      onClick={(event) => this.handleAddIncome(event)}
                    >
                      <span className="[ fas fa-plus ]" />
                      {STRINGS.INCOME.FORM.ADD_BTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

Income.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  incomeList: PropTypes.arrayOf(PropTypes.object),
};

Income.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default Income;
