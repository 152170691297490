import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import InviteApplicationSlot from "Communication/ApiClasses/InviteApplicationSlot";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleInviteApllicationSlot(action) {
  console.log(action);
  yield put(
    doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.INVITE_APPLICATION_SLOT)
  );
  const inviteApplicationSlot = new InviteApplicationSlot(action.data);
  try {
    const result = yield call(inviteApplicationSlot.getData);
    yield put({
      type: actionTypes.INVITE_APPLICATION_SLOT_SUCCESS,
      response: result,
      formData: { ...action.requestData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.INVITE_APPLICATION_SLOT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.INVITE_APPLICATION_SLOT)
    );
  }
}

// watcher
function* inviteApplicationSlotWatcherSaga() {
  yield takeEvery(
    actionTypes.INVITE_APPLICATION_SLOT,
    handleInviteApllicationSlot
  );
}

export default inviteApplicationSlotWatcherSaga;
