import { call, put, takeLatest } from "redux-saga/effects";
import RecoverPasswordRequest from "Communication/ApiClasses/RecoverPasswordRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import {
  TOKEN_KEY,
  S_APPLICANT_ID,
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_MSISDN,
  S_EMAIL,
  S_SSO,
  S_PERSONAL,
} from "../../Communication/Constants";

// worker
function* handleRecoverPassword(action) {
  yield put(doLoadingStart(STRINGS.FORGOTPASSWORD.API_KEYS.RECOVER_PASSWORD));
  const recoverPasswordApi = new RecoverPasswordRequest(action.data);
  try {
    const result = yield call(recoverPasswordApi.getData);
    sessionStorage.setItem(TOKEN_KEY, result.data.token);
    sessionStorage.setItem(S_MSISDN, result.data.msisdn);
    sessionStorage.setItem(S_EMAIL, result.data.email);
    sessionStorage.setItem(S_PERSONAL, result.data.personalId);
    sessionStorage.setItem(S_SSO, "no");
    sessionStorage.removeItem(S_APPLICANT_ID);
    sessionStorage.setItem(EXPIRES_AT, result.data.expiresAt);
    sessionStorage.setItem(REFRESH_EXPIRES_AT, result.data.refreshExpiresAt);
    yield put({
      type: actionTypes.RECOVER_PASSWORD_RESPONSE,
      success: result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.RECOVER_PASSWORD_ERROR,
      error: error.response.data.description,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.FORGOTPASSWORD.API_KEYS.RECOVER_PASSWORD)
    );
  }
}

// watcher
export default function* recoverPasswordWatcherSaga() {
  yield takeLatest(actionTypes.RECOVER_PASSWORD_REQUEST, handleRecoverPassword);
}
