import * as actionTypes from "Redux/Actions/actionTypes";

export const addDocument = (
  applicationId,
  productId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.ADD_DOCUMENT,
  data: {
    applicationId,
    productId,
    payLoad,
  },
  callback,
});

export const getDocumentList = (applicationId, callback = () => {}) => ({
  type: actionTypes.GET_DOCUMENT_LIST,
  data: {
    applicationId,
  },
  callback,
});

export const deleteDocument = (
  applicationId,
  documentId,
  callback = () => {}
) => ({
  type: actionTypes.DELETE_DOCUMENT,
  data: {
    applicationId,
    documentId,
  },
  callback,
});
