import * as actionTypes from "Redux/Actions/actionTypes";

export const getProductTemplate = (callback, product) => ({
  type: actionTypes.GET_PRODUCT_TEMPLATE,
  callback,
  product,
});

export const storeProductTemplate = (templates, processTemplate) => ({
  type: actionTypes.STORE_PRODUCT_TEMPLATE,
  templates,
  processTemplate,
});
