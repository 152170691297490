import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: "",
  error: "",
  accountPurposeList: [],
  indicateWhoList: [],
};

const ComplianceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ACCOUNT_PURPOSE:
      return {
        ...state,
        accountPurposeList: action.accountPurposeList,
      };
    case actionTypes.ADD_INDICATE_WHO:
      return {
        ...state,
        indicateWhoList: action.indicateWhoList,
      };

    case actionTypes.ACCOUNT_PURPOSE_ERROR:
      return {
        ...state,
        error: "account purpose list error ",
      };

    case actionTypes.INDICATE_WHO_ERROR:
      return {
        ...state,
        error: "Indicate who list error ",
      };

    default:
      return state;
  }
};

export default ComplianceReducer;
