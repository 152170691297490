import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class CardComponent extends PureComponent {
  render() {
    const { firstComponent, secondComponent } = this.props;
    return (
      <div className="[ info-tile-container ]">
        <div className="[ info-tile ]">
          {firstComponent}
          <div className="[ info-tile__description ]">{secondComponent}</div>
        </div>
      </div>
    );
  }
}

CardComponent.propTypes = {
  firstComponent: PropTypes.element,
  secondComponent: PropTypes.string,
};
CardComponent.defaultProps = {
  firstComponent: PropTypes.element,
  secondComponent: "",
};

export default CardComponent;
