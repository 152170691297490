import * as actionTypes from "Redux/Actions/actionTypes";

export const getLoanProduct = (callback = () => {}) => ({
  type: actionTypes.GET_LOAN_PRODUCT,
  callback,
});

export const updateLoanLendingProduct = (
  applicationId,
  lendingProductId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_LENDING_PRODUCT,
  data: {
    applicationId,
    lendingProductId,
    payLoad,
  },
  callback,
});

export const updateLoanSecurity = (
  applicationId,
  lendingProductId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_SECURITY,
  data: {
    applicationId,
    lendingProductId,
    payLoad,
  },
  callback,
});

export const updateLoanProductForm = (requestData, callback = () => {}) => ({
  type: actionTypes.UPDATE_LOAN_PRODUCT_FORM,
  data: requestData,
  requestData,
  callback,
});

export const getLoanLoanSecurity = (applicationId, callback = () => {}) => ({
  type: actionTypes.LOAN_GET_LOAN_SECURITY,
  data: {
    applicationId,
  },
  callback,
});

export const deleteLoanFinancial = (
  applicantId,
  finAnalysisId,
  financialId,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_DELETE_FINANCIAL,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
  },
  callback,
});

export const deleteLoanLoanSecurity = (
  applicationId,
  securityId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_DELETE_LOAN_SECURITY,
  data: {
    applicationId,
    securityId,
    payLoad,
  },
  callback,
});

export const getFinancialList = (requestData, callback = () => {}) => ({
  type: actionTypes.GET_FINANCIALANALYSYS_LIST,
  data: requestData,
  requestData,
  callback,
});

export const getFinancialAnalysisFinancialList = (
  requestData,
  callback = () => {}
) => ({
  type: actionTypes.GET_FINANCIALANALYSYS_FINANCIAL_LIST,
  data: requestData,
  requestData,
  callback,
});

export const getLendingProduct = (
  applicationId,
  doximProductId,
  callback = () => {}
) => ({
  type: actionTypes.GET_LENDING_PRODUCT,
  data: {
    applicationId,
    doximProductId,
  },
  callback,
});

export const updateLendingProduct = (
  applicationId,
  doximProductId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.UPDATE_LENDING_PRODUCT,
  data: {
    applicationId,
    doximProductId,
    payLoad,
  },
  callback,
});
