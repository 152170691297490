import * as actionTypes from "Redux/Actions/actionTypes";

export const addLoanExpense = (
  applicantId,
  finAnalysisId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_ADD_EXPENSE,
  data: {
    applicantId,
    finAnalysisId,
    payLoad,
  },
  callback,
});

// TODO update (has third param)
export const updateLoanExpense = (
  applicantId,
  finAnalysisId,
  financialId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_EXPENSE,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
    payLoad,
  },
  callback,
});

export const getLoanExpense = (
  applicantId,
  finAnalysisId,
  financialId,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_GET_EXPENSE,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
  },
  callback,
});
