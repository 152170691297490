import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetEmployerPositionCodes from "../../../Communication/ApiClasses/GetEmployerPositionCodes";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(actionTypes.GET_EMPLOYER_POSITION_CODES));
  const getEmployerPositionCodes = new GetEmployerPositionCodes();
  try {
    const result = yield call(getEmployerPositionCodes.getData);
    yield put({
      type: `${actionTypes.GET_EMPLOYER_POSITION_CODES}_SUCCESS`,
      response: result.data,
    });
    if (action.callback) {
      yield call(action.callback, result.data);
    }
  } catch (error) {
    yield put({
      type: `${actionTypes.GET_EMPLOYER_POSITION_CODES}_ERROR`,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.GET_EMPLOYER_POSITION_CODES));
  }
}
// watcher
export default function* getSaga() {
  yield takeLatest(actionTypes.GET_EMPLOYER_POSITION_CODES, handleSaga);
}
