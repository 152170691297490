import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";

import { S_APPLICANT_ID, HTTP_STATUS } from "Communication/Constants";

import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import BusinessClient from "./BusinessClient";
import {
  storeFilterBankingType,
  updateProductToVault,
} from "../MarketPlace/Action/MarketPlaceAction";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import InveriteAccountList from "../Inverite/InveriteAccountList";
import Inverite from "../Inverite/Inverite";
import { addApplicant } from "../Inverite/Actions/InveriteAction";
import { searchMember } from "../../Redux/Actions/ApplicantAction";
import { getApplicant } from "../../Redux/Actions/ApplicationAction";
import { updatePersonal } from "../../Redux/Actions/BusinessAction";
import {
  S_BANKING_TYPE_SWITCH,
  S_PERSONAL,
} from "../../Communication/Constants";
import PopupModal from "../Common/PopupModal/PopupModal";
import doLogout from "../../Redux/Actions/LogoutAction";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";

class BusinessClientContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showModal: false,
      iframeModal: false,
      showRequired: false,
      requiredList: null,
      iframeUrl: "",
      modalType: "",
      modal: {
        title: "",
        description: "",
        description2: null,
      },
      showVaultLimitError: false,
      showNonMember: false,
    };
  }

  componentDidMount() {
    const {
      provinceList,
      doGetProductFromVault,
      products,
      getActiveProduct,
      applicantData,
      personalData,
      continuehandler,
      actionBack,
      handleBack,
    } = this.props;

    if (personalData?.id) {
      continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT);
    } else {
      const activeProduct = getActiveProduct(products);
      let selectedMember = activeProduct?.foundMemberList.find(
        (x) => x.selectedMember
      );
      if (!selectedMember) {
        selectedMember = activeProduct?.additionalMemberList.find(
          (x) => x.selectedMember
        );
      }
      if (selectedMember?.authorizedSignatory) {
        if (products.length <= 0) {
          doGetProductFromVault((response) => {
            if (
              !response ||
              response.data?.length <= 0 ||
              sessionStorage.getItem(S_APPLICANT_ID)
            ) {
              const { history } = this.props;
              history.push(ROUTES.VAULT_DASHBOARD);
            }
          });
        }

        // province list is needed on about you page details
        if (provinceList && provinceList.length <= 0) {
          const { doGetProvinceData } = this.props;
          doGetProvinceData();
        }
      } else {
        if (actionBack) {
          handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT);
        } else {
          continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT);
        }
      }
    }
  }

  showModal = () => {
    this.setState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.INFORMATION,
      modal: {
        title: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_TITLE,
        description: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_MSG,
      },
    });
  };

  gotoMarketplace = () => {
    const { history } = this.props;
    history.push(ROUTES.VAULT_DASHBOARD + "?marketplace");
  };

  handleContinue = (data) => {
    const {
      continuehandler,
      doCreateInverite,
      finInfo,
      products,
      getActiveProduct,
      applicantData,
    } = this.props;
    const activeProduct = getActiveProduct(products);

    console.log("choice", data);
    if (data.isExistingBusinessClient) {
      const { doCreateInverite, finInfo } = this.props;
      if (finInfo.allowExistingMember) {
        doCreateInverite((response) => {
          if (response.status === HTTP_STATUS.OK) {
            sessionStorage.setItem(
              AppConstants.SESSION.INVERITE_ACCOUNTLIST_REQUEST_GUID,
              response.data.requestGuid
            );
            this.setState({
              iframeModal: true,
              iframeUrl: response.data.iframeUrl,
            });
          }
        });
      } else {
        this.showModal("", finInfo.existingMemberMessage);
      }
      // continuehandler(data, AppConstants.APPLICATIONSTEP.BUSINESS_EXIST);
    } else {
      if (!applicantData.isSoleProprietor) {
        continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT);
      } else {
        this.setState({
          showNonMember: true,
          modalType: AppConstants.MODALTYPE.INFORMATION,
          modal: {
            title: MODALMESSAGES.BUSINESS_NON_MEMBER.TITLE.split(
              "_PRODUCT_"
            ).join(activeProduct.productName),
            description: MODALMESSAGES.BUSINESS_NON_MEMBER.MESSAGE1,
            description2: MODALMESSAGES.BUSINESS_NON_MEMBER.MESSAGE2,
          },
        });
      }
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: false,
      iframeModal: false,
      showRequired: false,
    });
  };

  goToPersonalBanking = () => {
    const { doLogOutAction, doStoreFilterBankingType, history } = this.props;
    doLogOutAction(() => {
      sessionStorage.setItem(
        S_BANKING_TYPE_SWITCH,
        AppConstants.BANKING_TYPE.BUSINESS
      );
      doStoreFilterBankingType(AppConstants.BANKING_TYPE.PERSONAL);
      history.push(ROUTES.HOME);
    });
  };

  processInverite = (data) => {
    const {
      doUpdateProductToVault,
      products,
      continuehandler,
      doSearchMember,
      doUpdatePersonal,
      doGetApplicant,
    } = this.props;
    console.log("processInverite", data);

    doSearchMember(
      {
        memberNumber: data.data.memberNumber,
        transit: data.data.transit,
      },
      (res) => {
        doUpdatePersonal(
          {
            personalId: res.data.applicantId,
          },
          () => {
            sessionStorage.setItem(S_PERSONAL, res.data.applicantId);
            doGetApplicant({ id: res.data.applicantId, isPersonalData: true });
            products[0].memberNumber = data.data.memberNumber;
            doUpdateProductToVault(products, () => {
              this.setState(
                {
                  iframeModal: false,
                },
                () =>
                  continuehandler(
                    data,
                    AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT
                  )
              );
            });
          }
        );
      }
    );
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      iframeModal,
      iframeUrl,
      choice,
      showRequired,
      requiredList,
      showNonMember,
    } = this.state;
    const {
      productList,
      products,
      getActiveProduct,
      handleBack,
      applicantData,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessClient
          handleContinue={this.handleContinue}
          handleBack={handleBack}
          choice={choice}
          activeProduct={activeProduct}
          applicantData={applicantData}
        />
        {showRequired && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showRequired}
            btnText="Go To Marketplace"
            popupBtnClick={this.gotoMarketplace}
            requiredList={requiredList}
            productList={productList}
            addProduct={this.addProduct}
          />
        )}
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
        {iframeModal && (
          <Inverite
            iframeUrl={iframeUrl}
            iframeModal={iframeModal}
            toggleModal={this.toggleModal}
            showModal={iframeModal}
            popupBtnClick={this.toggleModal}
            processInverite={this.processInverite}
            closeBtnClick={this.closeInveriteWindow}
          />
        )}
        {showNonMember && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            description2={modal.description2}
            toggleModal={this.goToPersonalBanking}
            showModal={showNonMember}
            btnText={STRINGS.POPUPMODAL.CONTINUE}
            popupBtnClick={this.goToPersonalBanking}
          />
        )}
      </>
    );
  }
}

BusinessClientContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetAcoountDetails: PropTypes.func.isRequired,
  doCreateInverite: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  inSessionJointApplicant: PropTypes.object,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

BusinessClientContainer.defaultProps = {
  history: null,
  products: [],
  inSessionJointApplicant: null,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  productList: state.MarketplaceReducer.productList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  finInfo: state.MarketplaceReducer.finInfo,
  bankingType: state.MarketplaceReducer.bankingType,
  applicantData: state.ApplicationReducer.response,
  personalData: state.ApplicationReducer.personalData,
  actionBack: state.ApplicationReducer.actionBack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doStoreFilterBankingType: (bankingType) =>
      dispatch(storeFilterBankingType(bankingType)),
    doUpdatePersonal: (payLoad, callback) =>
      dispatch(updatePersonal(payLoad, callback)),
    doSearchMember: (params, callback) =>
      dispatch(searchMember(params, callback)),
    doGetApplicant: (payLoad, callback) =>
      dispatch(getApplicant(payLoad, callback)),
    doLogOutAction: (callback) => dispatch(doLogout(callback)),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
  };
};

const BusinessExistWrapper = withBusinessHOC(BusinessClientContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessExistWrapper);
