import { getApiImplementation } from "../CustomBaseApi";

export default class AddressApiRequest {
  getData = () => {
    const body = JSON.parse(JSON.stringify(this.getBody()));
    console.log("AddressApiRequest", this.getUrl(), this.getHeader(), body);
    return getApiImplementation()
      .callApi(this.getMethod(), this.getUrl(), body, this.getHeader())
      .then((response) => {
        if (!response) {
          throw new Error("responese undefined");
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
