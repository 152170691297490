import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import ApplicantCreateRequest from "Communication/ApiClasses/ApplicantCreateRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleApplicantCreateRequest(action) {
  console.log(action);
  yield put(
    doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.APPLICANT_CREATE_REQUEST)
  );
  const applicantCreateRequest = new ApplicantCreateRequest(action.data);
  try {
    console.log(applicantCreateRequest);
    const result = yield call(applicantCreateRequest.getData);
    yield put({
      type: actionTypes.APPLICANT_CREATE_REQUEST_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.APPLICANT_CREATE_REQUEST_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.APPLICANT_CREATE_REQUEST)
    );
  }
}

// watcher
function* applicantCreateRequestWatcherSaga() {
  yield takeLatest(
    actionTypes.APPLICANT_CREATE_REQUEST,
    handleApplicantCreateRequest
  );
}

export default applicantCreateRequestWatcherSaga;
