import * as actionTypes from "Redux/Actions/actionTypes";

export const getTermProduct = (requestData, callback = () => {}) => ({
  type: actionTypes.GET_TERM_PRODUCT,
  data: requestData,
  callback,
});

export const updateTermProduct = (
  applicationId,
  doximProductId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.UPDATE_TERM_PRODUCT,
  data: {
    applicationId,
    doximProductId,
    payLoad,
  },
  callback,
});
