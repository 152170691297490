import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import UpdateTermProductRequest from "../../../Communication/ApiClasses/UpdateTermProductRequest";

// worker
function* handleUpdateTermProduct(action) {
  yield put(doLoadingStart(`${actionTypes.UPDATE_TERM_PRODUCT}_SAGA`));
  const updateTermProductRequest = new UpdateTermProductRequest(
    action.data.applicationId,
    action.data.doximProductId,
    action.data.payLoad
  );
  try {
    const result = yield call(updateTermProductRequest.getData);
    yield put({
      type: `${actionTypes.UPDATE_TERM_PRODUCT}_SUCCESS`,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${actionTypes.UPDATE_TERM_PRODUCT}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${actionTypes.UPDATE_TERM_PRODUCT}_SAGA`));
  }
}

// watcher
export default function* updateTermProductWatcherSaga() {
  yield takeLatest(actionTypes.UPDATE_TERM_PRODUCT, handleUpdateTermProduct);
}
