import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetSpousalInformationRequest from "Communication/ApiClasses/GetSpousalInfoRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleupdateSpousalInformation(action) {
  // Start the spinner
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.GET_SPOUSAL_INFORMATION));
  // Form API request
  const updateSpousalInfo = new GetSpousalInformationRequest({}, [
    action.applicationId,
    action.contractProductId,
  ]);

  try {
    // Call the API
    const result = yield call(updateSpousalInfo.getData);
    // Give a call to the callback function
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.GET_SPOUSAL_INFORMATION_ERROR,
      error: e.message,
    });
    yield call(action.callback, e.message);
  } finally {
    // Stop the spinner
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.GET_SPOUSAL_INFORMATION));
  }
}

// watcher
export default function* handleupdateSpousalInformationSaga() {
  yield takeLatest(
    actionTypes.GET_SPOUSAL_INFORMATION,
    handleupdateSpousalInformation
  );
}
