import * as actionTypes from "Redux/Actions/actionTypes";
import STRINGS from "Constants/Strings";

const initialState = {
  success: "",
  error: "",
  countryList: [],
  statusList: [],
  incomeSourcesList: [],
  data: {},
  employerPositionCodes: [],
};

const EmploymentAndIncomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.countryList,
      };
    case actionTypes.ADD_STATUS_LIST:
      return {
        ...state,
        statusList: action.statusList,
      };
    case actionTypes.ADD_INCOME_SOURCES_LIST:
      return {
        ...state,
        incomeSourcesList: action.incomeSourcesList,
      };

    case actionTypes.ADD_EMPLOYMENT_AND_INCOME_FORM_DATA:
      return {
        ...state,
        data: action.data,
      };

    case actionTypes.STATUS_ERROR:
      return {
        ...state,
        error:
          STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERRORMESSAGE.EMPLOYMENT_STATUS,
      };
    case actionTypes.COUNTRY_ERROR:
      return {
        ...state,
        error: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERRORMESSAGE.COUNTRY_LIST,
      };
    case actionTypes.INCOME_SOURCES_ERROR:
      return {
        ...state,
        error: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERRORMESSAGE.INCOME_LIST,
      };
    case `${actionTypes.GET_EMPLOYER_POSITION_CODES}_SUCCESS`:
      return {
        ...state,
        employerPositionCodes: action.response,
      };
    default:
      return state;
  }
};

export default EmploymentAndIncomeReducer;
