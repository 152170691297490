import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as ROUTES from "Constants/Routes";

import {
  getProductList,
  updateVaultProductList,
  getProductFromVault,
  updateProductToVault,
  storeFilterBankingType,
  getFIInfo,
} from "./Action/MarketPlaceAction";
import MarketPlace from "./MarketPlace";
import STRINGS from "../../Constants/Strings";
import AppConstants from "../../Constants/AppConstants";
import { S_BANKING_TYPE } from "../../Communication/Constants";

class MarketPlaceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectFrom: AppConstants.BANKING_TYPE.PERSONAL,
    };
  }

  componentDidMount() {
    const { doGetProductList, finInfo, doGetFinInfo } = this.props;
    doGetProductList(() => {});
    if (!finInfo) {
      doGetFinInfo();
    }
    console.log("componentDidMount", this.props.location);
    if (
      this.props.location?.state?.type === AppConstants.BANKING_TYPE.BUSINESS
    ) {
      this.setState({ redirectFrom: AppConstants.BANKING_TYPE.BUSINESS });
    } else {
      this.setState({ redirectFrom: AppConstants.BANKING_TYPE.PERSONAL });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { applicantData, history } = this.props;
    if (!!applicantData) {
      console.log("redirecting");
      history.push("/dashboard?marketplace");
    }
  }

  onNavigationHandler = (route) => {
    const { history } = this.props;
    if (STRINGS.MARKETPLACE.PAGE_BANNER.SIGN_IN === route) {
      history.push(ROUTES.SIGN_IN);
    } else if (STRINGS.MARKETPLACE.PAGE_BANNER.REGISTER === route) {
      history.push(ROUTES.REGISTER);
    }
  };

  openRegistration = () => {
    const { history } = this.props;
    history.push(ROUTES.REGISTER);
  };

  openSignIn = () => {
    const { history } = this.props;
    history.push(ROUTES.SIGN_IN);
  };

  switchBanking = () => {
    const { doStoreFilterBankingType, bankingType } = this.props;
    const newBankingType =
      bankingType === AppConstants.BANKING_TYPE.PERSONAL
        ? AppConstants.BANKING_TYPE.BUSINESS
        : AppConstants.BANKING_TYPE.PERSONAL;
    sessionStorage.setItem(S_BANKING_TYPE, newBankingType);
    doStoreFilterBankingType(newBankingType);
  };

  render() {
    const {
      doUpdateVaultProductList,
      doGetProductFromVault,
      doUpdateProductToVault,
    } = this.props;
    const { redirectFrom } = this.state;
    console.log("render", redirectFrom);
    return (
      <MarketPlace
        openRegistration={this.openRegistration}
        openSignIn={this.openSignIn}
        redirectFrom={redirectFrom}
        navigateToSignIn={() =>
          this.onNavigationHandler(STRINGS.MARKETPLACE.PAGE_BANNER.SIGN_IN)
        }
        navigateToRegister={() =>
          this.onNavigationHandler(STRINGS.MARKETPLACE.PAGE_BANNER.REGISTER)
        }
        doUpdateVaultProductList={doUpdateVaultProductList}
        doGetProductFromVault={doGetProductFromVault}
        doUpdateProductToVault={doUpdateProductToVault}
        switchBanking={this.switchBanking}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  bankingType: state.MarketplaceReducer.bankingType,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doGetProductList: getProductList,
      doUpdateVaultProductList: updateVaultProductList,
      doGetProductFromVault: getProductFromVault,
      doUpdateProductToVault: updateProductToVault,
      doStoreFilterBankingType: storeFilterBankingType,
      doGetFinInfo: getFIInfo,
    },
    dispatch
  );
};

MarketPlaceContainer.propTypes = {
  doGetProductList: PropTypes.func,
  doUpdateVaultProductList: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetProductFromVault: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
};

MarketPlaceContainer.defaultProps = {
  doGetProductList: () => {},
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  doUpdateProductToVault: () => {},
  history: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketPlaceContainer);
