import { call, put, takeLatest } from "redux-saga/effects";
import GetApplicantListRequest from "Communication/ApiClasses/GetApplicantListRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleGetApplicantList(action) {
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.GET_APPLICANT_LIST));
  const getApplicantList = new GetApplicantListRequest();
  try {
    const result = yield call(getApplicantList.getData);

    yield put({
      type: actionTypes.GET_APPLICANT_LIST_SUCCESS,
      response: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPLICANT_LIST_ERROR,
      error,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.GET_APPLICANT_LIST));
  }
}

// watcher
export default function* getApplicantListWatcherSaga() {
  yield takeLatest(actionTypes.GET_APPLICANT_LIST, handleGetApplicantList);
}
