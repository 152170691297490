import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { LOAN_GET_REAL_ESTATE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetLoanRealEstate extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = [];

  url = LOAN_GET_REAL_ESTATE_URL;

  constructor(applicationId, securityId) {
    super();
    const paramList = [applicationId, securityId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
