import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppConstants from "Constants/AppConstants";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import STRINGS from "Constants/Strings";
import * as ROUTES from "Constants/Routes";
import { bindActionCreators } from "redux";
import { QuestionCircle } from "react-bootstrap-icons";
import ProductCard from "./ProductCard";
import PopupModal from "../PopupModal/PopupModal";
import {
  storeQuestionnaire,
  storeQuestionnaireProduct,
} from "../../Questionnaire/Actions/QuestionnaireAction";

class ProductCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isCardAddedToVault = (card) => {
    const { vaultProductList } = this.props;
    if (vaultProductList && vaultProductList.length !== 0) {
      const index = vaultProductList.findIndex(
        (elem) => elem.productId === card.productId
      );
      if (index !== -1) return true;
    }
    return false;
  };

  redirectToQuestionnaire = (id) => {
    console.log("redirectToQuestionnaire", id);
    const {
      history,
      bankingProductList,
      doStoreQuestionnaire,
      doStoreQuestionnaireProduct,
    } = this.props;

    doStoreQuestionnaire(
      bankingProductList.find((item) => item.id === id)?.questionnaire
    );
    doStoreQuestionnaireProduct(
      bankingProductList.find((item) => item.id === id)
    );
    history.push(ROUTES.QUESTIONNAIRE);
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState({
      showHelp: true,
      helpText: text,
    });
  };

  render() {
    const { showHelp, helpText } = this.state;

    const {
      bankingProductList,
      handleAddToVault,
      handleSeeDetailsClick,
    } = this.props;
    console.log("productList", bankingProductList);
    const renderCard = (cardArray, type) => {
      return (
        <div className="[ col-12 ]">
          <div className="[ overflow-auto ] [ text-nowrap ] [ carousel-container ]">
            {cardArray.map((product, idx) => {
              const p = { ...product, bankingType: type };
              return (
                product.show && (
                  <ProductCard
                    key={product.productId + idx}
                    product={p}
                    handleAddToVault={handleAddToVault}
                    addToVault
                    isCardAddedToVault={this.isCardAddedToVault}
                    handleSeeDetailsClick={handleSeeDetailsClick}
                  />
                )
              );
            })}
          </div>
        </div>
      );
    };

    const bankingList = bankingProductList.map((elem, idx) => (
      <>
        {(elem.products.length === 0 ||
          elem.products.filter((item) => item.show)?.length > 0) && (
          <div
            className={elem.show ? "[ product-card-container ]" : "[ d-none ]"}
            key={elem.id + idx}
          >
            <div className="[ row ]">
              <div className="[ col-12 ] [ form-subtitle ]">
                {elem.title}
                {elem.questionnaire && (
                  <span className="[ pl-2 ] [ help-me ]">
                    <button
                      type="button"
                      className="[ btn btn-sm ] [ tertiary-btn ]"
                      onClick={() => this.redirectToQuestionnaire(elem.id)}
                    >
                      <span className="[ btn-text ]">
                        {STRINGS.MARKETPLACE.BODY.HELP_ME_CHOOSE}
                      </span>
                    </button>
                    <QuestionCircle
                      className="[ question-icon ]"
                      onClick={() =>
                        this.handleOpenHelp(
                          STRINGS.MARKETPLACE.BODY
                            .HELP_ME_CHOOSE_MORE_INFO_DESCRIPTION
                        )
                      }
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="[ row ]">
              {renderCard(elem.products, elem.type)}
            </div>
          </div>
        )}
      </>
    ));

    return (
      <>
        <div className="[ form-title ]">
          {STRINGS.MARKETPLACE.BODY.PERSONAL_BANKING}
        </div>
        {bankingList}
        {showHelp && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={STRINGS.MARKETPLACE.BODY.HELP_ME_CHOOSE_MORE_INFO_TITLE}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

ProductCardContainer.propTypes = {
  bankingProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  handleAddToVault: PropTypes.func,
  handleSeeDetailsClick: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  doStoreQuestionnaire: PropTypes.func,
  doStoreQuestionnaireProduct: PropTypes.func,
};

ProductCardContainer.defaultProps = {
  bankingProductList: [],
  vaultProductList: [],
  handleAddToVault: () => {},
  handleSeeDetailsClick: () => {},
  history: {},
  doStoreQuestionnaire: () => {},
  doStoreQuestionnaireProduct: () => {},
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreQuestionnaire: storeQuestionnaire,
      doStoreQuestionnaireProduct: storeQuestionnaireProduct,
    },
    dispatch
  );
};
const ProductCardContainerWrapper = withVaultHOC(ProductCardContainer);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCardContainerWrapper);
