import * as actionTypes from "Redux/Actions/actionTypes";

export const doGetApplicantList = (callback = () => {}) => ({
  type: actionTypes.GET_APPLICANT_LIST,
  callback,
});

export const storeApplicantDetails = (request) => {
  return {
    type: actionTypes.STORE_APPLICATION_PROGRESS_REQUEST,
    request,
  };
};
