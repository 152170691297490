import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetProvinceList from "Communication/ApiClasses/GetProvinceList";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

function* handleGetProvinceList() {
  yield put(doLoadingStart(STRINGS.ABOUTYOU.API_KEYS.GET_PROVINCE_LIST));
  const getProvinceList = new GetProvinceList();
  try {
    const result = yield call(getProvinceList.getData);
    yield put({
      type: actionTypes.GET_PROVINCE_LIST_SUCCESS,
      response: result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PROVINCE_LIST_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.GET_PROVINCE_LIST));
  }
}

function* getProvinceListWatcherSaga() {
  yield takeLatest(actionTypes.GET_PROVINCE_LIST, handleGetProvinceList);
}

export default getProvinceListWatcherSaga;
