import * as actionTypes from "Redux/Actions/actionTypes";
import AppConstants from "../../../Constants/AppConstants";

const initialState = {
  applicationId: "",
  debitCardProductId: "",
  success: "",
  error: "",
};

const OnlineBankingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_ONLINE_BANKING:
      return {
        ...state,
        success: true,
        error: "",
        data: action.response,
      };
    case actionTypes.SET_ONLINE_BANKING:
      sessionStorage.setItem(
        AppConstants.SESSION.OLB_APPLICATION_ID,
        action.data.applicationId
      );
      sessionStorage.setItem(
        AppConstants.SESSION.OLB_PRODUCT_ID,
        action.data.debitCardProductId
      );
      return {
        ...state,
        applicationId: action.data.applicationId,
        debitCardProductId: action.data.debitCardProductId,
        templateId: action.data.templateId,
      };
    default:
      return state;
  }
};

export default OnlineBankingReducer;
