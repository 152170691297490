import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import CreateEsignDocRequest from "Communication/ApiClasses/CreateEsignDocRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleCreateEsignDoc(action) {
  yield put(doLoadingStart(STRINGS.SIGNATURE.API_KEYS.ESIGN_CREATE));
  const createEsignDoc = new CreateEsignDocRequest(action.data.applicationId);
  try {
    const result = yield call(createEsignDoc.getData);
    yield put({
      type: actionTypes.ESIGN_CREATE_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ESIGN_CREATE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.SIGNATURE.API_KEYS.ESIGN_CREATE));
  }
}

// watcher
function* createEsignDocWatcherSaga() {
  yield takeLatest(actionTypes.ESIGN_CREATE, handleCreateEsignDoc);
}

export default createEsignDocWatcherSaga;
