import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requireBusinessType: false,
      selectedBusinessType: "",
      templateId: "",
      errors: {
        selectedBusinessTypeErrorList: [],
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_TYPE,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        selectedBusinessType: activeProduct?.selectedBusinessType,
        templateId: activeProduct?.templateId ?? "",
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { selectedBusinessType, templateId } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({
        applicant: {
          contact: {},
          business: {
            businessType: selectedBusinessType,
          },
        },
        vault: {
          selectedBusinessType,
          templateId,
        },
      });
    }
  };

  verifyData = () => {
    let formIsValid = true;

    const { selectedBusinessType, errors } = this.state;

    const selectedBusinessTypeRes = checkErrors(
      selectedBusinessType,
      VALIDATIONS.BUSINESS_TYPE.BUSINESS_TYPE
    );

    this.setState({
      errors: {
        ...errors,
        selectedBusinessTypeErrorList: selectedBusinessTypeRes,
      },
    });

    if (selectedBusinessTypeRes.length > 0) {
      formIsValid = false;
    }

    return formIsValid;
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_TYPE);
  };

  handleSelectType = (event) => {
    const { finInfo, activeProduct } = this.props;
    const templateId =
      finInfo?.config?.businessTypes?.find(
        (item) => item.code === event.target.value
      )?.template ?? activeProduct?.templateId;
    this.setState({
      selectedBusinessType: event.target.value,
      templateId: templateId,
    });
  };

  render() {
    const {
      steps,
      activeStepID,
      removeApplication,
      finInfo,
      activeProduct,
    } = this.props;
    const { selectedBusinessType, requireBusinessType, errors } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ business-type-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_TYPE.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BUSINESS_TYPE.FORM.BUSINESS_TYPE_CATEGORY_LABEL}
            </div>
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-12 ]">
                <div className="[ product-list ] ">
                  {finInfo?.config?.businessTypes?.map((type) => (
                    <>
                      <button
                        type="button"
                        className={
                          type.code === selectedBusinessType
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        disabled={type.code === selectedBusinessType}
                        value={type.code}
                        onClick={(event) => this.handleSelectType(event)}
                      >
                        {type.description}
                      </button>
                    </>
                  ))}
                </div>
                <div className="[ error-msg ]">
                  {errors?.selectedBusinessTypeErrorList[0]}
                </div>
              </div>
            </div>
            {selectedBusinessType === "6" && (
              <div className="[ drawer-content-container alert alert-warning ] ">
                <div className="[ row ]">
                  <div className="[ col-12 col-lg-12 ] [ mb-3 ]">
                    <span className="[ info-title ]">
                      <i className="[ fas fa-exclamation-circle ]" />
                      {
                        STRINGS.BUSINESS_TYPE.FORM
                          .YES_TO_OTHER_BUSINESS_TYPE_TITLE
                      }
                    </span>
                    <p>
                      {STRINGS.BUSINESS_TYPE.FORM.YES_TO_OTHER_BUSINESS_TYPE_BODY.split(
                        "_PRODUCT_"
                      ).join(activeProduct.productName)}
                    </p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          STRINGS.BUSINESS_TYPE.FORM
                            .YES_TO_OTHER_BUSINESS_TYPE_BODY1,
                      }}
                    />
                  </div>
                  <div className="[ col-12 ]">
                    <button
                      type="button"
                      className="[ cta__primary-action-call btn info-btn ]"
                      onClick={removeApplication}
                    >
                      {STRINGS.BUSINESS_TYPE.FORM.REMOVE_FROM_VAULT_AND_GO_BACK}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                disabled={selectedBusinessType === "6" ? true : false}
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessType.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessType.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessType;
