import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

import * as actionTypes from "Redux/Actions/actionTypes";
import GetContract from "../../Communication/ApiClasses/GetContract";

// worker
function* handleGetContract(action) {
  yield put(
    doLoadingStart(`${actionTypes.GET_CONTRACT}_SAGA`, action.waitMessage)
  );
  const getContractApi = new GetContract(
    action.applicationId,
    action.applicationType,
    action.productId
  );
  try {
    const result = yield call(getContractApi.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(`${actionTypes.GET_CONTRACT}_SAGA`));
  }
}

// watcher
export default function* getAccountWatcherSaga() {
  yield takeLatest(actionTypes.GET_CONTRACT, handleGetContract);
}
