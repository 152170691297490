import { call, put, takeLatest } from "redux-saga/effects";
import UpdateApplicationRequest from "Communication/ApiClasses/UpdateFormRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import SearchEscRequest from "../../Communication/ApiClasses/SearchEscRequest";

// worker
function* handleSearchEsc(action) {
  yield put(doLoadingStart(actionTypes.ESC_SEARCH));

  const searchEscRequest = new SearchEscRequest(action.data);
  try {
    const result = yield call(searchEscRequest.getData);
    yield put({
      type: `${actionTypes.ESC_SEARCH}_SUCCESS`,
      response: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.ESC_SEARCH}_ERROR`,
      error,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.ESC_SEARCH));
  }
}

// watcher
export default function* handleSearchEscSaga() {
  yield takeLatest(actionTypes.ESC_SEARCH, handleSearchEsc);
}
