import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import DeleteBeneficiaryRequest from "Communication/ApiClasses/DeleteBeneficiaryRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleDeleteBeneficiary(action) {
  try {
    yield put(
      doLoadingStart(STRINGS.BENEFICIARIES.API_KEYS.DELETE_BENEFICIARY)
    );
    const deleteBeneficiaryRequest = new DeleteBeneficiaryRequest([
      action.applicationId,
      action.contractProductId,
      action.beneficiaryId,
    ]);
    const result = yield call(deleteBeneficiaryRequest.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error);
  } finally {
    yield put(
      doLoadingFinish(STRINGS.BENEFICIARIES.API_KEYS.DELETE_BENEFICIARY)
    );
  }
}

// watcher
export default function* deleteBeneficiaryWatcherSaga() {
  yield takeLatest(actionTypes.DELETE_BENEFICIARY, handleDeleteBeneficiary);
}
