import * as actionTypes from "Redux/Actions/actionTypes";

export const doGetIdType = () => ({
  type: actionTypes.GET_ID_TYPE,
});

export const doVerifyInstntDocument = (documentData, callback) => ({
  type: actionTypes.INSTNT_DOC_VERIFY,
  data: documentData,
  callback,
});

export const doSubmitVerify = (docData, callback) => ({
  type: actionTypes.SUBMIT_VERIFY,
  data: docData,
  callback,
});

export const doCreateApplication = (requestData, callback = () => {}) => ({
  type: actionTypes.CREATE_APPLICATION,
  data: requestData,
  requestData,
  callback,
});

export const doGetProvinceList = () => ({
  type: actionTypes.GET_PROVINCE_LIST,
});

export const doAddressKey = (callback = () => {}) => ({
  type: actionTypes.CREATE_ADDRESS_KEY,
  callback,
});

export const doGetAddress = (params, callback = () => {}) => ({
  type: actionTypes.ADDRESS_CORRECTION,
  data: params,
  params,
  callback,
});

export const getVaultMember = (callback = () => {}) => ({
  type: actionTypes.GET_VAULT_MEMBER,
  callback,
});

export const updateVaultMember = (params, callback = () => {}) => ({
  type: actionTypes.UPDATE_VAULT_MEMBER,
  data: params,
  callback,
});
