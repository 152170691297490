const FIInformation = {
  FI_NAME: "Motor City Community Credit Union",
  FI_APPLICATION_NAME: "SmartOpen",
  FI_EMAIL: "https://www.mcccu.com/about-us/contact/send-secure-message",
  FI_CONDITIONS: "https://www.mcccu.com/privacy-and-security/legal",
  FI_PRIVACY: "https://www.mcccu.com/privacy-and-security",
  FI_SITE: "https://www.mcccu.com/about-us/contact",
  FI_OVERDRAFT_URL: "https://www.mcccu.com/member-services/service-fees",
  FI_BENEFICIARY_URL:
    "https://www.mcccu.com/tools-and-calculators/knowledge-centre/smartopen-beneficiary-info",
  FI_SHARE_AMOUNT: "$10",
  FI_OVERDRAFT_DEFAULT_AMOUNT: "$100.00",
  FI_BOOK_APPOINTMENT: "https://mcccu.coconutcalendar.com/service",
};

export default FIInformation;
