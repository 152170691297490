import React from "react";
import PropTypes from "prop-types";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import variables from "Styles/color.scss";

function Stepper(props) {
  const { steps, active, title } = props;

  const getStepValue = () => {
    let result = 50;

    if (active && steps && active !== 0 && steps.length !== 0) {
      result = Math.round((active / steps.length) * 100);
    } else {
      console.log("stepper active", active);
      console.log("stepper steps", steps);
    }

    return result;
  };

  const step = getStepValue();

  return (
    <>
      <div className="[ form-container ] [ d-flex ] [ align-items-center ] [ mobile-stepper-container ]">
        <div className="[ mobile-stepper ]">
          <CircularProgressbar
            value={step}
            text={`${step}%`}
            background
            styles={{
              text: {
                // Text color
                fill: variables.stepperTextColor,
              },
              path: {
                // Path color
                stroke: variables.stepperCompleted,
              },
              background: {
                fill: variables.stepperActive,
              },
            }}
          />
        </div>
        <div className="[ stepper-form-title ]">
          {title ? title : steps[active - 1]?.Name}
        </div>
      </div>
    </>
  );
}
Stepper.propTypes = {
  active: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

export default Stepper;
