import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import AppConstants from "Constants/AppConstants";
import options from "Utils/EmploymentAndIncomeUtilities";
import RangeSlider from "react-bootstrap-range-slider";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import { commaFormatted } from "../../Utils/CommonUtilities";

class GICInfo extends React.Component {
  fieldAreYouTaxResidentOfCanada = "areYouTaxResidentOfCanada";

  fieldAreYouTaxResidentOfOtherCountry = "areYouTaxResidentOfOtherCountry";

  fieldCountryName = "countryName";

  constructor(props) {
    super(props);
    this.state = {
      areYouTaxResidentOfCanada: false,
      errors: {
        intendedUseError: [],
        amountError: [],
      },
      amount: 0,
      term: 0,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.GIC_INFO, (response) => {
      console.log(response);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { amount, term, intendedUse } = this.props;
    if (amount !== prevProps.amount) {
      this.setState({ amount });
    }
    if (term !== prevProps.term) {
      this.setState({ term });
    }
    if (intendedUse !== prevProps.intendedUse) {
      this.setState({ intendedUse });
    }
  }

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { amount, term, intendedUse } = this.state;
    const isValid = this.verifyData();
    if (isValid) {
      handleContinue({ amount, term, intendedUse });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.GIC_INFO);
  };

  handleCorrespondenceBtn = (flag) => {
    this.setState({
      isPaperCorrespondence: flag,
    });
  };

  handleOverdraftBtn = (flag) => {
    this.setState({
      isOverdraftProtected: flag,
    });
  };

  getOptions = (taxResidentHome, taxResidentOther) => {
    const { finInfo } = this.props;
    const option = options(
      taxResidentHome || false,
      taxResidentOther || false,
      finInfo
    );
    this.setState({ countryOption: option });
  };

  showCountryListField = (countryName) => {
    const { countryOption } = this.state;
    const selectedOption = countryOption.find(
      (obj) => obj.taxResidentOtherName === countryName
    );

    const { showOtherTaxResidencyField } = selectedOption.taxResidencyResult;
    this.setState({ showOtherTaxResidencyField });
  };

  handleChoice = (name, choice) => {
    this.setState({ [name]: choice });

    const {
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfOtherCountry,
      errors,
    } = this.state;
    this.getOptions(
      name === this.fieldAreYouTaxResidentOfCanada
        ? choice
        : areYouTaxResidentOfCanada,
      name === this.fieldAreYouTaxResidentOfOtherCountry
        ? choice
        : areYouTaxResidentOfOtherCountry
    );
    if (name === this.fieldAreYouTaxResidentOfCanada) {
      if (choice) {
        this.setState(
          {
            errors: {
              ...errors,
              areYouTaxResidentOfCanadaErrorList: "",
              areYouTaxResidentOfOtherCountryErrorList: "",
              sinSsnNoneErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      } else {
        this.setState(
          {
            sin: "",
            errors: {
              ...errors,
              sinErrorList: "",
              areYouTaxResidentOfCanadaErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      }
    } else if (choice) {
      this.setState(
        {
          errors: {
            ...errors,
            areYouTaxResidentOfOtherCountryErrorList: "",
            sinSsnNoneErrorList: "",
          },
        },
        () => {
          const { formIsValid } = this.state;
          if (!formIsValid) this.verifyData();
        }
      );
    } else {
      this.setState(
        {
          countryName: "",
          ssn: "",
          errors: {
            ...errors,
            countryNameErrorList: "",
            ssnErrorList: "",
            areYouTaxResidentOfOtherCountryErrorList: "",
          },
        },
        () => {
          const { formIsValid } = this.state;
          if (!formIsValid) this.verifyData();
        }
      );
    }
  };

  verifyData = (isContinueClicked = false) => {
    const { errors, amount, intendedUse } = this.state;
    const { activeProduct } = this.props;
    let errorLength = 0;
    let formIsValid = true;

    console.log("validating amount", amount, activeProduct?.amount?.min);
    const amountError = checkErrors(amount, VALIDATIONS.GIC_INFO.amount);
    console.log(amountError);

    if (amountError.length === 0) {
      if (amount < parseFloat(activeProduct?.amount?.min)) {
        amountError.push(
          STRINGS.GIC_INFO.FORM.GIC_MIN_MSG +
            " $" +
            commaFormatted(activeProduct?.amount?.min)
        );
      } else if (amount > parseFloat(activeProduct?.amount?.max)) {
        amountError.push(
          STRINGS.GIC_INFO.FORM.GIC_MAX_MSG +
            " $" +
            commaFormatted(activeProduct?.amount?.max)
        );
      }
    }
    console.log(amountError);
    errorLength += amountError.length;

    // const intendedUseError = checkErrors(
    //   intendedUse,
    //   VALIDATIONS.GIC_INFO.intendedUse
    // );
    //
    // errorLength += intendedUseError.length;

    if (errorLength > 0) {
      formIsValid = false;
    }
    this.setState({
      formIsValid,
      errors: {
        ...errors,
        amountError,
        // intendedUseError,
      },
    });
    return formIsValid;
  };

  setGICAmountValue = (value) => {
    console.log("setGICAmountValue", value);
    const { errors } = this.state;
    this.setState({
      amount: parseFloat(value.split(",").join("")),
      errors: {
        ...errors,
        amountError: [],
      },
    });
  };

  setGICTermValue = (value) => {
    this.setState({
      term: value,
    });
  };

  setIntendedUse = (value) => {
    this.setState({
      intendedUse: value,
    });
  };

  render() {
    const { amount, term, intendedUse, intendedUseError, errors } = this.state;
    const { activeProduct, intendedUseList } = this.props;

    const { steps, activeStepID, editMode } = this.props;

    const actionComponent = (
      <div className="[ GIC-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.GIC_INFO.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ form-subtitle ]">
                {STRINGS.GIC_INFO.SUBTITLE}
              </div>

              <div className="[ mb-4 ]">
                <div className="[ form-label ]">
                  {STRINGS.GIC_INFO.FORM.AMOUNT}
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 col-lg-8 order-lg-first ]">
                        <div className="[ row ]">
                          <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ]">
                            $ {commaFormatted(activeProduct?.amount?.min)}
                          </div>
                          <div className="[ col-6 ]">
                            <RangeSlider
                              value={amount}
                              min={activeProduct?.amount?.min}
                              max={activeProduct?.amount?.max}
                              onChange={(e) =>
                                this.setGICAmountValue(e.target.value)
                              }
                              tooltipLabel={(currentValue) =>
                                `$ ${commaFormatted(currentValue)}`
                              }
                              tooltipPlacement="top"
                              step={activeProduct?.amount?.steps}
                              className="[ w-100 ]"
                            />
                          </div>
                          <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ]">
                            $ {commaFormatted(activeProduct?.amount?.max)}
                          </div>
                        </div>
                      </div>
                      <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ] [ pl-0 ]">
                        <div className="[ input-group ] [ loan-amount ]">
                          <div className="[ input-group-prepend ]">
                            <span className="[ input-group-text ]">$</span>
                          </div>
                          <NumberFormat
                            isNumericString
                            maxLength={
                              AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH
                            }
                            decimalScale={0}
                            type="text"
                            allowLeadingZeros={false}
                            allowNegative={false}
                            thousandSeparator
                            // prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                            className="[ form-control ] [ amount-active ]"
                            // placeholder={
                            //   STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                            // }
                            onChange={(e) =>
                              this.setGICAmountValue(e.target.value)
                            }
                            value={amount}
                          />
                        </div>
                        <div className="[ error-msg align-content-right ]">
                          {errors.amountError && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {errors.amountError[0]}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.GIC_INFO.FORM.TERM}
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ row ]">
                      {Array.isArray(activeProduct?.term) && (
                        <>
                          <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ]">
                            <input
                              type="text"
                              inputMode="numeric"
                              value={`${term} ${STRINGS.LOAN_DETAILS.FORM.MONTHS}`}
                              className="[ amount-inactive ]"
                              disabled
                            />
                          </div>
                          <div className="[ col-12 col-lg-8 order-lg-first ]">
                            <div className="[ row ]">
                              <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ]">
                                {activeProduct?.term.min}
                              </div>
                              <div className="[ col-6 ]">
                                <RangeSlider
                                  value={term}
                                  min={activeProduct?.term?.min}
                                  max={activeProduct?.term?.max}
                                  onChange={(e) =>
                                    this.setGICTermValue(e.target.value)
                                  }
                                  tooltipLabel={(currentValue) =>
                                    `${currentValue}`
                                  }
                                  tooltipPlacement="top"
                                  step={activeProduct?.term?.steps}
                                  className="[ w-100 ]"
                                />
                              </div>
                              <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ]">
                                {activeProduct?.term?.max}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {!Array.isArray(activeProduct?.term) && (
                        <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ]">
                          <input
                            type="text"
                            inputMode="numeric"
                            value={`${term} ${STRINGS.LOAN_DETAILS.FORM.MONTHS}`}
                            className="[ amount-inactive ]"
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.GIC_INFO.FORM.INTEREST}
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ row ]">
                      <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ]">
                        <input
                          disabled
                          value={`${activeProduct?.rate} %`}
                          type="text "
                          name="interestRate"
                          className="[ form-control ] [ rate ]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

GICInfo.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  editMode: PropTypes.bool,
  finInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

GICInfo.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  editMode: true,
  activeProduct: null,
};

export default GICInfo;
