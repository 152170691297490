import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Backdrop from "Components/Common/Backdrop/Backdrop";

const ProgressIndicator = (props) => {
  const { loading, message } = props;

  let spinner = null;
  if (loading.length > 0) {
    spinner = (
      <>
        <div className="[ progress-indicator-container ]">
          <div className="[ icon loading-icon fa-spin ]" />
          <span className="progress-message">{message || ""}</span>
        </div>
        <Backdrop show />
      </>
    );
  }
  return spinner;
};

ProgressIndicator.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.string,
};
ProgressIndicator.defaultProps = {
  loading: [],
  message: null,
};

const mapStateToProps = (state) => ({
  loading: state.ProgressIndicatorReducer.loading,
  message: state.ProgressIndicatorReducer.message,
});

export default connect(mapStateToProps)(ProgressIndicator);
