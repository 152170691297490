import React from "react";
import PropTypes from "prop-types";
import { getMonth, getYear, parse, sub, subYears } from "date-fns";
import DatePicker from "react-datepicker";
import STRINGS from "../../Constants/Strings";

const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => end - i);
};

const DateOfBirth = (props) => {
  const { dateOfBirth, setDOB, readOnly } = props;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let startDate = new Date();
  startDate = subYears(startDate, 100);
  let endDate = new Date();
  endDate = subYears(endDate, 12);
  const minDate = sub(new Date(), { years: 100 });
  const maxDate = sub(new Date(), { years: 12 });
  const years = range(getYear(minDate), getYear(maxDate));

  const dob = dateOfBirth ? parse(dateOfBirth, "yyyy-MM-dd", new Date()) : null;

  return (
    <DatePicker
      className="[ datepicker ]"
      dateFormat="yyyy-MM-dd"
      selected={dob}
      onChange={(date) => setDOB(date)}
      minDate={startDate}
      maxDate={endDate}
      readOnly={readOnly}
      placeholderText={`${STRINGS.DOBPLACEHOLDER}`}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div>
          <button
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {">"}
          </button>
        </div>
      )}
    />
  );
};

DateOfBirth.propTypes = {
  dateOfBirth: PropTypes.string,
  setDOB: PropTypes.func,
};

DateOfBirth.defaultProps = {
  dateOfBirth: null,
  setDOB: () => {},
};

export default DateOfBirth;
