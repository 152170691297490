import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddApplicantRequest from "Communication/ApiClasses/AddApplicantRequest";
import AppConstants from "../../../Constants/AppConstants";

//  worker
function* handleCreateInverite(action) {
  yield put(doLoadingStart(actionTypes.ADD_APPLICANT + AppConstants.SAGA_WAIT));
  const addApplicantRequest = new AddApplicantRequest(action.data);
  try {
    const result = yield call(addApplicantRequest.getData);
    yield call(action.callback, result);
  } finally {
    yield put(
      doLoadingFinish(actionTypes.ADD_APPLICANT + AppConstants.SAGA_WAIT)
    );
  }
}

//  watcher
export default function* createApiWatcherSaga() {
  yield takeLatest(actionTypes.ADD_APPLICANT, handleCreateInverite);
}
