import BaseApiRequest from "./BaseApiRequest";
import { CONSENT_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class TermsAndConditionsRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    creditCheck: "",
    marketing: "",
    communication: "",
  };

  constructor(termsDetail) {
    super();
    this.body = termsDetail;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return CONSENT_URL;
  };

  getBody = () => {
    return this.body;
  };
}
