import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class Disbursement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTypeButton: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    window.scrollTo(0, 0);
    getDataFromServer(AppConstants.APPLICATIONSTEP.DISBURSEMENT, (response) => {
      console.log(response);
      this.setFormData(response);
    });
  }

  setFormData = () => {};

  handleAcctSelection = (event) => {
    const { updateDisbursement, disbursement } = this.props;
    updateDisbursement({
      ...disbursement,
      selectedOtherAccount: event.target.value,
    });
  };

  handleComment = (event) => {
    const { updateDisbursement, disbursement } = this.props;
    updateDisbursement({
      ...disbursement,
      comment: event.target.value,
    });
  };

  handleSelectAccountType = (accountType, product) => {
    console.log(accountType, product);
    const {
      openInverite,
      disbursement,
      updateDisbursement,
      accountList,
    } = this.props;
    console.log("accountList", accountList);
    if (
      accountType === AppConstants.ACCOUNT_TYPE.OTHER &&
      (!accountList || accountList?.length === 0)
    ) {
      openInverite();
    } else {
      updateDisbursement({
        ...disbursement,
        ...product,
        accountType,
      });
    }
  };
  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue();
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.DISBURSEMENT);
  };

  render() {
    const {
      accountTypeButton,
      showModal,
      type,
      modal,
      toggleModal,
      handlePopupModalBtnClick,
    } = this.state;
    const {
      steps,
      activeStepID,
      accountList,
      disbursement,
      openInverite,
      productList,
      accountError,
      otherAccountError,
      iframeModal,
    } = this.props;
    console.log(steps, activeStepID);

    const accountTypeList = ["Chequing", "Savings"];

    const accountListComponent = accountList.map((account, index) => (
      <option value={account.account} className="[ dropdown__option ]">
        {account.account_description} {account.account}
      </option>
    ));
    const productListComponent = productList?.map((product, index) => (
      <button
        type="button"
        disabled={
          product.productCode === disbursement.accountType &&
          product.basicDetails.acctNo === disbursement.basicDetails.acctNo &&
          !iframeModal
            ? true
            : false
        }
        className="[ btn custom-radio-unselected ] [ logo ]"
        value="chequing"
        onClick={(event) =>
          this.handleSelectAccountType(product.productCode, product)
        }
      >
        <div className="[ row ]">
          <div className="[ col-12 ] [ account-label ]">
            <span>{product.productDescription}</span>
          </div>
          <div className="[ col-12 ] [ account-num ]">
            <span>
              ****{" "}
              {product.basicDetails?.acctNo?.substr(
                product.basicDetails.acctNo.length - 4
              )}
            </span>
          </div>
        </div>
      </button>
    ));
    const accountTypeButtonComponent = (
      <div className="[ mb-3 ]">
        <div className="[ row ]">
          <div className="[ col-xl-6 ] [ mb-3 ] [ local-disbursement-accounts ]">
            <div className="[ product-card-container ]">
              <div className="[ row ]">
                <div className="[ col-12 ] [ form-subtitle ]">
                  {STRINGS.DISBURSEMENT.LOCAL_ACCOUNTS_SUBTITLE}
                </div>
              </div>

              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-12 ]">
                  {productListComponent && (
                    <div className="[ product-list ] ">
                      {productListComponent}
                    </div>
                  )}
                  {(!productListComponent ||
                    productListComponent?.length === 0) && (
                    <span className="[ info-message ]">
                      You do not have any accounts with us yet.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="[ col-xl-6 ] [ mb-3 ] [ outside-disbursement-accounts ]">
            <div className="[ product-card-container ]">
              <div className="[ row ]">
                <div className="[ col-12 ] [ form-subtitle ]">
                  {STRINGS.DISBURSEMENT.OTHER_ACCOUNTS}
                </div>
              </div>

              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-12 ]">
                  <div className="[ product-list ] ">
                    <button
                      type="button"
                      disabled={
                        AppConstants.ACCOUNT_TYPE.OTHER ===
                        disbursement.accountType
                          ? true
                          : false
                      }
                      className="[ btn custom-radio-unselected ]"
                      value="otherFi"
                      onClick={(event) =>
                        this.handleSelectAccountType(
                          AppConstants.ACCOUNT_TYPE.OTHER,
                          {}
                        )
                      }
                    >
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <span className="[ account-label ]">
                            {STRINGS.DISBURSEMENT.OTHER_FINANCIAL_ACCOUNTS}
                          </span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            accountError.length > 0 ? "[ error-msg ]" : "[ error-msg ]"
          }
        >
          {accountError ? accountError[0] : ""}
        </div>
      </div>
    );

    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.DISBURSEMENT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.DISBURSEMENT.SUBTITLE}
          </div>

          {accountTypeButtonComponent}

          {disbursement?.accountType === AppConstants.ACCOUNT_TYPE.OTHER && (
            <div className="[ drawer-content-container ] [ mb-3 ]">
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ form-label ]">
                      {accountList?.length > 0 && <>{accountList[0].bank}</>}
                    </div>
                    {accountList?.length === 0 && (
                      <>{STRINGS.LOAN_DETAILS.FORM.NO_ACCOUNTS}</>
                    )}
                    {accountList?.length > 0 && (
                      <select
                        value={
                          disbursement.selectedOtherAccount
                            ? disbursement.selectedOtherAccount
                            : ""
                        }
                        className="[ form-control ] [ select__dropdown ]"
                        onChange={this.handleAcctSelection}
                        disabled={
                          accountTypeButton === accountTypeList[0] ||
                          accountTypeButton === accountTypeList[1]
                            ? true
                            : false
                        }
                      >
                        <option
                          value=""
                          className="[ default ] [ dropdown__option ]"
                        >
                          {STRINGS.DISBURSEMENT.FORM.ACCOUNT_DEFAULT_SELECT}
                        </option>
                        {accountListComponent}
                      </select>
                    )}
                    <div className="[ error-msg ]">
                      {otherAccountError ? otherAccountError[0] : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div
                      className="[ float-right ]"
                      role="button"
                      onClick={openInverite}
                    >
                      {STRINGS.DISBURSEMENT.FORM.CHANGE_FIN}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <div className="[ form-label ] ">
                  {STRINGS.DISBURSEMENT.FORM.COMMENTS_DESCRIPTION}
                </div>
                <textarea
                  className="form-control"
                  placeholder="Comment"
                  value={disbursement.comment}
                  onChange={this.handleComment}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

Disbursement.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.oneOfType([PropTypes.object]),
  activeStepID: PropTypes.number,
};

Disbursement.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default Disbursement;
