import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessDetails from "./BusinessDetails";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { HTTP_STATUS } from "../../Communication/Constants";
import { profileEsc } from "../../Redux/Actions/ApplicantAction";
import { updateVaultMember } from "../AboutYou/Actions/AboutYouActions";

class BusinessDetailsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      doProfileEsc,
      doUpdateApplicant,
      doGetApplicant,
      vaultMember,
      doUpdateVaultMember,
      doUpdateProductToVault,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (vaultMember?.orderId) {
      doProfileEsc({ orderId: vaultMember.orderId, retry: 1 }, (profileRes) => {
        console.log(profileRes);
        if (profileRes.status === HTTP_STATUS.OK) {
          if (profileRes.data.status === "Complete") {
            const updatedVaultProductList = products;
            const idx = updatedVaultProductList.findIndex(
              (x) => x === activeProduct
            );
            updatedVaultProductList[idx].parties = profileRes.data.parties;
            doUpdateProductToVault(updatedVaultProductList);
            const updateRequest = {
              member: {
                businessNumber: profileRes.data.entity?.businessNumber,
              },
              business: {
                incorporationNumber: profileRes.data.entity?.number,
              },
              contact: {},
            };
            doUpdateApplicant(
              updateRequest,
              activeProduct.applicantId,
              (res) => {
                console.log("update result", res);

                delete vaultMember.orderId;
                doUpdateVaultMember(vaultMember, () => {
                  doGetApplicant({ id: activeProduct.applicantId }, () => {
                    console.log("esc profile pushed");
                  });
                });
              }
            );
          }
        }
      });
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const { continuehandler, doUpdateApplicant, applicantData } = this.props;
    doUpdateApplicant(data.applicant, applicantData.id, (res) => {
      console.log(res);
      if (res.status === HTTP_STATUS.OK) {
        continuehandler(
          data.vault,
          AppConstants.APPLICATIONSTEP.BUSINESS_DETAILS
        );
      }
    });
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      applicantData,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <BusinessDetails
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessDetailsContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessDetailsContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  vaultMember: state.ApplicationReducer.vaultMember,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doProfileEsc: (payLoad, callback) =>
      dispatch(profileEsc(payLoad, callback)),
    doUpdateVaultMember: (member, callback) =>
      dispatch(updateVaultMember(member, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetailsContainer);

const BusinessDetailsWrapper = withBusinessHOC(BusinessDetailsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessDetailsWrapper);
