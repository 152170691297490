import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import "./EmploymentAndIncome.scss";
import formatStringByPattern from "format-string-by-pattern";
import { Typeahead } from "react-bootstrap-typeahead";
import MaskedInput from "react-text-mask";
import { LENDING_PRODUCT } from "../../Communication/Constants";
import { isNewMember, stepAlreadyProcessed } from "../../Utils/LogicUtilities";

class EmploymentAndIncome extends React.Component {
  constructor(props) {
    super(props);
    // inputfield error object name  should be inputobject with suffix 'Error'
    //  For example input object: username, input error object: usernameError
    this.searchField = React.createRef();
    this.state = {
      employmentStatus: "",
      occupationCode: "",
      employerName: "",
      employerContactNumber: "",
      monthlyGrossIncome: "",
      sourceOfIncome: "",
      errors: {
        employmentStatusError: "",
        occupationCodeError: "",
        employerPositionError: "",
        employerNameError: "",
        employerContactNumberError: "",
        monthlyGrossIncomeError: "",
        sourceOfIncomeError: "",
        employerPhoneAreaError: [],
        employerPhoneError: [],
      },
      formIsValid: true,
      employerPhoneSettings: {},
      occupationCodeSettings: {},
      employmentStatusSettings: {},
      employmentPositionSettings: {},
      sourceOfIncomeSettings: {},
      property: {
        streetType: "",
        unitNumber: "",
        streetNumber: "",
        street: "",
        city: "",
        province: "",
        postalCode: "",
      },
      employerLine1Error: [],
      cityError: [],
      provinceError: [],
      postalError: [],
      showSearchResult: false,
      addressSearchResult: [],
      executeAdressSearch: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    window.scrollTo(0, 0);
    this.checkPageSettings();

    getDataFromServer(AppConstants.APPLICATIONSTEP.EMPLOYMENT, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    const { finInfo } = this.props;
    if (finInfo !== prevProps.finInfo) {
      this.checkPageSettings();
    }
  }

  setFormData = (response) => {
    const { activeProduct, processFlow } = this.props;
    const { employment } = response.data;
    if (
      activeProduct.prospect?.id &&
      !stepAlreadyProcessed(
        AppConstants.APPLICATIONSTEP.EMPLOYMENT,
        activeProduct.applicationStep.index,
        processFlow
      )
    ) {
      if (
        activeProduct.prospect.employment?.employerSalary &&
        activeProduct.prospect.employment?.employerSalary !== "0"
      ) {
        employment.employerSalary =
          activeProduct.prospect.employment?.employerSalary;
      }
      if (activeProduct.prospect.employment?.employerPhoneArea) {
        employment.employerPhoneArea =
          activeProduct.prospect.employment?.employerPhoneArea;
        employment.employerPhone =
          activeProduct.prospect.employment?.employerPhone;
      }
      if (activeProduct.prospect.employment?.currentEmployer) {
        employment.currentEmployer =
          activeProduct.prospect.employment?.currentEmployer;
      }
      if (activeProduct.prospect.employment?.employmentStatus) {
        employment.employmentStatus =
          activeProduct.prospect.employment?.employmentStatus;
      }
      if (activeProduct.prospect.employment?.occupationCode) {
        employment.occupationCode =
          activeProduct.prospect.employment?.occupationCode;
      }
      if (activeProduct.prospect.employment?.employerPosition) {
        employment.employerPosition =
          activeProduct.prospect.employment?.employerPosition;
      }
      if (activeProduct.prospect.employment?.incomeType) {
        employment.incomeType = activeProduct.prospect.employment?.incomeType;
      }
      if (activeProduct.prospect.employment?.employerLine1) {
        employment.employerLine1 =
          activeProduct.prospect.employment?.employerLine1;
        employment.employerLine2 =
          activeProduct.prospect.employment?.employerLine2;
        employment.employerStreetName =
          activeProduct.prospect.employment?.employerStreetName;
        employment.employerCity =
          activeProduct.prospect.employment?.employerCity;
        employment.employerProv =
          activeProduct.prospect.employment?.employerProv;
        employment.employerPc = activeProduct.prospect.employment?.employerPc;
      }
    }
    this.setState({
      monthlyGrossIncome: `${parseFloat(employment.employerSalary)}`,
      employerContactNumber: formatStringByPattern(
        AppConstants.EMPLOYMENTANDINCOME.PHONEFORMAT,
        employment.employerPhoneArea + employment.employerPhone
      ),
      employerName: employment.currentEmployer,
      employmentStatus: employment.employmentStatus,
      occupationCode: employment.occupationCode,
      employerPosition: employment.employerPosition,
      sourceOfIncome: employment.incomeType,
      property: {
        employerLine1: employment.employerLine1,
        employerLine2: employment.employerLine2,
        street: employment.employerStreetName,
        city: employment.employerCity,
        province: employment.employerProv?.trim(),
        postalCode: employment.employerPc,
      },
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target.name, event?.target.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    const eventName = event?.target.name;
    let eventValue = event?.target.value;
    if (
      event.target.name ===
      STRINGS.EMPLOYMENT_AND_INCOME.FORM.MONTHLY_GROSS_INCOME_FIELDNAME
    ) {
      eventValue = eventValue.replace(
        AppConstants.EMPLOYMENTANDINCOME.SALARY_PATTERN,
        ""
      );
      if (eventValue === "") {
        eventValue = "0";
      }
      eventValue = `${parseFloat(eventValue)}`;
    }
    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  handleSubmit = () => {
    const { onSubmitHandler } = this.props;
    if (this.verifyData(true)) onSubmitHandler(this.state);
  };

  verifyData = () => {
    const {
      errors,
      employmentStatus,
      occupationCode,
      employerPosition,
      employerName,
      sourceOfIncome,
      employerContactNumber,
      monthlyGrossIncome,
      employerPhoneSettings,
      occupationCodeSettings,
      employmentStatusSettings,
      employmentPositionSettings,
      sourceOfIncomeSettings,
      property,
    } = this.state;
    const { finInfo, activeProduct } = this.props;
    console.log("verifyData", employmentStatusSettings, property);
    let errorLength = 0;
    let formIsValid = true;

    let employmentStatusError = [];
    let occupationCodeError = [];
    let employerPositionError = [];
    let employerNameError = [];
    let employerContactNumberError = [];
    let monthlyGrossIncomeError = [];
    let sourceOfIncomeError = [];
    let employerLine1Error = [];
    let cityError = [];
    let provinceError = [];
    let postalError = [];

    if (
      employmentStatusSettings.page === AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      employmentStatusError = checkErrors(
        employmentStatus,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.employmentStatus
      );
      errorLength += employmentStatusError.length;
    }

    if (
      occupationCodeSettings.page === AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      occupationCodeError = checkErrors(
        occupationCode,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.jobTitle
      );
      errorLength += occupationCodeError.length;
    }

    if (
      employmentPositionSettings.page ===
      AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      employerPositionError = checkErrors(
        employerPosition,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.jobTitle
      );
      errorLength += employerPositionError.length;
    }

    employerNameError = checkErrors(
      employerName,
      VALIDATIONS.EMPLOYMENT_AND_INCOME.employerName
    );
    errorLength += employerNameError.length;

    if (
      employerPhoneSettings.page === AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      employerContactNumberError = checkErrors(
        employerContactNumber,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.employerContactNumber
      );
      errorLength += employerContactNumberError.length;
    }

    if (finInfo.showEmployerAddress && activeProduct.type === LENDING_PRODUCT) {
      employerLine1Error = checkErrors(
        property.employerLine1 ? property.employerLine1 : "",
        VALIDATIONS.EMPLOYMENT_AND_INCOME.ADDRESS1
      );
      errorLength += employerLine1Error.length;

      cityError = checkErrors(
        property.city ? property.city : "",
        VALIDATIONS.LOANS.ASSET_PROPERTY.CITY
      );
      errorLength += cityError.length;

      provinceError = checkErrors(
        property.province ? property.province : "",
        VALIDATIONS.LOANS.ASSET_PROPERTY.PROVINCE
      );
      errorLength += provinceError.length;

      postalError = checkErrors(
        property.postalCode ? property.postalCode : "",
        VALIDATIONS.LOANS.ASSET_PROPERTY.POSTAL
      );
      errorLength += postalError.length;
    }

    monthlyGrossIncomeError = checkErrors(
      monthlyGrossIncome,
      VALIDATIONS.EMPLOYMENT_AND_INCOME.monthlyGrossIncome
    );
    errorLength += monthlyGrossIncomeError.length;

    if (
      sourceOfIncomeSettings.page === AppConstants.APPLICATIONSTEP.EMPLOYMENT
    ) {
      sourceOfIncomeError = checkErrors(
        sourceOfIncome,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.sourceOfIncome
      );
      errorLength += sourceOfIncomeError.length;
    }

    if (errorLength > 0) {
      formIsValid = false;
    }
    this.setState({
      formIsValid,
      employerLine1Error,
      cityError,
      provinceError,
      postalError,
      errors: {
        ...errors,
        employmentStatusError,
        occupationCodeError,
        employerPositionError,
        employerNameError,
        employerContactNumberError,
        monthlyGrossIncomeError,
        sourceOfIncomeError,
      },
    });
    return formIsValid;
  };

  handleChangeAddress = (value) => {
    console.log("handleChangeAddress", value);
    if (value && value.length > 0) {
      if (value[0].label === STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS) {
        this.searchField.current.clear();
      }
      if (value[0].AddressLine1) {
        const address = {
          label: value[0].label,
        };
        const selectedAddress = [];
        selectedAddress.push(address);
        this.setState({
          selectedAddress,
          showSearchResult: false,
          property: {
            employerLine1: value[0].AddressLine1,
            employerLine2: "",
            city: value[0].City,
            province: value[0].StateProvince,
            postalCode: value[0].PostalCode,
          },
        });
      } else {
        this.setState({
          showSearchResult: false,
          executeAdressSearch: false,
        });
      }
    }
  };

  processSearch = (response) => {
    const { executeAdressSearch, country, property } = this.state;
    console.log("doGetAddress", response);
    const addressSearchResult = [];
    const addressRes = response.data.Output;
    console.log("doGetAddress", addressRes, executeAdressSearch);
    if (addressRes && addressRes.length > 0 && executeAdressSearch) {
      addressRes.forEach((raw) => {
        if (raw.Status !== "F") {
          if (raw.Ranges && raw.Ranges.length > 0) {
            raw.Ranges.forEach((range) => {
              const address = {
                label: range.FormattedRangeAddress,
                AddressLine1: range.FormattedRangeAddress.substring(
                  0,
                  range.FormattedRangeAddress.indexOf(",")
                ),
                StateProvince: raw.StateProvince,
                PostalCode: raw.PostalCode,
                City: raw.City,
                ProcessedBy: raw.ProcessedBy,
                Country: raw.Country === "CAN" ? "Canada" : "United States",
                Unit:
                  range.Range?.indexOf("-") > -1
                    ? range.Range.split("-")[0]
                    : "",
              };
              if (range.UnitsInfo && range.UnitsInfo.length > 0) {
                range.UnitsInfo.forEach((unit) => {
                  addressSearchResult.push({
                    ...address,
                    label: unit.FormattedUnitAddress,
                    AddressLine1: unit.FormattedUnitAddress.substring(
                      0,
                      unit.FormattedUnitAddress.indexOf(",")
                    ),
                    Unit: unit.UnitInfo,
                  });
                });
              } else {
                addressSearchResult.push(address);
              }
            });
          } else {
            const address = {
              label: raw.FormattedAddress,
              AddressLine1: raw.FormattedAddress.substring(
                0,
                raw.FormattedAddress.indexOf(",")
              ),
              StateProvince: raw.StateProvince,
              PostalCode: raw.PostalCode,
              City: raw.City,
              ProcessedBy: raw.ProcessedBy,
              Country: raw.Country === "CAN" ? "Canada" : "United States",
              Unit: "",
            };
            addressSearchResult.push(address);
          }
        }
      });
    }
    const address = {
      label: STRINGS.ADDRESS_SEARCH.DONT_SEE_ADDRESS,
      AddressLine1: property.employerLine1,
      StateProvince: property.province,
      PostalCode: property.postalCode,
      City: property.city,
      Country: country,
    };
    addressSearchResult.push(address);
    console.log("addressSearchResult", addressSearchResult);
    this.setState({ addressSearchResult, showSearchResult: true });
  };

  doFindAddress = (value, event) => {
    const { doGetAddress, addressInstanceKey } = this.props;
    const eventValue = event?.target.value;
    console.log("doFindAddress", value, eventValue);
    this.setState({ selectedAddress: [] }, () => {
      if (value && value.length > 4) {
        const params = {
          InstanceKey: addressInstanceKey,
          AddressLine1: value,
          Country: "Canada",
        };

        this.setState({ executeAdressSearch: true }, () => {
          doGetAddress(params, (response) => {
            this.processSearch(response);
          });
        });
      }
    });
  };

  updateProperty = (prop, value) => {
    const { property } = this.state;
    property[prop] = value;
    this.setState({
      property,
    });
    if (prop === "employerLine1") {
      this.setState({ employerLine1Error: [] });
    }
    if (prop === "employerLine2") {
      this.setState({ streetNameError: [] });
    }
    if (prop === "city") {
      this.setState({ cityError: [] });
    }
    if (prop === "province") {
      this.setState({ provinceError: [] });
    }
    if (prop === "postalCode") {
      this.setState({ postalError: [] });
    }
  };

  checkPageSettings() {
    const { finInfo, activeProduct } = this.props;
    const employerPhoneSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "employerPhone"
    );
    const occupationCodeSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "occupationCode"
    );
    const employmentStatusSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "employmentStatus"
    );
    const employmentPositionSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "employmentPosition"
    );
    const sourceOfIncomeSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "sourceOfIncome"
    );
    if (activeProduct.type === LENDING_PRODUCT) {
      // always show for loan products
      this.setState({
        employerPhoneSettings: {
          field: "employerPhone",
          page: "EMPLOYMENT_AND_INCOME",
        },
        occupationCodeSettings,
        employmentStatusSettings: {
          field: "employmentStatus",
          page: "EMPLOYMENT_AND_INCOME",
        },
        sourceOfIncomeSettings: {
          field: "sourceOfIncome",
          page: "EMPLOYMENT_AND_INCOME",
        },
        employmentPositionSettings,
      });
    } else {
      this.setState({
        employerPhoneSettings,
        occupationCodeSettings,
        employmentStatusSettings,
        sourceOfIncomeSettings,
        employmentPositionSettings,
      });
    }
  }

  render() {
    const {
      employmentStatus,
      occupationCode,
      employerPosition,
      employerName,
      sourceOfIncome,
      employerContactNumber,
      monthlyGrossIncome,
      errors,
      employerPhoneSettings,
      occupationCodeSettings,
      employmentStatusSettings,
      employmentPositionSettings,
      sourceOfIncomeSettings,
      property,
      employerLine1Error,
      cityError,
      provinceError,
      postalError,
      showSearchResult,
      addressSearchResult,
    } = this.state;

    const {
      statusList,
      incomeSourcesList,
      handleBack,
      steps,
      activeStepID,
      occupationCodes,
      employerPositionCodes,
      provinceList,
      finInfo,
      activeProduct,
      applicantData,
    } = this.props;

    const selectProvinceList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const actionComponent = (
      <div className="[ employment-and-income ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.EMPLOYMENT_AND_INCOME.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-subtitle ]">
              {isNewMember(applicantData)
                ? STRINGS.EMPLOYMENT_AND_INCOME.SUBTITLE
                : STRINGS.EMPLOYMENT_AND_INCOME.SUBTITLE_MEMBER}
            </div>
          </div>

          {employmentStatusSettings?.page ===
            AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.EMPLOYMENT_STATUS}
              </div>
              <select
                className="[ form-control select__dropdown]"
                id="exampleFormControlSelect1"
                name="employmentStatus"
                value={employmentStatus}
                disabled={
                  !!activeProduct.prospect?.employment?.employmentStatus
                }
                placeholder={
                  STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                    .EMPLOYMENT_STATUS
                }
                onChange={this.handleChange}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {
                    STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                      .EMPLOYMENT_STATUS
                  }
                </option>
                {statusList && Array.isArray(statusList)
                  ? statusList.map((status) => (
                      <option
                        key={status.code}
                        value={status.code}
                        className="[ dropdown__option ]"
                      >
                        {status.description}
                      </option>
                    ))
                  : ""}
              </select>
              <div className="[ error-msg ]">
                {errors?.employmentStatusError && (
                  <>{errors?.employmentStatusError}</>
                )}
              </div>
            </div>
          )}

          {occupationCodeSettings?.page ===
            AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.OCCUPATION}
              </div>
              {occupationCodeSettings.isText && (
                <div>
                  <input
                    type="text"
                    name="occupationCode"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                        .OCCUPATION_TEXT
                    }
                    value={occupationCode}
                    disabled={
                      !!activeProduct.prospect?.employment?.occupationCode
                    }
                    onChange={this.handleChange}
                  />
                  <div className="[ occupation_dislaimer ]">
                    <small className="[ footnotes form-text text-muted ]">
                      {STRINGS.EMPLOYMENT_AND_INCOME.FORM.OCCUPATION_DISCLAIMER}
                    </small>
                  </div>
                </div>
              )}
              {!occupationCodeSettings.isText && (
                <select
                  className="[ form-control select__dropdown]"
                  id="occupationCode"
                  name="occupationCode"
                  value={occupationCode}
                  disabled={
                    !!activeProduct.prospect?.employment?.occupationCode
                  }
                  placeholder={
                    STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.OCCUPATION
                  }
                  onChange={this.handleChange}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.OCCUPATION}
                  </option>
                  {occupationCodes
                    ? occupationCodes.map((occupation) => (
                        <option
                          key={occupation.code}
                          value={occupation.code}
                          className="[ dropdown__option ]"
                        >
                          {occupation.description}
                        </option>
                      ))
                    : ""}
                </select>
              )}

              <div className="[ error-msg ]">
                {errors?.occupationCodeError && (
                  <>{errors?.occupationCodeError}</>
                )}
              </div>
            </div>
          )}

          {employmentPositionSettings?.page ===
            AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.POSITION}
              </div>
              {employmentPositionSettings.isText && (
                <>
                  <input
                    type="text"
                    name="employerPosition"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                        .POSITION_TEXT
                    }
                    value={employerPosition}
                    disabled={
                      !!activeProduct.prospect?.employment?.employerPosition
                    }
                    onChange={this.handleChange}
                  />
                  <div className="[ position_dislaimer ]">
                    <small className="[ footnotes form-text text-muted ]">
                      {STRINGS.EMPLOYMENT_AND_INCOME.FORM.POSITION_DISCLAIMER}
                    </small>
                  </div>
                </>
              )}
              {!employmentPositionSettings.isText && (
                <select
                  className="[ form-control select__dropdown]"
                  id="employerPosition"
                  name="employerPosition"
                  value={employerPosition}
                  disabled={
                    !!activeProduct.prospect?.employment?.employerPosition
                  }
                  placeholder={
                    STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.POSITION
                  }
                  onChange={this.handleChange}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.POSITION}
                  </option>
                  {employerPositionCodes
                    ? employerPositionCodes.map((position) => (
                        <option
                          key={position.code}
                          value={position.code}
                          className="[ dropdown__option ]"
                        >
                          {position.description}
                        </option>
                      ))
                    : ""}
                </select>
              )}
              <div className="[ error-msg ]">
                {errors?.employerPositionError && (
                  <>{errors?.employerPositionError}</>
                )}
              </div>
            </div>
          )}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.EMPLOYER_NAME}
            </div>
            <input
              type="text"
              name="employerName"
              className="[ form-control ]"
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.EMPLOYER_NAME
              }
              value={employerName}
              disabled={!!activeProduct.prospect?.employment?.currentEmployer}
              onChange={this.handleChange}
            />
            <div className="[ error-msg ]">
              {errors?.employerNameError && <>{errors?.employerNameError}</>}
            </div>
          </div>

          {employerPhoneSettings?.page ===
            AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.EMPLOYER_CONTACT_NO}
              </div>
              <NumberFormat
                format={AppConstants.EMPLOYMENTANDINCOME.NUMBERFORMAT}
                className="[ form-control ]"
                type="text"
                name="employerContactNumber"
                value={employerContactNumber}
                disabled={!!activeProduct.prospect?.employment?.employerPhone}
                placeholder={`${STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.EMPLOYER_CONTACT_NO}`}
                onChange={this.handleChange}
              />
              <div className="[ error-msg ]">
                {errors?.employerContactNumberError && (
                  <>{errors?.employerContactNumberError}</>
                )}
              </div>
            </div>
          )}

          {finInfo.showEmployerAddress &&
            activeProduct.type === LENDING_PRODUCT && (
              <>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="col-md-12">
                      <div className="[ form-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS0}
                      </div>
                      <>
                        <Typeahead
                          ref={this.searchField}
                          id="basic-typeahead-single"
                          className="[ address-suggestions ]"
                          clearButton={false}
                          onChange={this.handleChangeAddress}
                          onInputChange={this.doFindAddress}
                          options={addressSearchResult}
                          labelKey="label"
                          emptyLabel={STRINGS.ADDRESS_SEARCH.NO_RECORDS_FOUND}
                          open={showSearchResult}
                          filterBy={() => true}
                          disabled={
                            !!activeProduct.prospect?.employment?.employerLine1
                          }
                          onBlur={() =>
                            this.setState({
                              showSearchResult: false,
                            })
                          }
                        />
                        <div>
                          <small>{STRINGS.ADDRESS_SEARCH.SEARCH_MESSAGE}</small>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 col-lg-12 ]">
                      {/* St. NAME */}
                      <div className="[ form-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS1}
                      </div>
                      <input
                        type="text "
                        id="employerLine1"
                        className="[ form-control ]"
                        placeholder={
                          STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS1
                        }
                        value={property.employerLine1}
                        disabled={
                          !!activeProduct.prospect?.employment?.employerLine1
                        }
                        onChange={(e) =>
                          this.updateProperty("employerLine1", e.target.value)
                        }
                      />
                      <div className="[ error-msg ] ">
                        {employerLine1Error ? employerLine1Error[0] : ""}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ]">
                    <div className="[ col-12 col-lg-12 ]">
                      {/* St. No. */}
                      <div className="[ form-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS2}
                      </div>
                      <input
                        type="text "
                        id="employerLine2"
                        className="[ form-control ]"
                        placeholder={
                          STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS2
                        }
                        value={property.employerLine2}
                        disabled={
                          !!activeProduct.prospect?.employment?.employerLine1
                        }
                        onChange={(e) =>
                          this.updateProperty("employerLine2", e.target.value)
                        }
                      />
                      <div className="[ error-msg ]" />
                    </div>
                  </div>
                  <div className="[ row ]">
                    <div className="[ col-12 col-md-4 ]">
                      <div className="[ form-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.CITY}
                      </div>
                      <>
                        <input
                          type="text"
                          id="city"
                          className="[ form-control ]"
                          placeholder={STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                          value={property.city}
                          disabled={
                            !!activeProduct.prospect?.employment?.employerLine1
                          }
                          onChange={(e) =>
                            this.updateProperty("city", e.target.value)
                          }
                        />
                        <div className="[ error-msg ]">
                          {cityError ? cityError[0] : ""}
                        </div>
                      </>
                    </div>
                    <div className="[ col-12 col-md-4 ]">
                      <div className="[ form-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.PROVINCE}
                      </div>
                      <select
                        className="[ form-control ] [ select__dropdown ]"
                        id="province"
                        value={property.province}
                        disabled={
                          !!activeProduct.prospect?.employment?.employerLine1
                        }
                        onChange={(e) =>
                          this.updateProperty("province", e.target.value)
                        }
                      >
                        <option
                          value=""
                          className="[ default ] [ dropdown__option ]"
                        >
                          {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.PROVINCE}
                        </option>
                        {selectProvinceList}
                      </select>
                      <div className="[ error-msg ]">
                        {provinceError ? provinceError[0] : ""}
                      </div>
                    </div>

                    <div className="[ col-12 col-md-4 ]">
                      <div className="[ form-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.POSTALCODE}
                      </div>
                      <>
                        <MaskedInput
                          mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                          className="[ form-control ] [ postalCode ]"
                          placeholder={
                            STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.POSTALCODE
                          }
                          guide={false}
                          id="postalCode"
                          value={property.postalCode}
                          disabled={
                            !!activeProduct.prospect?.employment?.employerLine1
                          }
                          onChange={(e) =>
                            this.updateProperty("postalCode", e.target.value)
                          }
                        />
                      </>
                      <div className="[ error-msg ]">
                        {postalError ? postalError[0] : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.MONTHLY_GROSS_INCOME}
            </div>
            <NumberFormat
              maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
              type="text"
              thousandSeparator
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={0}
              prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
              name="monthlyGrossIncome"
              className="[ form-control ]"
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                  .MONTHLY_GROSS_INCOME
              }
              onChange={(event) => this.handleChange(event)}
              value={monthlyGrossIncome}
              disabled={
                !!activeProduct.prospect?.employment?.employerSalary &&
                activeProduct.prospect?.employment?.employerSalary !== "0"
              }
            />

            <div className="[ error-msg ]">
              {errors?.monthlyGrossIncomeError && (
                <>{errors?.monthlyGrossIncomeError}</>
              )}
            </div>
          </div>

          {sourceOfIncomeSettings?.page ===
            AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SOURCE_OF_INCOME}
              </div>
              <select
                className="[ form-control select__dropdown]"
                id="sourceOfIncome"
                name="sourceOfIncome"
                value={sourceOfIncome}
                disabled={!!activeProduct.prospect?.employment?.incomeType}
                onChange={this.handleChange}
                placeholder={
                  STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                    .EMPLOYMENT_STATUS
                }
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {
                    STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                      .SOURCE_OF_INCOME
                  }
                </option>
                {incomeSourcesList
                  ? incomeSourcesList.map((incomeSource) => (
                      <option
                        key={incomeSource.code}
                        value={incomeSource.code}
                        className="[ dropdown__option ]"
                      >
                        {incomeSource.description}
                      </option>
                    ))
                  : ""}
              </select>
              <div className="[ error-msg ]">
                {errors?.sourceOfIncomeError && (
                  <>{errors?.sourceOfIncomeError}</>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <AuthBody
        actionComponent={actionComponent}
        securityText={null}
        infoType="welcomeBack"
        memberCreation
        handleBack={handleBack}
      />
    );
  }
}

EmploymentAndIncome.propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.object).isRequired,
  incomeSourcesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  employerPositionCodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  addressInstanceKey: PropTypes.oneOfType([PropTypes.object]),
  doGetAddress: PropTypes.func.isRequired,
  activeProduct: PropTypes.oneOfType([PropTypes.object]),
  occupationCodes: PropTypes.arrayOf(PropTypes.object),
  provinceList: PropTypes.arrayOf(PropTypes.object),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};
EmploymentAndIncome.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  finInfo: {},
  addressInstanceKey: "",
  activeProduct: {},
  occupationCodes: [],
  provinceList: [],
  applicantData: null,
};
export default EmploymentAndIncome;
