import AddressApiRequest from "./AddressApiRequest";
import { METHOD } from "../Constants";
import { GET_ADDRESS_INSTANCE_KEY_URL } from "../ApiUrls";
import { formURL } from "Utils/CommonUtilities";

export default class CreateAddressInstanceKey extends AddressApiRequest {
  method = METHOD.HTTP.GET;

  url = GET_ADDRESS_INSTANCE_KEY_URL;

  body = {};
  headers = {};
  constructor() {
    super();
    this.headers["Authorization"] = "Basic RG94SW5jNjM1Nzo1fjdAWmczT09Pd1s5aGteJGA4akdwTjY=";
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };

  getHeader = () => {
    return this.headers;
  };
}
