import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import "./GettingStarted.scss";

class GettingStarted extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      branchArray: [],

      branchDropdown: "",
      rendered: true,
      accountStatementType: "",
      reasonCode: "",
      promoCode: "",
      referralCode: "",

      accountStatementTypeError: "",
      branchDropdownError: "",
      reasonError: "",
      promoCodeError: "",
      referralCodeError: "",
      reasonCodeSettings: {},
    };

    this.id = 0;
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    window.scrollTo(0, 0);
    this.prepareReasonCodeSettings();

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.GETTINGSTARTED,
      (response) => {
        console.log(response);
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      gettingStartedBranchState,
      gettingStartedResidentState,
      finInfo,
    } = this.props;
    const { rendered } = this.state;

    if (gettingStartedBranchState.jsonData.length > 0 && rendered) {
      this.updateArray(gettingStartedBranchState, gettingStartedResidentState);
    }

    if (finInfo !== prevProps.finInfo) {
      this.prepareReasonCodeSettings();
    }
  }

  getDefaultStatementType = () => {
    const { finInfo } = this.props;

    return finInfo.mailingDefault ? finInfo.mailingDefault : "";
  };

  setFormData = (formData) => {
    this.setState({
      branchDropdown: formData?.branch === undefined ? "" : formData.branch,
      accountStatementType:
        formData.accountStatementType === undefined
          ? this.getDefaultStatementType()
          : formData.accountStatementType,
      reasonCode: formData?.reasonCode ? formData.reasonCode : "",
      promoCode: formData?.promoCode ? formData.promoCode : "",
      referralCode: formData?.referralCode ? formData.referralCode : "",
    });
  };

  verifyData = () => {
    let valid = true;
    const { finInfo } = this.props;
    const {
      branchDropdown,
      accountStatementType,
      reasonCode,
      reasonCodeSettings,
      promoCode,
      referralCode,
    } = this.state;

    let error = checkErrors(
      branchDropdown,
      VALIDATIONS.GETTING_STARTED.selectBranch
    );
    if (error.length) {
      valid = false;
      this.setState({ branchDropdownError: error });
    }

    if (!finInfo.hideStatementSelection) {
      error = checkErrors(
        accountStatementType,
        VALIDATIONS.GETTING_STARTED.selectAccountStatementRequired
      );
      if (error.length) {
        valid = false;
        this.setState({ accountStatementTypeError: error });
      }
    }

    if (
      reasonCodeSettings?.page === AppConstants.APPLICATIONSTEP.GETTINGSTARTED
    ) {
      error = checkErrors(
        reasonCode,
        VALIDATIONS.GETTING_STARTED.selectReasonRequired
      );
      console.log("validation of reason code", error, reasonCode);
      if (error.length) {
        valid = false;
        this.setState({ reasonError: error });
      }
    }

    if (valid) {
      const { submitRequest } = this.props;
      let body = {
        branch: branchDropdown,
        accountStatementType,
        reasonCode: null,
        promoCode,
        referralCode,
      };
      if (
        reasonCodeSettings?.page === AppConstants.APPLICATIONSTEP.GETTINGSTARTED
      ) {
        body = { ...body, reasonCode };
      }
      submitRequest(body);
    }
  };

  // Update array from reducer result
  updateArray = (branchObject, residentObject) => {
    this.setState({
      branchArray: branchObject.jsonData,
      rendered: false,
    });
    if (branchObject.jsonData.length > 0 && residentObject.length > 0) {
      this.setState({ rendered: false });
    }
  };

  changeBranchHandler = (event) => {
    this.setState({
      branchDropdown: event.target.value,
      branchDropdownError: "",
    });
  };

  changeReasonHandler = (event) => {
    this.setState({
      reasonCode: event.target.value,
      reasonError: "",
    });
  };

  changePromoCodeHandler = (event) => {
    this.setState({
      promoCode: event.target.value,
      promoCodeError: "",
    });
  };

  changeReferralCodeHandler = (event) => {
    this.setState({
      referralCode: event.target.value,
      referralCodeError: "",
    });
  };

  handleChoice = (value) => {
    this.setState({
      accountStatementType: value,
      accountStatementTypeError: "",
    });
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  prepareReasonCodeSettings() {
    const { finInfo } = this.props;
    const reasonCodeSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "reasonOpened"
    );
    this.setState({
      reasonCodeSettings,
    });
  }

  render() {
    const {
      accountStatementType,
      branchDropdownError,
      reasonError,
      accountStatementTypeError,
      branchArray,
      branchDropdown,
      showModal,
      modalType,
      modal,
      reasonCode,
      reasonCodeSettings,
      promoCode,
      promoCodeError,
      referralCode,
      referralCodeError,
    } = this.state;
    const { reasonCodes, finInfo } = this.props;

    const optionList = branchArray.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const reasonCodeList = reasonCodes.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const { steps, activeStepID } = this.props;

    const gettingStartedComponent = (
      <div className="[ getting-started-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.GETTING_STARTED.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.GETTING_STARTED.SELECT_BRANCH}
            </div>
            <select
              value={branchDropdown}
              className={
                branchDropdownError && branchDropdownError.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.changeBranchHandler}
              id={STRINGS.GETTING_STARTED.BRANCH_DROPDOWN_ID}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {optionList}
            </select>
            <div
              className={
                branchDropdownError.length > 0
                  ? "[ error-msg ]"
                  : "[ error-msg ]"
              }
            >
              {branchDropdownError ? branchDropdownError[0] : ""}
            </div>
          </div>

          {!finInfo.hideStatementSelection && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {
                  STRINGS.GETTING_STARTED
                    .SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT
                }
              </div>

              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="submit"
                    className={
                      accountStatementType === "paper"
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="accountStatementType"
                    onClick={(event) => this.handleChoice("paper", event)}
                  >
                    {STRINGS.GETTING_STARTED.PAPER}
                  </button>
                </div>

                <div className="[ col-6 ]">
                  <button
                    type="submit"
                    className={
                      accountStatementType === "email"
                        ? "[ btn custom-radio-selected ] "
                        : "[ btn custom-radio-unselected ] "
                    }
                    name="accountStatementType"
                    onClick={(event) => this.handleChoice("email", event)}
                  >
                    {STRINGS.GETTING_STARTED.EMAIL}
                  </button>
                </div>
              </div>
              <div className="[ error-msg ]">{accountStatementTypeError}</div>
            </div>
          )}

          {reasonCodeSettings?.page ===
            AppConstants.APPLICATIONSTEP.GETTINGSTARTED && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.GETTING_STARTED.SELECT_REASON_OPENED}
              </div>
              <select
                value={reasonCode}
                className={
                  reasonError && reasonError.length > 0
                    ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                    : "[ form-control ] [ select__dropdown ]"
                }
                onChange={this.changeReasonHandler}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.DROPDOWN_INITIAL_TEXT}
                </option>
                {reasonCodeList}
              </select>
              <div
                className={
                  reasonError.length > 0 ? "[ error-msg ]" : "[ error-msg ]"
                }
              >
                {reasonError ? reasonError[0] : ""}
              </div>
            </div>
          )}

          {finInfo.showPromoCode && (
            <div className="[ mb-3 mt-5 ]">
              <div className="[ divider mb-3 ]"></div>
              <div className="[ alert alert-info ]" role="alert">
                <div className="[ form-label ]">
                  {STRINGS.GETTING_STARTED.PROMO_CODE}
                </div>
                <input
                  type="text"
                  name="promoCode"
                  className="[ form-control ]"
                  value={promoCode}
                  onChange={this.changePromoCodeHandler}
                  maxLength={20}
                />
                <div className="[ position_dislaimer ]">
                  <small className="[ footnotes form-text text-muted ]">
                    {STRINGS.GETTING_STARTED.FORM.PROMO_CODE}
                  </small>
                </div>
              </div>
            </div>
          )}

          {finInfo.showReferralCode && (
            <div className="[ mb-3 mt-5 ]">
              <div className="[ divider mb-3 ]"></div>
              <div className="[ alert alert-info ]" role="alert">
                <div className="[ form-label ]">
                  {STRINGS.GETTING_STARTED.REFERRAL_CODE}
                </div>
                <input
                  type="text"
                  name="referralCode"
                  className="[ form-control ]"
                  value={referralCode}
                  onChange={this.changeReferralCodeHandler}
                  maxLength={20}
                />
                <div className="[ position_dislaimer ]">
                  <small className="[ footnotes form-text text-muted ]">
                    {STRINGS.GETTING_STARTED.FORM.REFERRAL_CODE}
                  </small>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-xl-6 ] [ offset-xl-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={gettingStartedComponent}
          memberCreation
          showNavigationBar={false}
        />
      </>
    );
  }
}
GettingStarted.propTypes = {
  submitRequest: PropTypes.func,
  gettingStartedBranchState: PropTypes.objectOf(PropTypes.any),
  gettingStartedResidentState: PropTypes.objectOf(PropTypes.any),
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  reasonCodes: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
};
GettingStarted.defaultProps = {
  submitRequest: () => {},
  gettingStartedBranchState: {},
  gettingStartedResidentState: {},
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  reasonCodes: [],
  finInfo: {},
};

const mapStateToProps = (state) => ({
  gettingStartedBranchState: state.GettingStartedBranchReducer,
  gettingStartedResidentState: state.GettingStartedResidentReducer,
  reasonCodes: state.ApplicationReducer.reasonCodes,
  finInfo: state.MarketplaceReducer.finInfo,
});

export default connect(mapStateToProps)(GettingStarted);
