import * as actionTypes from "Redux/Actions/actionTypes";

export const getProductList = (callback = () => {}) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST,
    callback,
  };
};

export const updateVaultProductList = (productList) => {
  return {
    type: actionTypes.UPDATE_VAULT_PRODUCT_LIST,
    data: productList,
  };
};

export const updateProductToVault = (productList, callback = () => {}) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_TO_VAULT,
    data: productList,
    callback,
  };
};

export const getProductFromVault = (callback = () => {}) => {
  return {
    type: actionTypes.GET_PRODUCT_FROM_VAULT,
    callback,
  };
};

export const storeFilterProduct = (productList, categoryList) => {
  return {
    type: actionTypes.FILTER_PRODUCT,
    productList,
    categoryList,
  };
};

export const updateActiveTab = (activeTab) => {
  return {
    type: actionTypes.UPDATE_ACTIVE_TAB,
    activeTab,
  };
};

export const getFIInfo = (callback = () => {}) => {
  return {
    type: actionTypes.GET_FI_INFO,
    callback,
  };
};

export const supportMessage = (message) => {
  return {
    type: actionTypes.SUPPORT_MESSAGE,
    data: message,
  };
};

export const storeFilterBankingType = (bankingType) => {
  return {
    type: actionTypes.FILTER_BANKING_TYPE,
    bankingType,
  };
};
