import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { LOAN_ADD_ASSET_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class AddLoanAsset extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = LOAN_ADD_ASSET_URL;

  constructor(applicantId, finAnalysisId, payLoad) {
    super();
    this.body = payLoad;
    const paramList = [applicantId, finAnalysisId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
