import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import { toggleEditFlag } from "Redux/Actions/ApplicationAction";

import BusinessIndividual from "./BusinessIndividual";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import PopupModal from "../Common/PopupModal/PopupModal";
import STRINGS from "../../Constants/Strings";
import { doCreateApplication } from "../AboutYou/Actions/AboutYouActions";
import { getProductIdInActiveProduct } from "../../Utils/LogicUtilities";
import { HTTP_STATUS } from "../../Communication/Constants";
import getBundleProductRelation from "../../Redux/Actions/GetBundleProductRelationAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import { VAULT_DASHBOARD } from "../../Constants/Routes";

class BusinessIndividualContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const { continuehandler } = this.props;
    continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_INDIVIDUAL);
  };

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      processTemplate,
      applicantData,
    } = this.props;
    const { showModal, modal, type } = this.state;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={this.handlePopupModalBtnClick}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <BusinessIndividual
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          handleEditPage={this.handleEditPage}
          activeProduct={activeProduct}
          finInfo={finInfo}
          processTemplate={processTemplate}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessIndividualContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doToggleEdit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessIndividualContainer.defaultProps = {
  history: {},
  steps: [],
  activeStepID: -1,
  doToggleEdit: () => {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  applicantData: state.ApplicationReducer.response,
  processTemplate: state.ApplicationReducer.processTemplate,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
    doCreateNewApplicant: (request, callback) =>
      dispatch(doCreateApplication(request, callback)),
    doGetBundleProductRelation: (activeProduct, callback) =>
      dispatch(getBundleProductRelation(activeProduct, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessIndividualContainer);

const BusinessReviewWrapper = withBusinessHOC(BusinessIndividualContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessReviewWrapper);
