import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { LOAN_ADD_REAL_ESTATE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class AddLoanRealEstate extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = LOAN_ADD_REAL_ESTATE_URL;

  constructor(applicationId, payLoad) {
    super();
    this.body = payLoad;
    const paramList = [applicationId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
