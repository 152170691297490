import React from "react";
import PropTypes from "prop-types";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import AppConstants from "Constants/AppConstants";
import Stepper from "Components/Common/Stepper/Stepper";
import STRINGS from "Constants/Strings";
import VALIDATIONS from "Constants/ValidationList";
import { HTTP_STATUS } from "Communication/Constants";
import checkErrors from "Utils/InputValidator";

import ConfirmPopupModal from "Components/Common/ConfirmPopupModal/ConfirmPopupModal";
import PopupModal from "Components/Common/PopupModal/PopupModal";

import BeneficiaryDetails from "./BeneficiaryDetails";
import "./Beneficiaries.scss";

class Beneficiaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiary: "",
      beneficiaryError: "",
      beneficiariesArray: [{ ...AppConstants.BENEFICIARY }],
      confirmShowModal: false,
      confirmModal: {},
      isEditBeneficiary: false,
      showAnotherButton: false,
      showContinueandAddanotherButton: true,
      yesClicked: true,
      renderDetails: false,
      confirmBeneficiaryDeleteShowModal: false,
      confirmBeneficiaryModal: {},
      isPrimaryPresent: true,
      isOnlyOnePrimaryType: false,
      confirmAddEditBeneficiaryShowModal: false,
      confirmAddEditBeneficiaryModal: {},
      addressInstanceKey: "",
      isNotHundredPercent: false,
      isNotHundredPercentMessage: "",
    };
  }

  componentDidMount() {
    const {
      getDataFromServer,
      doGetBeneficiaryTypeList,
      doGetProvinceData,
      doGetCountryList,
      doGetBeneficiaryRelationList,
      doAddressKey,
    } = this.props;
    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BENEFICIARIES,
      (response) => {
        this.setFormData(response);
      }
    );
    doGetCountryList();
    doGetProvinceData();
    doGetBeneficiaryTypeList();
    doGetBeneficiaryRelationList();

    doAddressKey((response) => {
      let addressInstanceKey = "";
      if (response.data.Output && response.data.Output.length > 0) {
        addressInstanceKey = response.data.Output[0].InstanceKey;
      }
      this.setState({ addressInstanceKey: addressInstanceKey });
    });
  }

  setFormData = (response) => {
    if (response && response.length !== 0) {
      const beneficiariesArray = [...response];
      const updatedBeneficiariesArray = beneficiariesArray.map((item) => {
        const beneficiary = { ...item };
        const formatedMobile =
          `(${beneficiary.homeArea})` +
          ` ${beneficiary.homePhone.substring(
            0,
            3
          )}-${beneficiary.homePhone.substring(
            beneficiary.homeArea.length,
            beneficiary.homePhone.length
          )}`;

        beneficiary.errors = { ...AppConstants.BENEFICIARY.errors };
        beneficiary.editMode = false;
        beneficiary.showEditAndDeletBtn = true;
        beneficiary.showSaveAndCancelBtn = false;
        beneficiary.mobileNo = formatedMobile;
        return beneficiary;
      });
      this.setState({
        beneficiary: true,
        beneficiariesArray: updatedBeneficiariesArray,
        renderDetails: true,
        showAnotherButton: true,
        showContinueandAddanotherButton: true,
      });
    } else {
      this.setState({
        beneficiary: false,
        showAnotherButton: false,
        showContinueandAddanotherButton: true,
      });
    }
  };

  // handle beneficiary click
  handleBeneficiaryChoiceClick = (value) => {
    if (value) {
      const { beneficiariesArray } = this.state;
      const newBeneficiary = { ...AppConstants.BENEFICIARY };
      if (
        beneficiariesArray.length === 1 &&
        beneficiariesArray[0].beneficiaryId === ""
      ) {
        beneficiariesArray[0] = newBeneficiary;
      }
      if (beneficiariesArray.length === 0) {
        beneficiariesArray[0] = newBeneficiary;
      }
      this.setState({
        beneficiary: value,
        yesClicked: true,
        renderDetails: true,
        beneficiariesArray,
        showContinueandAddanotherButton: false,
        showAnotherButton: false,
        isOnlyOnePrimaryType: false,
        isPrimaryPresent: true,
        isNotHundredPercent: false,
        isNotHundredPercentMessage: "",
      });
    } else {
      const { beneficiariesArray } = this.state;
      if (
        beneficiariesArray &&
        beneficiariesArray.length !== 0 &&
        beneficiariesArray[0].beneficiaryId !== ""
      ) {
        const modal = {
          title: STRINGS.BENEFICIARIES.FORM.DELETE_TITLE,
          description: STRINGS.BENEFICIARIES.FORM.DELETE_DESCRIPTION,
        };
        this.setState({
          beneficiariesArray,
          confirmShowModal: true,
          confirmModal: modal,
          showAnotherButton: false,
          yesClicked: false,
          renderDetails: true,
          beneficiary: true,
          showContinueandAddanotherButton: true,
          isOnlyOnePrimaryType: false,
          isPrimaryPresent: true,
          isNotHundredPercent: false,
          isNotHundredPercentMessage: "",
        });
      } else {
        this.setState({
          yesClicked: false,
          renderDetails: false,
          beneficiary: false,
          showContinueandAddanotherButton: true,
          isOnlyOnePrimaryType: false,
          isPrimaryPresent: true,
          isNotHundredPercent: false,
          isNotHundredPercentMessage: "",
        });
      }
    }
  };

  verifyBeneficiary = (beneficiary) => {
    console.log("verifyBeneficiary", beneficiary);
    let valid = true;
    const { beneficiariesArray } = this.state;
    const updatedBeneficiariesArray = [...beneficiariesArray];
    const {
      firstName,
      lastName,
      dateOfBirth,
      beneficiaryRelation,
      beneficiaryType,
      address1,
      city,
      province,
      postalCode,
      mobileNo,
      sin,
      country,
      errors,
      beneficiaryId,
      editMode,
      percentEntitlement,
    } = beneficiary;
    if (beneficiaryId === "" || editMode) {
      let errorList = { ...errors };
      const firstNameErrorList = checkErrors(
        firstName,
        VALIDATIONS.BENEFICIARIES.FIRSTNAME
      );
      errorList = { ...errorList, firstNameErrorList };
      if (firstNameErrorList && firstNameErrorList.length > 0) {
        valid = false;
      }

      const lastNameErrorList = checkErrors(
        lastName,
        VALIDATIONS.BENEFICIARIES.LASTNAME
      );
      errorList = { ...errorList, lastNameErrorList };
      if (lastNameErrorList && lastNameErrorList.length > 0) {
        valid = false;
      }

      const dateOfBirthErrorList = checkErrors(
        dateOfBirth,
        VALIDATIONS.BENEFICIARIES.DOB
      );
      errorList = { ...errorList, dateOfBirthErrorList };
      if (dateOfBirthErrorList && dateOfBirthErrorList.length > 0) {
        valid = false;
      }

      const beneficiaryRelationErrorList = checkErrors(
        beneficiaryRelation,
        VALIDATIONS.BENEFICIARIES.RELATION
      );
      errorList = { ...errorList, beneficiaryRelationErrorList };
      if (
        beneficiaryRelationErrorList &&
        beneficiaryRelationErrorList.length > 0
      ) {
        valid = false;
      }

      const beneficiaryTypeErrorList = checkErrors(
        beneficiaryType,
        VALIDATIONS.BENEFICIARIES.BENEFICIARY_TYPE
      );
      errorList = { ...errorList, beneficiaryTypeErrorList };
      if (beneficiaryTypeErrorList && beneficiaryTypeErrorList.length > 0) {
        valid = false;
      }

      const percentEntitlementErrorList = checkErrors(
        percentEntitlement,
        VALIDATIONS.BENEFICIARIES.PERCENTAGE
      );
      console.log("percentEntitlementErrorList", percentEntitlement);
      errorList = { ...errorList, percentEntitlementErrorList };
      if (
        percentEntitlementErrorList &&
        percentEntitlementErrorList.length > 0
      ) {
        valid = false;
      }

      const address1ErrorList = checkErrors(
        address1,
        VALIDATIONS.BENEFICIARIES.ADDRESS
      );
      errorList = { ...errorList, address1ErrorList };
      if (address1ErrorList && address1ErrorList.length > 0) {
        valid = false;
      }

      const cityErrorList = checkErrors(city, VALIDATIONS.BENEFICIARIES.CITY);
      errorList = { ...errorList, cityErrorList };
      if (cityErrorList && cityErrorList.length > 0) {
        valid = false;
      }

      const provinceErrorList = checkErrors(
        province,
        VALIDATIONS.BENEFICIARIES.PROVINCE
      );
      errorList = { ...errorList, provinceErrorList };
      if (provinceErrorList && provinceErrorList.length > 0) {
        valid = false;
      }

      const postalCodeErrorList = checkErrors(
        postalCode,
        VALIDATIONS.BENEFICIARIES.CA_POSTALCODE
      );
      errorList = { ...errorList, postalCodeErrorList };
      if (postalCodeErrorList && postalCodeErrorList.length > 0) {
        valid = false;
      }
      const mobileNoErrorList = checkErrors(
        mobileNo,
        VALIDATIONS.BENEFICIARIES.MOBILE_NO
      );
      errorList = { ...errorList, mobileNoErrorList };
      if (mobileNoErrorList && mobileNoErrorList.length > 0) {
        valid = false;
      }

      const sinErrorList = checkErrors(
        sin.replace(/-/g, ""),
        VALIDATIONS.BENEFICIARIES.SIN
      );

      errorList = { ...errorList, sinErrorList };
      if (sinErrorList && sinErrorList.length > 0) {
        valid = false;
      }

      const countryErrorList = checkErrors(
        country,
        VALIDATIONS.BENEFICIARIES.COUNTRY
      );
      errorList = { ...errorList, countryErrorList };
      if (countryErrorList && countryErrorList.length > 0) {
        valid = false;
      }

      if (Object.keys(errorList).length > 0) {
        const index = this.getBenficiaryIndex(beneficiariesArray, beneficiary);
        updatedBeneficiariesArray[index].errors = errorList;
      }
    }
    this.setState({
      beneficiariesArray: updatedBeneficiariesArray,
    });
    return valid;
  };

  verifyData = () => {
    let valid = true;
    const { beneficiary } = this.state;

    const error = checkErrors(
      beneficiary,
      VALIDATIONS.BENEFICIARIES.BENEFICIARY
    );

    if (error.length) {
      valid = false;
      this.setState({ beneficiaryError: error });
    }
    return valid;
  };

  handleEditClick = (beneficiary) => {
    const { beneficiariesArray } = this.state;
    const updatedBeneficiariesArray = [...beneficiariesArray];
    const beneficiaryIndex = this.getBenficiaryIndex(
      beneficiariesArray,
      beneficiary
    );
    updatedBeneficiariesArray[
      beneficiaryIndex
    ].editMode = !beneficiary.editMode;
    updatedBeneficiariesArray[
      beneficiaryIndex
    ].showSaveAndCancelBtn = !beneficiary.showSaveAndCancelBtn;
    this.setState({
      beneficiariesArray: updatedBeneficiariesArray,
      isEditBeneficiary: true,
      isOnlyOnePrimaryType: false,
      isPrimaryPresent: true,
      isNotHundredPercent: false,
      isNotHundredPercentMessage: "",
    });
  };

  validateOnContinueClick = () => {
    const { beneficiariesArray, beneficiary } = this.state;

    if (!beneficiary) {
      return true;
    }
    if (this.verifyData()) {
      const {
        doAddBeneficiaries,
        getActiveProduct,
        vaultProductList,
        doGetBeneficiaries,
      } = this.props;
      const activeProduct = getActiveProduct(vaultProductList);
      const newBeneficiary = {
        ...beneficiariesArray[beneficiariesArray.length - 1],
      };

      if (newBeneficiary.beneficiaryId === "") {
        if (this.verifyBeneficiary(newBeneficiary)) {
          const requestBody = newBeneficiary;
          doAddBeneficiaries(
            requestBody,
            activeProduct.applicationId,
            activeProduct.contractProductId,
            (response) => {
              if (response.status === HTTP_STATUS.OK) {
                doGetBeneficiaries(
                  activeProduct.applicationId,
                  activeProduct.contractProductId,
                  (getBeneficiariesDataRes) => {
                    if (getBeneficiariesDataRes.status === HTTP_STATUS.OK) {
                      const updatedBeneficiariesArray = getBeneficiariesDataRes.data.map(
                        (item) => {
                          const prevBeneficiary = { ...item };
                          prevBeneficiary.errors = {
                            ...AppConstants.BENEFICIARY.errors,
                          };
                          prevBeneficiary.editMode = false;
                          prevBeneficiary.showEditAndDeletBtn = true;
                          prevBeneficiary.showSaveAndCancelBtn = false;
                          return prevBeneficiary;
                        }
                      );
                      updatedBeneficiariesArray.push({
                        ...AppConstants.BENEFICIARY,
                      });
                      this.setState({
                        beneficiariesArray: updatedBeneficiariesArray,
                      });
                    }
                  }
                );
              }
            }
          );
        }
      } else {
        return false;
      }
    }
    return true;
  };

  saveBeneficiary = (beneficiary) => {
    const { isEditBeneficiary, beneficiariesArray } = this.state;
    if (isEditBeneficiary) {
      const {
        doEditBeneficiary,
        doGetBeneficiaries,
        getActiveProduct,
        vaultProductList,
      } = this.props;

      const updatedBeneficiariesArray = [...beneficiariesArray];
      if (this.verifyBeneficiary(beneficiary)) {
        const unformatedMobile = beneficiary.mobileNo.replace(/\(|\)|-| /g, "");

        const homeArea = unformatedMobile.substring(0, 3);

        const homePhone = unformatedMobile.substring(
          homeArea.length,
          unformatedMobile.length
        );
        const sin = beneficiary.sin.replace(/-/g, "");

        const request = { ...beneficiary, homeArea, homePhone, sin };
        delete request.errors;
        delete request.editMode;
        delete request.showEditAndDeletBtn;
        delete request.showSaveAndCancelBtn;
        delete request.mobileNo;
        const activeProduct = getActiveProduct(vaultProductList);
        doEditBeneficiary(
          request,
          activeProduct.applicationId,
          activeProduct.contractProductId,
          beneficiary.beneficiaryId,
          (editBeneficiariesDataRes) => {
            if (editBeneficiariesDataRes.status === HTTP_STATUS.OK) {
              doGetBeneficiaries(
                activeProduct.applicationId,
                activeProduct.contractProductId,
                (getBeneficiariesDataRes) => {
                  if (getBeneficiariesDataRes.status === HTTP_STATUS.OK) {
                    const newBeneficiaryIndex = this.getBenficiaryIndex(
                      getBeneficiariesDataRes.data,
                      beneficiary
                    );

                    const newBeneficiary = {
                      ...getBeneficiariesDataRes.data[newBeneficiaryIndex],
                    };

                    const oldBeneficiaryIndex = this.getBenficiaryIndex(
                      beneficiariesArray,
                      beneficiary
                    );

                    const oldBeneficiary = {
                      ...beneficiariesArray[oldBeneficiaryIndex],
                    };

                    updatedBeneficiariesArray[oldBeneficiaryIndex] = {
                      ...oldBeneficiary,
                      ...newBeneficiary,
                      editMode: false,
                      showSaveAndCancelBtn: false,
                    };

                    const modal = {
                      title:
                        STRINGS.BENEFICIARIES.FORM.UPDATE_BENEFICIARY_TITLE,
                      description:
                        STRINGS.BENEFICIARIES.FORM
                          .UPDATE_BENEFICIARY_DESCRIPTION,
                    };

                    this.setState({
                      confirmAddEditBeneficiaryShowModal: true,
                      confirmAddEditBeneficiaryModal: modal,
                      beneficiariesArray: updatedBeneficiariesArray,
                      isEditBeneficiary: false,
                      showContinueandAddanotherButton: true,
                      showAnotherButton: true,
                    });
                  }
                }
              );
            }
          }
        );
      }
    } else {
      const {
        doAddBeneficiaries,
        getActiveProduct,
        vaultProductList,
        doGetBeneficiaries,
      } = this.props;

      const activeProduct = getActiveProduct(vaultProductList);
      const newBeneficiary = {
        ...beneficiariesArray[beneficiariesArray.length - 1],
      };
      if (newBeneficiary.beneficiaryId === "") {
        if (this.verifyBeneficiary(newBeneficiary)) {
          const unformatedMobile = beneficiary.mobileNo.replace(
            /\(|\)|-| /g,
            ""
          );

          const homeArea = unformatedMobile.substring(0, 3);

          const homePhone = unformatedMobile.substring(
            homeArea.length,
            unformatedMobile.length
          );
          const sin = beneficiary.sin.replace(/-/g, "");
          const requestBody = {
            ...newBeneficiary,
            homeArea,
            homePhone,
            sin,
          };
          delete requestBody.mobileNo;
          delete requestBody.errors;
          delete requestBody.showEditAndDeletBtn;
          delete requestBody.showSaveAndCancelBtn;
          delete requestBody.editMode;

          doAddBeneficiaries(
            requestBody,
            activeProduct.applicationId,
            activeProduct.contractProductId,
            (response) => {
              if (response.status === HTTP_STATUS.OK) {
                doGetBeneficiaries(
                  activeProduct.applicationId,
                  activeProduct.contractProductId,
                  (getBeneficiariesDataRes) => {
                    if (getBeneficiariesDataRes.status === HTTP_STATUS.OK) {
                      const updatedBeneficiariesArray = getBeneficiariesDataRes.data.map(
                        (item) => {
                          const editBeneficiary = { ...item };
                          editBeneficiary.errors = {
                            ...AppConstants.BENEFICIARY.errors,
                          };
                          editBeneficiary.editMode = false;
                          editBeneficiary.showEditAndDeletBtn = true;
                          editBeneficiary.showSaveAndCancelBtn = false;

                          const formatedMobile =
                            `(${editBeneficiary.homeArea})` +
                            ` ${editBeneficiary.homePhone.substring(
                              0,
                              3
                            )}-${editBeneficiary.homePhone.substring(
                              editBeneficiary.homeArea.length,
                              editBeneficiary.homePhone.length
                            )}`;
                          editBeneficiary.mobileNo = formatedMobile;
                          return editBeneficiary;
                        }
                      );

                      const modal = {
                        title: STRINGS.BENEFICIARIES.FORM.ADD_BENEFICIARY_TITLE,
                        description:
                          STRINGS.BENEFICIARIES.FORM
                            .ADD_BENEFICIARY_DESCRIPTION,
                      };

                      this.setState({
                        confirmAddEditBeneficiaryShowModal: true,
                        confirmAddEditBeneficiaryModal: modal,
                        beneficiariesArray: updatedBeneficiariesArray,
                        showContinueandAddanotherButton: true,
                        showAnotherButton: true,
                      });
                    }
                  }
                );
              }
            }
          );
        }
      } else {
        const updatedBeneficiariesArray = [...beneficiariesArray];
        updatedBeneficiariesArray.push({ ...AppConstants.BENEFICIARY });
        this.setState({ beneficiariesArray: updatedBeneficiariesArray });
      }
    }
  };

  onContinueClick = () => {
    console.log("onContinueClick");
    const { beneficiariesArray, beneficiary } = this.state;
    const { continuehandler, finInfo } = this.props;
    console.log("onContinueClick", beneficiariesArray, beneficiary);
    if (!beneficiary) {
      continuehandler({}, AppConstants.APPLICATIONSTEP.BENEFICIARIES);
      return;
    }
    if (
      beneficiariesArray.length === 1 &&
      beneficiariesArray[0].beneficiaryId === ""
    ) {
      this.setState({ isPrimaryPresent: true });
      return;
    }
    if (beneficiariesArray.length > 0) {
      const primaryExist = beneficiariesArray.find((obj) => {
        return obj.beneficiaryType === finInfo.primaryBeneficiaryType;
      });
      if (primaryExist) {
        let totalPercentPrimary = 0;
        beneficiariesArray.forEach((b) => {
          if (b.beneficiaryType === finInfo.primaryBeneficiaryType) {
            totalPercentPrimary += b.percentEntitlement;
          }
        });
        if (totalPercentPrimary !== 100) {
          this.setState({
            isNotHundredPercent: true,
            isNotHundredPercentMessage:
              STRINGS.BENEFICIARIES.ERROR.NOT_HUNDRED_PERCENT_PRIMARY,
          });
          return;
        }
      }

      const alternateExist = beneficiariesArray.find((obj) => {
        return obj.beneficiaryType !== finInfo.primaryBeneficiaryType;
      });
      if (alternateExist) {
        let totalPercentAlternate = 0;
        beneficiariesArray.forEach((b) => {
          if (b.beneficiaryType !== finInfo.primaryBeneficiaryType) {
            totalPercentAlternate += b.percentEntitlement;
          }
        });
        if (totalPercentAlternate !== 100) {
          this.setState({
            isNotHundredPercent: true,
            isNotHundredPercentMessage:
              STRINGS.BENEFICIARIES.ERROR.NOT_HUNDRED_PERCENT_ALTERNATE,
          });
          return;
        }
      }
    }
    if (beneficiariesArray.length > 0 || this.validateOnContinueClick()) {
      this.setState({ isPrimaryPresent: false }, () => {
        beneficiariesArray.forEach((keys) => {
          if (keys.beneficiaryType === finInfo.primaryBeneficiaryType) {
            continuehandler({}, AppConstants.APPLICATIONSTEP.BENEFICIARIES);
            this.setState({ isPrimaryPresent: true });
          }
        });
      });
    }
  };

  clearClick = () => {
    const { beneficiariesArray } = this.state;
    const newBeneficiary = {
      ...beneficiariesArray[beneficiariesArray.length - 1],
    };
    if (newBeneficiary.beneficiaryId === "") {
      newBeneficiary.errors = { ...AppConstants.BENEFICIARY.errors };
      const updatedBeneficiariesArray = [...beneficiariesArray];
      updatedBeneficiariesArray[
        updatedBeneficiariesArray.length - 1
      ] = newBeneficiary;
      this.setState({ beneficiariesArray: updatedBeneficiariesArray });
    }
  };

  checkIsEmpty = (testBeneficiary) => {
    if (testBeneficiary.firstName === "") {
      return true;
    }
    if (testBeneficiary.lastName === "") {
      return true;
    }
    if (testBeneficiary.dateOfBirth === "") {
      return true;
    }
    if (testBeneficiary.beneficiaryRelation === "") {
      return true;
    }
    if (testBeneficiary.beneficiaryType === "") {
      return true;
    }
    if (testBeneficiary.address1 === "") {
      return true;
    }
    if (testBeneficiary.city === "") {
      return true;
    }
    if (testBeneficiary.province === "") {
      return true;
    }
    if (testBeneficiary.postalCode === "") {
      return true;
    }
    if (testBeneficiary.country === "") {
      return true;
    }
    if (testBeneficiary.sin === "") {
      return true;
    }
    return false;
  };

  addAnotherClick = () => {
    const { beneficiariesArray, yesClicked, isEditBeneficiary } = this.state;
    if (
      (!yesClicked &&
        beneficiariesArray.length === 1 &&
        this.checkIsEmpty(beneficiariesArray[0])) ||
      (beneficiariesArray.length === 1 &&
        this.checkIsEmpty(beneficiariesArray[0])) ||
      beneficiariesArray[beneficiariesArray.length - 1].beneficiaryId === "" ||
      isEditBeneficiary
    ) {
      return;
    }

    const updatedBeneficiariesArray = [...beneficiariesArray];

    if (
      updatedBeneficiariesArray.length >= 1 &&
      updatedBeneficiariesArray.firstName !== ""
    ) {
      const updatedBeneficiariesArray1 = updatedBeneficiariesArray.map(
        (item) => {
          const beneficiary = { ...item };
          beneficiary.showSaveAndCancelBtn = false;
          return beneficiary;
        }
      );
      updatedBeneficiariesArray1.push({ ...AppConstants.BENEFICIARY });
      this.setState({
        beneficiariesArray: updatedBeneficiariesArray1,
        showContinueandAddanotherButton: false,
        showAnotherButton: false,
        renderDetails: true,
        isPrimaryPresent: true,
        isNotHundredPercent: false,
      });
      return;
    }

    updatedBeneficiariesArray.push({ ...AppConstants.BENEFICIARY });
    this.setState({
      beneficiariesArray: updatedBeneficiariesArray,
      showContinueandAddanotherButton: true,
      showAnotherButton: false,
      renderDetails: true,
      isPrimaryPresent: true,
    });
  };

  handleSaveClick = (beneficiary) => {
    this.saveBeneficiary(beneficiary);
  };

  handleCancelClick = (beneficiary) => {
    const { isEditBeneficiary, beneficiariesArray } = this.state;
    if (isEditBeneficiary) {
      const {
        doGetBeneficiaries,
        getActiveProduct,
        vaultProductList,
      } = this.props;

      const updatedBeneficiariesArray = [...beneficiariesArray];
      const activeProduct = getActiveProduct(vaultProductList);
      this.setState({
        isOnlyOnePrimaryType: false,
      });
      doGetBeneficiaries(
        activeProduct.applicationId,
        activeProduct.contractProductId,
        (getBeneficiariesDataRes) => {
          if (getBeneficiariesDataRes.status === HTTP_STATUS.OK) {
            const newBeneficiaryIndex = this.getBenficiaryIndex(
              getBeneficiariesDataRes.data,
              beneficiary
            );

            const newBeneficiary = {
              ...getBeneficiariesDataRes.data[newBeneficiaryIndex],
            };

            const oldBeneficiaryIndex = this.getBenficiaryIndex(
              beneficiariesArray,
              beneficiary
            );

            const oldBeneficiary = {
              ...beneficiariesArray[oldBeneficiaryIndex],
            };

            updatedBeneficiariesArray[oldBeneficiaryIndex] = {
              ...oldBeneficiary,
              ...newBeneficiary,
              editMode: false,
              showSaveAndCancelBtn: false,
            };

            this.setState({
              beneficiariesArray: updatedBeneficiariesArray,
              isEditBeneficiary: false,
              showContinueandAddanotherButton: true,
              showAnotherButton: true,
              renderDetails: true,
              isOnlyOnePrimaryType: false,
              isPrimaryPresent: true,
            });
          }
        }
      );
    } else {
      const totalbeneficiary = beneficiariesArray.length;
      if (beneficiariesArray.length === 0) {
        this.setState({
          beneficiariesArray,
          isEditBeneficiary: false,
          showContinueandAddanotherButton: true,
          showAnotherButton: true,
          renderDetails: false,
          beneficiary: false,
          isOnlyOnePrimaryType: false,
          isPrimaryPresent: true,
        });
        return;
      }
      let beneficiariesNewArray = beneficiariesArray;
      if (beneficiariesArray[totalbeneficiary - 1].beneficiaryId === "") {
        beneficiariesArray.pop();
        beneficiariesNewArray = beneficiariesArray;
      }
      if (beneficiariesNewArray.length === 0) {
        this.setState({ beneficiary: false });
      }
      if (beneficiariesNewArray.length > 0) {
        this.setState({
          beneficiariesArray: beneficiariesNewArray,
          isEditBeneficiary: false,
          showContinueandAddanotherButton: true,
          showAnotherButton: true,
          renderDetails: true,
          isOnlyOnePrimaryType: false,
        });
      } else {
        this.setState({
          beneficiariesArray: beneficiariesNewArray,
          isEditBeneficiary: false,
          showContinueandAddanotherButton: true,
          showAnotherButton: true,
          renderDetails: false,
          isOnlyOnePrimaryType: false,
        });
      }
    }
  };

  onChangeHandler = (beneficiary) => {
    console.log("onChangeHandler", beneficiary);
    const { beneficiariesArray } = this.state;
    const updatedBeneficiariesArray = [...beneficiariesArray];
    const beneficiaryIndex = this.getBenficiaryIndex(
      beneficiariesArray,
      beneficiary
    );

    updatedBeneficiariesArray[beneficiaryIndex] = beneficiary;

    this.setState({ beneficiariesArray: updatedBeneficiariesArray });
  };

  handleDeleteSingleBeneficiary = (beneficiary) => {
    const { beneficiariesArray } = this.state;
    const updatedBeneficiariesArray = beneficiariesArray.filter((item) => {
      return item.beneficiaryId !== beneficiary.beneficiaryId;
    });

    if (beneficiary.beneficiaryId) {
      const {
        doDeleteBeneficiary,
        getActiveProduct,
        vaultProductList,
      } = this.props;
      const activeProduct = getActiveProduct(vaultProductList);
      doDeleteBeneficiary(
        activeProduct.applicationId,
        activeProduct.contractProductId,
        beneficiary.beneficiaryId,
        (getDeleteBeneficiaryRes) => {
          if (getDeleteBeneficiaryRes.status === HTTP_STATUS.OK) {
            const modal = {
              title: STRINGS.BENEFICIARIES.FORM.DELETE_BENEFICIARY_TITLE,
              description:
                STRINGS.BENEFICIARIES.FORM.DELETE_BENEFICIARY_DESCRIPTION,
            };

            this.setState({
              beneficiary: !!updatedBeneficiariesArray.length,
              beneficiariesArray: updatedBeneficiariesArray,
              confirmAddEditBeneficiaryShowModal: true,
              confirmAddEditBeneficiaryModal: modal,
            });
          }
        }
      );
    } else {
      this.setState({ beneficiariesArray: updatedBeneficiariesArray });
    }
  };

  handleDeleteBeneficiary = (beneficiary, single = true) => {
    if (single) {
      const modal = {
        title: STRINGS.BENEFICIARIES.FORM.DELETE_TITLE,
        description: STRINGS.BENEFICIARIES.FORM.DELETE_SINGLE_DESCRIPTION,
      };
      this.setState({
        confirmBeneficiaryDeleteShowModal: true,
        confirmBeneficiaryModal: modal,
        renderDetails: true,
        beneficiary,
      });
      return;
    }
    this.handleDeleteSingleBeneficiary(beneficiary);
  };

  getBenficiaryIndex = (beneficiariesArray, beneficiary) => {
    const index = beneficiariesArray.findIndex(
      (item) => item.beneficiaryId === beneficiary.beneficiaryId
    );
    return index;
  };

  /**
   * Handled Yes clicked event of delete product popup
   */

  handleYesClick = () => {
    const { beneficiariesArray } = this.state;
    // eslint-disable-next-line array-callback-return
    beneficiariesArray.map((beneficiary) => {
      this.handleDeleteBeneficiary(beneficiary, false);
    });
    const updateBeneficiariesArray = [{ ...AppConstants.BENEFICIARY }];
    this.setState({
      beneficiary: false,
      beneficiariesArray: updateBeneficiariesArray,
    });
  };

  /**
   * Handled No clicked event of  delete product popup
   */
  handleNoClick = () => {};

  confirmToggleModal = () => {
    const { confirmShowModal } = this.state;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: {},
    });
  };

  /**
   * Handled Yes clicked event of delete product popup
   */

  handleBeneficiaryDeleteYesClick = () => {
    const { beneficiary } = this.state;

    this.handleDeleteSingleBeneficiary(beneficiary);

    // const updateBeneficiariesArray = [{ ...AppConstants.BENEFICIARY }];
    // this.setState({
    //   beneficiary: false,
    //   beneficiariesArray: updateBeneficiariesArray,
    // });
  };

  handleBeneficiaryDeleteNoClick = () => {};

  confirmBeneficiaryDeleteToggleModal = () => {
    const { confirmBeneficiaryDeleteShowModal } = this.state;
    this.setState({
      confirmBeneficiaryDeleteShowModal: !confirmBeneficiaryDeleteShowModal,
      confirmBeneficiaryModal: {},
    });
  };

  showSaveAndCancelBtnBeneficiary = (beneficiaryDetails) => {
    let isshow = false;
    if (beneficiaryDetails.firstName === "") {
      isshow = true;
    }
    return isshow;
  };

  notHundredPercentTooggle = () => {
    const { isNotHundredPercent } = this.state;
    this.setState({
      isNotHundredPercent: !isNotHundredPercent,
    });
  };

  confirmAddEditBeneficiaryToggleModal = () => {
    const { confirmAddEditBeneficiaryShowModal } = this.state;
    this.setState({
      confirmAddEditBeneficiaryShowModal: !confirmAddEditBeneficiaryShowModal,
      confirmAddEditBeneficiaryModal: {},
    });
  };

  handleAddEditBeneficiaryYesClick = () => {};

  handleAddEditBeneficiaryNoClick = () => {};

  render() {
    const {
      beneficiary,
      beneficiariesArray,
      beneficiaryError,
      confirmShowModal,
      confirmModal,
      renderDetails,
      isEditBeneficiary,
      confirmBeneficiaryDeleteShowModal,
      confirmBeneficiaryModal,
      confirmAddEditBeneficiaryShowModal,
      confirmAddEditBeneficiaryModal,
      addressInstanceKey,
      showContinueandAddanotherButton,
      showAnotherButton,
      isPrimaryPresent,
      isOnlyOnePrimaryType,
      isNotHundredPercent,
      isNotHundredPercentMessage,
    } = this.state;
    const {
      steps,
      activeStepID,
      handleBack,
      provinceList,
      countryList,
      beneficiaryTypeList,
      beneficiaryRelationList,
      doGetAddress,
    } = this.props;

    const cardComponent = (
      <div className="[ drawer-content-container mb-3 ]">
        <div className="[ row ]">
          {beneficiariesArray.length === 0 ? (
            <div className="[ col-12 ]">
              <BeneficiaryDetails
                key={0}
                beneficiary={beneficiariesArray[0]}
                index={0}
                onInputChangeHandler={this.onChangeHandler}
                provinceList={provinceList}
                countryList={countryList}
                beneficiaryRelationList={beneficiaryRelationList}
                beneficiaryTypeList={beneficiaryTypeList}
                handleDeleteBeneficiary={this.handleDeleteBeneficiary}
                handleEditClick={this.handleEditClick}
                handleSaveClick={this.handleSaveClick}
                handleCancelClick={this.handleCancelClick}
                showSaveAndCancelBtn={() => {
                  this.showSaveAndCancelBtnBeneficiary(beneficiariesArray[0]);
                }}
                renderDetails={renderDetails}
                isEditBeneficiary={isEditBeneficiary}
                doGetAddress={doGetAddress}
                addressInstanceKey={addressInstanceKey}
              />
            </div>
          ) : (
            <div className="[ col-12 ]">
              {beneficiariesArray.map((beneficiaryDetails, index) => (
                <BeneficiaryDetails
                  key={beneficiaryDetails.beneficiaryId}
                  beneficiary={beneficiaryDetails}
                  index={index}
                  onInputChangeHandler={this.onChangeHandler}
                  provinceList={provinceList}
                  countryList={countryList}
                  beneficiaryRelationList={beneficiaryRelationList}
                  beneficiaryTypeList={beneficiaryTypeList}
                  handleDeleteBeneficiary={this.handleDeleteBeneficiary}
                  handleEditClick={this.handleEditClick}
                  handleSaveClick={this.handleSaveClick}
                  handleCancelClick={this.handleCancelClick}
                  showSaveAndCancelBtn={() => {
                    this.showSaveAndCancelBtnBeneficiary(beneficiaryDetails);
                  }}
                  renderDetails={renderDetails}
                  isEditBeneficiary={isEditBeneficiary}
                  doGetAddress={doGetAddress}
                  addressInstanceKey={addressInstanceKey}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
    const beneficiariesComponent = (
      <div className="[ beneficiary-container ]">
        <Stepper steps={steps} active={activeStepID} />
        <div className="[ form-title ]">{STRINGS.BENEFICIARIES.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.BENEFICIARIES_PURPOSE_LABEL}
            </div>
          </div>
          <div className="[ row ] [ custom-radios ]">
            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  beneficiary !== "" && beneficiary
                    ? "[ btn custom-radio-selected ] [ w-100 ]"
                    : "[ btn custom-radio-unselected ] [ w-100 ]"
                }
                onClick={() => {
                  this.handleBeneficiaryChoiceClick(true);
                }}
              >
                {STRINGS.COMMON.CHOICE.YES}
              </button>
            </div>
            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  beneficiary !== "" && !beneficiary
                    ? "[ btn custom-radio-selected ] [ w-100 ]"
                    : "[ btn custom-radio-unselected ] [ w-100 ]"
                }
                onClick={() => {
                  this.handleBeneficiaryChoiceClick(false);
                }}
              >
                {STRINGS.COMMON.CHOICE.NO}
              </button>
            </div>
          </div>
          <div
            className={
              beneficiary === ""
                ? "[ error-msg ]"
                : "[ error-msg ] [ invisible ]"
            }
          >
            {beneficiaryError[0]}
          </div>

          {beneficiary && cardComponent}
          <div className="[ error-msg-height ]">
            {!isPrimaryPresent ? (
              <div
                className={
                  isPrimaryPresent === false
                    ? "[ error-msg ]"
                    : "[ error-msg ] [ invisible ]"
                }
              >
                {STRINGS.BENEFICIARIES.ERROR.PRIMARY_REQUIRED}
              </div>
            ) : (
              ""
            )}
            {isOnlyOnePrimaryType ? (
              <div
                className={
                  isOnlyOnePrimaryType
                    ? "[ error-msg ]"
                    : "[ error-msg ] [ invisible ]"
                }
              >
                {STRINGS.BENEFICIARIES.ERROR.ONLY_ONE_PRIMARY_REQUIRED}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="[ row ] [ px-3 py-2 ]">
            {beneficiary !== "" && beneficiary && (
              <div className="[ col-xl-4 col-md-6 col-sm-6 col-10 ] [ offset-xl-0 offset-md-3 offset-sm-3 offset-1 ] [ px-xl-0 pl-md-4 pr-md-2 py-2 ]">
                <button
                  type="button"
                  className={
                    !showContinueandAddanotherButton
                      ? "[ btn ]  [ w-100 ] [ add-another-submit-btn ] "
                      : "[ btn ]  [ w-100 ] [ submit-btn ] "
                  }
                  onClick={this.addAnotherClick}
                  disabled={
                    (!showContinueandAddanotherButton && !showAnotherButton) ||
                    isEditBeneficiary
                  }
                >
                  {STRINGS.BENEFICIARIES.FORM.ADD_ANOTHER}
                </button>
              </div>
            )}
            {beneficiary !== "" && beneficiary && (
              <div className="[ mb-3 ] [ alert alert-warning ]" role="alert">
                <div className="[ row ] [ mb-3 ]">
                  <div className="[ col-12 ]">
                    <i className="[ fas fa-exclamation-circle ]"></i>
                    <span>
                      <strong>
                        {STRINGS.BENEFICIARIES.PRIVACY_DISCLAIMER_TITLE}
                      </strong>
                    </span>
                    <p>{STRINGS.BENEFICIARIES.PRIVACY_DISCLAIMER}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.onContinueClick}
                disabled={
                  (!showContinueandAddanotherButton && !showAnotherButton) ||
                  isEditBeneficiary
                }
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        {confirmShowModal && (
          <ConfirmPopupModal
            title={confirmModal.title}
            description={confirmModal.description}
            toggleModal={this.confirmToggleModal}
            showModal={confirmShowModal}
            noBtnClick={this.handleNoClick}
            yesBtnClick={this.handleYesClick}
            closeBtnClick={this.handleNoClick}
          />
        )}
        {confirmBeneficiaryDeleteShowModal && (
          <ConfirmPopupModal
            title={confirmBeneficiaryModal.title}
            description={confirmBeneficiaryModal.description}
            toggleModal={this.confirmBeneficiaryDeleteToggleModal}
            showModal={confirmBeneficiaryDeleteShowModal}
            noBtnClick={this.handleBeneficiaryDeleteNoClick}
            yesBtnClick={this.handleBeneficiaryDeleteYesClick}
            closeBtnClick={this.handleBeneficiaryDeleteNoClick}
          />
        )}

        {confirmAddEditBeneficiaryShowModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title=""
            description={confirmAddEditBeneficiaryModal.description}
            showModal={confirmAddEditBeneficiaryShowModal}
            closeBtnClick={this.confirmAddEditBeneficiaryToggleModal}
            toggleModal={this.confirmAddEditBeneficiaryToggleModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.handleAddEditBeneficiaryYesClick}
            showPopupModalButtons
          />
        )}

        {isNotHundredPercent && (
          <PopupModal
            type={AppConstants.MODALTYPE.FAILURE}
            title=""
            description={isNotHundredPercentMessage}
            showModal={isNotHundredPercent}
            closeBtnClick={this.notHundredPercentTooggle}
            toggleModal={this.notHundredPercentTooggle}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.notHundredPercentTooggle}
            showPopupModalButtons
          />
        )}

        <AuthBody
          actionComponent={beneficiariesComponent}
          handleBack={handleBack}
          memberCreation
        />
      </>
    );
  }
}

Beneficiaries.propTypes = {
  getDataFromServer: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  provinceList: PropTypes.arrayOf(PropTypes.object),
  countryList: PropTypes.arrayOf(PropTypes.object),
  doGetCountryList: PropTypes.func.isRequired,
  doGetProvinceData: PropTypes.func.isRequired,
  doGetBeneficiaryTypeList: PropTypes.func.isRequired,
  beneficiaryTypeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  doGetBeneficiaryRelationList: PropTypes.func.isRequired,
  beneficiaryRelationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  continuehandler: PropTypes.func.isRequired,
  doAddBeneficiaries: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  vaultProductList: PropTypes.arrayOf(PropTypes.any),
  doGetBeneficiaries: PropTypes.func.isRequired,
  doDeleteBeneficiary: PropTypes.func.isRequired,
  doEditBeneficiary: PropTypes.func.isRequired,
  finInfo: PropTypes.arrayOf(PropTypes.object),
};
Beneficiaries.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  countryList: [],
  provinceList: [],
  vaultProductList: [],
  finInfo: {},
};

export default Beneficiaries;
