function checkOptions(taxResidency, taxResidentHome, taxResidentOther) {
  return (
    taxResidency.taxResidentHome === taxResidentHome &&
    taxResidency.taxResidentOther === taxResidentOther
  );
}

const options = (taxResidentHome, taxResidentOther, finInfo) => {
  const option = finInfo.config.taxResidency.find((taxResidency) =>
    checkOptions(taxResidency, taxResidentHome, taxResidentOther)
  );

  return option?.options;
};

export default options;

export const getCountryName = (countryList, code) => {
  return countryList.filter((opt) => {
    return opt.taxResidencyResult.taxResidency === code;
  })[0]?.taxResidentOtherName;
};

export const setOptions = (taxResidency, finInfo) => {
  console.log("setOptions", taxResidency, finInfo.config?.taxResidency);
  const checkObj = finInfo.config?.taxResidency.find((taxResidene) =>
    taxResidene.options.find(
      (taxRes) => taxRes.taxResidencyResult?.taxResidency === taxResidency
    )
  );
  return checkObj;
};

/**
 * Validate a Canadian Social Insurance Number (SIN)
 * @param  {num || str} sin   A 9-digit Canadian SIN
 * @return {bool}             Validity of the input SIN
 */
export const validateSIN = (sin) => {
  let check;
  let even;
  let tot;
  let sinStr = sin;

  if (typeof sin === "number") {
    sinStr = sin.toString();
  }

  if (sinStr.length === 9) {
    // convert to an array & pop off the check digit
    sinStr = sinStr.split("");
    check = +sinStr.pop();

    even = sinStr
      // take the digits at the even indices
      .filter((_, i) => {
        return i % 2;
      })
      // multiply them by two
      .map((n) => {
        return n * 2;
      })
      // and split them into individual digits
      .join("")
      .split("");

    tot = sinStr
      // take the digits at the odd indices
      .filter((_, i) => {
        return !(i % 2);
      })
      // concatenate them with the transformed numbers above
      .concat(even)
      // it's currently an array of strings; we want numbers
      .map((n) => {
        return +n;
      })
      // and take the sum
      .reduce((acc, cur) => {
        return acc + cur;
      });

    // compare the result against the check digit
    return check === (10 - (tot % 10)) % 10;
  }

  return false;
};

export const validateAmount = (amount) => {
  console.log("validateAmount", amount);
  if (typeof amount === "number") {
    if (amount > -1) {
      return true;
    }
  } else {
    if (amount) {
      const amt = amount.replace("$", "").replace(",", "");
      console.log(`amount > ${amt}`);
      if (amt > -1) {
        return true;
      }
    }
  }
  return false;
};
