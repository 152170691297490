import { call, put, takeLatest } from "redux-saga/effects";

import { UPDATE_PERSONAL } from "../Actions/actionTypes";
import UpdatePersonalRequest from "../../Communication/ApiClasses/UpdatePersonalRequest";
import {
  doLoadingFinish,
  doLoadingStart,
} from "../../Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

function* executeSaga(action) {
  yield put(doLoadingStart(`${UPDATE_PERSONAL}_SAGA`));
  const updatePersonalRequest = new UpdatePersonalRequest(action.payLoad);
  try {
    const result = yield call(updatePersonalRequest.getData);
    yield put({
      type: `${UPDATE_PERSONAL}_SUCCESS`,
      error: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${UPDATE_PERSONAL}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${UPDATE_PERSONAL}_SAGA`));
  }
}

export default function* handleSaga() {
  yield takeLatest(UPDATE_PERSONAL, executeSaga);
}
