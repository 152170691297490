import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import DeleteDebitCard from "Communication/ApiClasses/DeleteDebitCardRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleDebitCard(action) {
  console.log("handleDebitCard", action);
  yield put(doLoadingStart(STRINGS.DEBIT_CARD.API_KEYS.DELETE_DEBIT_CARD));
  const addBundleProduct = new DeleteDebitCard(
    action.data.applicationId,
    action.data.debitProductId
  );
  try {
    const result = yield call(addBundleProduct.getData);
    yield put({
      type: actionTypes.DELETE_DEBIT_CARD_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_DEBIT_CARD_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.DEBIT_CARD.API_KEYS.DELETE_DEBIT_CARD));
  }
}

// watcher
function* deleteDebitCardWatcherSaga() {
  yield takeLatest(actionTypes.DELETE_DEBIT_CARD, handleDebitCard);
}

export default deleteDebitCardWatcherSaga;
