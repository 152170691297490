import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessCompliance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessIntendedPurpose: "",
      errors: {
        businessIntendedPurposeErrorList: [],
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_COMPLIANCE,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    console.log("active product", activeProduct);
    if (prevProps !== this.props) {
      this.setState({
        businessIntendedPurpose: activeProduct?.businessIntendedPurpose ?? "",
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { businessIntendedPurpose } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({
        request: { intendedUse: businessIntendedPurpose },
        vault: { businessIntendedPurpose },
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_COMPLIANCE);
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    const { businessIntendedPurpose, errors } = this.state;

    const businessIntendedPurposeRes = checkErrors(
      businessIntendedPurpose,
      VALIDATIONS.BUSINESS_COMPLIANCE.PURPOSE
    );

    this.setState({
      errors: {
        ...errors,
        businessIntendedPurposeErrorList: businessIntendedPurposeRes,
      },
    });

    if (businessIntendedPurposeRes.length > 0) {
      formIsValid = false;
    }
    return formIsValid;
  };

  render() {
    const { steps, activeStepID, finInfo } = this.props;
    const { businessIntendedPurpose, errors } = this.state;
    console.log(steps, activeStepID);

    const optionListComponent = finInfo?.config?.businessPurpose?.map(
      (data) => (
        <option
          className="[ dropdown__option ]"
          value={data.code}
          key={data.code}
        >
          {data.description}
        </option>
      )
    );

    const actionComponent = (
      <div className="[ business-compliance-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.BUSINESS_COMPLIANCE.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_COMPLIANCE.FORM.INTENDED_PURPOSE_LABEL}
                </div>
                <select
                  value={businessIntendedPurpose}
                  name="businessIntendedPurpose"
                  className={
                    errors.businessIntendedPurposeErrorList &&
                    errors.businessIntendedPurposeErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                      : "[ form-control ] [ select__dropdown ]"
                  }
                  onChange={this.handleChange}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.DROPDOWN_INITIAL_TEXT}
                  </option>
                  {optionListComponent}
                </select>
                <div className="[ error-msg ]">
                  {errors?.businessIntendedPurposeErrorList[0]}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
        blockBack={true}
      />
    );
  }
}

BusinessCompliance.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessCompliance.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessCompliance;
