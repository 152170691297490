import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import { toggleEditFlag } from "Redux/Actions/ApplicationAction";

import BusinessReview from "./BusinessReview";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import PopupModal from "../Common/PopupModal/PopupModal";
import STRINGS from "../../Constants/Strings";
import { doCreateApplication } from "../AboutYou/Actions/AboutYouActions";
import { getProductIdInActiveProduct } from "../../Utils/LogicUtilities";
import { HTTP_STATUS } from "../../Communication/Constants";
import getBundleProductRelation from "../../Redux/Actions/GetBundleProductRelationAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import { VAULT_DASHBOARD } from "../../Constants/Routes";

class BusinessReviewContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { processTemplate, history, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    const vaultStep = processTemplate?.flow?.find(
      (x) => x.componentName === activeProduct?.applicationStep?.componentName
    );
    console.log("vaultStep", vaultStep);
    if (
      vaultStep &&
      (vaultStep.componentType === "Application" ||
        vaultStep.componentName ===
          AppConstants.APPLICATIONSTEP.BUSINESS_REVIEW)
    ) {
      history.push(VAULT_DASHBOARD);
    }
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      products,
      getActiveProduct,
      doCreateNewApplicant,
      doGetBundleProductRelation,
      doUpdateProductToVault,
      finInfo,
      applicantData,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    // eslint-disable-next-line no-empty
    console.log("activeProduct", activeProduct);
    if (activeProduct.applicationId) {
      continuehandler(null, AppConstants.APPLICATIONSTEP.BUSINESS_REVIEW);
    } else {
      doCreateNewApplicant(
        {
          bundleProductId: activeProduct.productId,
          isCommercial: true,
          isSoleProprietor: applicantData.isSoleProprietor,
          dateOfBirth: "1900-01-01",
        },
        (response) => {
          console.log("create ", response);
          if (response.status === HTTP_STATUS.OK) {
            const updatedVaultProductList = products;

            if (
              updatedVaultProductList &&
              updatedVaultProductList.length !== 0
            ) {
              const idx = updatedVaultProductList.findIndex(
                (x) => x === activeProduct
              );

              updatedVaultProductList[idx].applicationId =
                response.data.applicationId;
              updatedVaultProductList[idx].applicantId =
                response.data.applicantId;
              if (
                response.data.doximProducts &&
                response.data.doximProducts.length !== 0
              ) {
                if (response.data.doximProducts.length === 1) {
                  updatedVaultProductList[idx].doximProductId =
                    response.data.doximProducts[idx].id;
                } else {
                  // search registered contract
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (registeredContract) {
                    updatedVaultProductList[0].contractProductId = registeredContract
                      ? registeredContract.id
                      : null;
                  }
                  const nonRegisteredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type !==
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (nonRegisteredContract) {
                    updatedVaultProductList[0].doximProductId =
                      nonRegisteredContract.id;
                  } else {
                    updatedVaultProductList[idx].doximProductId =
                      response.data.doximProducts[idx].id;
                    console.error("UNHANDLED MULTI PRODUCTS", response.data);
                  }
                }
              }

              doGetBundleProductRelation(
                {
                  bundleProductId: activeProduct.productId,
                  demandProductId: updatedVaultProductList[idx].productId,
                  applicationId: updatedVaultProductList[idx].applicationId,
                },
                (getBundleProductRelationRes) => {
                  if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
                    console.log(
                      "getBundleProductRelationRes",
                      getBundleProductRelationRes
                    );
                    updatedVaultProductList[idx].bundleProducts =
                      getBundleProductRelationRes.data;
                    doUpdateProductToVault(updatedVaultProductList, () => {
                      continuehandler(
                        null,
                        AppConstants.APPLICATIONSTEP.BUSINESS_REVIEW
                      );
                    });
                  }
                }
              );
            }
          }
        }
      );
    }
  };

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      finInfo,
      products,
      getActiveProduct,
      processTemplate,
      applicantData,
    } = this.props;
    const { showModal, modal, type } = this.state;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={this.handlePopupModalBtnClick}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => this.handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <BusinessReview
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          handleEditPage={this.handleEditPage}
          activeProduct={activeProduct}
          finInfo={finInfo}
          processTemplate={processTemplate}
          applicantData={applicantData}
        />
      </>
    );
  }
}

BusinessReviewContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doToggleEdit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessReviewContainer.defaultProps = {
  history: {},
  steps: [],
  activeStepID: -1,
  doToggleEdit: () => {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  applicantData: state.ApplicationReducer.response,
  processTemplate: state.ApplicationReducer.processTemplate,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
    doCreateNewApplicant: (request, callback) =>
      dispatch(doCreateApplication(request, callback)),
    doGetBundleProductRelation: (activeProduct, callback) =>
      dispatch(getBundleProductRelation(activeProduct, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessReviewContainer);

const BusinessReviewWrapper = withBusinessHOC(BusinessReviewContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessReviewWrapper);
