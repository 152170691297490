import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { APPLICATION_SLOT_DELETE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class DeleteApplicationSlot extends BaseApiRequest {
  method = METHOD.HTTP.DELETE;

  body = {};

  url = APPLICATION_SLOT_DELETE_URL;

  constructor(reqData) {
    super();
    console.log(reqData);

    const { applicationId, slotId } = reqData;
    const paramList = [applicationId, slotId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
