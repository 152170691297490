import * as actionTypes from "Redux/Actions/actionTypes";

const doLogout = (callback) => {
  return {
    type: actionTypes.LOGOUT_REQUEST,
    callback,
  };
};

export default doLogout;
