import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  disableBack,
  getApplicant,
  toggleBackFlag,
  toggleEditFlag,
  setNotFirstFlag,
  updateProductTemplate,
  toggleEditConfirmFlag,
} from "../Redux/Actions/ApplicationAction";
import * as ROUTES from "../Constants/Routes";
import PopupModal from "../Components/Common/PopupModal/PopupModal";
import STRINGS from "../Constants/Strings";
import {
  getCurrentStepIndexFromTemplateFlowByStep,
  getProduct,
} from "../Utils/CommonUtilities";
import MODALMESSAGES from "../Constants/Messages";
import AppConstants from "../Constants/AppConstants";
// import { updateProductToVault } from "../Components/MarketPlace/Action/MarketPlaceAction";

import { HTTP_STATUS } from "Communication/Constants";
import {
  getProductIdInActiveProduct,
  verifyAge,
} from "../Utils/LogicUtilities";

import {
  updateProductToVault,
  getProductFromVault,
  getProductList,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { getProductTemplate } from "Redux/Actions/ProductTemplateActions";
import getBundleProductRelation from "Redux/Actions/GetBundleProductRelationAction";
import { S_APPLICANT_ID, S_PERSONAL } from "../Communication/Constants";
import updateApplicantData from "../Redux/Actions/UpdateApplicantAction";
import { getApplicationProperty } from "../Components/JointApplicant/Actions/JointApplicantAction";

export default function withBusinessHOC(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        steps: [],
        activeStepID: -1,
        showModal: false,
        modal: {
          title: "",
          description: "",
          type: "",
        },
      };
    }

    onContinueHandler = (request, step) => {
      const { vaultProductList, doUpdateProductToVault } = this.props;
      console.log("onContinueHandler", request, step);

      if (request) {
        if (vaultProductList.length > 0) {
          vaultProductList[0] = {
            ...vaultProductList[0],
            ...request,
          };
        }
        console.log("updatedProducts", vaultProductList);
        doUpdateProductToVault(vaultProductList, () => {
          this.continueToNextStep(step);
        });
      } else {
        this.continueToNextStep(step);
      }
    };

    continueToNextStep = (step) => {
      const { doToggleBackFlag, doDisableBack } = this.props;
      doToggleBackFlag(false);
      doDisableBack(false);
      switch (step) {
        default:
          this.addApplicationProgressOrRedirect(step);
      }
    };

    /**
     *
     * @param {*} step default value is blank, need only for first step
     * @return callback
     * There are following steps followed before calling the get APIs respectively.
     * 1. Get the product details from the product vault.
     * 2. Get the templateId and template details of the active product
     * 3. Prepare the stepper details for each steps
     * 4. Check if step has been completed previously or not then call the Get API i.e. step 5
     *
     * So the following method is Step 1
     */
    getDataFromServer = (step = "", callback) => {
      const {
        doGetProductFromVault,
        vaultProductList,
        productList,
      } = this.props;
      console.log("getDataFromServer", step);
      if (vaultProductList.length <= 0) {
        doGetProductFromVault((productsRes) => {
          if (productsRes && productsRes.length > 0) {
            if (productsRes?.status === HTTP_STATUS.OK) {
              this.getProcessFlowAndCallApplicantDetails(
                productsRes?.data ? productsRes.data : [],
                step,
                callback
              );
            }
          } else {
            this.redirectToDashboard();
          }
        });
      } else {
        this.getProcessFlowAndCallApplicantDetails(
          vaultProductList,
          step,
          callback
        );
      }

      if (productList && productList.length <= 0) {
        const { doGetProductList } = this.props;
        doGetProductList();
      }

      switch (step) {
        default:
          callback(null);
      }
    };
    /**
     * Step 2
     * @param {*} products
     * @param {*} step
     * @param {*} callback
     */
    getProcessFlowAndCallApplicantDetails = (products, step = "", callback) => {
      const {
        processTemplate,
        doGetProductTemplateFlow,
        doGetBundleProductRelation,
        applicantData,
      } = this.props;
      console.log("getProcessFlowAndCallApplicantDetails", products, step);
      const activeProduct = this.getActiveProduct(products);
      if (!processTemplate || processTemplate?.flow?.length <= 0) {
        doGetProductTemplateFlow((response) => {
          this.getActiveTemplateAndCallNextMethod(
            response,
            activeProduct,
            step,
            callback
          );
        }, activeProduct);
      } else if (processTemplate.templateId !== activeProduct?.templateId) {
        const { doUpdateProductTemplate, templates } = this.props;

        // if the product is deleted in between steps then need to change the
        // template ID
        doUpdateProductTemplate(activeProduct);

        this.getActiveTemplateAndCallNextMethod(
          templates,
          activeProduct,
          step,
          callback
        );
      } else {
        doGetBundleProductRelation(
          {
            bundleProductId: getProductIdInActiveProduct(
              activeProduct,
              applicantData
            ),
            demandProductId: activeProduct.productId,
            applicationId: activeProduct.applicationId,
          },
          (getBundleProductRelationRes) => {
            if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
              this.getCompletedApplicationsAndGetApplicantDetails(
                processTemplate,
                step,
                getBundleProductRelationRes.data,
                callback
              );
            }
          }
        );
      }
    };

    /**
     * Helper method
     */
    getActiveTemplateAndCallNextMethod = (
      templates,
      activeProduct,
      step,
      callback
    ) => {
      console.log(
        "getActiveTemplateAndCallNextMethod",
        templates,
        activeProduct,
        step
      );
      const { doGetBundleProductRelation, applicantData } = this.props;
      const processTemp = this.getTemplateByTemplateID(
        templates,
        activeProduct?.templateId
      );

      doGetBundleProductRelation(
        {
          bundleProductId: getProductIdInActiveProduct(
            activeProduct,
            applicantData
          ),
          demandProductId: activeProduct.productId,
          applicationId: activeProduct.applicationId,
        },
        (getBundleProductRelationRes) => {
          if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
            this.getCompletedApplicationsAndGetApplicantDetails(
              processTemp,
              step,
              getBundleProductRelationRes.data,
              callback
            );
          }
        }
      );
    };
    /**
     * Step 3
     * @param {*} applicationTemplate
     * @param {*} activeProduct
     * @param {*} step
     * @param {*} bundleProductRelation
     */
    prepareStepsAndActiveStep = (
      applicationTemplate,
      activeProduct,
      step,
      bundleProductRelation
    ) => {
      console.log("prepareStepsAndActiveStep", step);
      const { processTemplate } = this.props;

      const steps = [];
      let activeStepID = -1;

      let index = 1;
      processTemplate.flow.forEach((flow) => {
        steps.push({
          Id: index,
          Name: STRINGS.STEPS[flow.componentName],
        });
        if (flow.componentName === step) {
          activeStepID = index;
        }
        index += 1;
      });
      console.log("result -> ", steps, activeStepID);
      this.setState({ steps, activeStepID });
    };

    /**
     * step 4
     * @param {*} applicationTemplate
     * @param {*} step
     * @param {*} callback
     */
    getCompletedApplicationsAndGetApplicantDetails = (
      applicationTemplate,
      step,
      bundleProductRelation,
      callback
    ) => {
      console.log(
        "getCompletedApplicationsAndGetApplicantDetails",
        applicationTemplate,
        step,
        bundleProductRelation
      );
      const { products, doGetApplicant } = this.props;
      const activeProduct = this.getActiveProduct(products);
      // prepares stepper
      if (activeProduct?.applicantId) {
        doGetApplicant(
          { id: activeProduct.applicantId },
          (getAccountActionRes) => {
            this.prepareStepsAndActiveStep(
              applicationTemplate,
              activeProduct,
              step,
              bundleProductRelation
            );
          }
        );
      } else {
        this.prepareStepsAndActiveStep(
          applicationTemplate,
          activeProduct,
          step,
          bundleProductRelation
        );
      }
    };

    addApplicationProgressOrRedirect = (
      step,
      responseApplicantDetails,
      shouldNotUpdate = true,
      justAPassThrough = false
    ) => {
      const { products, doUpdateProductToVault, processTemplate } = this.props;

      const activeProduct = this.getActiveProduct(products);
      console.log(
        "addApplicationProgressOrRedirect",
        step,
        responseApplicantDetails,
        shouldNotUpdate,
        justAPassThrough,
        processTemplate,
        products
      );

      // this.redirectToNextStep(step);
      if (
        shouldNotUpdate &&
        (!this.isCurrentStepCompleted(step) ||
          (activeProduct && activeProduct?.applicationStep?.index === null))
      ) {
        let updatedProductList = [...products];
        const nextStep = this.getNextApplicationStep(step, processTemplate);
        const nextStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
          processTemplate,
          nextStep
        );
        console.log(nextStep, nextStepIndex);
        const request = this.prepareApplicationProgress(
          processTemplate.flow[nextStepIndex - 1].componentName,
          responseApplicantDetails
        );
        updatedProductList[0] = request;
        doUpdateProductToVault(updatedProductList, () => {
          this.redirectToNextStep(step);
        });
      } else {
        this.redirectToNextStep(step);
      }
    };

    /**
     * Prepare the application progress object
     * @param {*} step
     * @param {*} responseApplicantDetails
     */
    prepareApplicationProgress = (step, responseApplicantDetails = null) => {
      console.log("prepareApplicationProgress", step, responseApplicantDetails);
      const { products, processTemplate, inSessionJointApplicant } = this.props;
      console.log("products", products);
      const activeProduct = this.getActiveProduct(products);

      const applicationStep = this.getCurrentStepFromTemplateFlowByStep(
        processTemplate,
        step
      );

      // Not sure about this
      if (responseApplicantDetails) {
        sessionStorage.setItem(
          S_APPLICANT_ID,
          responseApplicantDetails.applicantId
        );
        activeProduct.applicantId = responseApplicantDetails.applicantId;
        activeProduct.applicationId = responseApplicantDetails.applicationId;
      }
      if (activeProduct) {
        activeProduct.applicationStep = {
          index: applicationStep.index,
          componentName: applicationStep.componentName,
        };
      }

      return activeProduct;
    };

    redirectToNextStep = (step, isNotFirstFlag = false, processTemp) => {
      const {
        history,
        isEdit,
        isEditPage,
        isEditConfirm,
        isNotFirst,
        processTemplate,
        products,
      } = this.props;
      console.log(
        "redirectToNextStep",
        step,
        isNotFirstFlag,
        processTemp,
        isEdit,
        isNotFirst
      );
      console.log(
        "redirectToNextStep",
        step,
        isNotFirstFlag,
        processTemp,
        isEditConfirm
      );
      const notFirstFlag = isNotFirstFlag || isNotFirst;

      const flow =
        processTemplate.flow?.length > 0
          ? processTemplate.flow?.map((item) => item.componentName)
          : [];
      const currentStepIndex = getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      const activeProduct = this.getActiveProduct(products);
      const businessReviewStepIndex = getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        AppConstants.APPLICATIONSTEP.BUSINESS_REVIEW
      );
      const businessConfirmStepIndex = getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        AppConstants.APPLICATIONSTEP.BUSINESS_CONFIRM
      );
      /**
       * Edit flow from confirm
       * Use reducers isEdit flag.
       * if isEdit true then make it false.
       */
      if (isEdit) {
        const { doToggleEdit } = this.props;
        doToggleEdit(false);
        if (isEditPage) {
          history.push(isEditPage);
        } else {
          history.push(ROUTES.BUSINESS_REVIEW);
        }
      } else if (isEditConfirm) {
        const { doToggleEditConfirm } = this.props;
        doToggleEditConfirm(false);

        history.push(ROUTES.BUSINESS_CONFIRM);
      } else if (notFirstFlag) {
        const { processTemplate } = this.props;
        let finalProcessTemplate = { ...processTemplate };
        if (processTemp && processTemp?.flow.length > 0) {
          finalProcessTemplate = { ...processTemp };
        }
        this.navigateToNextApplicationStep(step, finalProcessTemplate, history);
      } else {
        const nextStep = this.getNextStep(step);
        console.log("nextStep", nextStep);
        if (nextStep === step) {
          // if next step is the same as current step
          this.removeCurrentActiveApplication(
            this.showNextProductPopUpOrRedirect
          );
        } else {
          this.openStep(ROUTES[nextStep]);
        }
      }
    };

    showNextProductPopUpOrRedirect = () => {
      console.log("showNextProductPopUpOrRedirect");
      const { vaultProductList } = this.props;
      if (vaultProductList && vaultProductList.length !== 0) {
        this.showContinueWithNextProductPopup();
      } else {
        this.redirectToDashboard();
      }
    };

    /**
     * Show Pop up for next product
     */
    showContinueWithNextProductPopup = () => {
      const { productList, vaultProductList } = this.props;

      const product = getProduct(productList, vaultProductList);
      const modal = {};
      const description =
        MODALMESSAGES.FUNDING_AMOUNT.FUND_PRODUCT_POPUP_MESSAGE;
      modal.title = MODALMESSAGES.FUNDING_AMOUNT.FUND_PRODUCT;
      modal.description = description.replace("$", product.productName);
      modal.type = AppConstants.MODALTYPE.SUCCESS;
      this.setState({
        modal,
        showModal: true,
      });
    };

    /**
     * To start configuring the second product
     * Assuming the about you step will be skipping in the next product configutaion
     */
    navigateToNextProduct = () => {
      const { applicantData, vaultProductList, doGetApplicant } = this.props;
      const activeProduct = vaultProductList[0];
      activeProduct.active = true;
      doGetApplicant({ id: applicantData.id }, (getAccountActionRes) => {
        this.continueOnNextProduct(activeProduct, getAccountActionRes.data);
      });
    };

    continueOnNextProduct = (activeProduct, applicantData) => {
      // Set the not first flag to true
      // this flag gets invalidated when user clicks on back when configuraing second product
      const { doSetNotFirstFlag } = this.props;
      doSetNotFirstFlag(true);

      // checking if the second products process template is different or same
      const { processTemplate } = this.props;
      let processTemp = { ...processTemplate };

      if (activeProduct.templateId !== processTemplate?.templateId) {
        // if process template different then updating the reducer
        const { doUpdateProductTemplate, templates } = this.props;
        doUpdateProductTemplate(activeProduct);

        processTemp = this.getTemplateByTemplateID(
          templates,
          activeProduct.templateId
        );
      }

      let nextComponent = processTemp.flow.find(
        (flow) =>
          ((flow.componentType === AppConstants.COMPONENTSTEPTYPE.APPLICANT &&
            !this.stepVisisted(flow.componentName)) ||
            flow.componentType ===
              AppConstants.COMPONENTSTEPTYPE.APPLICATION) &&
          flow.componentName !==
            AppConstants.APPLICATIONSTEP.BUSINESS_DOC_REVIEW
      );
      console.log("nextComponent", nextComponent, activeProduct);
      let nextStep = nextComponent?.componentName;
      // check if the second product configuration is dropeed in between then
      // do not call create application for same product twice
      if (verifyAge(applicantData.member.dob, activeProduct)) {
        this.startProcessingApplication(activeProduct, nextStep);
      } else {
        this.setState({
          showModalAge: true,
          modalAge: {
            title: STRINGS.ABOUTYOU.AGE_RESTRICTION.TITLE,
            description: STRINGS.ABOUTYOU.AGE_RESTRICTION.MESSAGE,
          },
        });
      }
    };

    startProcessingApplication = (activeProduct, nextStep) => {
      const { history } = this.props;
      console.log("startProcessingApplication", activeProduct, nextStep);
      history.push(ROUTES[nextStep]);
    };

    openStep = (route) => {
      const { history, processTemplate } = this.props;
      const steps = [];
      let activeStepID = -1;

      let index = 1;
      console.log("PROCESS TEMPLATE OPEN STEP", processTemplate);
      processTemplate.flow.forEach((flow) => {
        steps.push({
          Id: index,
          Name: STRINGS.STEPS[flow.componentName],
        });
        if (flow.componentName === route) {
          activeStepID = index;
        }
        index += 1;
      });
      this.setState({ steps, activeStepID }, () => {
        history.push(route);
      });
    };

    redirectToDashboard = () => {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    };

    /**
     * Returns active product if not found then returns first object from the array
     * @param {*} products
     */
    getActiveProduct = (products) => {
      const { bankingType } = this.props;
      let activeProduct = products?.find(
        (product) =>
          product?.active &&
          product.bankingType === AppConstants.BANKING_TYPE.BUSINESS
      );
      if (!activeProduct) {
        activeProduct = products?.find(
          (product) => product.bankingType === bankingType
        );
        if (!activeProduct?.applicantId) {
          const jointSlotId = sessionStorage.getItem(
            STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
          );
          if (jointSlotId) {
            const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
            if (applicantId) {
              activeProduct.applicantId = applicantId;
            }
          }
        }
      }
      return activeProduct;
    };

    /**
     * Returns template from the list of templates which matches the template ID
     * @param {*} templates
     * @param {*} templateId
     */
    getTemplateByTemplateID = (templates, templateId) => {
      return templates.find((template) => template.templateId === templateId);
    };

    /**
     * returns the step object from the template flow which matches the param step
     * @param {*} template
     * @param {*} step
     */
    getCurrentStepFromTemplateFlowByStep = (template, step) => {
      console.log("getCurrentStepFromTemplateFlowByStep", template, step);
      return template.flow.find(
        (applicantStep) => applicantStep.componentName === step
      );
    };

    /**
     * returns the step object's index from the template flow which matches the param step
     * @param {*} template
     * @param {*} step
     */
    getCurrentStepIndexFromTemplateFlowByStep = (template, step) => {
      return template?.flow?.findIndex(
        (applicantStep) => applicantStep.componentName === step
      );
    };

    /**
     * When configuring second product navigating to next application step
     * or non visited applicant step
     * @param {*} step
     * @param {*} processTemplate
     * @param {*} history
     */
    navigateToNextApplicationStep = (step, processTemplate, history) => {
      console.log("navigateToNextApplicationStep", step);
      const nextStep = this.getNextApplicationStep(step, processTemplate);
      console.log("nextStep", nextStep);
      if (nextStep === step) {
        this.showNextProductPopUpOrRedirect();
      } else {
        history.push(ROUTES[nextStep]);
      }
    };

    /**
     * To get the next application step
     * or non visited applicant step
     * @param {*} step
     * @param {*} processTemplate
     */
    getNextApplicationStep = (step, processTemplate) => {
      console.log("getNextApplicationStep", step);
      const {
        products,
        applicationCompleteList,
        applicantData,
        finInfo,
        onlineBankingInfo,
        fundProduct,
      } = this.props;
      let nextStep = step;
      let currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );
      currentStepIndex += 1;
      const activeProduct = this.getActiveProduct(products);
      while (currentStepIndex < processTemplate?.flow?.length) {
        if (
          processTemplate?.flow[currentStepIndex]?.componentType ===
            AppConstants.COMPONENTSTEPTYPE.APPLICATION ||
          !this.stepVisisted(
            processTemplate?.flow[currentStepIndex]?.componentName
          )
        ) {
          const targetNextStep =
            processTemplate?.flow[currentStepIndex]?.componentName;

          nextStep = processTemplate?.flow[currentStepIndex]?.componentName;
          break;
        }
        currentStepIndex += 1;
      }

      return nextStep;
    };

    /**
     * When configuring second product then check if the step visited in previously
     * completed products in the session
     * @param {*} step
     */
    stepVisisted = (step) => {
      const { templates, products, sessionCompletedProducts } = this.props;
      let stepCompleted = false;
      // this part added to check if the step is completed in current template or not
      const activeProduct = this.getActiveProduct(products);
      let completedTemplate = null;
      for (let i = 0; i < sessionCompletedProducts.length; i += 1) {
        const completedProduct = sessionCompletedProducts[i];

        if (completedProduct) {
          completedTemplate = this.getTemplateByTemplateID(
            templates,
            completedProduct.templateId
          );
        }

        if (completedTemplate) {
          const stepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
            completedTemplate,
            step
          );

          if (stepIndex >= 0) {
            stepCompleted = true;
            break;
          }
          stepCompleted = false;
        } else {
          // this part added to check if the step is completed in current template or not
          completedTemplate = this.getTemplateByTemplateID(
            templates,
            activeProduct.templateId
          );

          if (completedTemplate) {
            const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
              completedTemplate,
              step
            );

            if (
              currentStepObj &&
              activeProduct.applicationStep?.index <= currentStepObj.index
            ) {
              stepCompleted = true;
              break;
            }
          }
        }
      }
      return stepCompleted;
    };

    getNextStep = (step) => {
      let nextStep = step;
      const { processTemplate } = this.props;
      console.log("getNextStep", step, processTemplate);

      const currentStepIndex = getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      console.log("currentStepIndex", currentStepIndex);

      if (
        currentStepIndex !== -1 &&
        currentStepIndex !== processTemplate.flow.length - 1
      ) {
        // if current step is not the last step

        nextStep = processTemplate.flow[currentStepIndex + 1].componentName;
      }

      if (nextStep === AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT) {
        const personalId = sessionStorage.getItem(S_PERSONAL);
        if (personalId && personalId !== "null") {
          nextStep = this.getNextStep(
            AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT
          );
        }
      }

      return nextStep;
    };

    /**
     * Check if current step is already visited or not
     * so the application progress should be updated or not
     * @param {*} step
     */
    isCurrentStepCompleted = (step) => {
      console.log("isCurrentStepCompleted", step);
      const { processTemplate } = this.props;

      const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
        processTemplate,
        step
      );

      console.log("currentStepObj", currentStepObj);

      // Previous application progress.
      const { products } = this.props;
      const activeProduct = this.getActiveProduct(products);

      console.log(
        "currentStepObj result",
        !currentStepObj ||
          !activeProduct ||
          activeProduct?.applicationStep?.index === null ||
          currentStepObj.index < activeProduct?.applicationStep?.index
      );

      return (
        !currentStepObj ||
        !activeProduct ||
        activeProduct?.applicationStep?.index === null ||
        currentStepObj.index < activeProduct?.applicationStep?.index
      );
    };

    /**
     * From the current template flow goes to the previous step
     * @param {*} currentStep
     */
    onBackHandleClick = (currentStep) => {
      const previousStep = this.getPreviousStep(currentStep);
      const { isNotFirst, doToggleBackFlag } = this.props;
      if (isNotFirst) {
        const { doSetNotFirstFlag } = this.props;
        doSetNotFirstFlag(false);
      }
      if (previousStep) {
        const { history, doToggleEdit } = this.props;
        doToggleEdit(false);
        doToggleBackFlag(true);
        history.push(ROUTES[previousStep]);
      }
    };

    /**
     * Returns the previous step from current template flow
     * @param {*} step
     */
    getPreviousStep = (step) => {
      const { processTemplate, applicantData, personalData } = this.props;
      console.log("getPreviousStep", step);
      let previousStep = "";

      const applicantStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      if (applicantStepIndex) {
        previousStep = processTemplate.flow[applicantStepIndex - 1]
          .componentName
          ? processTemplate.flow[applicantStepIndex - 1].componentName
          : processTemplate.flow[applicantStepIndex - 2].componentName;
      }

      if (
        previousStep === AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT &&
        personalData?.id
      ) {
        previousStep = this.getPreviousStep(
          AppConstants.APPLICATIONSTEP.BUSINESS_CLIENT
        );
      }

      return previousStep;
    };

    removeCurrentActiveApplication = (callback) => {
      const {
        products,
        vaultProductList,
        doUpdateProductToVault,
        history,
      } = this.props;
      console.log("removeCurrentActiveApplication", products, vaultProductList);

      // no need to pass the result value into new variable, since it's returning the deleted element instead the existing data
      // we can do this instead
      // const updatedProducts = {...vaultProductList} // updatedProducts.splice(0, 1);
      vaultProductList.splice(0, 1);
      console.log("updatedProducts", vaultProductList);
      doUpdateProductToVault(vaultProductList, () => {
        if (callback) {
          callback();
        } else {
          history.push(ROUTES.VAULT_DASHBOARD);
        }
      });
    };

    render() {
      const { steps, activeStepID, showModal, modal } = this.state;
      return (
        <>
          {showModal && (
            <PopupModal
              type={modal.type}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showModal}
              popupBtnClick={this.navigateToNextProduct}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
              closeBtnClick={this.handlePopupModalCloseBtnClick}
            />
          )}
          <WrappedComponent
            getDataFromServer={(step, callback) =>
              this.getDataFromServer(step, callback)
            }
            continuehandler={(data, currentStep) =>
              this.onContinueHandler(data, currentStep)
            }
            handleBack={(step) => this.onBackHandleClick(step)}
            getNextStep={(step) => this.getNextStep(step)}
            steps={steps}
            activeStepID={activeStepID}
            showNextProductPopUpOrRedirect={this.showNextProductPopUpOrRedirect}
            getActiveProduct={this.getActiveProduct}
            navigateToNextProduct={this.navigateToNextProduct}
            removeCurrentActiveApplication={this.removeCurrentActiveApplication}
            {...this.props}
          />
        </>
      );
    }
  }

  HOC.propTypes = {
    doToggleEdit: PropTypes.func.isRequired,
    doToggleBackFlag: PropTypes.func.isRequired,
    doDisableBack: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    processTemplate: PropTypes.oneOfType([PropTypes.object]),
    isEdit: PropTypes.bool.isRequired,
    isNotFirst: PropTypes.bool.isRequired,
    vaultProductList: PropTypes.arrayOf(PropTypes.object),
    productList: PropTypes.arrayOf(PropTypes.object),
    bankingType: PropTypes.string.isRequired,
  };

  HOC.defaultProps = {
    processTemplate: [],
    vaultProductList: [],
    productList: [],
    bankingType: AppConstants.BANKING_TYPE.BUSINESS,
  };

  const mapStateToProps = (state) => ({
    inReview: state.ApplicationReducer.inReview,
    uploadedAt: state.ApplicationReducer.uploadedAt,
    isEdit: state.ApplicationReducer.isEdit,
    isEditPage: state.ApplicationReducer.isEditPage,
    isEditConfirm: state.ApplicationReducer.isEditConfirm,
    processTemplate: state.ApplicationReducer.processTemplate,
    vaultProductList: state.VaultReducer.vaultProductList,
    templates: state.ApplicationReducer.templates,
    productList: state.MarketplaceReducer.productList,
    completedApplications: state.ApplicationReducer.applicationCompleteList,
    applicantList: state.ApplicationReducer.applicantList,
    appProperties: state.JoinApplicantReducer.appProperties,
    isNotFirst: state.ApplicationReducer.isNotFirst,
    sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
    applicantData: state.ApplicationReducer.response,
    personalData: state.ApplicationReducer.personalData,
    isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
    enableDebitCard: state.DebitCardReducer.enableDebitCard,
    applicationAppliedList: state.ApplicationReducer.applicationAppliedList,
    inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
    onlineBankingInfo: state.OnlineBankingReducer,
    gettingStarted: state.ApplicationReducer.gettingStarted,
    applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
    finInfo: state.MarketplaceReducer.finInfo,
    fundProduct: state.VaultReducer.fundProduct,
    loanProducts: state.LoanDetailsReducer.loanProducts,
    bankingProductList: state.AccountInformationReducer.bankingProductList,
    bankingType: state.MarketplaceReducer.bankingType,
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      doGetApplicant: (payLoad, callback) =>
        dispatch(getApplicant(payLoad, callback)),
      doUpdateApplicant: (request, applicantId, callback) =>
        dispatch(updateApplicantData(request, applicantId, callback)),
      doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
      doToggleEditConfirm: (flag) => dispatch(toggleEditConfirmFlag(flag)),
      doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
      doDisableBack: (flag) => dispatch(disableBack(flag)),
      doGetProductFromVault: (callback) =>
        dispatch(getProductFromVault(callback)),
      doGetProductList: () => dispatch(getProductList()),
      doUpdateProductToVault: (vaultProductList, callback) =>
        dispatch(updateProductToVault(vaultProductList, callback)),
      doGetProductTemplateFlow: (callback, product) =>
        dispatch(getProductTemplate(callback, product)),
      doUpdateProductTemplate: (activeProduct) =>
        dispatch(updateProductTemplate(activeProduct)),
      doGetBundleProductRelation: (activeProduct, callback) =>
        dispatch(getBundleProductRelation(activeProduct, callback)),
      doSetNotFirstFlag: (flag) => dispatch(setNotFirstFlag(flag)),
      doGetApplicationProperty: (request, callback) =>
        dispatch(getApplicationProperty(request, callback)),
    };
  };

  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
}
