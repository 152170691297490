import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddLoanAsset from "Communication/ApiClasses/AddLoanAssetRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAddLoanAsset(action) {
  yield put(doLoadingStart(STRINGS.ASSET.API_KEYS.LOAN_ADD_ASSET));
  const addLoanAsset = new AddLoanAsset(
    action.data.applicantId,
    action.data.finAnalysisId,
    action.data.payLoad
  );
  console.log(action);
  try {
    const result = yield call(addLoanAsset.getData);
    yield put({
      type: actionTypes.LOAN_ADD_ASSET_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_ADD_ASSET_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ASSET.API_KEYS.LOAN_ADD_ASSET));
  }
}

// watcher
function* addLoanAssetWatcherSaga() {
  yield takeLatest(actionTypes.LOAN_ADD_ASSET, handleAddLoanAsset);
}

export default addLoanAssetWatcherSaga;
