import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class Fees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.FEES, (response) => {
      this.setFormData(response);
    });
  }

  setFormData = () => {};

  handleChange = (choice) => {
    const { handleChange } = this.props;
    handleChange(choice);
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue();
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.FEES);
  };

  render() {
    const { steps, activeStepID } = this.props;
    console.log(steps, activeStepID);
    const actionComponent = (
      <div className="[ fees-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.FEES.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              {/* PSA SEARCH FEES */}
              <div className="[ col-12 col-md-4 ]">
                <div className="[ form-label ] ">
                  {STRINGS.FEES.PSA_SEARCH_FEES_LABEL}
                </div>
                <NumberFormat
                  maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                  type="text"
                  thousandSeparator
                  prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                  name="psaresearchfees"
                  className="[ form-control ]"
                  placeholder={STRINGS.FEES.PSA_SEARCH_FEES_LABEL}
                />
                <div className="[ error-msg ]" />
              </div>

              {/* PSA REGISTRATION */}
              <div className="[ col-12 col-md-4 ]">
                <div className="[ form-label ] ">
                  {STRINGS.FEES.PSA_REGISTRATION_LABEL}
                </div>
                <NumberFormat
                  maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                  type="text"
                  thousandSeparator
                  prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                  name="psaregistration"
                  className="[ form-control ]"
                  placeholder={STRINGS.FEES.PSA_REGISTRATION_LABEL}
                />
                <div className="[ error-msg ]" />
              </div>

              {/* APPLICATION DISBURSEMENT */}
              <div className="[ col-12 col-md-4 ]">
                <div className="[ form-label ] ">
                  {STRINGS.FEES.APPLICATION_DISBURSEMENT_LABEL}
                </div>
                <NumberFormat
                  maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                  type="text"
                  thousandSeparator
                  prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                  name=""
                  className="[ form-control ]"
                  placeholder={STRINGS.FEES.APPLICATION_DISBURSEMENT_LABEL}
                />
                <div className="[ error-msg ]" />
              </div>
            </div>
            <hr />
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              {/* TOTAL FEES */}
              <div className="[ col-12 ]">
                <div className="[ form-label ] ">
                  {STRINGS.FEES.TOTAL_FEES_LABEL}
                </div>
                <NumberFormat
                  maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                  type="text"
                  thousandSeparator
                  prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                  name="totalfees"
                  className="[ form-control ]"
                  placeholder={STRINGS.FEES.TOTAL_FEES_LABEL}
                />
                <div className="[ error-msg ]" />
              </div>
            </div>
          </div>
        </div>

        {/* CHRIS NOTE: These commented out fields were in the interface; however, are not identified in the documentation. */}
        {/* PROPERTY SEARCH */}
        {/* <div className="[ col-12 col-md-3 ]">
          <div className="[ form-label ] ">{STRINGS.FEES.PROPERTY_SEARCH_LABEL}</div>
          <span className="[ dollar-sign ]">$</span>
          <NumberFormat
            maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
            type="text"
            thousandSeparator
            prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
            name="propertysearch"
            className="[ form-control ]"
            placeholder={STRINGS.FEES.PROPERTY_SEARCH_LABEL}
            onChange={this.handleAssetChange(asset, "value")}
            value={asset.value ? asset.value : ""}
          />
          <div className="[ error-msg ]" />
        </div> */}
        {/* TITLE INSURANCE LEGAL FEES */}
        {/* <div className="[ col-12 ]">
          <div className="[ form-label ]">
            {STRINGS.FEES.TITLE_INSURANCE_LEGAL_FEES_LABEL}
          </div>
          <>
            <input
              type="text "
              name="titleinsurancelegalfees"
              className="[ form-control ]"
              placeholder={STRINGS.FEES.TITLE_INSURANCE_LEGAL_FEES_LABEL}
            />
          </>
          <br />
        </div>
        {/* APPRAISAL INSPECTION */}
        {/* <div className="[ col-12 ]">
          <div className="[ form-label ]">
            {STRINGS.FEES.APPRAISAL_INSPECTION_LABEL}
          </div>
          <>
            <input
              type="text "
              name="appraisalinspection"
              className="[ form-control ]"
              placeholder={STRINGS.FEES.APPRAISAL_INSPECTION_LABEL}
            />
          </>
          <br />
        </div>  */}

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

Fees.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

Fees.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default Fees;
