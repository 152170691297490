import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

import * as actionTypes from "Redux/Actions/actionTypes";
import GetApplication from "../../Communication/ApiClasses/GetApplication";

// worker
function* handleGetApplication(action) {
  yield put(doLoadingStart(`${actionTypes.GET_APPLICATION}_SAGA`));
  const getApplicationApi = new GetApplication(action.applicationId);
  try {
    const result = yield call(getApplicationApi.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(`${actionTypes.GET_APPLICATION}_SAGA`));
  }
}

// watcher
export default function* getAccountWatcherSaga() {
  yield takeLatest(actionTypes.GET_APPLICATION, handleGetApplication);
}
