import * as actionTypes from "Redux/Actions/actionTypes";

export const setSpousalInfo = (
  SpousalInfo,
  applicationId,
  regidteredcontractproduct,
  contractProductId,
  callback = () => {}
) => {
  return {
    type: actionTypes.SET_SPOUSAL_INFO,
    SpousalInfo,
    applicationId,
    regidteredcontractproduct,
    contractProductId,
    callback,
  };
};

export const getSpousalInfo = (
  applicationId,
  regidteredcontractproduct,
  contractProductId,
  callback = () => {}
) => {
  return {
    type: actionTypes.GET_SPOUSAL_INFORMATION,
    applicationId,
    regidteredcontractproduct,
    contractProductId,
    callback,
  };
};
