import * as actionTypes from "Redux/Actions/actionTypes";

export const addFundMethod = (fundMethod) => ({
  type: actionTypes.FUND_METHOD,
  data: fundMethod,
});
export const addFundAmount = (fundAmount) => ({
  type: actionTypes.FUND_AMOUNT,
  data: fundAmount,
});

export const paymentProcess = (paymentDetails, callback) => ({
  type: actionTypes.PROCESS_REQUEST,
  data: paymentDetails,
  callback,
});

export const getFundPaymentDetails = (callback) => ({
  type: actionTypes.FUND_PAYMENT_DETAILS,
  callback,
});

export const storeFundProduct = (product) => ({
  type: actionTypes.STORE_FUND_PRODUCT,
  data: product,
});
