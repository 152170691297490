import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";
import { HTTP_STATUS } from "Communication/Constants";
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BeforeWeStart from "./BeforeWeStart";
import { fetchJsonData } from "../TermsAndConditions/Actions/TermsActions";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

class BeforeWeStartContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      jsonData: null,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const { fetchJsonDatas } = this.props;
    fetchJsonDatas((res) => {
      console.log(res);
      this.setState({
        jsonData: res.data,
      });
    });
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    const { termsState, history, inSessionJointApplicant } = this.props;

    if (prevProps !== this.props && !inSessionJointApplicant) {
      if (termsState?.success && termsState.success.status === HTTP_STATUS.OK) {
        history.push(ROUTES.GETTING_STARTED);
      }
    }
  }

  handleChange = () => {};

  handleContinue = (choice) => {
    const { continuehandler } = this.props;
    continuehandler({ choice }, AppConstants.APPLICATIONSTEP.BEFORE_WE_START);
  };

  render() {
    const { linkTermsArray, checkboxArray, choice, jsonData } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <BeforeWeStart
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          linkTermsArray={linkTermsArray}
          checkboxArray={checkboxArray}
          activeStepID={activeStepID}
          jsonData={jsonData}
        />
      </>
    );
  }
}

BeforeWeStartContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BeforeWeStartContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  termsState: {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  termsState: state.TermsAndConditionReducer,
  jsonState: state.TermsJsonReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    fetchJsonDatas: (callback) => dispatch(fetchJsonData(callback)),
  };
};

const BeforeWeStartWrapper = withApplicantHOC(BeforeWeStartContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeforeWeStartWrapper);
