import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import ProductApiRequest from "Communication/ApiClasses/ProductApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleProduct(action) {
  yield put(doLoadingStart(STRINGS.MARKETPLACE.API_KEYS.PRODUCT_LIST));
  const productApiRequest = new ProductApiRequest();
  try {
    const result = yield call(productApiRequest.getData);
    yield call(action.callback, result);
    yield put({
      type: actionTypes.STORE_PRODUCT_LIST,
      data: result.data,
    });
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.MARKETPLACE.API_KEYS.PRODUCT_LIST));
  }
}

// watcher
export default function* productWatcherSaga() {
  yield takeLatest(actionTypes.GET_PRODUCT_LIST, handleProduct);
}
