import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import BusinessCompliance from "./BusinessCompliance";
import * as ROUTES from "Constants/Routes";
import withBusinessHOC from "../../Hoc/BusinessHOC";
import { updateProductInformation } from "../../Redux/Actions/ProductInformationAction";

class BusinessComplianceContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      continuehandler,
      doUpdateProductInformation,
      getActiveProduct,
      products,
      doximProducts,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    doUpdateProductInformation(
      data.request,
      activeProduct.applicationId,
      activeProduct.type,
      doximProducts?.find((x) => (x.type = "demand"))?.id,
      () => {
        continuehandler(
          data.vault,
          AppConstants.APPLICATIONSTEP.BUSINESS_COMPLIANCE
        );
      }
    );
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      finInfo,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    return (
      <>
        <BusinessCompliance
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          activeProduct={activeProduct}
          finInfo={finInfo}
        />
      </>
    );
  }
}

BusinessComplianceContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessComplianceContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  doximProducts: state.ApplicationReducer.doximProducts,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  finInfo: state.MarketplaceReducer.finInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doUpdateProductInformation: (
      requestBody,
      applicationId,
      productType,
      doximProductId,
      callback
    ) =>
      dispatch(
        updateProductInformation(
          requestBody,
          applicationId,
          productType,
          doximProductId,
          callback
        )
      ),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessComplianceContainer);

const BusinessComplianceWrapper = withBusinessHOC(BusinessComplianceContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessComplianceWrapper);
