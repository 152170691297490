import BaseApiRequest from "./BaseApiRequest";
import { GETTING_STARTED_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GettingStartedRequest extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  body = {
    branch: "",
    isCardRequired: false,
  };

  constructor(apiBody) {
    super();
    this.body = apiBody;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return GETTING_STARTED_URL;
  };

  getBody = () => {
    return this.body;
  };
}
