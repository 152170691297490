import BucketApiRequest from "./BucketApiRequest";
import { GET_LOAN_PRODUCTS } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetLoanProduct extends BucketApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return GET_LOAN_PRODUCTS;
  };

  getBody = () => {
    return this.body;
  };
}
