import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import ROUTES from "../../Constants/AppConstants";

class BusinessDebit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessDebit: "",
      businessDebitErrorList: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        businessDebit: !!activeProduct?.businessDebit,
      });
    }
  }

  setFormData = () => {};

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { businessDebit } = this.state;
    let valid = this.verifyData();

    if (valid) {
      handleContinue({ businessDebit });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_DEBIT);
  };

  handleChoice = (name, value) => {
    const errorObject = `${name}${STRINGS.COMMON.ERROR_LIST}`;
    this.setState({
      [name]: value,
      [errorObject]: "",
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        [errorObject]: "",
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;

    return formIsValid;
  };

  render() {
    const { steps, activeStepID } = this.props;
    const { businessDebit, businessDebitErrorList } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ business-debit-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_DEBIT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.BUSINESS_DEBIT.FORM.ACCOUNT_DEBIT_CARDS_LABEL}
                </div>
                <div className="[ row ] [ custom-radios ]">
                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        businessDebit
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      name="businessDebit"
                      onClick={(event) =>
                        this.handleChoice("businessDebit", true, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.YES}
                    </button>
                  </div>

                  <div className="[ col-6 ]">
                    <button
                      type="submit"
                      className={
                        !businessDebit
                          ? "[ btn custom-radio-selected ] "
                          : "[ btn custom-radio-unselected ] "
                      }
                      name="businessDebit"
                      onClick={(event) =>
                        this.handleChoice("businessDebit", false, event)
                      }
                    >
                      {STRINGS.COMMON.CHOICE.NO}
                    </button>
                  </div>
                </div>
                <div className="[ error-msg ]">{businessDebitErrorList[0]}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessDebit.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessDebit.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessDebit;
