import { call, put, takeLatest } from "redux-saga/effects";
import UpdateApplicationRequest from "Communication/ApiClasses/UpdateFormRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleUpdateApplicant(action) {
  yield put(
    doLoadingStart(
      STRINGS.COMMON.API_KEYS.UPDATE_APPLICANT,
      action.data.waitMessage
    )
  );

  const request = action.data;
  delete request.waitMessage;

  const updateApplicationApi = new UpdateApplicationRequest(
    request,
    action.applicationId
  );
  try {
    const result = yield call(updateApplicationApi.getData);
    yield put({
      type: actionTypes.UPDATE_APPLICANT_RESPONSE,
      success: result,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_APPLICANT_ERROR,
      error: error.response?.data.description,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.UPDATE_APPLICANT));
  }
}

// watcher
export default function* handleUpdateApplicantSaga() {
  yield takeLatest(actionTypes.UPDATE_APPLICANT_REQUEST, handleUpdateApplicant);
}
