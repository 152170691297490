import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import * as ROUTES from "Constants/Routes";
import Questionnaire from "./Questionnaire";
import { getLoanProduct } from "../LoanDetails/Actions/LoanDetailsAction";
import { getFIInfo } from "../MarketPlace/Action/MarketPlaceAction";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";
import { getApplicantType } from "../JointApplicant/Actions/JointApplicantAction";
import {
  getOccupationCodes,
  getReasonCodes,
} from "../../Redux/Actions/ApplicationAction";
import { getCentral } from "../../Redux/Actions/LoginAction";
import { addApplicant } from "../Inverite/Actions/InveriteAction";
import doCheckExistingUser from "../Register/Actions/ExistingUserActions";

class QuestionnaireContainer extends Component {
  constructor() {
    super();
    this.state = {
      showCaptcha: true,
    };
  }
  componentDidMount() {
    const { questionnaire, history } = this.props;
    console.log("questionnaire", questionnaire.questionList);
    if (questionnaire.questionList === undefined) {
      history.push(ROUTES.MARKETPLACE);
    }
  }

  hideCaptcha = () => {
    this.setState({ showCaptcha: false });
  };

  render() {
    const {
      history,
      loanProducts,
      finInfo,
      productList,
      doUpdateVaultProductList,
      vaultProductList,
      questionnaire,
      questionnaireProduct,
      doGetProductFromVault,
      doUpdateProductToVault,
      applicantData,
    } = this.props;
    const { showCaptcha } = this.state;
    return (
      <>
        <Questionnaire
          history={history}
          finInfo={finInfo}
          productList={productList}
          doUpdateVaultProductList={doUpdateVaultProductList}
          vaultProductList={vaultProductList}
          loanProducts={loanProducts}
          questionnaire={questionnaire}
          questionnaireProduct={questionnaireProduct}
          doGetProductFromVault={doGetProductFromVault}
          doUpdateProductToVault={doUpdateProductToVault}
          applicantData={applicantData}
          showCaptcha={showCaptcha}
          hideCaptcha={this.hideCaptcha}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doGetLoanProduct: getLoanProduct,
      doGetFinInfo: getFIInfo,
      doGetProvinceList,
      doGetApplicantType: getApplicantType,
      doGetOccupationCodes: getOccupationCodes,
      doGetReasonCodes: getReasonCodes,
      doGetCentralInfo: getCentral,
      doAddApplicant: addApplicant,
      doCheckExistingUser,
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  signInState: state.SigninReducer,
  finInfo: state.MarketplaceReducer.finInfo,
  vaultProductList: state.VaultReducer.vaultProductList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  questionnaire: state.QuestionnaireReducer.questionnaire,
  questionnaireProduct: state.QuestionnaireReducer.product,
  applicantData: state.ApplicationReducer.response,
});

QuestionnaireContainer.propTypes = {
  doUpdateVaultProductList: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doGetProductFromVault: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  questionnaire: PropTypes.oneOfType([PropTypes.object]),
  questionnaireProduct: PropTypes.oneOfType([PropTypes.object]),
};
QuestionnaireContainer.defaultProps = {
  doUpdateVaultProductList: () => {},
  doUpdateProductToVault: () => {},
  doGetProductFromVault: () => {},
  history: {},
  finInfo: {},
  productList: [],
  vaultProductList: [],
  questionnaire: {},
  questionnaireProduct: {},
};
const QuestionnaireContainerWrapper = withVaultHOC(QuestionnaireContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireContainerWrapper);
