import BaseApiRequest from "./BaseApiRequest";
import { VERIFICATIONCODE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class VerificationCodeRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    otpId: "",
    otp: "",
  };

  constructor(userDetail) {
    super();
    this.body.otpId = userDetail.otpId;
    this.body.otp = userDetail.otp;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return VERIFICATIONCODE_URL;
  };

  getBody = () => {
    return this.body;
  };
}
