import { getApiImplementation } from "../BaseApi";

export default class InstntApiRequest {
  getData = () => {
    const accessToken = null;
    return getApiImplementation()
      .callApi(this.getMethod(), this.getUrl(), this.getBody(), accessToken)
      .then((response) => {
        if (!response) {
          throw new Error("response undefined");
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
