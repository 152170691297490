import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddDocument from "Communication/ApiClasses/AddDocumentRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAddDocument(action) {
  yield put(doLoadingStart(STRINGS.DOCUMENT_UPLOAD.API_KEYS.ADD_DOCUMENT));
  const addDocument = new AddDocument(
    action.data.applicationId,
    action.data.productId,
    action.data.payLoad
  );
  console.log(action);
  try {
    const result = yield call(addDocument.getData);
    yield put({
      type: actionTypes.ADD_DOCUMENT_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ADD_DOCUMENT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.DOCUMENT_UPLOAD.API_KEYS.ADD_DOCUMENT));
  }
}

// watcher
function* addDocumenttWatcherSaga() {
  yield takeLatest(actionTypes.ADD_DOCUMENT, handleAddDocument);
}

export default addDocumenttWatcherSaga;
