/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import * as ROUTES from "Constants/Routes";
import { isLogin } from "Utils";

const PrivateRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        isLogin() || !restricted ? (
          <Component
            {...props}
            showVault={rest.showVault}
            showVaultClicked={rest.showVaultClicked}
          />
        ) : (
          <Redirect to={ROUTES.SIGN_IN} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  restricted: PropTypes.bool.isRequired,
  component: PropTypes.elementType,
};
PrivateRoute.defaultProps = { component: {} };

export default PrivateRoute;
