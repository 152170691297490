import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import GetGlobalVault from "Communication/ApiClasses/GetGlobalVault";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleGetApplicantVaultSaga(action) {
  console.log("handleGetApplicantVaultSaga");
  console.log(action);

  yield put(doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.GET_GLOBAL_VAULT));
  const getGlobalVault = new GetGlobalVault(action.data);
  try {
    const result = yield call(getGlobalVault.getData);
    console.log(result);

    if (action.data.product) {
      yield put({
        type: actionTypes.STORE_GET_PRODUCT_TEMPLATE,
        data: action.data.productTemplates,
        product: action.data.product,
      });
    }

    yield put({
      type: actionTypes.GET_GLOBAL_VAULT_SUCCESS,
      response: result,
      formData: { ...action.requestData },
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_GLOBAL_VAULT_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.GET_GLOBAL_VAULT)
    );
  }
}

// watcher
export default function* getApplicantVaultWatcherSaga() {
  yield takeEvery(actionTypes.GET_GLOBAL_VAULT, handleGetApplicantVaultSaga);
}
