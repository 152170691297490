import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: "",
  error: "",
};

const TermsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONSENT_RESPONSE:
      return {
        ...state,
        success: action.success,
        error: "",
      };
    case actionTypes.CONSENT_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
      };

    default:
      return state;
  }
};

export default TermsReducer;
