import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: "",
  error: "",
  beneficiaryTypeList: [],
  beneficiaryRelationList: [],
  beneficiaryId: "",
};

const BeneficiariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BENEFICIARY_TYPE_LIST:
      return {
        ...state,
        beneficiaryTypeList: action.beneficiaryTypeList,
      };

    case actionTypes.GET_BENEFICIARY_TYPE_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case actionTypes.ADD_BENEFICIARY_RELATION_LIST:
      return {
        ...state,
        beneficiaryRelationList: action.beneficiaryRelationList,
      };

    case actionTypes.GET_BENEFICIARY_RELATION_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case actionTypes.ADD_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        beneficiaryId: action.data.beneficiaryId,
      };
    case actionTypes.ADD_BENEFICIARIES_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

export default BeneficiariesReducer;
