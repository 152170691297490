import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import LinkTermComponent from "../TermsAndConditions/LinkTermComponent";
import CheckBoxComponent from "../TermsAndConditions/CheckBoxComponent";
import CardComponent from "../Common/CardComponent/CardComponent";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import { COMMON_BUCKET_URL } from "../../Communication/ApiUrls";

class BeforeWeStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      termsErrorText: "",
      termsAccepted: "",
      checkboxArray: [],
      linkTermsArray: [],
      rendered: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer, jsonData } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BEFORE_WE_START,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate() {
    const { jsonData } = this.props;
    const { rendered } = this.state;
    let documentArray = [];
    const checkboxArray = [];
    const tempArray = [];
    if (jsonData && !rendered) {
      jsonData.map((data) => {
        // Check if object has any documents
        if (data.document) {
          documentArray = data.document;
          documentArray.map((document) => {
            // push the elements to temperory array
            tempArray.push({
              description: document.description,
              filename: document.filename,
            });
            return null;
          });
        }

        // Only accept terms
        if (checkboxArray.length == 0) {
          checkboxArray.push({
            mandatory: data.mandatory,
            description: data.description,
            code: data.code,
            consent: data.consent,
            checked: false,
          });
        }
        return null;
      });

      // set the state variables
      this.setState({
        linkTermsArray: tempArray,
        checkboxArray,
        rendered: true,
      });
    }
  }

  setFormData = () => {};

  handleChange = (choice) => {
    const { handleChange } = this.props;
    handleChange(choice);
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue();
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BEFORE_WE_START);
  };

  verifyData = () => {
    const { checkboxArray, termsAccepted } = this.state;
    let valid = true;
    const error = checkErrors(
      termsAccepted,
      VALIDATIONS.TERMS_AND_CONDITIONS.tearmAndCondition
    );
    if (error.length) {
      valid = false;
      this.setState({ termsErrorText: error });
    }

    // Get all mandatory checkboxes
    const checkboxes = checkboxArray;
    checkboxes.map((data) => {
      if (data.mandatory && !data.checked) {
        valid = false;
      }
      return null;
    });

    if (valid) {
      this.handleContinue();
    } else {
      this.setState({ termsAccepted: "" });
    }
  };

  onChangeCheckBoxHandler = (code, object, checked) => {
    const { checkboxArray } = this.state;
    const index = checkboxArray.map((o) => o.code).indexOf(code);
    let termsAccepted;
    if (checked) {
      checkboxArray[index].checked = true;
      termsAccepted = true;
    } else {
      checkboxArray[index].checked = false;
      termsAccepted = false;
    }
    if (checkboxArray[index].mandatory === true && termsAccepted) {
      this.setState({ checkboxArray, termsAccepted });
    } else {
      this.setState({ termsErrorText: "", termsAccepted: "" });
    }
  };

  linkClickHandler = (fileName, name) => {
    // if filename is valid
    if (fileName) {
      // First reinitialize modal state
      this.setState({ showModal: false }, () => {
        // Initialize variables
        const modal = {};

        // Set data into modal variable
        modal.title = "";
        modal.description = (
          <object
            type="text/html"
            aria-label="Default"
            data={`${COMMON_BUCKET_URL}${fileName}`}
          />
        );

        // set values to state variables
        this.setState({
          showModal: true,
          modal,
          modalType: name,
        });
      });
    }
  };

  render() {
    const {
      choice,
      checkboxArray,
      linkTermsArray,
      evaluate,
      termsErrorText,
    } = this.state;
    const { steps, activeStepID, jsonData } = this.props;
    console.log(steps, activeStepID, jsonData);

    const cardComponentFirst = (
      <div className="[ info-tile__header]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-birthday-cake ]"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="age">{STRINGS.MEMBERCREATION.AGE_TEXT}</div>
        </div>
      </div>
    );
    const cardComponentTwo = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="fab fa-canadian-maple-leaf"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="sin">{STRINGS.MEMBERCREATION.SIN_TEXT}</div>
        </div>
      </div>
    );
    const cardComponentThree = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-address-card ]"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="govtID">{STRINGS.MEMBERCREATION.GOVT_ID}</div>
        </div>
      </div>
    );
    const cardComponentFour = (
      <div className="[ info-tile__header ]">
        <div className="[ info-tile__icon ]">
          <i className="[ fas fa-chart-pie ]"></i>
        </div>
        <div className="[ info-tile__title ]">
          <div htmlFor="membershare">
            {STRINGS.MEMBERCREATION.MEMBERSHARE_TEXT}
          </div>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="[ terms-and-conditions-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BEFORE_WE_START.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row info-items]">
            <CardComponent
              firstComponent={cardComponentFirst}
              secondComponent={STRINGS.MEMBERCREATION.FIRST_CARD_TEXT}
            />
            <CardComponent
              firstComponent={cardComponentTwo}
              secondComponent={STRINGS.MEMBERCREATION.SECOND_CARD_TEXT}
            />
            <CardComponent
              firstComponent={cardComponentThree}
              secondComponent={STRINGS.MEMBERCREATION.THIRD_CARD_TEXT}
            />
            <CardComponent
              firstComponent={cardComponentFour}
              secondComponent={STRINGS.MEMBERCREATION.FOURTH_CARD_TEXT}
            />
          </div>

          <div className="[ divider ]" />
          <div className="[ terms-and-conditions__links-container ]">
            <div className="[ alert alert-info ]" role="alert">
              <h4 className="[ alert-heading ]">
                {STRINGS.MEMBERCREATION.AGREE_TERMS}
              </h4>
              <hr />
              {linkTermsArray.length > 0 && (
                <LinkTermComponent
                  linkTermsArray={linkTermsArray}
                  linkClickHandler={this.linkClickHandler}
                />
              )}
            </div>
          </div>
          <div className="[ divider ]" />
          <div>
            {checkboxArray.map((data) => (
              <CheckBoxComponent
                key={data.code}
                data={data}
                onChangeCheckBoxHandler={this.onChangeCheckBoxHandler}
                evaluate={evaluate}
                error={termsErrorText}
              />
            ))}
            <div>
              <div className="[ mb-3 ]">
                <label>
                  You also understand and agree that some financial applications
                  may require [FI Name] to request a hard credit check. This may
                  or may not impact your credit score. You will be alerted
                  within your application(s) if a hard credit check will be
                  required.
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BeforeWeStart.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  jsonData: PropTypes.arrayOf(PropTypes.object),
};

BeforeWeStart.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  jsonData: {},
};

export default BeforeWeStart;
