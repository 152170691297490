import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import {
  getFinancialAnalysisFinancialList,
  deleteLoanFinancial,
} from "Components/LoanDetails/Actions/LoanDetailsAction";
import Expense from "./Expense";
import {
  addLoanExpense,
  updateLoanExpense,
  getLoanExpense,
} from "./Actions/ExpenseActions";
import {
  getFinancialList,
  getLoanProduct,
} from "../LoanDetails/Actions/LoanDetailsAction";
import { showExpensePage } from "../../Utils/LogicUtilities";

class ExpenseContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: false,
      financialType: "Expense",
      oldExpenseList: null,
      expenseList: [],
      success: true,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      handleBack,
      continuehandler,
      actionBack,
      config,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (showExpensePage(activeProduct, config)) {
      this.initializeLoanDetail();
    } else {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.EXPENSE);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.EXPENSE);
      }
    }
  }

  handleChange = (choice) => {
    console.log(choice);
    const { expenseList } = this.state;
    this.setState({ choice: choice });
    if (choice) {
      expenseList.push({
        expenseType: "",
        description: "",
        monthlyAmount: "",
        adjustment: "",
      });
      this.setState({
        expenseList,
      });
    } else {
      this.setState({
        expenseList: [],
      });
    }
  };

  handleContinue = (expenseList) => {
    console.log("handleContinue");
    const { financialType } = this.state;
    const {
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
    } = this.props;
    const activeProduct = getActiveProduct(products);

    doGetFinancialAnalysisFinancialList(
      {
        applicantId: activeProduct.applicantId,
        finAnalysisId: activeProduct.finAnalysisId,
      },
      (res) => {
        const currentExpenseList = [];
        res.forEach((financial, idx) => {
          if (financial.type === financialType && idx !== 0) {
            currentExpenseList.push({
              expenseType: financial.subType,
              description: financial.description,
              monthlyAmount: financial.monthly,
              financialId: financial.financialId,
            });
          }
        });
        this.processExpense(currentExpenseList, expenseList, activeProduct);
      }
    );
  };

  removeInvalidChars(value) {
    console.log("removeInvalidChars", value, typeof value);
    if (value && typeof value !== "number") {
      return value.split("$").join("").split(",").join("");
    }
    return value;
  }

  updateExpense(activeProduct, oldExpense, newExpense) {
    console.log("updateExpense", oldExpense, newExpense);
    const { doUpdateLoanExpense } = this.props;
    if (
      oldExpense.expenseType !== newExpense.expenseType ||
      oldExpense.description !== newExpense.description ||
      oldExpense.monthlyAmount !== newExpense.monthlyAmount ||
      oldExpense.isPrimaryResidence !== newExpense.isPrimaryResidence
    ) {
      doUpdateLoanExpense(
        activeProduct.applicantId,
        activeProduct.finAnalysisId,
        newExpense.financialId,
        {
          SubType: newExpense.expenseType,
          Description: newExpense.description
            .split("[PrimaryResidence]")
            .join(""),
          MonthlyAmount: this.removeInvalidChars(newExpense.monthlyAmount),
          IsPrimaryResidence: newExpense.isPrimaryResidence,
        },
        (res) => {
          console.log(res);
        }
      );
    }
  }

  parseAmount(str) {
    if (isNaN(str)) {
      const matches = str.match(/(\d+)/);
      if (matches) {
        return matches[0];
      } else {
        return 0;
      }
    } else {
      return str;
    }
  }

  deleteExpense = (activeProduct, expense) => {
    console.log("deleteExpense", expense);
    const { doDeleteLoanFinancial } = this.props;

    if (expense) {
      doDeleteLoanFinancial(
        activeProduct.applicantId,
        activeProduct.finAnalysisId,
        expense.financialId,
        (res) => {}
      );
    }
  };

  processExpense(currentExpenseList, newExpenseList, activeProduct) {
    console.log("processExpense", currentExpenseList, newExpenseList);
    const { continuehandler, doGetApplicationProperty } = this.props;
    let updateCtr = 0;
    newExpenseList.forEach((expense, idx) => {
      if (expense.financialId) {
        const currentExpense = currentExpenseList.find(
          (item) => item.financialId === expense.financialId
        );
        this.updateExpense(activeProduct, currentExpense, expense);
        updateCtr += 1;
      } else {
        this.addExpense(activeProduct, expense);
        updateCtr += 1;
      }
    });

    currentExpenseList.forEach((expense, idx) => {
      const curr = newExpenseList.find(
        (item) => item.financialId === expense.financialId
      );
      if (!curr) {
        this.deleteExpense(activeProduct, expense);
        updateCtr += 1;
      }
    });
    console.log("waiting before continuing", updateCtr);
    doGetApplicationProperty(
      {
        applicationId: activeProduct.applicationId,
        delayTime: updateCtr * 1000,
      },
      () => {
        continuehandler("", AppConstants.APPLICATIONSTEP.EXPENSE);
      }
    );
  }

  addExpense(activeProduct, expense) {
    console.log("addExpense", expense);
    const { doAddLoanExpense } = this.props;
    doAddLoanExpense(
      activeProduct.applicantId,
      activeProduct.finAnalysisId,
      {
        SubType: expense.expenseType,
        Description: expense.description.split("[PrimaryResidence]").join(""),
        MonthlyAmount: this.removeInvalidChars(expense.monthlyAmount),
        IsPrimaryResidence: expense.isPrimaryResidence,
      },
      (res) => {
        console.log(res);
      }
    );
  }

  initializeLoanDetail() {
    const {
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
    } = this.props;
    const { financialType } = this.state;

    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    doGetFinancialAnalysisFinancialList(
      {
        applicantId: activeProduct.applicantId,
        finAnalysisId: activeProduct.finAnalysisId,
      },
      (res) => {
        const expenseList = [];
        res.forEach((financial, idx) => {
          if (financial.type === financialType && idx !== 0) {
            expenseList.push({
              expenseType: financial.subType,
              description: financial.description,
              monthlyAmount: financial.monthly,
              adjustment: financial.adjustment,
              financialId: financial.financialId,
              applicantId: activeProduct.applicantId,
              finAnalysisId: activeProduct.finAnalysisId,
              isPrimaryResidence: financial.isPrimaryResidence,
            });
          }
        });
        console.log(expenseList);
        this.setState({
          expenseList: expenseList,
          choice: expenseList && expenseList.length > 0,
        });
      }
    );
  }

  render() {
    const { choice, expenseList } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      expenseTypes,
    } = this.props;

    return (
      <>
        <Expense
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          deleteExpense={this.deleteExpense}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          expenseList={expenseList}
          expenseTypes={expenseTypes}
        />
      </>
    );
  }
}

ExpenseContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doGetFinancialAnalysisFinancialList: PropTypes.func.isRequired,
};

ExpenseContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  loanProductForm: state.LoanDetailsReducer.loanProductForm,
  finAnalysisId: state.LoanDetailsReducer.finAnalysisId,
  financialAnalysisFinancialList:
    state.LoanDetailsReducer.financialAnalysisFinancialList.data,
  expenseTypes: state.LoanDetailsReducer.expenseTypes,
  actionBack: state.ApplicationReducer.actionBack,
  config: state.LoanDetailsReducer.config,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doAddLoanExpense: (applicantId, finAnalysisId, payLoad, callback) =>
      dispatch(addLoanExpense(applicantId, finAnalysisId, payLoad, callback)),
    doUpdateLoanExpense: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLoanExpense(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
    doGetLoanProduct: (callback) => dispatch(getLoanProduct(callback)),
    doGetLoanExpense: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        getLoanExpense(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    doGetFinancialList: (reqData, callback) =>
      dispatch(getFinancialList(reqData, callback)),
    doDeleteLoanFinancial: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        deleteLoanFinancial(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
  };
};

const ExpenseWrapper = withApplicantHOC(ExpenseContainer);

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseWrapper);
