import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import ForgotPasswordRequest from "../../Communication/ApiClasses/ForgotPasswordRequest";

// worker
function* handleForgotPassword(action) {
  yield put(doLoadingStart(STRINGS.FORGOTPASSWORD.API_KEYS.FORGOT_PASSWORD));
  const forgotPasswordApi = new ForgotPasswordRequest(action.data);
  try {
    const result = yield call(forgotPasswordApi.getData);
    yield put({
      type: actionTypes.FORGOT_PASSWORD_RESPONSE,
      success: result,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.FORGOT_PASSWORD_ERROR,
      error: error.response.data.description,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.FORGOTPASSWORD.API_KEYS.FORGOT_PASSWORD));
  }
}

// watcher
export default function* forgotPasswordWatcherSaga() {
  yield takeLatest(actionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword);
}
