/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GettingStartedGetResidentsRequest from "Communication/ApiClasses/GettingStartedGetResidentsRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGettingStartedResident(action) {
  yield put(doLoadingStart(STRINGS.GETTING_STARTED.API_KEYS.RESIDENT_LIST));
  const residentData = action.data;
  const residentApi = new GettingStartedGetResidentsRequest(residentData);
  try {
    const result = yield call(residentApi.getData);
    yield put({
      type: actionTypes.GETTINGSTARTED_RESIDENT_RESPONSE,
      success: result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GETTINGSTARTED_RESIDENT_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.GETTING_STARTED.API_KEYS.RESIDENT_LIST));
  }
}

// watcher
export function* gettingStartedResidentWatcherSaga() {
  yield takeLatest(
    actionTypes.GETTINGSTARTED_RESIDENT_REQUEST,
    handleGettingStartedResident
  );
}
