import { call, put, takeLatest } from "redux-saga/effects";
import GetFinancialAnalysisFinancialList from "Communication/ApiClasses/GetFinancialAnalysisFinancialList";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetFinancialAnalysisFinancialListtSaga(action) {
  yield put(
    doLoadingStart(
      STRINGS.LOAN_DETAILS.API_KEYS.GET_FINANCIALANALYSYS_FINANCIAL_LIST
    )
  );
  console.log(action);
  const getFinancialAnalysisFinancialList = new GetFinancialAnalysisFinancialList(
    action.data.applicantId,
    action.data.finAnalysisId
  );
  try {
    const result = yield call(getFinancialAnalysisFinancialList.getData);
    yield put({
      type: actionTypes.GET_FINANCIALANALYSYS_FINANCIAL_LIST_SUCCESS,
      response: result.data,
      data: result.data,
      formData: { ...action.requestData },
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_FINANCIALANALYSYS_FINANCIAL_LIST_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(
        STRINGS.LOAN_DETAILS.API_KEYS.GET_FINANCIALANALYSYS_FINANCIAL_LIST
      )
    );
  }
}
// watcher
export default function* getGetFinancialAnalysisFinancialListWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_FINANCIALANALYSYS_FINANCIAL_LIST,
    handleGetFinancialAnalysisFinancialListtSaga
  );
}
