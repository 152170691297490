import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { GET_APPLICANT_BANKING_PRODUCT_LIST_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetApplicantBankingProductList extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  url = GET_APPLICANT_BANKING_PRODUCT_LIST_URL;

  constructor(applicantId) {
    super();
    const paramList = [applicantId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
