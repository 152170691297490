import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import TermsAndConditionsRequest from "Communication/ApiClasses/TermsAndConditionsRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleTerms(action) {
  yield put(doLoadingStart(STRINGS.MEMBERCREATION.API_KEYS.TERMS_LIST));
  const consentData = action.data;
  const consentApi = new TermsAndConditionsRequest(consentData);
  try {
    const result = yield call(consentApi.getData);
    yield put({
      type: actionTypes.CONSENT_RESPONSE,
      success: result,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.CONSENT_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.MEMBERCREATION.API_KEYS.TERMS_LIST));
  }
}

// watcher
export default function* termsWatcherSaga() {
  yield takeLatest(actionTypes.CONSENT_REQUEST, handleTerms);
}
