import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetBeneficiariesApiRequest from "Communication/ApiClasses/GetBeneficiariesApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetBeneficiaries(action) {
  try {
    yield put(doLoadingStart(STRINGS.BENEFICIARIES.API_KEYS.GET_BENEFICIARIES));
    const getBeneficiariesApi = new GetBeneficiariesApiRequest([
      action.applicationId,
      action.contractProductId,
    ]);
    const result = yield call(getBeneficiariesApi.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.GET_BENEFICIARIES_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.BENEFICIARIES.API_KEYS.GET_BENEFICIARIES)
    );
  }
}

// watcher
export default function* getBeneficiaryRelationWatcherSaga() {
  yield takeLatest(actionTypes.GET_BENEFICIARIES, handleGetBeneficiaries);
}
