import * as actionTypes from "Redux/Actions/actionTypes";
import { GET_CENTRAL } from "./actionTypes";

export const setAccessToken = (token) => {
  return {
    type: actionTypes.SET_ACCESS_TOKEN,
    token,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const registration = (userdata) => {
  return {
    type: actionTypes.REGISTRATION,
    userdata,
  };
};

export const getCentral = (token, callback = () => {}) => ({
  type: actionTypes.GET_CENTRAL,
  token,
  callback,
});
