import { GETTING_STARTED_API_KEY } from "../Constants/APIConstants";

export const URLS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BUCKET_URL: process.env.REACT_APP_BUCKET_URL,
  SPECTRUM_ADDRESS_URL: process.env.REACT_APP_SPECTRUM_ADDRESS_URL,
  LOGIN: "login",
  ACCOUNT_CONTEXT: "profile/",
  DOXIM_ACCOUNT_CONTEXT: "institution/",
  DOXIM_DOC_CONTEXT: "document/",
  PAYSAFE_CONTEXT: "fund/",
  MARKETPLACE_CONTEXT: "marketplace/",
  API_VERSION: "v1/",
  REGISTRATION: "register/request",
  VERIFICATIONCODE: "register/confirm",
  EXISTINGUSER: "existing",
  RESET_PASSWORD: "password",
  GET_PASSWORD_CRITERIA: "register/passwordcriteria",
  SIGNIN: "login",
  RECOVERPASSWORD: "recoverpassword/request",
  RECOVERPASSWORD_VERIFICATIONCODE: "recoverpassword/setpassword",
  CONFIRM_APPLY: "application/apply",
  LANGUAGE_TYPE: "language/en/",
  GET_COUNTRY: "countries.json",
  GET_STATUS: "employmentStatus.json",
  GET_INCOME_SOURCES: "incomeSources.json",
  UPDATE_APPLICANT: "applicant/",
  COMPLETE_APPLICANT: "applicant/complete/",
  CONSENT: "consent",
  WHITE_LABEL_RESOURCE_URL: process.env.WHITE_LABEL_RESOURCE_URL,
  TERMS_URL: "termsAndConditionsList.json",
  VAULT_API: "vault/",
  VAULT_NAME: "applicationprogress",
  GETTING_STARTED_VAULT: "gettingstarted",
  GETTING_STARTED_GET_BRANCH: "branchList.json",
  GETTING_STARTED_GET_RESIDENT: "residencyOptions.json",
  TAX_RESIDENCY: "taxResidency.json",
  ACCOUNT_PURPOSE: "accountPurpose.json",
  GET_INDICATE_WHO: "pepRelationship.json",
  SUBMITVERIFY: "submitidverify",
  GET_ID_TYPES: "governmentIdList.json",
  CREATE_APP: "create",
  GET_PROVINCE_LIST: "province.json",
  GET_COUNTRIES_TAX_LIST: "countriesTax.json",
  GET_REFRESH_TOKEN: "login/refresh",
  LOGOUT: "logout",
  INVERITE_CREATE_API: "create",
  INVERITE_CREATE_ACCOUNT_LIST_API: "create/accountlist",
  INVERITE_FETCH_API: "fetch",
  INVERITE_FETCH_ACCOUNT_LIST_API: "fetch/accountlist/%1",
  ACCOUNT_VERIFICATION: "verification/",
  PROCESS: "process",
  GET_FUND_PAYMENT_DETAILS: "FIInfo.json",
  GET_FI_INFO_DETAILS: "FIInfo.json",
  APPLICANT_LIST: "applicant/list",
  SEARCH_MEMBER: "applicant/searchMember",
  KYC_INVITE: "applicant/kycInvite",
  GET_PRODUCT_LIST: "FIProducts.json",
  PRODUCT_VAULT: "productVault",
  PRODUCT_IMAGE_URL: "images/",
  UPDATE_APPLICATION_ID: "application/",
  APPLICATION: "application/%1",
  APPLICATION_LIST: "application/list",
  PRODUCT_TEMPLATE: "productTemplates.json",
  PRODUCT_INFORMATION_API: "application/%1/%2/%3",
  GET_INTENDED_USE: "intended-use.json",
  GET_BENEFICIARY_TYPE: "BeneficiaryType.json",
  GET_BENEFICIARY_RELATION: "BeneficiaryRelation.json",
  OCCUPATION_CODES: "occupationCodes.json",
  EMPLOYER_POSITION_CODES: "employerPosition.json",
  REASON_CODES: "reasonOpenedCodes.json",
  ADD_BENEFICIARIES:
    "application/%1/registeredcontractproduct/%2/beneficiary/add",
  BENEFICIARIES: "application/%1/registeredcontractproduct/%2/beneficiary/%3",
  GET_BENEFICIARIES:
    "application/%1/registeredcontractproduct/%2/beneficiary/list",
  SPOUSAL_INFO_API: "application/%1/registeredcontractproduct/%2",
  APPLICATION_SLOT_ADD: "application/%1/slot/add",
  APPLICATION_SLOT_INVITE: "application/%1/slot/%2/invite",
  APPLICATION_SLOT_DELETE: "application/%1/slot/%2",
  APPLICATION_SLOT_FILL: "application/%1/slot/%2/fill",
  APPLICATION_SLOT_GET_INVITED: "application/slot/invited",
  APPLICATION_PROPERTY_GET: "application/%1/property",
  APPLICANT_TYPE: "applicantType.json",
  BUNDLE_PRODUCT_RELATION: "bundleproduct/%1/relation",
  ADD_BUNDLE_PRODUCT: "application/%1/bundleproduct/add",
  DELETE_DEBIT_CARD: "application/%1/debitcardproduct/%2",
  GLOBAL_VAULT: "globalvault/%1",
  APPLICANT_CREATE_REQUEST: "applicant/create/request",
  APPLICANT_CREATE_SELF: "applicant/create/self",
  APPLICANT_ADD: "applicant/add",
  FIND_APPLICANT: "applicant/find",
  OTP_VERIFY_INVITE: "otp/verify/invite",
  ENABLE_ONLINE_BANKING: "applicant/%1/onlinebanking/enable",
  ESIGN_CREATE: "application/%1/esign/doc/create",
  ESIGN_CHECK: "application/%1/esign/doc/check",
  ESIGN_SEND: "application/%1/esign/package/send",
  ESIGN_GET: "application/%1/esign",
  APPLICANT_CREATE_CONFIRM: "applicant/create/confirm",
  LOAN_PRODUCTS: "loanProducts.json",
  LENDING_PRODUCT: "application/%1/lendingproduct/%2",
  ADD_LOAN_ASSET: "applicant/%1/financialanalysis/%2/asset",
  GET_LOAN_ASSET: "applicant/%1/financialanalysis/%2/asset/%3",
  UPDATE_LOAN_ASSET: "applicant/%1/financialanalysis/%2/asset/%3",
  ADD_LOAN_INCOME: "applicant/%1/financialanalysis/%2/income",
  GET_LOAN_INCOME: "applicant/%1/financialanalysis/%2/income/%3",
  UPDATE_LOAN_INCOME: "applicant/%1/financialanalysis/%2/income/%3",
  ADD_LOAN_EXPENSE: "applicant/%1/financialanalysis/%2/expense",
  GET_LOAN_EXPENSE: "applicant/%1/financialanalysis/%2/expense/%3",
  UPDATE_LOAN_EXPENSE: "applicant/%1/financialanalysis/%2/expense/%3",
  ADD_LOAN_LIABILITIES: "applicant/%1/financialanalysis/%2/liability",
  GET_LOAN_LIABILITIES: "applicant/%1/financialanalysis/%2/liability/%3",
  UPDATE_LOAN_LIABILITIES: "applicant/%1/financialanalysis/%2/liability/%3",
  ADD_LOAN_VEHICLE: "application/%1/vehicle",
  GET_LOAN_VEHICLE: "application/%1/vehicle/%2",
  UPDATE_LOAN_VEHICLE: "application/%1/vehicle/%2",
  ADD_LOAN_REAL_ESTATE: "application/%1/realestate",
  GET_LOAN_REAL_ESTATE: "application/%1/realestate/%2",
  UPDATE_LOAN_REAL_ESTATE: "application/%1/realestate/%2",
  UPDATE_LOAN_SECURITY: "application/%1/lendingproduct/%2/security",
  GET_LOAN_LOAN_SECURITY: "application/%1/security/list",
  DELETE_LOAN_FINANCIAL: "applicant/%1/financialanalysis/%2/financial/%3",
  DELETE_LOAN_LOAN_SECURITY: "application/%1/security/%2",
  GET_FINANCIALANALYSYS_LIST: "applicant/%1/financialanalysis/list",
  GET_FINANCIALANALYSYS_FINANCIAL_LIST:
    "applicant/%1/financialanalysis/%2/financial/list",
  GET_DOCUMENT_LIST: "application/%1/document/list",
  ADD_DOCUMENT: "application/%1/product/%2/add",
  DELETE_DOCUMENT: "application/%1/document/%2",
  APPLICANT_BANKING_PRODUCT_LIST: "applicant/%1/banking/product/list",
  ADDRESS_INSTANCE_KEY: "rest/CreateAddressingInstanceKey/results.json",
  GET_ADDRESS: "rest/GlobalTypeAheadUSCAN/results.json",
  SUPPORT_MESSAGE: "support/message",
  TERM_PRODUCT: "application/%1/termdepositproduct/%2",
  DEMAND_PRODUCT: "application/%1/demandproduct/%2",
  CONTRACT: "application/%1/%2/%3",
  CENTRAL: "central/%1",
  ESC_SEARCH: "esc/search",
  ESC_REQUEST: "esc/request",
  ESC_PROFILE: "esc/profile",
  SUBMIT_QUESTIONNAIRE: "questionnaire",
  GET_PROSPECT: "applicant/prospect",
  VAULT_MEMBER: "member",
  PERSONAL: "personal",
};

const COMMON_BASE_URL = URLS.BASE_URL + URLS.ACCOUNT_CONTEXT + URLS.API_VERSION;
const INVERITE_BASE_URL =
  URLS.BASE_URL + URLS.ACCOUNT_VERIFICATION + URLS.API_VERSION;
const COMMON_DOXIM_URL =
  URLS.BASE_URL + URLS.DOXIM_ACCOUNT_CONTEXT + URLS.API_VERSION;
const COMMON_DOXIM_DOC_URL =
  URLS.BASE_URL + URLS.DOXIM_DOC_CONTEXT + URLS.API_VERSION;

const COMMON_PAYSAFE_URL =
  URLS.BASE_URL + URLS.PAYSAFE_CONTEXT + URLS.API_VERSION;
const COMMON_MARKETPLACE_URL =
  URLS.BASE_URL + URLS.MARKETPLACE_CONTEXT + URLS.API_VERSION;

export const COMMON_BUCKET_URL = URLS.BUCKET_URL + URLS.LANGUAGE_TYPE;

export const SIGNIN_URL = COMMON_BASE_URL + URLS.SIGNIN;

export const REGISTER_URL = COMMON_BASE_URL + URLS.REGISTRATION;

export const EXISTINGUSER_URL = COMMON_BASE_URL + URLS.EXISTINGUSER;

export const VERIFICATIONCODE_URL = COMMON_BASE_URL + URLS.VERIFICATIONCODE;

export const RECOVERPASSWORD_URL = COMMON_BASE_URL + URLS.RECOVERPASSWORD;

export const GET_PASSWORD_CRITERIA_URL =
  COMMON_BASE_URL + URLS.GET_PASSWORD_CRITERIA;

export const RECOVERPASSWORD_VERIFICATIONCODE_URL =
  COMMON_BASE_URL + URLS.RECOVERPASSWORD_VERIFICATIONCODE;

export const RESET_PASSWORD_URL = COMMON_BASE_URL + URLS.RESET_PASSWORD;

export const GET_COUNTRY_URL = COMMON_BUCKET_URL + URLS.GET_COUNTRY;

export const GET_STATUS_URL = COMMON_BUCKET_URL + URLS.GET_STATUS;

export const GET_INCOME_SOURCES_URL =
  COMMON_BUCKET_URL + URLS.GET_INCOME_SOURCES;

export const UPDATE_APPLICANT_URL = COMMON_DOXIM_URL + URLS.UPDATE_APPLICANT;

export const GETTING_STARTED_URL =
  COMMON_BASE_URL + URLS.VAULT_API + GETTING_STARTED_API_KEY;

export const CONSENT_URL = COMMON_BASE_URL + URLS.CONSENT;

export const TERMS_BUCKET_URL = COMMON_BUCKET_URL + URLS.TERMS_URL;

export const GETTING_STARTED_GET_BRANCHES =
  URLS.BUCKET_URL + URLS.LANGUAGE_TYPE + URLS.GETTING_STARTED_GET_BRANCH;

export const GETTING_STARTED_GET_RESIDENT =
  URLS.BUCKET_URL + URLS.LANGUAGE_TYPE + URLS.GETTING_STARTED_GET_RESIDENT;

export const GET_ACCOUNT_PURPOSE_URL = COMMON_BUCKET_URL + URLS.ACCOUNT_PURPOSE;

export const GET_INDICATE_WHO = COMMON_BUCKET_URL + URLS.GET_INDICATE_WHO;

export const GET_VAULT = COMMON_BASE_URL + URLS.VAULT_API + URLS.VAULT_NAME;

export const GET_GETTING_STARTED_VAULT =
  COMMON_BASE_URL + URLS.VAULT_API + URLS.GETTING_STARTED_VAULT;

export const GET_APPLICANT_DETAILS = COMMON_DOXIM_URL + URLS.UPDATE_APPLICANT;

export const GET_COMPLETE_APPLICANT_DETAILS =
  COMMON_DOXIM_URL + URLS.COMPLETE_APPLICANT;

export const GET_ID_TYPES = COMMON_BUCKET_URL + URLS.GET_ID_TYPES;

export const SUBMIT_VERIFY = `${URLS.BASE_URL}identity/${URLS.API_VERSION}${URLS.SUBMITVERIFY}`;

export const CREATE_APPLICATION = COMMON_DOXIM_URL + URLS.CREATE_APP;

export const CONFIRM_APPLY_URL = COMMON_DOXIM_URL + URLS.CONFIRM_APPLY;

export const GENERATE_ACCESS_TOKEN = COMMON_BASE_URL + URLS.GET_REFRESH_TOKEN;

export const GET_PROVINCE_LIST = COMMON_BUCKET_URL + URLS.GET_PROVINCE_LIST;

export const GET_COUNTRIES_TAX_LIST =
  COMMON_BUCKET_URL + URLS.GET_COUNTRIES_TAX_LIST;

export const LOAN_ADD_ASSET_URL = COMMON_DOXIM_URL + URLS.ADD_LOAN_ASSET;

export const LOAN_GET_ASSET_URL = COMMON_DOXIM_URL + URLS.GET_LOAN_ASSET;

export const LOAN_UPDATE_ASSET_URL = COMMON_DOXIM_URL + URLS.UPDATE_LOAN_ASSET;

export const LOAN_ADD_INCOME_URL = COMMON_DOXIM_URL + URLS.ADD_LOAN_INCOME;

export const LOAN_GET_INCOME_URL = COMMON_DOXIM_URL + URLS.GET_LOAN_INCOME;

export const LOAN_UPDATE_INCOME_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_LOAN_INCOME;

export const LOAN_ADD_EXPENSE_URL = COMMON_DOXIM_URL + URLS.ADD_LOAN_EXPENSE;

export const LOAN_GET_EXPENSE_URL = COMMON_DOXIM_URL + URLS.GET_LOAN_EXPENSE;

export const LOAN_UPDATE_EXPENSE_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_LOAN_EXPENSE;

export const LOAN_ADD_LIABILITIES_URL =
  COMMON_DOXIM_URL + URLS.ADD_LOAN_LIABILITIES;

export const LOAN_GET_LIABILITIES_URL =
  COMMON_DOXIM_URL + URLS.GET_LOAN_LIABILITIES;

export const LOAN_UPDATE_LIABILITIES_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_LOAN_LIABILITIES;

export const LOAN_ADD_VEHICLE_URL = COMMON_DOXIM_URL + URLS.ADD_LOAN_VEHICLE;

export const LOAN_GET_VEHICLE_URL = COMMON_DOXIM_URL + URLS.GET_LOAN_VEHICLE;

export const LOAN_UPDATE_VEHICLE_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_LOAN_VEHICLE;

export const LOAN_ADD_REAL_ESTATE_URL =
  COMMON_DOXIM_URL + URLS.ADD_LOAN_REAL_ESTATE;

export const LOAN_GET_REAL_ESTATE_URL =
  COMMON_DOXIM_URL + URLS.GET_LOAN_REAL_ESTATE;

export const LOAN_UPDATE_REAL_ESTATE_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_LOAN_REAL_ESTATE;

export const LOAN_UPDATE_SECURITY_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_LOAN_SECURITY;

export const LOAN_GET_LOAN_SECURITY_URL =
  COMMON_DOXIM_URL + URLS.GET_LOAN_LOAN_SECURITY;

export const LOAN_DELETE_FINANCIAL_URL =
  COMMON_DOXIM_URL + URLS.DELETE_LOAN_FINANCIAL;

export const LOAN_DELETE_LOAN_SECURITY_URL =
  COMMON_DOXIM_URL + URLS.DELETE_LOAN_LOAN_SECURITY;

export const INSTNT_URLS = {
  INSTNT_BASE_URL: process.env.REACT_APP_INSTNT_URL,
  GET_FORM_CODE: "getformcodes/",
  INSTNT_ID: process.env.REACT_APP_INSTNT_KEY,
  DOCUMENT_VERIFY: "docverify/authenticate/",
  DOCUMENT_VERIFY_VERSION: "v1.0",
  INSTNT_VERIFY: "/public/transactions/%1/attachments/verify/",
};

export const INSTNT_GET_FORM =
  INSTNT_URLS.INSTNT_BASE_URL +
  INSTNT_URLS.GET_FORM_CODE +
  INSTNT_URLS.INSTNT_ID;

export const DOCUMENT_VERIFY =
  INSTNT_URLS.INSTNT_BASE_URL + INSTNT_URLS.INSTNT_VERIFY;

export const LOGOUT_URL = COMMON_BASE_URL + URLS.LOGOUT;

export const INVERITE_CREATE_API_URL =
  INVERITE_BASE_URL + URLS.INVERITE_CREATE_API;
export const INVERITE_CREATE_ACCOUNT_LIST_API_URL =
  INVERITE_BASE_URL + URLS.INVERITE_CREATE_ACCOUNT_LIST_API;
export const INVERITE_FETCH_API_URL =
  INVERITE_BASE_URL + URLS.INVERITE_FETCH_API;
export const INVERITE_FETCH_ACCOUNT_LIST_API_URL =
  INVERITE_BASE_URL + URLS.INVERITE_FETCH_ACCOUNT_LIST_API;

export const PROCESS_URL = COMMON_PAYSAFE_URL + URLS.PROCESS;

export const GET_FUND_PAYMENT_DETAILS_URL =
  COMMON_BUCKET_URL + URLS.GET_FUND_PAYMENT_DETAILS;

export const GET_FI_INFO_URL = COMMON_BUCKET_URL + URLS.GET_FI_INFO_DETAILS;

export const GET_APPLICANT_LIST_URL = COMMON_DOXIM_URL + URLS.APPLICANT_LIST;

export const SEARCH_MEMBER_URL = COMMON_DOXIM_URL + URLS.SEARCH_MEMBER;

export const KYC_INVITE_URL = COMMON_DOXIM_URL + URLS.KYC_INVITE;

export const GET_ACCOUNT_DETAILS = COMMON_BASE_URL;

export const GET_PRODUCT_LIST_URL = COMMON_BUCKET_URL + URLS.GET_PRODUCT_LIST;

export const PRODUCT_VAULT_URL =
  COMMON_BASE_URL + URLS.VAULT_API + URLS.PRODUCT_VAULT;

export const VAULT_URL = COMMON_BASE_URL + URLS.VAULT_API;

export const VAULT_MEMBER_URL =
  COMMON_BASE_URL + URLS.VAULT_API + URLS.VAULT_MEMBER;
export const PRODUCT_IMAGE_URL = URLS.BUCKET_URL + URLS.PRODUCT_IMAGE_URL;

export const UPDATE_APPLICATION_ID_URL =
  COMMON_DOXIM_URL + URLS.UPDATE_APPLICATION_ID;

export const APPLICATION_LIST_URL = COMMON_DOXIM_URL + URLS.APPLICATION_LIST;

export const GET_PRODUCT_TEMPLATE = COMMON_BUCKET_URL + URLS.PRODUCT_TEMPLATE;

export const UPDATE_PRODUCT_INFORMATION_URL =
  COMMON_DOXIM_URL + URLS.PRODUCT_INFORMATION_API;

export const GET_INTENDED_USE_URL = COMMON_BUCKET_URL + URLS.GET_INTENDED_USE;

export const GET_PRODUCT_INFORMATION_URL =
  COMMON_DOXIM_URL + URLS.PRODUCT_INFORMATION_API;

export const GET_BENEFICIARY_TYPE_URL =
  COMMON_BUCKET_URL + URLS.GET_BENEFICIARY_TYPE;

export const GET_BENEFICIARY_RELATION_URL =
  COMMON_BUCKET_URL + URLS.GET_BENEFICIARY_RELATION;

export const ADD_BENEFICIARIES_URL = COMMON_DOXIM_URL + URLS.ADD_BENEFICIARIES;

export const GET_BENEFICIARIES_URL = COMMON_DOXIM_URL + URLS.GET_BENEFICIARIES;

export const MANIPULATE_BENEFICIARIES_URL =
  COMMON_DOXIM_URL + URLS.BENEFICIARIES;
export const SET_SPOUSAL_INFO_URL = COMMON_DOXIM_URL + URLS.SPOUSAL_INFO_API;

export const GET_SPOUSAL_INFORMATION_URL =
  COMMON_DOXIM_URL + URLS.SPOUSAL_INFO_API;

export const APPLICATION_SLOT_DELETE_URL =
  COMMON_DOXIM_URL + URLS.APPLICATION_SLOT_DELETE;
export const APPLICATION_SLOT_ADD_URL =
  COMMON_DOXIM_URL + URLS.APPLICATION_SLOT_ADD;
export const APPLICATION_SLOT_INVITE_URL =
  COMMON_DOXIM_URL + URLS.APPLICATION_SLOT_INVITE;
export const APPLICATION_SLOT_GET_INVITED_URL =
  COMMON_DOXIM_URL + URLS.APPLICATION_SLOT_GET_INVITED;
export const APPLICATION_PROPERTY_GET_URL =
  COMMON_DOXIM_URL + URLS.APPLICATION_PROPERTY_GET;
export const APPLICATION_SLOT_FILL_URL =
  COMMON_DOXIM_URL + URLS.APPLICATION_SLOT_FILL;
export const GET_APPLICANT_TYPE_URL = COMMON_BUCKET_URL + URLS.APPLICANT_TYPE;
export const GET_BUNDLE_PRODUCT_RELATION_URL =
  COMMON_MARKETPLACE_URL + URLS.BUNDLE_PRODUCT_RELATION;
export const ADD_BUNDLE_PRODUCT_URL =
  COMMON_DOXIM_URL + URLS.ADD_BUNDLE_PRODUCT;
export const DELETE_DEBIT_CARD_URL = COMMON_DOXIM_URL + URLS.DELETE_DEBIT_CARD;
export const GLOBAL_VAULT_ADD_URL = COMMON_BASE_URL + URLS.GLOBAL_VAULT;
export const GLOBAL_VAULT_GET_URL = COMMON_BASE_URL + URLS.GLOBAL_VAULT;
export const APPLICANT_CREATE_REQUEST_URL =
  COMMON_DOXIM_URL + URLS.APPLICANT_CREATE_REQUEST;
export const APPLICANT_CREATE_SELF_URL =
  COMMON_DOXIM_URL + URLS.APPLICANT_CREATE_SELF;
export const APPLICANT_ADD_URL = COMMON_DOXIM_URL + URLS.APPLICANT_ADD;
export const FIND_APPLICANT_URL = COMMON_DOXIM_URL + URLS.FIND_APPLICANT;
export const OTP_VERIFY_INVITE_URL = COMMON_BASE_URL + URLS.OTP_VERIFY_INVITE;
export const ENABLE_ONLINE_BANKING_PASSWORD_URL =
  COMMON_DOXIM_URL + URLS.ENABLE_ONLINE_BANKING;
export const ESIGN_CREATE_URL = COMMON_DOXIM_URL + URLS.ESIGN_CREATE;
export const ESIGN_CHECK_URL = COMMON_DOXIM_URL + URLS.ESIGN_CHECK;
export const ESIGN_SEND_URL = COMMON_DOXIM_URL + URLS.ESIGN_SEND;
export const ESIGN_GET_URL = COMMON_DOXIM_URL + URLS.ESIGN_GET;
export const APPLICANT_CREATE_CONFIRM =
  COMMON_DOXIM_URL + URLS.APPLICANT_CREATE_CONFIRM;
export const GET_LOAN_PRODUCTS = COMMON_BUCKET_URL + URLS.LOAN_PRODUCTS;
export const FINANCIALANALYSYS_LIST_URL =
  COMMON_DOXIM_URL + URLS.GET_FINANCIALANALYSYS_LIST;
export const FINANCIALANALYSYS_FINANCIAL_LIST =
  COMMON_DOXIM_URL + URLS.GET_FINANCIALANALYSYS_FINANCIAL_LIST;

export const LENDING_PRODUCT_URL = COMMON_DOXIM_URL + URLS.LENDING_PRODUCT;

export const GET_DOCUMENT_LIST_URL = COMMON_DOXIM_URL + URLS.GET_DOCUMENT_LIST;

export const ADD_DOCUMENT_URL = COMMON_DOXIM_DOC_URL + URLS.ADD_DOCUMENT;

export const DELETE_DOCUMENT_URL = COMMON_DOXIM_URL + URLS.DELETE_DOCUMENT;

export const GET_APPLICANT_BANKING_PRODUCT_LIST_URL =
  COMMON_DOXIM_URL + URLS.APPLICANT_BANKING_PRODUCT_LIST;

export const GET_ADDRESS_INSTANCE_KEY_URL =
  URLS.SPECTRUM_ADDRESS_URL + URLS.ADDRESS_INSTANCE_KEY;

export const GET_ADDRESS_URL = URLS.SPECTRUM_ADDRESS_URL + URLS.GET_ADDRESS;
export const SUPPORT_MESSAGE_URL = COMMON_BASE_URL + URLS.SUPPORT_MESSAGE;
export const GET_OCCUPATION_CODES = COMMON_BUCKET_URL + URLS.OCCUPATION_CODES;
export const GET_REASON_CODES = COMMON_BUCKET_URL + URLS.REASON_CODES;
export const GET_EMPLOYER_POSITION_CODES =
  COMMON_BUCKET_URL + URLS.EMPLOYER_POSITION_CODES;

export const TERM_PRODUCT_URL = COMMON_DOXIM_URL + URLS.TERM_PRODUCT;

export const GET_APPLICATION_URL = COMMON_DOXIM_URL + URLS.APPLICATION;
export const GET_CONTRACT_URL = COMMON_DOXIM_URL + URLS.CONTRACT;
export const UPDATE_APPLICATION_URL = COMMON_DOXIM_URL + URLS.APPLICATION;
export const UPDATE_CONTRACT_URL = COMMON_DOXIM_URL + URLS.CONTRACT;

export const CENTRAL_URL = COMMON_BASE_URL + URLS.CENTRAL;

export const ESC_SEARCH_URL = COMMON_BASE_URL + URLS.ESC_SEARCH;
export const ESC_REQUEST_URL = COMMON_BASE_URL + URLS.ESC_REQUEST;
export const ESC_PROFILE_URL = COMMON_BASE_URL + URLS.ESC_PROFILE;

export const QUESTIONNAIRE_URL = COMMON_BASE_URL + URLS.SUBMIT_QUESTIONNAIRE;
export const QUESTIONNAIRE_UPLOAD_URL =
  COMMON_DOXIM_URL + URLS.SUBMIT_QUESTIONNAIRE;

export const GET_PROSPECT_URL = COMMON_DOXIM_URL + URLS.GET_PROSPECT;

export const UPDATE_PERSONAL_URL = COMMON_BASE_URL + URLS.PERSONAL;
