import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import { HTTP_STATUS, S_APPLICANT_ID } from "Communication/Constants";

import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";

import Inverite from "Components/Inverite/Inverite";
import ExistingMember from "./ExistingMember";
import { LENDING_PRODUCT } from "../../Communication/Constants";
import { getProductInfo } from "../../Utils/CommonUtilities";
import {
  updateProductToVault,
  updateVaultProductList,
} from "../MarketPlace/Action/MarketPlaceAction";

class ExistingMemberContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showModal: false,
      iframeModal: false,
      showRequired: false,
      requiredList: null,
      iframeUrl: "",
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      showVaultLimitError: false,
    };
  }

  componentDidMount() {
    const { doGetProductFromVault, products } = this.props;

    if (products.length <= 0) {
      doGetProductFromVault((response) => {
        if (
          !response ||
          response.data?.length <= 0 ||
          sessionStorage.getItem(S_APPLICANT_ID)
        ) {
          const { history } = this.props;
          history.push(ROUTES.VAULT_DASHBOARD);
        }
      });
    }
  }

  showModal = (
    title = MODALMESSAGES.EXISTINGMEMBER.SUCCESS_TITLE,
    description = MODALMESSAGES.EXISTINGMEMBER.SUCCESS_MSG
  ) => {
    this.setState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.INFORMATION,
      modal: {
        title: title,
        description: description,
      },
    });
  };

  processRequiredProductsForNewApplicant = () => {
    console.log("processRequiredProductsForNewApplicant");
    const { history, doGetAcoountDetails, products } = this.props;
    const activeProduct = products[0];
    if (activeProduct.required && activeProduct.required.length > 0) {
      this.setState({
        showRequired: true,
        requiredList: activeProduct.required,
      });
    } else {
      doGetAcoountDetails((accountRes) => {
        if (accountRes.data.consent === null) {
          history.push(ROUTES.MEMBER_CREATION);
        } else {
          if (
            activeProduct.bankingType === AppConstants.BANKING_TYPE.BUSINESS
          ) {
            history.push(ROUTES.BUSINESS_TYPE);
          } else {
            history.push(ROUTES.GETTING_STARTED);
          }
        }
      });
    }
  };

  gotoMarketplace = () => {
    const { history } = this.props;
    history.push(ROUTES.VAULT_DASHBOARD + "?marketplace");
  };

  handleContinue = (choice) => {
    if (!choice) {
      const {
        history,
        doGetAcoountDetails,
        inSessionJointApplicant,
      } = this.props;
      if (inSessionJointApplicant) {
        history.push(ROUTES.MEMBER_CREATION);
      } else {
        this.processRequiredProductsForNewApplicant();
      }
    } else {
      const { doCreateInverite, finInfo } = this.props;
      if (finInfo.allowExistingMember) {
        doCreateInverite((response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.setState({
              iframeModal: true,
              iframeUrl: response.data.iframeUrl,
            });
          }
        });
      } else {
        this.showModal("", finInfo.existingMemberMessage);
      }
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: false,
      iframeModal: false,
      showRequired: false,
    });
  };

  addProduct = (productId) => {
    console.log("addProduct", productId);
    const {
      loanProducts,
      finInfo,
      products,
      productList,
      doUpdateProductToVault,
      history,
      bankingType,
    } = this.props;
    if (products && products.length >= finInfo.vaultLimit) {
      this.setState({
        showVaultLimitError: true,
      });
    }

    const selectedProduct = getProductInfo(productId, productList);
    console.log("selectedProduct", selectedProduct, this.props);
    const product = {
      ...selectedProduct,
      // don't store product details as it makes the data too big
      productDetails: null,
      active: true,
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
      },
      bankingType,
    };
    const rand = Math.floor(Math.random() * 1000);
    product.productIndex = rand;

    if (product.type === LENDING_PRODUCT) {
      const loanProduct = loanProducts.find(
        (item) => product.productId === item.id
      );
      product.category = loanProduct.category;
      product.subCategory = loanProduct.subCategory;
      product.productType = loanProduct.productType;
      product.productCode = loanProduct.productCode;
      product.cutoff = loanProduct.cutoff;
      product.applicationType = loanProduct.applicationType;
    } else {
      product.category = "";
      product.subCategory = "";
    }

    const newProductList = [product].concat(products);
    if (newProductList.length > 1) {
      newProductList[1].active = false;
    }

    doUpdateProductToVault(newProductList, () => {
      history.push(ROUTES.MEMBER_CREATION);
    });
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      iframeModal,
      iframeUrl,
      choice,
      showRequired,
      requiredList,
    } = this.state;
    const { productList } = this.props;

    return (
      <>
        <ExistingMember handleContinue={this.handleContinue} choice={choice} />
        {showRequired && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showRequired}
            btnText="Go To Marketplace"
            popupBtnClick={this.gotoMarketplace}
            requiredList={requiredList}
            productList={productList}
            addProduct={this.addProduct}
          />
        )}
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
        {iframeModal && (
          <Inverite
            iframeUrl={iframeUrl}
            iframeModal={iframeModal}
            toggleModal={this.toggleModal}
            showModal={iframeModal}
            popupBtnClick={this.toggleModal}
          />
        )}
      </>
    );
  }
}

ExistingMemberContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetAcoountDetails: PropTypes.func.isRequired,
  doCreateInverite: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  inSessionJointApplicant: PropTypes.object,
};

ExistingMemberContainer.defaultProps = {
  history: null,
  products: [],
  inSessionJointApplicant: null,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  productList: state.MarketplaceReducer.productList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  finInfo: state.MarketplaceReducer.finInfo,
  bankingType: state.MarketplaceReducer.bankingType,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingMemberContainer);
