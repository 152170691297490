import BaseApiRequest from "./BaseApiRequest";
import { SUPPORT_MESSAGE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class SupportMessageRequests extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    message: "",
  };

  url = SUPPORT_MESSAGE_URL;

  constructor(message) {
    super();

    this.body.message = message;
    console.log("SupportMessageRequests", this.body, this.url);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
