import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { CENTRAL_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetCentralInfo extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  url = CENTRAL_URL;

  constructor(token) {
    super();
    const paramList = [token];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
